import * as ui from "@material-ui/core";
import { definitions } from "../schema/api";

const labels: { [key: string]: string } = {
  all: "すべて",
  client: "管理者選択",
};

interface ClientSelectorProps {
  all?: boolean;
  clients: definitions["Client"][];
  selectedValue: number;
  onChange: (e: any) => void;
}

function clientsToMenuItems(clients: definitions["Client"][]): JSX.Element[] {
  const doms = [];
  for (let i = 0; i < clients.length; i += 1) {
    const client = clients[i];
    doms.push(
      <ui.MenuItem key={`client${client.id}`} value={client.id}>
        {client.name}
      </ui.MenuItem>
    );
  }
  return doms;
}

function ClientSelector(props: ClientSelectorProps) {
  return (
    <ui.Box>
      <ui.InputLabel id="client-id-label">{labels.client}</ui.InputLabel>
      <ui.Select
        fullWidth
        labelId="client-id-label"
        id="client-id"
        name="client_id"
        value={props.selectedValue || 0}
        onChange={props.onChange}
      >
        {props.all && (
          <ui.MenuItem key={`client-all`} value={0}>
            {labels.all}
          </ui.MenuItem>
        )}
        {clientsToMenuItems(props.clients)}
      </ui.Select>
    </ui.Box>
  );
}

export { ClientSelector };
