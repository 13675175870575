import { useContext } from "react";
import * as ui from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import ArrowDropUp from "@material-ui/icons/ArrowDropUp";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import { Context, Sort, sortType, ReducerActionPayload, RecordTypeCode, RecordType } from "../context/DataStoreContext";

const labels: { [key: string]: string } = {
  notFound: "データが見つかりませんでした",
  displayCount: "表示件数",
  all: "全",
  dataUnit: "件",
};

const defaultItemsPerPage = 30;
const defaultPerPageCandidates = [30, 50, 100];

type RecordListProps<T extends RecordTypeCode> = {
  type: T;
  items?: RecordType<T>[];
  cols: { label: string; key: keyof RecordType<T> | "control" }[];
  defaultText?: string;
  onRowClicked?: (item: RecordType<T>) => void;
  customStyle?: (item: RecordType<T>) => { [key: string]: string } | undefined;
  onPageChanged?: (page: number) => void;
  extraKeyItemCallbacks?: { [key: string]: (item: RecordType<T>) => JSX.Element };
  perPageCandidates?: number[];
  sort?: boolean;
};

function RecordList<T extends RecordTypeCode>(props: RecordListProps<T>) {
  const { state: contextState, dispatch } = useContext(Context);
  const listParamter = contextState?.[props.type].listParameter;
  const items = props.items || listParamter?.list || [];
  const page = listParamter?.page || 1;
  const itemsPerPage = listParamter?.perPage || defaultItemsPerPage;
  const sorts = listParamter?.sorts || [];

  const perPageCandidates = props.perPageCandidates
    ? [...props.perPageCandidates]
    : [...defaultPerPageCandidates];

  if (
    itemsPerPage &&
    perPageCandidates.indexOf(itemsPerPage) === -1
  ) {
    for (let i = 0; i < perPageCandidates.length; i += 1) {
      if (perPageCandidates[i] > itemsPerPage) {
        perPageCandidates.splice(i, 0, itemsPerPage);
        break;
      }
    }
  }

  const toggleSort = (reportKey: (typeof props.cols)[number]["key"]) => {
    const newSorts = [...sorts] as Sort<RecordType<T>>[];
    const sortIndex = newSorts.findIndex(({ key }) => key === reportKey);
    const sort = (sortIndex > -1 ? newSorts.splice(sortIndex, 1).shift() : { key: reportKey, order: sortType.desc }) as Sort<RecordType<T>>;
    sort.order = sort.order === sortType.asc ? sortType.desc : sortType.asc;
    newSorts.push(sort);
    dispatch && dispatch({ type: "SET_LIST_SORTS", payload: { type: props.type, sorts: newSorts } as ReducerActionPayload["SET_LIST_SORTS"] });
  };

  const onPaginated = (e: React.ChangeEvent<unknown>, page: number) => {
    dispatch && dispatch({ type: "SET_LIST_OPTION", payload: { type: props.type, parameter: { page } } as ReducerActionPayload["SET_LIST_OPTION"] });
    props.onPageChanged && props.onPageChanged(page);
  };

  const onChangePerPage = (itemsPerPage: number) => {
    dispatch && dispatch({ type: "SET_LIST_OPTION", payload: { type: props.type, parameter: { perPage: itemsPerPage } } as ReducerActionPayload["SET_LIST_OPTION"] });
  };

  let itemKey = 0;

  return (
    <>
      <ui.Grid
        container
        item
        xs={12}
        justifyContent="flex-end"
        alignItems="center"
        style={{ paddingBottom: "8px" }}
      >
        <ui.Grid
          container
          item
          spacing={2}
          justifyContent="flex-end"
          alignItems="center"
        >
          <ui.Grid item>
            <ui.Typography variant="caption">
              {labels.displayCount}
            </ui.Typography>
          </ui.Grid>
          {perPageCandidates.map((count) => (
            <ui.Grid item key={`perpage-${count}`}>
              {count === itemsPerPage ? (
                <b>{count}</b>
              ) : (
                <ui.Link
                  style={{ cursor: "pointer" }}
                  onClick={() => onChangePerPage(count)}
                >
                  {count}
                </ui.Link>
              )}
            </ui.Grid>
          ))}
          <ui.Grid item>
            <ui.Typography variant="caption">|</ui.Typography>
          </ui.Grid>
          <ui.Grid item>
            <Pagination
              count={
                Math.ceil(items.length / itemsPerPage) as number
              }
              page={page}
              onChange={onPaginated}
            />
          </ui.Grid>
          <ui.Grid item>
            <ui.Typography variant="caption">
              {`${labels.all} ${items.length} ${labels.dataUnit}`}{" "}
            </ui.Typography>
          </ui.Grid>
        </ui.Grid>
      </ui.Grid>
      <ui.TableContainer component={ui.Paper}>
        <ui.Table stickyHeader aria-label="record-list-table">
          <ui.TableHead>
            <ui.TableRow>
              {props.cols.map((col) => (
                <ui.TableCell key={`head-${col.key as string}`}>
                  {col.label}
                  {col.label && (props.sort || props.sort === undefined) && (
                    <ui.IconButton
                      style={{ paddingBottom: "4px", paddingTop: "4px" }}
                      onClick={() => toggleSort(col.key)}
                    >
                      {sorts.find(({ key }) => key === col.key)?.order === sortType.asc ? (
                        <ArrowDropDown />
                      ) : (
                        <ArrowDropUp />
                      )}
                    </ui.IconButton>
                  )}
                </ui.TableCell>
              ))}
            </ui.TableRow>
          </ui.TableHead>
          <ui.TableBody>
            {items.length === 0 ? (
              <ui.TableRow key="0">
                <ui.TableCell>
                  {props.defaultText || labels.notFound}
                </ui.TableCell>
              </ui.TableRow>
            ) : (
              items
                .slice(
                  (page - 1) * itemsPerPage,
                  (page - 1) * itemsPerPage + itemsPerPage
                )
                .map((item) => {
                  itemKey += 1;
                  let style = {
                    cursor: props.onRowClicked ? "pointer" : "default",
                  };
                  if (props.customStyle) {
                    const customStyle = props.customStyle(item);
                    if (customStyle) {
                      style = Object.assign(style, customStyle);
                    }
                  }
                  return (
                    <ui.TableRow
                      key={itemKey}
                      hover={!!props.onRowClicked}
                      style={style}
                      onClick={(e: any) =>
                        props.onRowClicked && props.onRowClicked(item)
                      }
                    >
                      {props.cols.map((col) => (
                        <ui.TableCell key={`row-${col.key as string}-${itemKey}`}>
                          {props.extraKeyItemCallbacks &&
                          props.extraKeyItemCallbacks[col.key as string]
                            ? props.extraKeyItemCallbacks[col.key as string](item)
                            : (item as any)[col.key]}
                        </ui.TableCell>
                      ))}
                    </ui.TableRow>
                  );
                })
            )}
          </ui.TableBody>
        </ui.Table>
      </ui.TableContainer>
    </>
  );
}

export { RecordList };
