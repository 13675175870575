import react from "react";
import * as ui from "@material-ui/core";
import * as auth from "../../auth";
import * as rest from "../../rest";
import * as datetime from "../../datetime";
import { definitions } from "../../schema/api";
import { typeValueToLabel } from "../../const/report_image_type";
import { resources, controls } from "../../const/resource";
import { ImagePreview } from "../../components/image_preview";
import { RecordList } from "../../components/record_list";

import CircularProgress from "@material-ui/core/CircularProgress";

const labels = {
  type: "画像種別",
  any: "全て",
  from: "開始",
  to: "終了",
  staff: "スタッフ",
  area: "スタッフエリア",
  instruction: "指示",
  chain: "チェーン",
  storeSearch: "店舗検索",
  store: "店舗",
  date: "日付",
  image: "画像",
  columnOption: "1ページ毎の画像数",
  textOption: "情報を追加",
  textOptionDate: "訪問日",
  textOptionStaffName: "スタッフ名",
  textOptionStoreName: "店舗名",
  preview: "プレビュー",
  reportDetail: "日報詳細",
  notFound: "該当する画像がありません",
  download: "ダウンロード",
  downloading: "ダウンロード中...",
  maxDownloadExceededAlert: (count: number): string =>
    `一度にダウンロードできる画像の数は ${count}枚までです。`,
};

type ReportImageRow = {
  reportId: number;
  reportImageId: number;
  date: Date;
  chainName: string;
  storeName: string;
  staffName: string;
  chainId: number;
  storeId: number;
  instructionTitle: string;
  typeLabel: string;
  imagePath: string;
  imageAlt: string;
};

type RetrieveState = {
  initialized: boolean;
  reports: definitions["Report"][];
  reportImages: definitions["ReportImage"][];
  reportImageRows: ReportImageRow[];
  reportImageTypes: definitions["ReportImageType"][];
  instructions: definitions["Instruction"][];
  instructionId: number;
  staffs: definitions["Staff"][];
  filteredStaffs: definitions["Staff"][];
  staffIds: number[];
  chains: definitions["Chain"][];
  chainId: number;
  storeSearchWord: string;
  stores: definitions["Store"][];
  filteredStores: definitions["Store"][];
  storeIds: number[];
  area: string;
  type: number;
  fromDate: Date;
  toDate: Date;
  photosPerPage: number;
  currentPage: number;
  dateChecked: boolean;
  staffNameChecked: boolean;
  storeNameChecked: boolean;
  preview: JSX.Element | null;
  downloading: boolean;
};

const anyType = 0;
const anyInstructionId = 0;
const anyStaffId = 0;
const anyChainId = 0;
const anyStoreId = 0;

const idFromDate = "reportimage-fromdate";
const idToDate = "reportimage-todate";

const maxReportImageDownloadCount = 60;

function Retrieve() {
  const today = new Date();
  const [state, setState] = react.useState<RetrieveState>({
    initialized: false,
    reports: [],
    reportImages: [],
    reportImageRows: [],
    reportImageTypes: [],
    instructions: [],
    instructionId: anyInstructionId,
    staffs: [],
    filteredStaffs: [],
    staffIds: [anyStaffId],
    chains: [],
    chainId: anyChainId,
    storeSearchWord: "",
    stores: [],
    filteredStores: [],
    storeIds: [anyStoreId],
    area: "",
    type: anyType,
    fromDate: new Date(today.getFullYear(), today.getMonth(), 1),
    toDate: today,
    photosPerPage: 4,
    dateChecked: true,
    currentPage: 1,
    staffNameChecked: true,
    storeNameChecked: true,
    preview: null,
    downloading: false,
  });

  const updateReportImageRows = () => {
    state.reportImageRows = state.reports
      .filter((report) => {
        if (
          state.staffIds.indexOf(anyStaffId) === -1 &&
          state.staffIds.indexOf(report.staff_id) === -1
        ) {
          return false;
        }
        if (state.chainId !== anyChainId && report.chain_id !== state.chainId) {
          return false;
        }
        if (
          state.storeIds.indexOf(anyStoreId) === -1 &&
          state.storeIds.indexOf(report.store_id || -1) === -1
        ) {
          return false;
        }
        return true;
      })
      .map((report: definitions["Report"]) => {
        const rows = report.report_images
          .filter((reportImage) => {
            if (state.type !== anyType && reportImage.type !== state.type) {
              return false;
            }
            if (
              state.instructionId !== anyInstructionId &&
              reportImage.instruction_id !== state.instructionId
            ) {
              return false;
            }
            return true;
          })
          .map((reportImage: definitions["ReportImage"]) => {
            const instruction = state.instructions.find(
              (instruction) => instruction.id === reportImage.instruction_id
            );

            return {
              reportId: report.id,
              reportImageId: reportImage.id,
              date: new Date(report.date),
              chainName: report.chain?.name || "",
              storeName: report.store?.name || "",
              staffName: report.staff?.name || "",
              chainId: report.chain?.id || 0,
              storeId: report.store?.id || 0,
              instructionTitle: instruction ? instruction.title : "",
              typeLabel: typeValueToLabel(reportImage.type),
              imagePath: `/${reportImage.path}`,
              imageAlt: reportImage.name,
            };
          });

        return rows;
      })
      .flat()
      .sort((l, r) => {
        if (l.date.getTime() === r.date.getTime()) {
          if (l.chainId === r.chainId) {
            if (l.storeId === r.storeId) {
              return 0;
            }
            return l.storeId < r.storeId ? -1 : 1;
          }
          return l.chainId < r.chainId ? -1 : 1;
        }
        return l.date.getTime() < r.date.getTime() ? -1 : 1;
      });
  };

  const refreshReports = (fromDate: Date, toDate: Date) => {
    return new rest.Report()
      .getAll(
        {
          from_date: datetime.toTextInputCalendarFormat(fromDate),
          to_date: datetime.toTextInputCalendarFormat(toDate),
        },
        ["Chain", "Store", "Staff", "IndividualReports", "ReportImages"],
        auth.getToken()
      )
      .then((json: rest.ReportsGetResponse) => {
        state.reports = json;
      })
      .then(() => {
        return Promise.all([
          refreshInstructions(),
          refreshChains(),
          refreshStores(),
        ]);
      })
      .then(() => {
        if (
          !state.instructions.find(
            (instruction) => instruction.id === state.instructionId
          )
        ) {
          state.instructionId = anyInstructionId;
        }
        if (!state.chains.find((chain) => chain.id === state.chainId)) {
          state.chainId = anyChainId;
        }
        if (
          !state.stores.find((store) => state.storeIds.indexOf(store.id) !== -1)
        ) {
          state.storeIds = [anyStoreId];
        }

        state.filteredStores = state.stores.filter((store) => {
          if (state.chainId === anyChainId) {
            return store;
          }
          return state.chainId === store.chain_id;
        });

        state.reportImages = state.reports
          .map((report: definitions["Report"]) => report.report_images || [])
          .flat();
      });
  };

  const refreshInstructions = () => {
    const instructionIds = state.reports
      .map((report) =>
        report.individual_reports.map(
          (individualReport) => individualReport.instruction_id
        )
      )
      .flat();

    if (instructionIds.length === 0) {
      state.instructions = [];
      return Promise.resolve();
    }

    return new rest.Instruction()
      .getByIds(instructionIds, auth.getToken())
      .then((json: rest.InstructionsGetResponse) => {
        state.instructions = json;
      });
  };

  const refreshChains = () => {
    const chainsMap: { [id: number]: definitions["Chain"] } = {};
    for (let i = 0; i < state.reports.length; i += 1) {
      const chain = state.reports[i].chain;
      if (chain) {
        chainsMap[chain.id] = chain;
      }
    }
    state.chains = Object.keys(chainsMap).map(
      (id) => chainsMap[parseInt(id, 10)]
    );
    return Promise.resolve();
  };

  const refreshStores = () => {
    const storesMap: { [id: number]: definitions["Store"] } = {};
    for (let i = 0; i < state.reports.length; i += 1) {
      const store = state.reports[i].store;
      if (store) {
        storesMap[store.id] = store;
      }
    }
    state.stores = Object.keys(storesMap).map(
      (id) => storesMap[parseInt(id, 10)]
    );
  };

  react.useEffect(() => {
    if (!state.initialized) {
      state.initialized = true;

      Promise.all([
        new rest.ReportImageType()
          .getAll(auth.getToken())
          .then((json: rest.ReportImageTypesGetResponse) => {
            state.reportImageTypes = json;
          }),
        new rest.Staff()
          .getAll(auth.getToken())
          .then((json: rest.StaffsGetResponse) => {
            state.staffs = json;
            state.filteredStaffs = json;
          }),
      ])
        .then(() => {
          const fromDateDom = document.getElementById(
            idFromDate
          ) as HTMLInputElement;
          const toDateDom = document.getElementById(
            idToDate
          ) as HTMLInputElement;

          const fromDate = fromDateDom
            ? new Date(fromDateDom.value)
            : state.fromDate;
          const toDate = toDateDom ? new Date(toDateDom.value) : state.toDate;

          // browser set old value after first render frame
          return refreshReports(fromDate, toDate);
        })
        .then(() => {
          updateReportImageRows();
          setState({ ...state });
        })
        .catch(console.log);
    }
  });

  const onDateChanged = (property: "fromDate" | "toDate", date: Date) => {
    state[property] = date;
    if (state.fromDate.getTime() <= state.toDate.getTime()) {
      refreshReports(state.fromDate, state.toDate).then(() => {
        updateReportImageRows();
        setState({ ...state });
      });
    }
  };

  const onNumberValueTypeChanged = (
    key: "type" | "instructionId" | "chainId",
    value: number
  ) => {
    state[key] = value;
    updateReportImageRows();
    setState({ ...state });
  };

  const onStaffIdsChanged = (staffIds: number[]) => {
    const anyStaffIndex = staffIds.indexOf(anyStaffId);
    if (staffIds.length >= 2 && anyStaffIndex !== -1) {
      staffIds.splice(anyStaffIndex, 1);
    }
    state.staffIds = staffIds;
    updateReportImageRows();
    setState({ ...state });
  };
  const onStoreIdsChanged = (storeIds: number[]) => {
    const anyStoreIndex = storeIds.indexOf(anyStoreId);
    if (storeIds.length >= 2 && anyStoreIndex !== -1) {
      storeIds.splice(anyStoreIndex, 1);
    }
    state.storeIds = storeIds;
    updateReportImageRows();
    setState({ ...state });
  };

  const onStoreSearchWordChanged = (word: string) => {
    state.storeSearchWord = word;
    setState({ ...state });
  };

  const onAreaChanged = (area: string) => {
    state.area = area;
    setState({ ...state });
  };

  const onPhotosPerPageChanged = (photosPerPage: number) => {
    state.photosPerPage = photosPerPage;
    setState({ ...state });
  };

  const onTextOptionChanged = (option: string, value: boolean) => {
    switch (option) {
      case "date":
        state.dateChecked = value;
        break;
      case "staffName":
        state.staffNameChecked = value;
        break;
      case "storeName":
        state.storeNameChecked = value;
        break;
    }

    setState({ ...state });
  };

  const showPreview = (path: string, alt: string) => {
    state.preview = (
      <ui.Box>
        <img src={path} width="100%" alt={alt} />
      </ui.Box>
    );
    setState({ ...state });
  };
  const onPreviewClose = () => {
    state.preview = null;
    setState({ ...state });
  };

  const onPageChanged = (page: number) => {
    state.currentPage = page;
    setState({ ...state });
  };

  const onDownload = () => {
    if (state.reportImageRows.length > 0) {
      const reportImageIds = state.reportImageRows.map(
        (row) => row.reportImageId
      );

      const downloadingImageIds = [];
      for (
        let i = maxReportImageDownloadCount * (state.currentPage - 1);
        i < maxReportImageDownloadCount * state.currentPage &&
        i < reportImageIds.length;
        i += 1
      ) {
        downloadingImageIds.push(reportImageIds[i]);
      }

      if (downloadingImageIds.length > maxReportImageDownloadCount) {
        window.alert(
          labels.maxDownloadExceededAlert(maxReportImageDownloadCount)
        );
        return;
      }

      state.downloading = true;
      setState({ ...state });

      const currentPage = state.currentPage;

      const textOption = {
        date: state.dateChecked,
        staffName: state.staffNameChecked,
        storeName: state.storeNameChecked,
      };

      new rest.Pptx()
        .get(
          downloadingImageIds,
          textOption,
          state.photosPerPage,
          auth.getToken()
        )
        .then((blob: Blob) => {
          rest.downloadAsFile(blob, `photos_${currentPage}.pptx`);
          state.downloading = false;
          setState({ ...state });
        })
        .catch((e) => {
          state.downloading = false;
          setState({ ...state });
        });
    }
  };

  return (
    <ui.Container>
      <ui.Grid container spacing={4}>
        <ui.Grid item xs={12}></ui.Grid>
        <ui.Grid item xs={6} sm={3}>
          <ui.TextField
            id={idFromDate}
            fullWidth
            label={labels.from}
            type="date"
            defaultValue={datetime.toTextInputCalendarFormat(
              state.fromDate || new Date()
            )}
            InputLabelProps={{ shrink: true }}
            onChange={(e) =>
              onDateChanged("fromDate", new Date(e.target.value))
            }
          />
        </ui.Grid>
        <ui.Grid item xs={6} sm={3}>
          <ui.TextField
            id={idToDate}
            fullWidth
            label={labels.to}
            type="date"
            defaultValue={datetime.toTextInputCalendarFormat(
              state.toDate || new Date()
            )}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => onDateChanged("toDate", new Date(e.target.value))}
          />
        </ui.Grid>

        <ui.Grid item xs={6} sm={3}>
          <ui.TextField
            label={labels.area}
            onBlur={(e) => onAreaChanged(e.target.value)}
            fullWidth
            rows={6}
            defaultValue={state.area}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
        </ui.Grid>

        <ui.Grid item xs={6} sm={3}>
          <ui.InputLabel id="staff-id-label">{labels.staff}</ui.InputLabel>
          <ui.Select
            fullWidth
            multiple
            labelId="staff-id-label"
            value={state.staffIds}
            onChange={(e: any) => {
              onStaffIdsChanged(
                e.target.value.map((v: any) => parseInt(v, 10))
              );
            }}
          >
            <ui.MenuItem key={"staff_id-0"} value={anyStaffId}>
              {labels.any}
            </ui.MenuItem>
            {state.staffs
              .filter((staff) => {
                if (state.area === "") {
                  return staff;
                }
                return staff.area.indexOf(state.area) >= 0;
              })
              .map((staff: definitions["Staff"]) => (
                <ui.MenuItem key={`staff_id-${staff.id}`} value={staff.id}>
                  {staff.name}
                </ui.MenuItem>
              ))}
          </ui.Select>
        </ui.Grid>

        <ui.Grid item xs={6} sm={3}>
          <ui.InputLabel id="instruction-id-label">
            {labels.instruction}
          </ui.InputLabel>
          <ui.Select
            fullWidth
            labelId="instruction-id-label"
            value={state.instructionId}
            onChange={(e: any) => {
              onNumberValueTypeChanged(
                "instructionId",
                parseInt(e.target.value, 10)
              );
            }}
          >
            <ui.MenuItem key={"instruction_id-0"} value={anyInstructionId}>
              {labels.any}
            </ui.MenuItem>
            {state.instructions.map(
              (instruction: definitions["Instruction"]) => (
                <ui.MenuItem
                  key={`instruction_id-${instruction.id}`}
                  value={instruction.id}
                >
                  {instruction.title}
                </ui.MenuItem>
              )
            )}
          </ui.Select>
        </ui.Grid>

        <ui.Grid item xs={6} sm={3}>
          <ui.InputLabel id="chain-id-label">{labels.chain}</ui.InputLabel>
          <ui.Select
            fullWidth
            labelId="chain-id-label"
            value={state.chainId}
            onChange={(e: any) => {
              onNumberValueTypeChanged("chainId", parseInt(e.target.value, 10));
            }}
          >
            <ui.MenuItem key={"chain_id-0"} value={anyChainId}>
              {labels.any}
            </ui.MenuItem>
            {state.chains.map((chain: definitions["Chain"]) => (
              <ui.MenuItem key={`chain_id-${chain.id}`} value={chain.id}>
                {chain.name}
              </ui.MenuItem>
            ))}
          </ui.Select>
        </ui.Grid>

        <ui.Grid item xs={6} sm={3}>
          <ui.TextField
            label={labels.storeSearch}
            onChange={(e) => onStoreSearchWordChanged(e.target.value)}
            fullWidth
            value={state.storeSearchWord}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
        </ui.Grid>

        <ui.Grid item xs={6} sm={3}>
          <ui.InputLabel id="store-id-label">{labels.store}</ui.InputLabel>
          <ui.Select
            fullWidth
            multiple
            labelId="store-id-label"
            value={state.storeIds}
            onChange={(e: any) => {
              onStoreIdsChanged(
                e.target.value.map((v: any) => parseInt(v, 10))
              );
            }}
          >
            <ui.MenuItem key={"store_id-0"} value={anyStoreId}>
              {labels.any}
            </ui.MenuItem>
            {state.filteredStores
              .filter((store) => {
                if (state.storeSearchWord === "") {
                  return true;
                }
                return store.name.indexOf(state.storeSearchWord) >= 0;
              })
              .map((store: definitions["Store"]) => (
                <ui.MenuItem key={`store_id-${store.id}`} value={store.id}>
                  {store.name}
                </ui.MenuItem>
              ))}
          </ui.Select>
        </ui.Grid>

        <ui.Grid item xs={6} sm={3}>
          <ui.InputLabel id="report_image_type-id-label">
            {labels.type}
          </ui.InputLabel>
          <ui.Select
            fullWidth
            labelId="report_image_type-id-label"
            value={state.type}
            onChange={(e: any) => {
              onNumberValueTypeChanged("type", parseInt(e.target.value, 10));
            }}
          >
            <ui.MenuItem key={"report_image_type-0"} value={anyType}>
              {labels.any}
            </ui.MenuItem>
            {state.reportImageTypes.map(
              (type: definitions["ReportImageType"]) => (
                <ui.MenuItem
                  key={`report_image_type-${type.id}`}
                  value={type.id}
                >
                  {typeValueToLabel(type.id)}
                </ui.MenuItem>
              )
            )}
          </ui.Select>
        </ui.Grid>

        <ui.Grid item xs={12} sm={3}>
          <ui.InputLabel id="columnoption-label">
            {labels.columnOption}
          </ui.InputLabel>
          <ui.RadioGroup
            row
            aria-label="columnoption"
            value={state.photosPerPage}
            onChange={(e) =>
              onPhotosPerPageChanged(parseInt(e.target.value, 10))
            }
          >
            {[1, 2, 3, 4, 6].map((n) => (
              <ui.FormControlLabel
                key={`columnoption-${n}`}
                value={n}
                control={<ui.Radio />}
                label={`${n}`}
              />
            ))}
          </ui.RadioGroup>
        </ui.Grid>

        <ui.Grid item xs={12} sm={3}>
          <ui.InputLabel id="chain-id-label">{labels.textOption}</ui.InputLabel>
          <ui.FormControl required component="fieldset">
            <ui.FormGroup row>
              <ui.FormControlLabel
                key="date"
                control={
                  <ui.Checkbox
                    value="date"
                    checked={state.dateChecked}
                    onChange={(e) =>
                      onTextOptionChanged("date", e.target.checked)
                    }
                  />
                }
                label={labels.textOptionDate}
              />
              <ui.FormControlLabel
                key="staffName"
                control={
                  <ui.Checkbox
                    value="staffName"
                    checked={state.staffNameChecked}
                    onChange={(e) =>
                      onTextOptionChanged("staffName", e.target.checked)
                    }
                  />
                }
                label={labels.textOptionStaffName}
              />
              <ui.FormControlLabel
                key="storeName"
                control={
                  <ui.Checkbox
                    value="storeName"
                    checked={state.storeNameChecked}
                    onChange={(e) =>
                      onTextOptionChanged("storeName", e.target.checked)
                    }
                  />
                }
                label={labels.textOptionStoreName}
              />
            </ui.FormGroup>
          </ui.FormControl>
        </ui.Grid>

        <ui.Grid
          container
          item
          xs={12}
          sm={3}
          spacing={1}
          justifyContent="flex-end"
          alignItems="center"
        >
          {state.downloading ? (
            <>
              <ui.Grid item>
                <CircularProgress />
              </ui.Grid>
              <ui.Grid item>{labels.downloading}</ui.Grid>
            </>
          ) : (
            <ui.Button
              variant="contained"
              color="primary"
              onClick={onDownload}
              disabled={state.reportImages.length === 0}
            >
              {labels.download}
            </ui.Button>
          )}
        </ui.Grid>

        <ui.Grid item xs={12}>
          <RecordList<ReportImageRow>
            defaultText={labels.notFound}
            cols={[
              { label: labels.date, key: "date" },
              { label: labels.chain, key: "chainName" },
              { label: labels.store, key: "storeName" },
              { label: labels.staff, key: "staffName" },
              { label: labels.instruction, key: "instructionTitle" },
              { label: labels.type, key: "typeLabel" },
              { label: "", key: "control" },
            ]}
            sort={false}
            items={state.reportImageRows}
            itemsPerPage={maxReportImageDownloadCount}
            perPageCandidates={[maxReportImageDownloadCount]}
            onPageChanged={onPageChanged}
            extraKeyItemCallbacks={{
              date: (row) => (
                <>{datetime.toTextInputCalendarFormat(row.date)}</>
              ),
              control: (row) => (
                <ui.Grid container spacing={2}>
                  <ui.Grid container item xs={6} justifyContent="center">
                    <ui.Button
                      color="secondary"
                      onClick={() => showPreview(row.imagePath, row.imageAlt)}
                    >
                      {labels.preview}
                    </ui.Button>
                  </ui.Grid>
                  <ui.Grid
                    container
                    item
                    xs={6}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <ui.Link
                      href={`/${resources.report.identifier}/${controls.update.identifier}/${row.reportId}`}
                    >
                      {labels.reportDetail}
                    </ui.Link>
                  </ui.Grid>
                </ui.Grid>
              ),
            }}
          />
        </ui.Grid>
      </ui.Grid>
      <ImagePreview
        open={!!state.preview}
        content={state.preview}
        onPreviewClose={() => onPreviewClose()}
      />
    </ui.Container>
  );
}

export { Retrieve };
