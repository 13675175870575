import react from "react";
import { useLocation } from "react-router-dom";
import * as ui from "@material-ui/core";
import * as c from "../../const";
import * as auth from "../../auth";
import * as rest from "../../rest";
import { definitions } from "../../schema/api";
import { Form } from "./form";
import { Loading } from "../../components/loading";

const labels: { [key: string]: string } = {
  succeed: "登録しました",
  failed: "登録に失敗しました",
};

type CreateState = {
  user: definitions["User"];
  client: definitions["Client"];
  clientCompanies: definitions["ClientCompany"][];
  initialized: boolean;
};
type CreateProps = {
  showGlobalNotification?: (message: string) => void;
};

function useQuery() {
  const { search } = useLocation();

  return react.useMemo(() => new URLSearchParams(search), [search]);
}

function Create(props: CreateProps) {
  const query = useQuery();
  const initialClientCompanyId = query.get("client_company_id");

  const [state, setState] = react.useState<CreateState>({
    user: {
      id: c.api.newRecordId,
      name: "",
      password: "",
      email: "",
    },
    client: {
      id: 0,
      user_id: 0,
      client_company_id: initialClientCompanyId
        ? parseInt(initialClientCompanyId, 10)
        : 0,
      name: "",
      name_kana: "",
      phone: "",
      remarks: "",
    },
    clientCompanies: [],
    initialized: false,
  });

  const refreshStores = () => {
    const chains = state.client.client_company?.chains;
    if (!chains) {
      return Promise.resolve();
    }
    return new rest.Store()
      .getAllByChainIds(
        chains.map((chain) => chain.id),
        auth.getToken()
      )
      .then((json: rest.StoresGetResponse) => {
        const storesByChainId: { [chainId: number]: definitions["Store"][] } =
          {};
        for (let i = 0; i < json.length; i += 1) {
          const store = json[i];
          if (!storesByChainId[store.chain_id]) {
            storesByChainId[store.chain_id] = [];
          }
          storesByChainId[store.chain_id].push(store);
        }
        for (let i = 0; i < (chains.length || -1); i += 1) {
          const chain = chains![i];
          chain.stores = storesByChainId[chain.id] || [];
        }
      });
  };

  // store <- chains <- client_company <- client

  react.useEffect(() => {
    if (!state.initialized) {
      state.initialized = true;

      new rest.ClientCompany()
        .getAll(["Chains"], auth.getToken())
        .then((json: rest.ClientCompaniesGetResponse) => {
          state.clientCompanies = json || [];
          const clientCompany = state.clientCompanies.find(
            (clientCompany) =>
              clientCompany.id === state.client.client_company_id
          );
          if (clientCompany) {
            state.client.client_company_id = clientCompany.id;
            state.client.client_company = clientCompany;
          } else if (state.clientCompanies.length > 0) {
            state.client.client_company_id = json[0].id;
            state.client.client_company = json[0];
          }
        })
        .then(() => refreshStores())
        .then(() => setState({ ...state }));
    }
  });

  const onNameChanged = (name: string) => {
    state.user.name = name;
    state.client.name = name;
    setState({ ...state });
  };
  const onEmailChanged = (email: string) => {
    state.user.email = email;
    setState({ ...state });
  };
  const onPasswordChanged = (password: string) => {
    state.user.password = password;
    setState({ ...state });
  };
  const onClientStringValueChanged = (
    name: "name_kana" | "phone" | "remarks",
    value: string
  ) => {
    state.client[name] = value;
    setState({ ...state });
  };

  const onClientCompanyChanged = (id: number) => {
    const clientCompany = state.clientCompanies.find(
      (clientCompany) => clientCompany.id === id
    );
    state.client.client_company_id = clientCompany!.id;
    state.client.client_company = clientCompany;
    refreshStores().then(() => setState({ ...state }));
  };

  const onSubmit = () => {
    const putClient = { ...state.client };
    delete putClient.client_company;
    delete putClient.chains;
    delete putClient.client_stores;

    const client = new rest.Client();
    client
      .put(
        [
          {
            client: putClient,
            user: {
              id: c.api.newRecordId,
              name: state.user.name,
              email: state.user.email,
              password: state.user.password || "",
            },
          },
        ],
        auth.getToken()
      )
      .then((ret: object) => {
        props.showGlobalNotification &&
          props.showGlobalNotification(labels.succeed);
      })
      .catch(() => {
        props.showGlobalNotification &&
          props.showGlobalNotification(labels.failed);
      });
  };

  if (!state.initialized) {
    return <Loading />;
  }

  return (
    <ui.Container>
      <ui.Grid
        container
        spacing={0}
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        <Form
          user={state.user}
          client={state.client}
          clientCompanies={state.clientCompanies}
          onEmailChanged={onEmailChanged}
          onPasswordChanged={onPasswordChanged}
          onClientCompanyChanged={onClientCompanyChanged}
          onNameChanged={onNameChanged}
          onNameKanaChanged={(v) => onClientStringValueChanged("name_kana", v)}
          onPhoneChanged={(v) => onClientStringValueChanged("phone", v)}
          onRemarksChanged={(v) => onClientStringValueChanged("remarks", v)}
          onSubmit={onSubmit}
        />
      </ui.Grid>
    </ui.Container>
  );
}

export { Create };
