import * as ui from "@material-ui/core";
import { useParams } from "react-router-dom";
import { definitions } from "../../schema/api";
import { resources, controls } from "../../const/resource";
import * as auth from "../../auth";
import * as rest from "../../rest";
import { Form } from "./form";

const labels = {
  failed: "掲示板の更新に失敗しました",
};

type UpdateProps = {
  showGlobalNotification?: (message: string) => void;
};

type UpdateParams = {
  id: string;
};

function Update(props: UpdateProps) {
  const params = useParams<UpdateParams>();
  const noticeBoardId = parseInt(params.id as string, 10);

  const onSubmit = (
    noticeBoard: definitions["NoticeBoard"],
    files: File[],
    noticeBoardFileIdsBeforeUpdate: number[]
  ) => {
    new rest.NoticeBoard()
      .post(noticeBoard.id, noticeBoard, auth.getToken())
      .then((json: rest.NoticeBoardPutResponse) => {
        const promises = [];
        // replace to new file
        if (files.length > 0) {
          promises.push(
            new rest.NoticeBoardFile().post(json.id, files, auth.getToken())
          );
        }
        // delete olf file if assosiated files are deleted
        const deletingFileIds: number[] = [];
        if (noticeBoard.notice_board_files) {
          for (let i = 0; i < noticeBoardFileIdsBeforeUpdate.length; i += 1) {
            const id = noticeBoardFileIdsBeforeUpdate[i];
            const exists = !!noticeBoard.notice_board_files.find(
              (f) => f.id === id
            );
            if (!exists) {
              deletingFileIds.push(id);
            }
          }
        }
        if (deletingFileIds.length > 0) {
          for (let i = 0; i < deletingFileIds.length; i += 1) {
            promises.push(
              new rest.NoticeBoardFile().delete(
                deletingFileIds[i],
                auth.getToken()
              )
            );
          }
        }
        return Promise.all(promises);
      })
      .then(() => {
        window.location.href = `/${resources.notice_board.identifier}/${controls.retrieve.identifier}`;
      })
      .catch(() => {
        props.showGlobalNotification &&
          props.showGlobalNotification(labels.failed);
      });
  };

  return (
    <ui.Container>
      <Form noticeBoardId={noticeBoardId} onSubmit={onSubmit} />
    </ui.Container>
  );
}

export { Update };
