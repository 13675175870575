type IndividualReportResult = {
  identifier: string;
  label: string;
  value: number;
};

// TODO: not syncronized with the master data (there is no master data)
const results: { [type: string]: IndividualReportResult } = Object.freeze({
  done: {
    identifier: "done",
    label: "完了",
    value: 1,
  },
  undone: {
    identifier: "undone",
    label: "未完了",
    value: 2,
  },
  pend: {
    identifier: "pend",
    label: "保留",
    value: 3,
  },
  notRequired: {
    identifier: "notRequired",
    label: "対象外",
    value: 4,
  },
});

const resultValueToLabel = (resultValue: number): string => {
  const targetKey = Object.keys(results).find((key) => {
    const type = results[key];
    if (type.value === resultValue) {
      return key;
    }
    return null;
  });
  return targetKey ? results[targetKey].label : "";
};

const defaultType = results.before;

export { results, resultValueToLabel, defaultType };
