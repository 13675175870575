import react from "react";
import { useParams } from "react-router-dom";
import * as ui from "@material-ui/core";
import * as auth from "../../auth";
import * as rest from "../../rest";
import { definitions } from "../../schema/api";
import { createStore } from "../../const/definitions/store";
import { Loading } from "../../components/loading";

import { Form } from "./form";

const labels: { [key: string]: string } = {
  succeed: "登録しました",
  failed: "登録に失敗しました",
};

type UpdateState = {
  store: definitions["Store"];
  initialized: boolean;
};
type UpdateProps = {
  showGlobalNotification?: (message: string) => void;
};

type UpdateParams = {
  id: string;
};

function Update(props: UpdateProps) {
  const params = useParams<UpdateParams>();
  const storeId = parseInt(params.id as string, 10);

  const [state, setState] = react.useState<UpdateState>({
    store: createStore(),
    initialized: false,
  });

  const onChange = (store: definitions["Store"]) => {
    state.store = store;
    setState({ ...state });
  };

  const onSubmit = () => {
    const store = new rest.Store();
    store
      .post(storeId, state.store, auth.getToken())
      .then((ret: object) => {
        props.showGlobalNotification &&
          props.showGlobalNotification(labels.succeed);
      })
      .catch(() => {
        props.showGlobalNotification &&
          props.showGlobalNotification(labels.failed);
      });
  };

  react.useEffect(() => {
    if (!state.initialized) {
      state.initialized = true;
      new rest.Store()
        .get(storeId, auth.getToken())
        .then((json: rest.StoreGetIdResponse) => {
          state.store = json;
        })
        .then(() => setState({ ...state }));
    }
  });

  if (!state.initialized) {
    return <Loading />;
  }

  return (
    <ui.Container>
      <Form store={state.store} onChange={onChange} onSubmit={onSubmit} />
    </ui.Container>
  );
}

export { Update };
