import react from "react";
import * as ui from "@material-ui/core";
import { definitions } from "../../schema/api";
import { resources, controls } from "../../const/resource";
import { UserForm } from "../../components/user_form";
import { FormattedTextField } from "../../components/formatted_text_field";
import { ClientCompanySelector } from "../../components/client_company_selector";
import { NavigatorBack } from "../../components/navigator_back";
import { createFormValidator, FormValidator } from "../../validation/form";

const labels: { [key: string]: string } = {
  list: "一覧",
  titleCreate: "管理者登録",
  titleUpdate: "管理者更新",
  name: "管理者名",
  email: "ログイン用Eメール (確認中)",
  password: "パスワード",
  nameKana: "管理者名(カナ)",
  phone: "電話番号",
  remarks: "備考 (任意)",
  showLogin: "ログイン情報を確認",
  submit: "登録",
};

type FormState = {
  validator: FormValidator;
  showUserForm: boolean;
  userFormError: boolean;
};

type FormProps = {
  user: definitions["User"];
  client: definitions["Client"];
  clientCompanies: definitions["ClientCompany"][];
  onUserFormEnabled?: () => void;
  onEmailChanged: (email: string) => void;
  onPasswordChanged: (password: string) => void;
  onClientCompanyChanged: (id: number) => void;
  onNameChanged: (name: string) => void;
  onNameKanaChanged: (nameKana: string) => void;
  onPhoneChanged: (phone: string) => void;
  onRemarksChanged: (remarks: string) => void;
  onSubmit: () => void;
};

function Form(props: FormProps) {
  const validator = createFormValidator("client");
  if (props.client.id !== 0) {
    validator.validateAll(props.client);
  }
  const [state, setState] = react.useState<FormState>({
    validator,
    showUserForm: props.user.id === 0,
    userFormError: false,
  });

  const onUpdateLoginInfoClicked = () => {
    state.showUserForm = true;
    setState({ ...state });
    props.onUserFormEnabled && props.onUserFormEnabled();
  };

  return (
    <ui.Grid container spacing={4}>
      <ui.Grid container item xs={12} spacing={0}>
        <NavigatorBack
          xs={12}
          sm={12}
          label={labels.list}
          href={`/${resources.client.identifier}/${controls.retrieve.identifier}`}
        />
        <ui.Grid item xs={12}>
          <ui.Typography variant="h6">
            {props.client.id !== 0 ? labels.titleUpdate : labels.titleCreate}
          </ui.Typography>
        </ui.Grid>
      </ui.Grid>

      <UserForm
        show={state.showUserForm}
        user={props.user}
        onEmailChanged={props.onEmailChanged}
        onPasswordChanged={props.onPasswordChanged}
        onShowToggled={onUpdateLoginInfoClicked}
        onErrorUpdated={(hasError) => {
          state.userFormError = hasError;
          setState({ ...state });
        }}
      />

      <ui.Grid item xs={6}>
        <ClientCompanySelector
          clientCompanies={props.clientCompanies}
          selectedValue={props.client.client_company_id}
          onChanged={(id: number) => props.onClientCompanyChanged(id)}
        />
      </ui.Grid>

      <ui.Grid item xs={6}>
        <FormattedTextField
          {...state.validator.formProps.phone}
          phone
          id="client-phone-id"
          label={labels.phone}
          onBlur={(e) => {
            state.validator.validate(e.target.value, "phone");
            props.onPhoneChanged(e.target.value);
          }}
          fullWidth
          rows={6}
          defaultValue={props.client.phone}
          variant="outlined"
          name="phone"
        />
      </ui.Grid>

      <ui.Grid item xs={6}>
        <FormattedTextField
          {...state.validator.formProps.name}
          trim
          singleByteSpace
          id="client-name-id"
          label={labels.name}
          onBlur={(e) => {
            state.validator.validate(e.target.value, "name");
            props.onNameChanged(e.target.value);
          }}
          fullWidth
          rows={6}
          defaultValue={props.client.name}
          variant="outlined"
          name="name"
        />
      </ui.Grid>

      <ui.Grid item xs={6}>
        <FormattedTextField
          {...state.validator.formProps.name_kana}
          trim
          singleByteSpace
          id="client-namekana-id"
          label={labels.nameKana}
          onBlur={(e) => {
            state.validator.validate(e.target.value, "name_kana");
            props.onNameKanaChanged(e.target.value);
          }}
          fullWidth
          rows={6}
          defaultValue={props.client.name_kana}
          variant="outlined"
          name="name_kana"
        />
      </ui.Grid>

      <ui.Grid item xs={12}>
        <ui.TextField
          {...state.validator.formProps.remarks}
          id="client-remarks-id"
          label={labels.remarks}
          onBlur={(e) => {
            state.validator.validate(e.target.value, "remarks");
            props.onRemarksChanged(e.target.value);
          }}
          fullWidth
          rows={6}
          defaultValue={props.client.remarks}
          variant="outlined"
          name="remarks"
        />
      </ui.Grid>

      <ui.Grid item xs={12}>
        <ui.Button
          disabled={
            state.userFormError || state.validator.submitButtonProps.disabled
          }
          variant="contained"
          color="secondary"
          type="submit"
          onClick={props.onSubmit}
        >
          {labels.submit}
        </ui.Button>
      </ui.Grid>
    </ui.Grid>
  );
}

export { Form };
