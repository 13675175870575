import { request, methods } from "./request";
import { paths } from "../schema/api";

type InstructionGetParam =
  paths["/instruction/{id}"]["get"]["parameters"]["query"];
type InstructionsGetParam =
  paths["/instructions"]["get"]["parameters"]["query"];
type InstructionsGetYearMonthParam =
  paths["/instructions/{year}/{month}"]["get"]["parameters"]["query"];
type InstructionsGetYearMonthDateParam =
  paths["/instructions/{year}/{month}/{date}"]["get"]["parameters"]["query"];
type InstructionsGetYearMonthRangeParam =
  paths["/instructions/year_month_range"]["get"]["parameters"]["query"];
type InstructionGetResponse =
  paths["/instruction/{id}"]["get"]["responses"][200]["schema"];
type InstructionsGetResponse =
  paths["/instructions"]["get"]["responses"][200]["schema"];
type InstructionsGetYearMonthResponse =
  paths["/instructions/{year}/{month}"]["get"]["responses"][200]["schema"];
type InstructionsGetYearMonthDateResponse =
  paths["/instructions/{year}/{month}/{date}"]["get"]["responses"][200]["schema"];
type InstructionsGetYearMonthRangeResponse =
  paths["/instructions/year_month_range"]["get"]["responses"][200]["schema"];
type InstructionsPutParam =
  paths["/instructions"]["put"]["parameters"]["body"]["instructions"];
type InstructionsPutResponse =
  paths["/instructions"]["put"]["responses"][200]["schema"];
type InstructionPostParam =
  paths["/instruction/{id}"]["post"]["parameters"]["body"]["instruction"];

class Instruction {
  get(id: number, arg2: string[] | string, arg3: string = "") {
    const params: InstructionGetParam = {};
    let token: string = "";

    if (typeof arg2 === "string") {
      token = arg2;
    } else {
      params.scope = arg2.join(",");
      token = arg3;
    }
    return request({
      token,
      params,
      method: methods.Get,
      resource: `instruction/${id}`,
    });
  }
  getByIds(
    ids: number[],
    arg2: string[] | string,
    arg3: string = ""
  ): Promise<InstructionsGetResponse> {
    const params: InstructionsGetParam = { ids: ids.join(",") };
    let token: string = "";

    if (typeof arg2 === "string") {
      token = arg2;
    } else {
      params.scope = arg2.join(",");
      token = arg3;
    }
    return request({
      token,
      params,
      method: methods.Get,
      resource: "instructions",
    });
  }
  getAll(
    arg1: InstructionsGetParam | string | string[],
    arg2: string | string[] = "",
    arg3: string = ""
  ): Promise<InstructionsGetResponse> {
    let params: InstructionsGetParam = {};
    let token: string = "";

    if (typeof arg1 === "string") {
      token = arg1;
    } else if (arg1.constructor.name === "Array") {
      params.scope = (arg1 as string[]).join(",");
      token = arg2 as string;
    } else {
      params = arg1 as InstructionsGetParam;
      if (arg2.constructor.name === "Array") {
        params.scope = (arg2 as string[]).join(",");
        token = arg3;
      } else {
        token = arg2 as string;
      }
    }

    return request({
      token,
      params,
      resource: "instructions",
      method: methods.Get,
    });
  }
  getYearMonth(
    year: number,
    month: number,
    params: InstructionsGetYearMonthParam,
    token: string = ""
  ): Promise<InstructionsGetResponse> {
    return request({
      token,
      params,
      resource: `instructions/${year}/${month}`,
      method: methods.Get,
    });
  }
  getYearMonthDate(
    year: number,
    month: number,
    date: number,
    params: InstructionsGetYearMonthDateParam,
    arg5: string[] | string,
    arg6: string = ""
  ): Promise<InstructionsGetResponse> {
    let token = "";
    if (typeof arg5 === "string") {
      token = arg5;
    } else {
      params.scope = arg5.join(",");
      token = arg6;
    }

    return request({
      token,
      params,
      resource: `instructions/${year}/${month}/${date}`,
      method: methods.Get,
    });
  }
  getRange(
    params: InstructionsGetYearMonthRangeParam,
    token: string = ""
  ): Promise<InstructionsGetYearMonthRangeResponse> {
    return request({
      token,
      params,
      method: methods.Get,
      resource: "instructions/year_month_range",
    });
  }

  post(id: number, instruction: InstructionPostParam, token: string = "") {
    return request({
      token,
      method: methods.Post,
      resource: `instruction/${id}`,
      params: instruction,
    });
  }

  put(instructions: InstructionsPutParam, token: string = "") {
    return request({
      token,
      method: methods.Put,
      resource: "instructions",
      params: instructions,
    });
  }
  delete(id: number, token: string = "") {
    return request({
      token,
      method: methods.Delete,
      resource: `instruction/${id}`,
    });
  }
}

export { Instruction };
export type {
  InstructionGetResponse,
  InstructionsGetResponse,
  InstructionsGetYearMonthResponse,
  InstructionsGetYearMonthDateResponse,
  InstructionsGetYearMonthRangeResponse,
  InstructionsGetYearMonthDateParam,
  InstructionsGetYearMonthRangeParam,
  InstructionsPutParam,
  InstructionsPutResponse,
};
