import * as ui from "@material-ui/core";

import Close from "@material-ui/icons/Close";

const labels: { [key: string]: string } = {
  close: "閉じる",
};

interface CloseItemProps {
  onClicked: (e: any) => void;
}

function CloseItem(props: CloseItemProps) {
  return (
    <ui.ListItem button onClick={props.onClicked}>
      <ui.ListItem>
        <ui.ListItemIcon>
          <Close />
        </ui.ListItemIcon>
        <ui.ListItemText>{labels.close}</ui.ListItemText>
      </ui.ListItem>
    </ui.ListItem>
  );
}

export { CloseItem };
export type { CloseItemProps };
