import * as ui from "@material-ui/core";
import { definitions } from "../schema/api";

const labels: { [key: string]: string } = {
  chain: "チェーン選択",
};

interface ChainSelectorProps {
  chains: definitions["Chain"][];
  selectedValue: number;
  onChange: (e: any) => void;
}

function ChainSelector(props: ChainSelectorProps) {
  return (
    <ui.Box>
      <ui.InputLabel id="chain-id-label">{labels.chain}</ui.InputLabel>
      <ui.Select
        fullWidth
        labelId="chain-id-label"
        id="chain-id"
        name="chain_id"
        value={props.selectedValue || ""}
        onChange={props.onChange}
      >
        {props.chains.map((chain: definitions["Chain"]) => (
          <ui.MenuItem key={`chain${chain.id}`} value={chain.id}>
            {chain.name}
          </ui.MenuItem>
        ))}
      </ui.Select>
    </ui.Box>
  );
}

export { ChainSelector };
