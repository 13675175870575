import * as ui from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as auth from "../../auth";
import { ResourceItem } from "./home_menu/resource_item";
import { resources } from "../../const/resource";
import { roles } from "../../const/role";
import { WorkTime } from "../../components/work_time";
import { ResourceItemStaffWorkTimeRetrieve } from "./home_menu/resource_item_staff_work_time_retrieve";
import { NoticeBoard } from "../../components/notice_board";
import { SystemNotificationForm } from "../../components/system_notification_form";

import Description from "@material-ui/icons/Description";
import Edit from "@material-ui/icons/Edit";
import List from "@material-ui/icons/List";
import People from "@material-ui/icons/People";
import Business from "@material-ui/icons/Business";
import HomeWork from "@material-ui/icons/HomeWork";
import Storefront from "@material-ui/icons/Storefront";
import CalendarToday from "@material-ui/icons/CalendarToday";
import Image from "@material-ui/icons/Image";
import Schedule from "@material-ui/icons/Schedule";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

const resourceDisplay = {
  [roles.root.identifier]: [
    resources.chain.identifier,
    resources.store.identifier,
    resources.client.identifier,
    resources.client_company.identifier,
    resources.client_reader.identifier,
    resources.staff.identifier,
  ],
  [roles.admin.identifier]: [
    resources.chain.identifier,
    resources.store.identifier,
    resources.client.identifier,
    resources.client_company.identifier,
    resources.client_reader.identifier,
    resources.staff.identifier,
  ],
  [roles.staff.identifier]: [
    resources.work_time.identifier,
    resources.notice_board.identifier,
    resources.report.identifier,
    resources.calendar.identifier,
  ],
  [roles.client_reader.identifier]: [
    resources.report.identifier,
    resources.report_image.identifier,
    resources.instruction.identifier,
    resources.calendar.identifier,
  ],
  [roles.client.identifier]: [
    resources.instruction.identifier,
    resources.report.identifier,
    resources.report_image.identifier,
    resources.calendar.identifier,
    resources.work_time.identifier,
    resources.staff.identifier,
  ],
};

function customResourceItem(roleName: string, identifier: string): JSX.Element | null {
  switch (identifier) {
    case resources.work_time.identifier:
      return roleName === roles.client.identifier
        ? <ResourceItemStaffWorkTimeRetrieve />
        : <WorkTime />;
    case resources.notice_board.identifier:
      return <NoticeBoard />;
  }
  return null;
}

function iconByResource(identifier: string): JSX.Element {
  switch (identifier) {
    case resources.instruction.identifier:
      return <Description fontSize="large" />;
    case resources.report.identifier:
      return <Edit fontSize="large" />;
    case resources.report_image.identifier:
      return <Image fontSize="large" />;
    case resources.calendar.identifier:
      return <CalendarToday fontSize="large" />;
    case resources.client_company.identifier:
      return <Business fontSize="large" />;
    case resources.client.identifier:
    case resources.client_reader.identifier:
    case resources.staff.identifier:
      return <People fontSize="large" />;
    case resources.chain.identifier:
      return <HomeWork fontSize="large" />;
    case resources.store.identifier:
      return <Storefront fontSize="large" />;
    case resources.work_time.identifier:
      return <Schedule fontSize="large" />;
  }

  return <List fontSize="large" />;
}

function HomeMenu() {
  const classes = useStyles();
  const authorizations = auth.getAuthorizations() || {};
  const role = auth.getRole() || { userName: "", roleName: "" };
  const resourceIdentifiers = Object.keys(authorizations);
  const resourceOrder = resourceDisplay[role.roleName] || [];
  const orderedResourceIdentifiers = resourceOrder.filter(
    (resource) => resourceIdentifiers.includes(resource)
  );

  const resourceItems = orderedResourceIdentifiers.map((resourceId) => (
    <ui.Grid container item={true} xs={12} sm={4} key={resourceId} direction="column">
      {customResourceItem(role.roleName, resourceId) || (
        <ui.Card>
          <ui.CardHeader
            avatar={iconByResource(resourceId)}
            title={(resources as any)[resourceId].label || resourceId}
            titleTypographyProps={{ variant: "body1" }}
            action={
              <ResourceItem
                resource={resourceId}
                flag={authorizations[resourceId]}
              />
            }
          />
        </ui.Card>
      )}
    </ui.Grid>
  ));

  return (
    <ui.Box className={classes.root}>
      <ui.Grid container spacing={2}>
        {role.roleName === roles.root.identifier && (
          <SystemNotificationForm editable date />
        )}
        {resourceItems}
      </ui.Grid>
    </ui.Box>
  );
}

export { HomeMenu };
