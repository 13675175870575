import * as ui from "@material-ui/core";
import { Link } from "react-router-dom";

import ExitToApp from "@material-ui/icons/ExitToApp";

const labels: { [key: string]: string } = {
  login: "再ログイン",
};

interface LoginItemProps {
  onClicked: (e: any) => void;
}

function LoginItem(props: LoginItemProps) {
  return (
    <ui.ListItem button component={Link} to="/" onClick={props.onClicked}>
      <ui.ListItem>
        <ui.ListItemIcon>
          <ExitToApp />
        </ui.ListItemIcon>
        <ui.ListItemText>{labels.login}</ui.ListItemText>
      </ui.ListItem>
    </ui.ListItem>
  );
}

export { LoginItem };
