const save = (key: string, value: string, expireSeconds: number) => {
  const date = new Date();
  date.setTime(date.getTime() + expireSeconds * 1000);
  const offset = -date.getTimezoneOffset() / 60;
  document.cookie = `${key}=${value};timezone=${offset};expires=${date.toUTCString()};path=/`;
};

const get = (name: string): string => {
  const kvs = document.cookie.split(";");
  for (let i = 0; i < kvs.length; i += 1) {
    const [key, value] = kvs[i].split("=");
    if (key.trim() === name) {
      return value;
    }
  }
  return "";
};

const remove = (key: string) => {
  const date = new Date();
  date.setTime(0);
  const offset = -date.getTimezoneOffset() / 60;
  document.cookie = `${key}=;timezone=${offset};expires=${date.toUTCString()};path=/`;
};

export { save, get, remove };
