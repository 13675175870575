import { request, methods } from "./request";
import { paths } from "../schema/api";

type ReportImageTypesGetResponse =
  paths["/report_image_types"]["get"]["responses"][200]["schema"];

class ReportImageType {
  getAll(token: string = ""): Promise<ReportImageTypesGetResponse> {
    return request({
      token,
      method: methods.Get,
      resource: "report_image_types",
    });
  }
}

export { ReportImageType };
export type { ReportImageTypesGetResponse };
