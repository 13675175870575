import * as ui from "@material-ui/core";
import { definitions } from "../schema/api";

const labels: { [key: string]: string } = {
  all: "すべて",
  staff: "スタッフ",
};

interface StaffSelectorProps {
  all?: boolean;
  staffs: definitions["Staff"][];
  selectedValue: number;
  label?: string;
  onChanged: (staffId: number) => void;
}

function staffsToMenuItems(staffs: definitions["Staff"][]): JSX.Element[] {
  const doms = [];
  for (let i = 0; i < staffs.length; i += 1) {
    const staff = staffs[i];
    doms.push(
      <ui.MenuItem key={`staff${staff.id}`} value={staff.id}>
        {staff.name}
      </ui.MenuItem>
    );
  }
  return doms;
}

function StaffSelector(props: StaffSelectorProps) {
  const onStaffChanged = (e: any) => {
    props.onChanged(parseInt(e.target.value, 10));
  };
  return (
    <ui.Box>
      <ui.InputLabel id="staff-id-label">{props.label || labels.staff}</ui.InputLabel>
      <ui.Select
        fullWidth
        labelId="staff-id-label"
        id="staff-id"
        name="staff_id"
        value={props.selectedValue || 0}
        onChange={onStaffChanged}
      >
        {props.all && (
          <ui.MenuItem key={`staff-all`} value={0}>
            {labels.all}
          </ui.MenuItem>
        )}
        {staffsToMenuItems(props.staffs)}
      </ui.Select>
    </ui.Box>
  );
}

export { StaffSelector };
