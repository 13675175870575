import * as ui from "@material-ui/core";
import * as auth from "../../auth";
import * as rest from "../../rest";
import { definitions } from "../../schema/api";
import { rules } from "../../validation/rules";
import { CsvTable } from "../../components/csv_table";
import * as format from "../../strings/format";

const labels = {
  succeed: "一括登録に成功しました",
  failed: "一括登録に失敗しました",
};

const chainPropertyLabelMap: { [name: string]: string } = {
  name: "チェーン名",
  name_kana: "チェーン名カナ",
};

type BulkInsertProps = {
  showGlobalNotification?: (message: string) => void;
};

function BulkInsert(props: BulkInsertProps) {
  const chainTemplate: definitions["Chain"] = {
    id: 0,
    name: "",
    name_kana: "",
  };

  const dataMediator = (
    col: string,
    val: string,
    ref: definitions["Chain"]
  ) => {
    switch (col) {
      case "name":
      case "name_kana": {
        (ref as any)[col] = format.trim(format.singleByteSpace(val));
        break;
      }
      default: {
        (ref as any)[col] = val;
        break;
      }
    }
  };

  const onRegister = (chains: definitions["Chain"][]) => {
    const chain = new rest.Chain();
    chain
      .put(chains, auth.getToken())
      .then((ret: object) => {
        props.showGlobalNotification &&
          props.showGlobalNotification(labels.succeed);
      })
      .catch(() => {
        props.showGlobalNotification &&
          props.showGlobalNotification(labels.failed);
      });
  };

  return (
    <ui.Container>
      <ui.Grid container spacing={2} xs={12}>
        <ui.Grid item xs={12} />
        <CsvTable<definitions["Chain"]>
          colLabels={chainPropertyLabelMap}
          default={chainTemplate}
          rule={rules.chain}
          dataMediator={dataMediator}
          onRegister={onRegister}
        />
      </ui.Grid>
    </ui.Container>
  );
}

export { BulkInsert };
