import { definitions } from "../../schema/api";

const createCalendarVisit = (): definitions["CalendarVisit"] => {
  return {
    id: 0,
    calendar_id: 0,
    chain_id: 0,
    store_id: 0,
    is_meeting: false,
    is_training: false,
  };
};

const createMeetingCalendarVisit = (): definitions["CalendarVisit"] => {
  const visit = createCalendarVisit();
  visit.is_meeting = true;
  return visit;
};

const createTrainingCalendarVisit = (): definitions["CalendarVisit"] => {
  const visit = createCalendarVisit();
  visit.is_training = true;
  return visit;
};

export {
  createCalendarVisit,
  createMeetingCalendarVisit,
  createTrainingCalendarVisit,
};
