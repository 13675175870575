import react from "react";
import * as ui from "@material-ui/core";
import * as c from "../../const";
import * as auth from "../../auth";
import * as rest from "../../rest";
import { definitions } from "../../schema/api";
import { createStaff } from "../../const/definitions/staff";
import { createUser } from "../../const/definitions/user";
import { roles } from "../../const/role";
import { Loading } from "../../components/loading";
import { Form } from "./form";

const labels: { [key: string]: string } = {
  succeed: "登録しました",
  failed: "登録に失敗しました",
  clientRetrieveFailed: "管理者の登録情報が不正です",
};

type CreateState = {
  user: definitions["User"];
  staff: definitions["Staff"];
  client?: definitions["Client"];
  clientCompany?: definitions["ClientCompany"];
  clients: rest.ClientsGetResponse;
  clientCompanyId: number;
  initialized: boolean;
};
type CreateProps = {
  showGlobalNotification?: (message: string) => void;
};

function Create(props: CreateProps) {
  const [state, setState] = react.useState<CreateState>({
    user: createUser(),
    staff: createStaff(),
    clients: [],
    clientCompanyId: 0,
    initialized: false,
  });

  const refreshClients = (clientCompanyId: number) => {
    const client = new rest.Client();
    return client
      .getAllByClientCompanyId(clientCompanyId, [], auth.getToken())
      .then((json: rest.ClientsGetResponse) => {
        state.staff.client_id = json.length > 0 ? json[0].id : 0;
        state.clients = json;
      });
  };

  react.useEffect(() => {
    if (!state.initialized) {
      state.initialized = true;

      const role = auth.getRole();
      if (role?.roleName === roles.client.identifier) {
        new rest.User()
          .getMe(["Client.ClientCompany"], auth.getToken())
          .then((user: rest.UserGetMeResponse) => {
            if (!user.client?.client_company) {
              props.showGlobalNotification &&
                props.showGlobalNotification(labels.clientRetrieveFailed);
              return;
            }
            state.client = user.client!;
            state.staff.client_id = state.client.id;
            state.clientCompany = user.client!.client_company;
            state.clientCompanyId = state.clientCompany.id;
          })
          .then(() => setState({ ...state }));
      } else {
        setState({ ...state });
      }
    }
  });

  const onNameChanged = (name: string) => {
    state.user.name = name;
    state.staff.name = name;
    setState({ ...state });
  };

  const onChanged = (
    attribute: "name_kana" | "phone" | "area" | "group" | "email_pc",
    value: string
  ) => {
    state.staff[attribute] = value;
    setState({ ...state });
  };

  const onEmailChanged = (email: string) => {
    state.user.email = email;
    setState({ ...state });
  };
  const onPasswordChanged = (password: string) => {
    state.user.password = password;
    setState({ ...state });
  };

  const onClientChanged = (clientId: number) => {
    state.staff.client_id = clientId;
    setState({ ...state });
  };
  const onClientCompanyChanged = (id: number) => {
    state.clientCompanyId = id;
    refreshClients(state.clientCompanyId).then(() => setState({ ...state }));
  };
  const onIsQuittedChanged = (quitted: boolean) => {
    state.staff.is_quitted = quitted;
    setState({ ...state });
  };

  const onSubmit = () => {
    const staff = new rest.Staff();
    staff
      .put(
        [
          {
            staff: state.staff,
            user: {
              id: c.api.newRecordId,
              name: state.user.name,
              email: state.user.email,
              password: state.user.password || "",
            },
          },
        ],
        auth.getToken()
      )
      .then((ret: object) => {
        props.showGlobalNotification &&
          props.showGlobalNotification(labels.succeed);
        state.staff = createStaff();
        state.user = createUser();
        setState({ ...state });
      })
      .catch(() => {
        props.showGlobalNotification &&
          props.showGlobalNotification(labels.failed);
      });
  };

  if (!state.initialized) {
    return <Loading />;
  }

  return (
    <ui.Container>
      <ui.Grid
        container
        spacing={0}
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        <Form
          clientCompanyId={state.clientCompanyId}
          clients={state.clients}
          client={state.client}
          clientCompany={state.clientCompany}
          user={state.user}
          staff={state.staff}
          onClientCompanyChanged={onClientCompanyChanged}
          onClientChanged={onClientChanged}
          onNameChanged={onNameChanged}
          onNameKanaChanged={(v) => onChanged("name_kana", v)}
          onGroupChanged={(v) => onChanged("group", v)}
          onAreaChanged={(v) => onChanged("area", v)}
          onEmailPcChanged={(v) => onChanged("email_pc", v)}
          onPhoneChanged={(v) => onChanged("phone", v)}
          onEmailChanged={onEmailChanged}
          onIsQuittedChanged={onIsQuittedChanged}
          onPasswordChanged={onPasswordChanged}
          onSubmit={onSubmit}
        />
      </ui.Grid>
    </ui.Container>
  );
}

export { Create };
