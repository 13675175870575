import react from "react";
import * as ui from "@material-ui/core";
import { useParams } from "react-router-dom";
import * as auth from "../../auth";
import * as rest from "../../rest";
import { createReport } from "../../const/definitions/report";
import { createStaff } from "../../const/definitions/staff";
import { definitions } from "../../schema/api";
import { createFormValidator } from "../../validation/form";
import { Loading } from "../../components/loading";
import { ErrorDisplay } from "../../components/error_display";
import { NavigatorBack } from "../../components/navigator_back";
import { CommonInstructionReport } from "./form/common_instruction_report";
import { IndividualInstructionReports } from "./form/individual_instruction_reports";
import { ReportImageInput } from "./form/report_image_input";

const labels = {
  list: "一覧",
  edit: "編集",
  delete: "削除",
  notFound: "日報が見つかりませんでした",
};

type ShowState = {
  myStaffId?: number;
  initialized: boolean;
  report: definitions["Report"];
  instructions: definitions["Instruction"][];
  staff: definitions["Staff"];
  baseReportImageIds: number[];
  deleted: boolean;
};
type ShowProps = {
  showGlobalNotification?: (message: string) => void;
};

type ShowParams = {
  id: string;
};

function Show(props: ShowProps) {
  const params = useParams<ShowParams>();
  const reportId = parseInt(params.id as string, 10);

  const [state, setState] = react.useState<ShowState>({
    initialized: false,
    report: createReport(),
    instructions: [],
    staff: createStaff(),
    baseReportImageIds: [],
    deleted: false,
  });

  react.useEffect(() => {
    if (state.initialized) {
      return;
    }
    (async () => {
      state.initialized = true;

      const reportScopes = [
        "ReportImages",
        "IndividualReports",
        "IndividualReports.IndividualReportListItems",
        "Store",
      ];
      const [json, user] = await Promise.all([
        (new rest.Report()).get(reportId, reportScopes, auth.getToken()),
        (new rest.User()).getMe(["Staff"], auth.getToken()),
      ]);
      if (user.staff && user.staff?.id > 0) {
        state.myStaffId = user.staff!.id;
      }
      if ((json as definitions["Response404"]).message !== undefined) {
        state.deleted = true;
      } else {
        state.report = json as rest.ReportGetResponse;
        state.baseReportImageIds = state.report.report_images.map(({ id }) => id);
        const instructionIds = (state.report.individual_reports || []).map(({ instruction_id: id }) => id);
        state.report.report_images.forEach(({ instruction_id: id }) => {
          if (id && !instructionIds.includes(id)) {
            instructionIds.push(id);
          }
        });
        const staffScopes = [
          "Client",
          "Client.ClientCompany",
          "Client.Chains",
          "Client.ClientStores.Store",
          "Stores",
        ];
        const [_, staff, instructions] = await Promise.all([
          (new rest.Report()).check(reportId, user.id, auth.getToken()),
          (new rest.Staff()).get(state.report.staff_id, staffScopes, auth.getToken()),
          (new rest.Instruction()).getByIds(instructionIds, ["InstructionListItems"], auth.getToken()),
        ]);
        state.staff = staff;
        state.instructions = instructions;
      }

      setState({ ...state });
    })();
  });

  if (!state.initialized) {
    return <Loading />;
  }

  if (state.deleted) {
    return <ErrorDisplay message={labels.notFound} />;
  }

  return (
    <ui.Container>
      <ui.Grid
        container
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        <ui.Grid item xs={12}></ui.Grid>
        <ui.Grid item xs={12}>
          <NavigatorBack xs={12} sm={12} label={labels.list} back />
        </ui.Grid>
        <ui.Grid item xs={12}>
          <CommonInstructionReport
            validator={createFormValidator("report")}
            confirm={true}
            staffs={[]}
            report={state.report}
            staff={state.staff}
            onDateChanged={(date: Date) => {}}
            onStaffChanged={(staffId: number) => {}}
            onReportChanged={() => {}}
            onReportChangedWithInstructionChanges={() => {}}
          />
        </ui.Grid>
        <ui.Grid item xs={12}>
          <IndividualInstructionReports
            confirm={true}
            instructions={state.instructions}
            individualReports={
              !state.report.is_meeting && !state.report.is_training
                ? state.report.individual_reports
                : []
            }
            onChanged={() => {}}
          />
        </ui.Grid>
        <ui.Grid item xs={12}>
          <ReportImageInput
            reportId={state.report.id}
            instructions={state.instructions}
            confirm={true}
            images={state.report.report_images}
            onFileChanged={() => {}}
          />
        </ui.Grid>
        <ui.Grid item xs={12}>
          <NavigatorBack xs={12} sm={12} label={labels.list} back />
        </ui.Grid>
      </ui.Grid>
    </ui.Container>
  );
}

export { Show };
