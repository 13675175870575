import * as rest from "../rest";
import { controls } from "../const/resource";
import * as cookie from "../storage/cookie";
import { storage } from "../const/storage";

interface Authorization {
  crud: number;
  resource_name: string;
}

interface AuthorizationMap {
  [key: string]: number;
}

interface Role {
  userId: number;
  userName: string;
  roleName: string;
}

const resourceCrudFlag = Object.freeze({
  create: 1 << 0,
  retrieve: 1 << 1,
  update: 1 << 2,
  delete: 1 << 3,
});

const resourceCrudIdentifierToFlag = (identifier: string): number => {
  switch (identifier) {
    case controls.create.identifier:
      return resourceCrudFlag.create;
    case controls.retrieve.identifier:
      return resourceCrudFlag.retrieve;
    case controls.show.identifier:
      return resourceCrudFlag.retrieve;
    case controls.update.identifier:
      return resourceCrudFlag.update;
    case controls.remove.identifier:
      return resourceCrudFlag.delete;
    default:
      return 0;
  }
};

const isAuthorized = (resource: string | number, flag: number): boolean => {
  let authorizationLevel: number = 0;
  if (typeof resource === "string") {
    const authorizations = getAuthorizations();
    if (!authorizations) {
      return false;
    }

    authorizationLevel = authorizations[resource];
    if (!authorizationLevel) {
      return false;
    }
  } else {
    authorizationLevel = resource;
  }

  return (authorizationLevel & flag) === flag;
};

type LoginParams = rest.AuthPostParam;
const login = async (params: LoginParams): Promise<rest.UserGetIdResponse | null> => {
  const res = await (new rest.Auth()).post(params, getToken());
  if (!res.token) {
    return null;
  }
  saveToken(res.token);

  const mappedAuthorizations: AuthorizationMap = {};
  res.authorizations.forEach((authorization: Authorization) => {
    mappedAuthorizations[authorization.resource_name] = authorization.crud;
  });

  const user: rest.UserGetIdResponse = await (new rest.User()).getMe(["Role", "UserExtendedAuthorizations"], getToken());
  user.user_extended_authorizations?.forEach(({ resource_name, crud }) => {
    mappedAuthorizations[resource_name] = crud;
  });

  saveAuthorizations(mappedAuthorizations);
  saveRole({
    userId: user.id || 0,
    userName: user.name || "",
    roleName: user.role?.name || "",
  });

  return user;
};

const logout = ({ redirect }: { redirect: boolean } = { redirect: true }) => {
  cookie.remove(storage.token.key);
  cookie.remove(storage.authorization.key);
  cookie.remove(storage.role.key);

  if (redirect) {
    window.location.href = "/";
  }
};

const saveAuthorizations = (authorizations: AuthorizationMap) => {
  const jsonStr = JSON.stringify(authorizations);
  cookie.save(
    storage.authorization.key,
    jsonStr,
    storage.authorization.expires
  );
};
const getAuthorizations = (): AuthorizationMap | null => {
  try {
    return JSON.parse(cookie.get(storage.authorization.key));
  } catch (e) {
    return null;
  }
};

const saveRole = (role: Role) => {
  const cookieRole = { ...role };
  cookieRole.userName = encodeURIComponent(cookieRole.userName);

  const jsonStr = JSON.stringify(cookieRole);
  cookie.save(storage.role.key, jsonStr, storage.role.expires);
};
const getRole = (): Role | null => {
  try {
    const role = JSON.parse(cookie.get(storage.role.key));
    role.userName = decodeURIComponent(role.userName);
    return role;
  } catch (e) {
    return null;
  }
};

const saveToken = (token: string) => {
  cookie.save(storage.token.key, token, storage.token.expires);
};

const getToken = (): string => {
  return cookie.get(storage.token.key);
};

const hasToken = (): boolean => {
  return !!getToken();
};

const extendToken = () => {
  const token = cookie.get(storage.token.key);
  if (token) {
    cookie.save(storage.token.key, token, storage.token.expires);
    const authorizations = cookie.get(storage.authorization.key);
    if (authorizations) {
      cookie.save(
        storage.authorization.key,
        authorizations,
        storage.authorization.expires
      );
    }
  }
};

const validateCurrentToken = (): boolean => {
  if (!getToken()) {
    return false;
  }
  return true;
};

export {
  validateCurrentToken,
  hasToken,
  getToken,
  getRole,
  login,
  logout,
  isAuthorized,
  getAuthorizations,
  extendToken,
  resourceCrudIdentifierToFlag,
  resourceCrudFlag,
};
export type { Authorization, AuthorizationMap, LoginParams, Role };
