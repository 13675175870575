import { useNavigate } from "react-router-dom";
import * as ui from "@material-ui/core";
import ArrowBack from "@material-ui/icons/ArrowBack";

type NavigatorBackProps = {
  xs?: boolean | ui.GridSize;
  sm?: boolean | ui.GridSize;
  back?: boolean;
  href?: string;
  label?: string;
};

function NavigatorBack(props: NavigatorBackProps) {
  const navigate = useNavigate();

  return (
    <ui.Grid
      container
      item
      xs={props.xs}
      sm={props.sm}
      justifyContent="flex-start"
      alignItems="center"
    >
      <ui.IconButton
        style={{ padding: "12px 0px" }}
        onClick={() => {
          if (props.back) {
            window.history.back();
          } else if (props.href) {
            navigate(props.href);
          }
        }}
      >
        <ArrowBack />
        <ui.Typography variant="body1">{props.label}</ui.Typography>
      </ui.IconButton>
    </ui.Grid>
  );
}

export { NavigatorBack };
