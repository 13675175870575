const defaultLocale = "ja-JP";
const defaultLanguageCode = "ja";

const locales = {
  ja: "ja-JP",
};

function currentLocale(): string {
  return defaultLocale;
}
function currentLanguageCode(): string {
  return defaultLanguageCode;
}

export {
  locales,
  currentLocale,
  currentLanguageCode,
  defaultLocale,
  defaultLanguageCode,
};
