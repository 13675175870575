import react from "react";
import * as ui from "@material-ui/core";
import { definitions } from "../schema/api";
import { FormattedTextField } from "./formatted_text_field";
import { createFormValidator, FormValidator } from "../validation/form";

const labels = {
  email: "ログインメールアドレス",
  password: "パスワード",
  updateLoginInfo: "ログイン情報を更新する",
};

type UserFormState = {
  validator: FormValidator;
};

type UserFormProps = {
  show: boolean;
  user: definitions["User"];
  onEmailChanged: (email: string) => void;
  onPasswordChanged: (password: string) => void;
  onErrorUpdated?: (hasError: boolean) => void;
  onShowToggled: () => void;
};

function UserForm(props: UserFormProps) {
  const validator = createFormValidator("user");
  if (props.onErrorUpdated) {
    validator.onValidated = (errors) => {
      const hasError = !!Object.keys(errors).find(
        (key) => errors[key] && errors[key].length > 0
      );
      props.onErrorUpdated!(hasError);
    };
  }

  const [state] = react.useState<UserFormState>({
    validator,
  });

  return props.show ? (
    <>
      <ui.Grid item xs={6}>
        <FormattedTextField
          {...state.validator.formProps.email}
          email
          id="email-id"
          label={labels.email}
          onBlur={(e) => {
            state.validator.validate(e.target.value, "email");
            props.onEmailChanged(e.target.value);
          }}
          fullWidth
          rows={6}
          defaultValue={props.user.email}
          variant="outlined"
        />
      </ui.Grid>
      <ui.Grid item xs={6}>
        <ui.TextField
          {...state.validator.formProps.password}
          id="password-id"
          label={labels.password}
          onBlur={(e) => {
            state.validator.validate(e.target.value, "password");
            props.onPasswordChanged(e.target.value);
          }}
          fullWidth
          type="password"
          rows={6}
          defaultValue={""}
          variant="outlined"
        />
      </ui.Grid>
    </>
  ) : (
    <ui.Grid item xs={12}>
      <ui.Grid container justifyContent="center" alignItems="center">
        <ui.Button
          variant="contained"
          color="primary"
          onClick={() => props.onShowToggled()}
        >
          {labels.updateLoginInfo}
        </ui.Button>
      </ui.Grid>
    </ui.Grid>
  );
}

export { UserForm };
