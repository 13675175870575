import { request, methods } from "./request";
import { paths, definitions } from "../schema/api";
import { createUser } from "../const/definitions/user";

type ClientGetResponse =
  paths["/client/{id}"]["get"]["responses"][200]["schema"];
type ClientsGetResponse = paths["/clients"]["get"]["responses"][200]["schema"];
type ClientsGetParam = paths["/clients"]["get"]["parameters"]["query"];
type ClientsPutParam =
  paths["/clients"]["put"]["parameters"]["body"]["clients"];
type ClientPostParam =
  paths["/client/{id}"]["post"]["parameters"]["body"]["client"];

class Client {
  get(
    id: number,
    arg2: string[] | string,
    arg3: string = ""
  ): Promise<ClientGetResponse> {
    let params = {};
    let token = "";
    if (typeof arg2 === "string") {
      token = arg2;
    } else {
      params = { scope: arg2.join(",") };
      token = arg3;
    }
    return request({
      token,
      params,
      method: methods.Get,
      resource: `client/${id}`,
    });
  }
  getAll(
    arg1: string[] | string,
    arg2: string = ""
  ): Promise<ClientsGetResponse> {
    let params = {};
    let token = "";
    if (typeof arg1 === "string") {
      token = arg1;
    } else {
      params = { scope: arg1.join(",") };
      token = arg2;
    }
    return request({
      token,
      params,
      method: methods.Get,
      resource: "clients",
    });
  }
  getAllByClientCompanyId(
    clientCompanyId: number,
    arg2: string[] | string,
    arg3: string = ""
  ): Promise<ClientsGetResponse> {
    const params: ClientsGetParam = { client_company_id: clientCompanyId };
    let token = "";
    if (typeof arg2 === "string") {
      token = arg2;
    } else {
      params.scope = arg2.join(",");
      token = arg3;
    }

    return request({
      token,
      params,
      method: methods.Get,
      resource: "clients",
    });
  }
  post(id: number, client: definitions["Client"], token: string = "") {
    return this.postSensitive(
      id,
      false,
      {
        client,
        user: createUser(),
      },
      token
    );
  }
  postSensitive(
    id: number,
    sensitive: boolean,
    client: ClientPostParam,
    token: string = ""
  ) {
    return request({
      token,
      method: methods.Post,
      resource: `client/${id}?sensitive=${sensitive}`,
      params: client,
    });
  }
  put(clients: ClientsPutParam, token: string = "") {
    return request({
      token,
      method: methods.Put,
      resource: "clients",
      params: clients,
    });
  }
}

export { Client };
export type {
  ClientGetResponse,
  ClientsGetResponse,
  ClientsPutParam,
  ClientPostParam,
};
