import { request, methods } from "./request";
import { paths } from "../schema/api";

type ClientReaderGetResponse =
  paths["/client_reader/{id}"]["get"]["responses"][200]["schema"];
type ClientReadersGetResponse =
  paths["/client_readers"]["get"]["responses"][200]["schema"];
type ClientReaderGetParam =
  paths["/client_reader/{id}"]["get"]["parameters"]["query"];
type ClientReadersGetParam =
  paths["/client_readers"]["get"]["parameters"]["query"];
type ClientReadersPutParam =
  paths["/client_readers"]["put"]["parameters"]["body"]["client_readers"];
type ClientReaderPostParam =
  paths["/client_reader/{id}"]["post"]["parameters"]["body"]["client_reader"];

class ClientReader {
  get(id: number, arg2: string[] | string, arg3: string = "") {
    let params: ClientReaderGetParam = {};
    let token = "";
    if (typeof arg2 === "string") {
      token = arg2;
    } else {
      token = arg3;
      params = { scope: arg2.join(",") };
    }

    return request({
      params,
      token,
      method: methods.Get,
      resource: `/client_reader/${id}`,
    });
  }

  getAllByClientCompanyId(
    clientComapnyId: number,
    arg2: string[] | string,
    arg3: string = ""
  ): Promise<ClientReadersGetResponse> {
    const params: ClientReadersGetParam = {
      client_company_id: clientComapnyId,
    };
    let token = "";
    if (typeof arg2 === "string") {
      token = arg2;
    } else {
      params.scope = arg2.join(",");
      token = arg3;
    }

    return request({
      token,
      params,
      method: methods.Get,
      resource: "client_readers",
    });
  }
  put(clientReaders: ClientReadersPutParam, token: string = "") {
    return request({
      token,
      method: methods.Put,
      resource: "client_readers",
      params: clientReaders,
    });
  }

  post(id: number, clientReader: ClientReaderPostParam, token: string = "") {
    return this.postSensitive(id, false, clientReader, token);
  }
  postSensitive(
    id: number,
    sensitive: boolean,
    client: ClientReaderPostParam,
    token: string = ""
  ) {
    return request({
      token,
      method: methods.Post,
      resource: `client_reader/${id}?sensitive=${sensitive}`,
      params: client,
    });
  }
}

export { ClientReader };
export type {
  ClientReaderGetResponse,
  ClientReadersGetResponse,
  ClientReaderPostParam,
  ClientReadersPutParam,
};
