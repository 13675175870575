import * as ui from "@material-ui/core";
import { definitions } from "../../schema/api";
import { resources, controls } from "../../const/resource";
import * as auth from "../../auth";
import * as rest from "../../rest";
import { Form } from "./form";

const labels = {
  failed: "掲示板の作成に失敗しました",
};

type CreateProps = {
  showGlobalNotification?: (message: string) => void;
};

function Create(props: CreateProps) {
  const onSubmit = (
    noticeBoard: definitions["NoticeBoard"],
    files: File[],
    noticeBoardFileIdsBeforeUpdate: number[]
  ) => {
    const now = new Date();
    new rest.NoticeBoard()
      .put(
        noticeBoard,
        now.getFullYear(),
        now.getMonth() + 1,
        now.getDate(),
        auth.getToken()
      )
      .then((json: rest.NoticeBoardPutResponse) =>
        new rest.NoticeBoardFile().post(json.id, files, auth.getToken())
      )
      .then(() => {
        window.location.href = `/${resources.notice_board.identifier}/${controls.retrieve.identifier}`;
      })
      .catch(() => {
        props.showGlobalNotification &&
          props.showGlobalNotification(labels.failed);
      });
  };

  return (
    <ui.Container>
      <Form onSubmit={onSubmit} />
    </ui.Container>
  );
}

export { Create };
