import react from "react";
import * as ui from "@material-ui/core";
import { Hidden } from '@mui/material';
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import * as auth from "../auth";
import { Names } from "../const/resource";
import { Header } from "./role_menu/header";
import { Account } from "./role_menu/account";
import { SideMenu } from "./role_menu/side_menu";
import { ErrorDisplay } from "../components/error_display";

const labels: { [key: string]: string } = {
  unauthorized: "この操作は許可されていません",
  back: "戻る",
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    sectionDesktop: {
      display: "flex",
    },
    center: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    contextText: {
      color: "#aaaaaa",
      textAlign: "center",
      fontWeight: "bold",
    },
    invalid: {
      backgroundColor: "#667777",
    },
    unauthorized: {
      backgroundColor: "#d91e11",
    },
    root: {
      backgroundColor: "#34314a",
    },
    admin: {
      backgroundColor: "#999999",
    },
    client: {
      backgroundColor: "#188038",
    },
    client_reader: {
      backgroundColor: "#43b9df",
    },
    staff: {
      backgroundColor: "#ec6e6c",
    },
  })
);

interface RoleMenuProps {
  title?: string;
  resource?: Names;
  control?: Names;
  userName?: string;
  roleName?: string;
  authorized?: boolean;
  children?: JSX.Element | JSX.Element[];
}

type RoleMenuState = {
  anchor?: Element | ((element: Element) => Element) | null;
  open: {
    menu: boolean;
    account: boolean;
  };
};

function RoleMenu(props: RoleMenuProps) {
  const [state, setState] = react.useState<RoleMenuState>({
    anchor: null,
    open: {
      menu: false,
      account: false,
    },
  });
  const classes = useStyles();

  const toggleMenu = (menu: "account" | "menu", e: any) => {
    state.open[menu] = !state.open[menu];
    if (menu === "account") {
      state.anchor = state.open[menu] ? e.currentTarget : null;
    }
    setState({ ...state });
  };

  let title = props.title || "";

  if (props.resource && props.control) {
    if (!title) {
      title = `${props.resource.label} | ${props.control.label}`;
    }
  }

  return (
    <ui.Box className={classes.grow}>
      {props.authorized ? (
        <ui.AppBar
          position="static"
          className={
            props.roleName
              ? (classes as any)[props.roleName] || classes.invalid
              : classes.invalid
          }
        >
          <Header
            title={title}
            name={props.userName || ""}
            role={props.roleName || ""}
            menuButton
            accountButton
            onAccountToggled={(e) => toggleMenu("account", e)}
            onMenuToggled={(e) => toggleMenu("menu", e)}
          />

          <Hidden smUp implementation="css">
            <SideMenu
              open={state.open.menu}
              onToggled={(e) => toggleMenu("menu", e)}
            />
          </Hidden>

          <Account
            name={props.userName || ""}
            open={state.open.account}
            onClose={(e) => toggleMenu("account", e)}
            anchor={state.anchor}
          />
        </ui.AppBar>
      ) : (
        <ui.AppBar position="static" className={classes.unauthorized}>
          <Header title={title} />
        </ui.AppBar>
      )}
      {(props.resource &&
        props.control &&
        auth.isAuthorized(
          props.resource.identifier,
          auth.resourceCrudIdentifierToFlag(props.control.identifier)
        )) ||
      !props.resource ||
      !props.control ? (
        props.children
      ) : (
        <ErrorDisplay message={labels.unauthorized} />
      )}
    </ui.Box>
  );
}

export { RoleMenu };
export type { RoleMenuProps };
