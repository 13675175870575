import { request, methods } from "./request";
import { paths } from "../schema/api";

type HolidaysGetResponse =
  paths["/holidays/{year}/{month}"]["get"]["responses"][200]["schema"];

class Holiday {
  get(
    year: number,
    month: number,
    token: string = ""
  ): Promise<HolidaysGetResponse> {
    return request({
      token,
      method: methods.Get,
      resource: `holidays/${year}/${month}`,
    });
  }
}

export { Holiday };
export type { HolidaysGetResponse };
