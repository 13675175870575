type Names = { identifier: string; label: string };

const roles: { [resource: string]: Names } = Object.freeze({
  root: {
    identifier: "root",
    label: "root",
  },
  admin: {
    identifier: "admin",
    label: "システム管理者",
  },
  staff: {
    identifier: "staff",
    label: "スタッフ",
  },
  client: {
    identifier: "client",
    label: "管理者",
  },
  client_company: {
    identifier: "client_company",
    label: "クライアント企業",
  },
  client_reader: {
    identifier: "client_reader",
    label: "クライアント担当者",
  },
});

export { roles };
