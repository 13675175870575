import react from "react";
import * as ui from "@material-ui/core";
import { resources } from "../../const/resource";
import * as auth from "../../auth";
import * as rest from "../../rest";
import { definitions } from "../../schema/api";
import { Loading } from "../../components/loading";
import { Form } from "./form";

type CreateState = {
  client?: definitions["Client"];
  initialized: boolean;
};
type CreateProps = {
  showGlobalNotification?: (message: string) => void;
};

function Create(props: CreateProps) {
  const [state, setState] = react.useState<CreateState>({
    initialized: false,
  });

  react.useEffect(() => {
    if (state.initialized) {
      return;
    }

    new rest.User()
      .getMe(["Client"], auth.getToken())
      .then((json: rest.UserGetIdResponse) => {
        if (!json) {
          return;
        }
        if (
          !auth.isAuthorized(
            resources.instruction.identifier,
            auth.resourceCrudFlag.create
          )
        ) {
          return;
        }

        state.client = json.client;
      })
      .then(() => {
        state.initialized = true;
        setState({ ...state });
      });
  });

  if (!state.initialized) {
    return <Loading />;
  }

  return (
    <ui.Container>
      <Form
        type="create"
        userClient={state.client}
        showGlobalNotification={props.showGlobalNotification}
      />
    </ui.Container>
  );
}

export { Create };
