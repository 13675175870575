import { request, methods } from "./request";
import { definitions, paths } from "../schema/api";
import { createUser } from "../const/definitions/user";

type StaffsGetResponse = paths["/staffs"]["get"]["responses"][200]["schema"];
type StaffGetIdResponse =
  paths["/staff/{id}"]["get"]["responses"][200]["schema"];
type StaffsGetParam = paths["/staffs"]["get"]["parameters"]["query"];
type StaffsPutParam = paths["/staffs"]["put"]["parameters"]["body"]["staffs"];
type StaffPostParam =
  paths["/staff/{id}"]["post"]["parameters"]["body"]["staff"];
type StaffPostParamOption = Partial<Omit<StaffPostParam, "staff" | "user">>;

class Staff {
  get(
    id: number,
    arg1: string[] | string,
    arg2: string = ""
  ): Promise<StaffGetIdResponse> {
    let params = {};
    let token = "";

    if (typeof arg1 === "string") {
      params = {};
      token = arg1;
    } else {
      params = { scope: arg1.join(",") };
      token = arg2;
    }

    return request({
      token,
      params,
      resource: `staff/${id}`,
      method: methods.Get,
    });
  }
  getAll(
    arg1: string | string[],
    arg2: string = ""
  ): Promise<StaffsGetResponse> {
    let params = {};
    let token = "";
    const resource = "staffs";

    if (typeof arg1 === "string") {
      token = arg1;
    } else {
      params = { scope: arg1 };
      token = arg2;
    }
    return request({
      token,
      params,
      resource,
      method: methods.Get,
    });
  }
  getByClientId(
    clientId: number,
    arg2: string | string[],
    arg3: string = ""
  ): Promise<StaffsGetResponse> {
    let params: StaffsGetParam = { client_id: clientId };
    let token = "";

    if (typeof arg2 === "string") {
      token = arg2;
    } else {
      params = { scope: arg2.join(",") };
      token = arg3;
    }

    return request({
      token,
      params,
      method: methods.Get,
      resource: "staffs",
    });
  }
  post(id: number, staff: definitions["Staff"], token: string = "", option: StaffPostParamOption = {} ) {
    return this.postSensitive(
      id,
      false,
      {
        staff,
        user: createUser(),
        ...option,
      },
      token
    );
  }
  postSensitive(
    id: number,
    sensitive: boolean,
    staff: StaffPostParam,
    token: string = ""
  ) {
    return request({
      token,
      method: methods.Post,
      resource: `staff/${id}?sensitive=${sensitive}`,
      params: staff,
    });
  }
  put(staffs: StaffsPutParam, token: string = "") {
    return request({
      token,
      method: methods.Put,
      resource: "staffs",
      params: staffs,
    });
  }
}

export { Staff };
export type {
  StaffsGetResponse,
  StaffGetIdResponse,
  StaffsPutParam,
  StaffPostParam,
};
