import { definitions } from "../../schema/api";
import { toRFC3339 } from "../../datetime/index";

const createWorkTime = (): definitions["WorkTime"] => {
  return {
    id: 0,
    staff_id: 0,
    year: 0,
    month: 0,
    date: 0,
    begin: toRFC3339(new Date()),
    end: toRFC3339(new Date()),
  };
};

export { createWorkTime };
