import { request, methods } from "./request";
import { paths } from "../schema/api";

type AuthPostParam = paths["/auth"]["post"]["parameters"]["body"]["login"];

class Auth {
  post(auth: AuthPostParam, token: string = "") {
    return request({
      token,
      method: methods.Post,
      resource: "auth",
      params: auth,
    });
  }
}

export { Auth };
export type { AuthPostParam };
