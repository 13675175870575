import { request, methods } from "./request";

class NoticeBoardFile {
  post(noticeBoardId: number, files: File[], token: string = "") {
    return Promise.all(
      files.map(
        (file) =>
          new Promise<{ name: string; content: string }>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e: any) => {
              const base64 = e.target.result.split(",")[1];
              resolve({
                name: file.name,
                content: base64,
              });
            };
            reader.onerror = (error) => {
              console.log(error);
              reject(error);
            };
            reader.readAsDataURL(file);
          })
      )
    ).then((nameAndContents: { name: string; content: string }[]) =>
      request({
        token,
        method: methods.Post,
        resource: "notice_board_files",
        params: nameAndContents.map(({ name, content }) => {
          return {
            content,
            id: 0,
            notice_board_id: noticeBoardId,
            file_name: name,
            file_path: "",
          };
        }),
      })
    );
  }

  delete(id: number, token: string = "") {
    return request({
      token,
      method: methods.Delete,
      resource: `notice_board_file/${id}`,
    });
  }
}

export { NoticeBoardFile };
