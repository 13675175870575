import { request, methods } from "./request";
import { paths } from "../schema/api";

type SystemNotificationGetResponse =
  paths["/system_notification"]["get"]["responses"][200]["schema"];
type SystemNotificationPostParam =
  paths["/system_notification"]["post"]["parameters"]["body"]["system_notification"];

class SystemNotification {
  get(): Promise<SystemNotificationGetResponse> {
    console.log("rest get");
    return request({
      method: methods.Get,
      resource: `system_notification`,
    });
  }
  post(params: SystemNotificationPostParam, token: string = "") {
    return request({
      token,
      params,
      method: methods.Post,
      resource: "system_notification",
    });
  }
  delete(token: string = "") {
    return request({
      token,
      method: methods.Delete,
      resource: "system_notification",
    });
  }
}

export { SystemNotification };
export type { SystemNotificationGetResponse, SystemNotificationPostParam };
