import react from "react";
import * as ui from "@material-ui/core";
import * as auth from "../../auth";
import * as rest from "../../rest";
import { api } from "../../const";
import { createStaff } from "../../const/definitions/staff";
import { activities } from "../../const/calendar_activity";
import { resources } from "../../const/resource";
import { definitions } from "../../schema/api";
import { StaffSelector } from "../../components/staff_selector";
import { Loading } from "../../components/loading";
import { Schedule } from "./create/schedule";
import { CalendarList } from "./create/calendar_list";

import "./calendar.css";

const labels: { [key: string]: string } = {
  staff: "スタッフ",
  staffNotSelected: "スケジュール登録可能なスタッフが選択されていません。",
  year: "年",
  month: "月",
  day: "日",
  dayHead: "日付",
  activity: "予定",
  memo: "メモ",
  checkWork: "チェックした日付を出勤登録",
  checkDayoff: "チェックした日付を休暇登録",
};

type CreateState = {
  initialized: boolean;
  isRoot: boolean;
  year: number;
  month: number;
  daysCheckboxValues: boolean[];
  chains: definitions["Chain"][];
  staffs: definitions["Staff"][];
  staff: definitions["Staff"];
  objectiveVisitCountByStoreId: { [storeId: number]: number };
  holidays: string[];
  storesById: { [id: number]: definitions["Store"] };
  calendarsByDay: (definitions["Calendar"] | null)[];
  reportsByDay: definitions["Report"][][];
  dialogCalendar: definitions["Calendar"] | null;
  dialogHolidayChecked: boolean;
  calendarActivities: definitions["CalendarActivity"][];
};

function Create() {
  const today = new Date();
  const days = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate();

  const [state, setState] = react.useState<CreateState>({
    initialized: false,
    isRoot: false,
    year: today.getFullYear(),
    month: today.getMonth() + 1,
    daysCheckboxValues: Array(days).fill(false),
    chains: [],
    staff: createStaff(),
    objectiveVisitCountByStoreId: {},
    staffs: [],
    storesById: {},
    holidays: [],
    calendarsByDay: Array(days).fill(null),
    reportsByDay: Array(days).fill([]),
    dialogCalendar: null,
    dialogHolidayChecked: false,
    calendarActivities: [],
  });

  react.useEffect(() => {
    if (!state.initialized) {
      state.initialized = true;

      Promise.all([
        new rest.User()
          .getMe(["Staff", "Client"], auth.getToken())
          .then((json: rest.UserGetIdResponse) => {
            const authenticated = auth.isAuthorized(
              resources.calendar.identifier,
              auth.resourceCrudFlag.create
            );

            if (!authenticated) {
              return Promise.reject();
            }

            if (json.staff && json.staff.id > 0) {
              state.staff = json.staff;
              return;
            }

            state.isRoot = !json.client || json.client.id === 0;

            return (
              state.isRoot
                ? new rest.Staff().getAll(["Stores"], auth.getToken())
                : new rest.Staff().getByClientId(
                    json.client!.id,
                    ["Stores"],
                    auth.getToken()
                  )
            ).then((json: rest.StaffsGetResponse) => {
              state.staffs = json;
              if (state.staffs.length > 0) {
                state.staff = state.staffs[0];
              }
            });
          })
          .then(() => onStaffIdChanged(state.staff?.id)),
        new rest.CalendarActivity()
          .getAll(auth.getToken())
          .then((json: rest.CalendarActivitiesGetResponse) => {
            state.calendarActivities = json;
          }),
      ])
        .then(() =>
          Promise.all([refreshCalendars(), refreshReports(), refreshHolidays()])
        )
        .then(() => setState({ ...state }));
    }
  });

  const onStaffIdChanged = (staffId?: number) => {
    if (!staffId) {
      return Promise.resolve(null);
    }

    let promise = null;
    if (state.staffs.length > 0) {
      const staff = state.staffs.find((staff) => staff.id === staffId);
      if (!staff) {
        return Promise.resolve(null);
      }
      promise = Promise.resolve(staff);
    } else {
      promise = new rest.Staff().get(staffId, ["Stores"], auth.getToken());
    }

    return promise
      .then(
        (staff: rest.StaffGetIdResponse | definitions["Staff"]) =>
          (state.staff = staff)
      )
      .then(() =>
        new rest.Client().get(
          state.staff.client_id,
          ["ClientStores"],
          auth.getToken()
        )
      )
      .then((client: rest.ClientGetResponse) => {
        state.objectiveVisitCountByStoreId = {};
        (state.staff.stores || []).forEach((store) => {
          state.objectiveVisitCountByStoreId[store.id] = 0;
        });
        (client.client_stores || []).forEach((clientStore) => {
          if (
            state.objectiveVisitCountByStoreId[clientStore.store_id] !==
            undefined
          ) {
            state.objectiveVisitCountByStoreId[clientStore.store_id] =
              clientStore.objective_visit_count!;
          }
        });
      })
      .then(() => {
        state.storesById = {};
        const chainIdMap: { [id: number]: boolean } = {};
        if (state.staff.stores) {
          for (let i = 0; i < state.staff.stores.length; i += 1) {
            const store = state.staff.stores[i];
            state.storesById[store.id] = store;
            chainIdMap[store.chain_id] = true;
          }
        }

        const chainIds = Object.keys(chainIdMap);
        if (chainIds.length === 0) {
          state.chains = [];
          return Promise.resolve();
        }

        return new rest.Chain()
          .getByIds(
            chainIds.map((id) => parseInt(id, 10)),
            auth.getToken()
          )
          .then((json) => {
            state.chains = json;
            const storeIds = Object.keys(state.storesById);
            for (let i = 0; i < state.chains.length; i += 1) {
              const chain = state.chains[i];
              chain.stores = [];
              for (let j = 0; j < storeIds.length; j += 1) {
                const store = (state.storesById as any)[storeIds[j]];
                if (store && store.chain_id === chain.id) {
                  chain.stores.push(store);
                }
              }
            }
          });
      });
  };

  const refreshReports = () => {
    if (!state.staff) {
      return Promise.resolve();
    }
    return new rest.Report()
      .getYearMonth(
        state.year,
        state.month,
        { staff_id: state.staff.id },
        auth.getToken()
      )
      .then((json: rest.ReportsGetYearMonthResponse) => {
        state.reportsByDay = [];
        for (let i = 0; i < days; i += 1) {
          state.reportsByDay.push([]);
        }

        for (let i = 0; i < json.length; i += 1) {
          const report = json[i];
          const date = new Date(report.date);
          state.reportsByDay[date.getDate() - 1]?.push(report);
        }
      });
  };

  const refreshCalendars = () => {
    if (!state.staff) {
      return Promise.resolve();
    }

    // state.month indicates current month, this line wants next line
    const days = new Date(state.year, state.month, 0).getDate();
    state.daysCheckboxValues = Array(days).fill(false);
    state.calendarsByDay = Array(days).fill(null);

    return new rest.Calendar()
      .getYearMonth(
        state.year,
        state.month,
        { staff_id: state.staff.id },
        ["Activity", "Visits"],
        auth.getToken()
      )
      .then((json: rest.CalendarsGetYearMonthResponse) => {
        for (let i = 0; i < json.length; i += 1) {
          const calendar = json[i];
          state.calendarsByDay[calendar.date - 1] = calendar;
        }
      });
  };

  const refreshHolidays = () => {
    return new rest.Holiday()
      .get(state.year, state.month, auth.getToken())
      .then((json: rest.HolidaysGetResponse) => {
        state.holidays = json;
      });
  };

  const onCheckAllChanged = (checked: boolean) => {
    state.daysCheckboxValues.fill(checked);
    setState({ ...state });
  };
  const onDayCheckChanged = (dayIndex: number, checked: boolean) => {
    state.daysCheckboxValues[dayIndex] = checked;
    setState({ ...state });
  };

  const onDayClicked = (calendar: definitions["Calendar"]) => {
    calendar.staff_id = state.staff.id;
    calendar.year = state.year;
    calendar.month = state.month;

    state.dialogCalendar = calendar;
    if (!state.calendarsByDay[calendar.date - 1]) {
      state.calendarsByDay[calendar.date - 1] = calendar;
    }

    setState({ ...state });
  };

  const onCloseDay = () => {
    state.dialogCalendar = null;
    setState({ ...state });
  };

  const onSubmitDay = (calendar: definitions["Calendar"]) => {
    if (!state.dialogCalendar) {
      return;
    }

    const dayIndex = state.dialogCalendar.date - 1;
    state.calendarsByDay[dayIndex] = state.dialogCalendar;

    return new rest.Calendar()
      .post(
        state.dialogCalendar.year,
        state.dialogCalendar.month,
        state.dialogCalendar.date,
        calendar,
        auth.getToken()
      )
      .then((res) => {
        const calendar = state.calendarsByDay[dayIndex];
        if (calendar) {
          calendar.id = res.id;
          if (calendar.visits) {
            for (let i = 0; i < calendar.visits.length; i += 1) {
              calendar.visits[i].calendar_id = res.id;
            }
          }
        }
        return refreshCalendars();
      })
      .then(() => {
        state.dialogCalendar = null;
        setState({ ...state });
      });
  };

  const yearRange = [];
  for (let year = 2021; year <= state.year + 1; year += 1) {
    yearRange.push(year);
  }

  const onYearChanged = (year: number) => {
    // state.month indicates current month, this line wants next line
    state.year = year;
    Promise.all([refreshCalendars(), refreshReports(), refreshHolidays()]).then(
      () => setState({ ...state })
    );
  };
  const onMonthChanged = (month: number) => {
    // state.month indicates current month, this line wants next line
    state.month = month;
    Promise.all([refreshCalendars(), refreshReports(), refreshHolidays()]).then(
      () => setState({ ...state })
    );
  };

  const alignActivities = (activityName: string): number[] => {
    const targetActivity = state.calendarActivities.find(
      (a: definitions["CalendarActivity"]) => a.name === activityName
    );

    if (!targetActivity) {
      return [];
    }

    return state.daysCheckboxValues.map((checked, index) => {
      if (!checked) {
        const day = index + 1;
        const targetDay = state.calendarsByDay[day - 1];
        if (targetDay) {
          return targetDay.activity_id;
        }
        return 0;
      }
      return targetActivity.id;
    });
  };

  const onSubmit = (e: React.SyntheticEvent, activityName: string) => {
    const alignedActivities = alignActivities(activityName);
    if (alignedActivities.length === 0) {
      console.log("willwork activity not found");
    }

    const params = {
      activities: alignedActivities,
      staff_id: state.staff.id,
    };
    return new rest.Calendar()
      .postMonth(state.year, state.month, params, auth.getToken())
      .then(() => refreshCalendars())
      .then(() => setState({ ...state }));
  };

  if (!state.initialized) {
    return <Loading />;
  }

  return (
    <ui.Container>
      <ui.Grid container spacing={2}>
        <ui.Grid item xs={12} />
        <ui.Grid container item xs={12} sm={9} md={10} alignItems="center">
          {state.staffs.length > 0 ? (
            <StaffSelector
              staffs={state.staffs}
              selectedValue={state.staff?.id || 0}
              onChanged={(staffId: number) => {
                onStaffIdChanged(staffId)
                  .then(() =>
                    Promise.all([refreshCalendars(), refreshReports()])
                  )
                  .then(() => setState({ ...state }));
              }}
            />
          ) : (
            <ui.Box>
              <ui.Typography variant="h6">
                {state.staff?.name || ""}
              </ui.Typography>
            </ui.Box>
          )}
        </ui.Grid>

        <ui.Grid item xs={3} sm={2} md={1}>
          <ui.InputLabel id="year-id-label">{labels.year}</ui.InputLabel>
          <ui.Select
            labelId="year-id-label"
            id="year-id"
            name="year"
            value={state.year}
            onChange={(e: any) => onYearChanged(parseInt(e.target.value, 10))}
          >
            {yearRange.map((year: number) => (
              <ui.MenuItem key={`year${year}`} value={year}>
                {year}
              </ui.MenuItem>
            ))}
          </ui.Select>
        </ui.Grid>
        <ui.Grid item xs={2} sm={1} md={1}>
          <ui.InputLabel id="month-id-label">{labels.month}</ui.InputLabel>
          <ui.Select
            labelId="month-id-label"
            id="month-id"
            name="month"
            value={state.month}
            onChange={(e: any) => onMonthChanged(parseInt(e.target.value, 10))}
          >
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month: number) => (
              <ui.MenuItem key={`month${month}`} value={month}>
                {month}
              </ui.MenuItem>
            ))}
          </ui.Select>
        </ui.Grid>
        <ui.Grid item xs={7} sm={12} />

        {state.staff.id === 0 ? (
          <ui.Grid container item xs={12}>
            <ui.Typography variant="h6">
              {labels.staffNotSelected}
            </ui.Typography>
          </ui.Grid>
        ) : (
          <ui.Grid container item xs={12}>
            <ui.Grid item xs={12}>
              <CalendarList
                year={state.year}
                month={state.month}
                holidays={state.holidays}
                daysCheckboxValues={state.daysCheckboxValues}
                storesById={state.storesById}
                calendarsByDay={state.calendarsByDay}
                reportsByDay={state.reportsByDay}
                calendarActivities={state.calendarActivities}
                onCheckAllChanged={onCheckAllChanged}
                onDayCheckChanged={onDayCheckChanged}
                onDayClicked={onDayClicked}
              />
            </ui.Grid>
            <ui.Grid
              container
              item
              xs={12}
              sm={6}
              justifyContent="center"
              direction="row"
              alignItems="center"
            >
              <ui.Button
                variant="contained"
                color="primary"
                type="button"
                onClick={(e) => onSubmit(e, api.calendarActivityNames.willWork)}
              >
                {labels.checkWork}
              </ui.Button>
            </ui.Grid>
            <ui.Grid
              container
              item
              xs={12}
              sm={6}
              justifyContent="center"
              direction="row"
              alignItems="center"
            >
              <ui.Button
                variant="contained"
                color="secondary"
                type="button"
                onClick={(e) => onSubmit(e, api.calendarActivityNames.dayoff)}
              >
                {labels.checkDayoff}
              </ui.Button>
            </ui.Grid>
          </ui.Grid>
        )}
      </ui.Grid>

      {state.dialogCalendar && (
        <Schedule
          calendar={state.dialogCalendar}
          calendarActivities={state.calendarActivities.filter(
            (activity) =>
              activity.name === activities.willwork.identifier ||
              activity.name === activities.meeting.identifier ||
              activity.name === activities.training.identifier ||
              activity.name === activities.dayoff.identifier
          )}
          objectiveVisitCountByStoreId={state.objectiveVisitCountByStoreId}
          chains={state.chains}
          onClose={onCloseDay}
          onSubmit={onSubmitDay}
        />
      )}
    </ui.Container>
  );
}

export { Create };
