import react, { useContext } from "react";
import * as ui from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as auth from "../auth";
import { SystemNotificationForm } from "../components/system_notification_form";
import { useNavigate } from "react-router-dom";
import { Context } from "../context/DataStoreContext";

const useStyles = makeStyles((theme) => ({
  themeButton: {
    backgroundColor: "#d91e11",
  },
}));

const labels = {
  email: "メールアドレス",
  password: "パスワード",
  submit: "ログイン",
  error: "ログインできませんでした、ログイン情報をご確認ください。",
};

type LoginState = {
  login: {
    email: string;
    password: string;
  };
  loginError: boolean;
};

function Login() {
  const classes = useStyles();
  const { dispatch } = useContext(Context);
  const navigate = useNavigate();

  const [state, setState] = react.useState<LoginState>({
    login: {
      email: "",
      password: "",
    },
    loginError: false,
  });

  const onChanged = (
    name: "email" | "password",
    event: react.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    state.login[name] = event.target.value;
    setState({ ...state });
  };

  const onSubmit = (event: React.SyntheticEvent) => {
    auth.login(state.login).then((res) => {
      res && dispatch && dispatch({ type: "SET_USER_PROFILE", payload: res });
      navigate('/home');
    });
  };

  return (
    <ui.Container>
      <ui.Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <ui.Grid item xs={12} />

        <ui.Grid container item xs={12} sm={4} spacing={2}>
          <ui.Grid
            container
            item
            xs={12}
            justifyContent="center"
            alignItems="center"
          >
            <img
              src="/storelink_logo_r.png"
              alt="store link"
              style={{ maxWidth: "240px" }}
            />
          </ui.Grid>

          <ui.Grid item xs={12}>
            <SystemNotificationForm date />
          </ui.Grid>

          {state.loginError && (
            <ui.Grid item xs={12}>
              {labels.error}
            </ui.Grid>
          )}

          <ui.Grid
            container
            item
            xs={12}
            justifyContent="center"
            alignItems="center"
          >
            <ui.TextField
              required
              fullWidth
              id="email-id"
              label={labels.email}
              value={state.login.email}
              onChange={(e) => onChanged("email", e)}
            />
          </ui.Grid>
          <ui.Grid
            container
            item
            xs={12}
            justifyContent="center"
            alignItems="center"
          >
            <ui.TextField
              required
              fullWidth
              id="password-id"
              type="password"
              label={labels.password}
              value={state.login.password}
              onChange={(e) => onChanged("password", e)}
            />
          </ui.Grid>

          <ui.Grid
            container
            item
            xs={12}
            justifyContent="center"
            alignItems="center"
          >
            <ui.Button
              className={classes.themeButton}
              variant="contained"
              color="secondary"
              type="button"
              onClick={onSubmit}
            >
              {labels.submit}
            </ui.Button>
          </ui.Grid>
        </ui.Grid>
      </ui.Grid>
    </ui.Container>
  );
}

export { Login };
