import react from "react";
import * as ui from "@material-ui/core";
import * as auth from "../../auth";
import * as rest from "../../rest";
import { resources } from "../../const/resource";
import { definitions } from "../../schema/api";
import { CsvTable } from "../../components/csv_table";
import * as format from "../../strings/format";
import {
  validators,
  Validator,
  ValidationResult,
} from "../../validation/validator";

const labels = {
  succeed: "一括登録に成功しました",
  failed: "一括登録に失敗しました",
  succeededDownload: "ダウンロードが完了しました",
  failedDownload: "ダウンロードに失敗しました",
  acknowledgementText:
    "アップロードした CSV に含まれる管理者の担当チェーン、担当店舗、及びスタッフの担当店舗は全て上書きされます",
  staffIsQuitted: (staffName: string) => `${staffName} は退職済みです`,
  storeDoesNotExistInChain: (chainName: string, storeName: string) =>
    `${chainName} に ${storeName} は存在しません`,
  chainIsNotManagedByClient: (chainName: string, clientName: string) =>
    `${chainName} は ${clientName} の管理会社の管理チェーンではありません`,
  staffIsNotManagedByClient: (staffName: string, clientName: string) =>
    `${staffName} は ${clientName} が管理するスタッフではありません`,
  storeIsAssignedToSameStaff: (storeName: string) =>
    `${storeName} が同じスタッフに割り当てられています`,
  storeIsAssignedToOtherStaff: (storeName: string) =>
    `${storeName} は別の行でスタッフが既に割り当てられています`,
  differentStoreCodesAssignedToSameStore: (storeName: string) =>
    `${storeName} に複数の店舗コードが割り当てられています`,
  notAuthorized: "このアカウントは権限がありません",
};

const labelMap: { [name: string]: string } = {
  client_name: "管理者名",
  chain_name: "チェーン名",
  store_name: "店舗名",
  objective_visit_count: "目標訪問数",
  store_code: "店舗コード",
  staff_name: "スタッフ名",
};

const template: CsvTemplate = {
  client_name: "管理者名",
  chain_name: "チェーン名",
  store_name: "店舗名",
  objective_visit_count: "目標訪問数",
  store_code: "店舗コード",
  staff_name: "スタッフ名",
};

type CsvTemplate = {
  client_name: string;
  chain_name: string;
  store_name: string;
  objective_visit_count: string;
  store_code: string;
  staff_name: string;
};

type BulkInsertState = {
  clients: definitions["Client"][];
  chains: definitions["Chain"][];
  stores: definitions["Store"][];
  staffs: definitions["Staff"][];
  clientMapByClientName: { [clientName: string]: definitions["Client"] };
  chainMapByChainName: { [chainName: string]: definitions["Chain"] };
  staffMapByStaffName: { [staffName: string]: definitions["Staff"] };
  chainsByClientId: { [clientId: number]: definitions["Chain"][] };
  initialized: boolean;
};
type BulkInsertProps = {
  showGlobalNotification?: (message: string) => void;
};

type NameExistsValidatorSrc = ({ name: string } & { [name: string]: any })[];

function createNameExistsValidator(src: NameExistsValidatorSrc): Validator {
  return {
    identifier: "nameExists",
    errorMessage: "存在しない名前です",
    sample: "登録されている名前を入力",
    validate(value: string | number): boolean {
      return !!src.find((item) => item.name === value);
    },
  };
}

function validateAssociation(
  datum: string[][],
  clientMapByClientName: { [clientName: string]: definitions["Client"] },
  chainMapByChainName: { [chainName: string]: definitions["Chain"] },
  staffMapByStaffName: { [staffName: string]: definitions["Staff"] },
  chainsByClientId: { [clientId: number]: definitions["Chain"][] }
): { [row: number]: ValidationResult } {
  const keys = Object.keys(template);
  const clientIndex = keys.indexOf("client_name");
  const chainIndex = keys.indexOf("chain_name");
  const storeIndex = keys.indexOf("store_name");
  const staffIndex = keys.indexOf("staff_name");
  const storeCodeIndex = keys.indexOf("store_code");

  const result: { [row: number]: ValidationResult } = {};

  const storeCodeByStoreIdAndClientName: {
    [id: number]: { [clientName: string]: string };
  } = {};
  const staffIdByClientNameAndStoreId: {
    [clientName: string]: { [id: number]: number };
  } = {};

  for (let i = 1; i < datum.length; i += 1) {
    const row = datum[i];

    // skip required checks
    const clientName = row[clientIndex];
    if (!clientName) {
      continue;
    }
    const chainName = row[chainIndex];
    if (!chainName) {
      continue;
    }
    const storeName = row[storeIndex];
    if (!storeName) {
      continue;
    }
    const staffName = row[staffIndex];
    if (!staffName) {
      continue;
    }

    // skip name existence checks
    const client = clientMapByClientName[clientName];
    if (!client) {
      continue;
    }
    const chain = chainMapByChainName[chainName];
    if (!chain) {
      continue;
    }
    const staff = staffMapByStaffName[staffName];
    if (!staff) {
      continue;
    }

    if (staff.is_quitted) {
      if (!result[i]) {
        result[i] = {};
      }
      result[i].staff_name = {
        valid: false,
        reason: labels.staffIsQuitted(staffName),
      };
      continue;
    }

    // check if store blongs to chain
    const store = (chain.stores || []).find(
      (store) => store.name === storeName
    );
    if (!store) {
      if (!result[i]) {
        result[i] = {};
      }
      result[i].store_name = {
        valid: false,
        reason: labels.storeDoesNotExistInChain(chainName, storeName),
      };
      continue;
    }

    // check if chain belongs to client company
    const exists = (chainsByClientId[client.id] || []).find(
      (item) => item.id === chain.id
    );
    if (!exists) {
      if (!result[i]) {
        result[i] = {};
      }
      result[i].chain_name = {
        valid: false,
        reason: labels.chainIsNotManagedByClient(chainName, clientName),
      };
      continue;
    }

    // check if staff belongs to client
    if (clientMapByClientName[clientName].id !== staff.client_id) {
      if (!result[i]) {
        result[i] = {};
      }
      result[i].staff_name = {
        valid: false,
        reason: labels.staffIsNotManagedByClient(staffName, clientName),
      };
      continue;
    }

    const storeCode = row[storeCodeIndex];
    if (!storeCode) {
      continue;
    }

    // check if the same store associated to the same staff
    if (!staffIdByClientNameAndStoreId[clientName]) {
      staffIdByClientNameAndStoreId[clientName] = {};
    }

    if (
      staffIdByClientNameAndStoreId[clientName] &&
      staffIdByClientNameAndStoreId[clientName][store.id] &&
      staffIdByClientNameAndStoreId[clientName][store.id] === staff.id
    ) {
      if (!result[i]) {
        result[i] = {};
      }
      result[i].store_name = {
        valid: false,
        reason: labels.storeIsAssignedToSameStaff(storeName),
      };
      continue;
    } else {
      if (staffIdByClientNameAndStoreId[clientName][store.id]) {
        if (!result[i]) {
          result[i] = {};
        }
        result[i].store_name = {
          valid: false,
          reason: labels.storeIsAssignedToOtherStaff(storeName),
        };
        continue;
      } else {
        staffIdByClientNameAndStoreId[clientName][store.id] = staff.id;
      }
    }

    // check if the different store code is associated to the same store
    if (
      storeCodeByStoreIdAndClientName[store.id] &&
      storeCodeByStoreIdAndClientName[store.id][clientName] &&
      storeCodeByStoreIdAndClientName[store.id][clientName] !== storeCode
    ) {
      if (!result[i]) {
        result[i] = {};
      }
      result[i].store_code = {
        valid: false,
        reason: labels.differentStoreCodesAssignedToSameStore(storeName),
      };
      continue;
    } else {
      if (!storeCodeByStoreIdAndClientName[store.id]) {
        storeCodeByStoreIdAndClientName[store.id] = {};
      }
      storeCodeByStoreIdAndClientName[store.id][clientName] = storeCode;
    }
  }
  return result;
}

function StoreBulkInsert(props: BulkInsertProps) {
  const [state, setState] = react.useState<BulkInsertState>({
    clients: [],
    chains: [],
    stores: [],
    staffs: [],
    clientMapByClientName: {},
    chainMapByChainName: {},
    staffMapByStaffName: {},
    chainsByClientId: {},
    initialized: false,
  });

  const rule: { [name: string]: Validator[] } = {
    client_name: [
      validators.required,
      createNameExistsValidator(state.clients),
    ],
    chain_name: [validators.required, createNameExistsValidator(state.chains)],
    store_name: [validators.required, createNameExistsValidator(state.stores)],
    store_code: [validators.alphaNumericUnderScore],
    objective_visit_count: [validators.numeric],
    staff_name: [createNameExistsValidator(state.staffs)],
  };

  const dataMediator = (col: string, val: string, ref: CsvTemplate) => {
    switch (col) {
      case "client_name":
      case "chain_name":
      case "store_name":
      case "staff_name": {
        (ref as any)[col] = format.trim(format.singleByteSpace(val));
        break;
      }
      case "store_code": {
        (ref as any)[col] = format.alphanumericbar(val);
        break;
      }
      default: {
        (ref as any)[col] = val;
        break;
      }
    }
  };

  const downloadCsv = () => {
    new rest.User()
      .getMe(["Client"], auth.getToken())
      .then((json) => {
        if (json.client) {
          return json.client.id;
        }

        const authenticated =
          auth.isAuthorized(
            resources.staff.identifier,
            auth.resourceCrudFlag.update
          ) &&
          auth.isAuthorized(
            resources.client.identifier,
            auth.resourceCrudFlag.update
          );

        if (!authenticated) {
          props.showGlobalNotification &&
            props.showGlobalNotification(labels.notAuthorized);
          return Promise.reject();
        }

        return 0;
      })
      .then((clientId) =>
        clientId > 0
          ? new rest.Store().getAssociations("csv", clientId, auth.getToken())
          : new rest.Store().getAssociations("csv", auth.getToken())
      )
      .then((ret: object) => {
        props.showGlobalNotification &&
          props.showGlobalNotification(labels.succeededDownload);
      })
      .catch(() => {
        props.showGlobalNotification &&
          props.showGlobalNotification(labels.failedDownload);
      });
  };

  const onRegister = (associations: CsvTemplate[]) => {
    new rest.Store()
      .postAssociations(associations, auth.getToken())
      .then((ret: object) => {
        props.showGlobalNotification &&
          props.showGlobalNotification(labels.succeed);
      })
      .catch(() => {
        props.showGlobalNotification &&
          props.showGlobalNotification(labels.failed);
      });
  };

  react.useEffect(() => {
    if (!state.initialized) {
      Promise.all([
        new rest.Client().getAll(["ClientCompany.Chains"], auth.getToken()),
        new rest.Chain().getAll(["Stores"], auth.getToken()),
        new rest.Staff().getAll(auth.getToken()),
      ]).then(([clients, chains, staffs]) => {
        state.initialized = true;
        state.clients = clients;
        state.chains = chains;
        state.stores = [];
        state.staffs = staffs;

        for (let i = 0; i < state.clients.length; i += 1) {
          const client = state.clients[i];
          state.clientMapByClientName[client.name] = client;
        }

        for (let i = 0; i < state.chains.length; i += 1) {
          const stores = state.chains[i].stores || [];
          for (let j = 0; j < stores.length; j += 1) {
            state.stores.push(stores[j]);
          }
        }
        for (let i = 0; i < state.chains.length; i += 1) {
          state.chainMapByChainName[state.chains[i].name] = state.chains[i];
        }
        for (let i = 0; i < state.staffs.length; i += 1) {
          state.staffMapByStaffName[state.staffs[i].name] = state.staffs[i];
        }
        for (let i = 0; i < state.clients.length; i += 1) {
          state.chainsByClientId[state.clients[i].id] =
            state.clients[i].client_company?.chains || [];
        }

        setState({ ...state });
      });
    }
  });

  return (
    <ui.Container>
      <ui.Grid container item spacing={2} xs={12}>
        <ui.Grid item xs={12} />
        <CsvTable<CsvTemplate>
          colLabels={labelMap}
          default={template}
          rule={rule}
          acknowledgementText={labels.acknowledgementText}
          onDownloadClicked={downloadCsv}
          onRegister={onRegister}
          dataMediator={dataMediator}
          customValidator={(datum) =>
            validateAssociation(
              datum,
              state.clientMapByClientName,
              state.chainMapByChainName,
              state.staffMapByStaffName,
              state.chainsByClientId
            )
          }
        />
      </ui.Grid>
    </ui.Container>
  );
}

export { StoreBulkInsert };
