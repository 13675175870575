import React, { ReactNode, useReducer, createContext } from "react";
import { definitions } from "../schema/api";

export type RecordListReport = definitions["Report"] & {
  dateType: Date;
  staffName: string;
  reportType: string;
  chainName: string;
  storeName: string;
};

export type RecordListInstruction = definitions["Instruction"] & {
  chainCount: number;
  fromDate: Date;
  toDate: Date;
};

export type RecordListStore = definitions["Store"] & { chainStoreId: string };

export const sortType = { asc: 1, desc: 2 };

export type Sort<T> = { order: typeof sortType[keyof typeof sortType], key: keyof T };

export type Record = RecordListReport | RecordListInstruction | RecordListStore | definitions["WorkTime"];

export type RecordListParameter<T extends Record> = {
  list: T[];
  sorts: Sort<T>[];
  page: number;
  perPage: number;
};

export type DataStore = {
  user: {
    profile?: definitions["User"],
    staff?: definitions["User"]["staff"],
  },
  report: {
    listParameter: RecordListParameter<RecordListReport>;
    clients?: definitions["Client"][];
    staffs: definitions["Staff"][];
    currentClientId?: number;
    defaultStaffId: number;
    showCheckedReport: boolean;
    year?: number;
    month?: number;
    staffId?: number;
    chainId: number;
    storeIds: number[];
    storeSearchWord: string;
  };
  instruction: {
    listParameter: RecordListParameter<RecordListInstruction>;
    year?: number;
    month?: number;
  };
  store: {
    chains: definitions["Chain"][];
    chainId?: definitions["Chain"]["id"];
    listParameter: RecordListParameter<RecordListStore>;
    showClosedStore: boolean;
  };
  workTime: {
    listParameter: RecordListParameter<definitions["WorkTime"]>;
    year?: number;
    month?: number;
    date?: number;
  }
};

export type RecordTypeCode = "report" | "instruction" | "store" | "workTime";

export type RecordType<T extends RecordTypeCode> = DataStore[T]["listParameter"]["list"][number];

export type ReducerActionPayload = {
  SET_USER_PROFILE: definitions["User"];
  SET_USER_STAFF: definitions["User"]["staff"];
  SET_REPORT_YEAR_MONTH: {
    reports: DataStore["report"]["listParameter"]["list"];
    clients: DataStore["report"]["clients"];
    staffs: DataStore["report"]["staffs"];
    currentClientId: DataStore["report"]["currentClientId"];
    year: DataStore["report"]["year"];
    month: DataStore["report"]["month"];
    page?: DataStore["report"]["listParameter"]["page"];
    staffId?: DataStore["report"]["staffId"];
  };
  SET_REPORT_OPTION: Partial<{
    showCheckedReport: DataStore["report"]["showCheckedReport"];
    currentClientId: DataStore["report"]["currentClientId"];
    defaultStaffId: DataStore["report"]["defaultStaffId"];
    staffId: DataStore["report"]["staffId"];
    chainId: number;
    storeIds: number[];
    storeSearchWord: string;
  }>;
  SET_INSTRUCTION_YEAR_MONTH: {
    year?: DataStore["instruction"]["year"];
    month?: DataStore["instruction"]["month"];
  };
  SET_STORE_CHAINS: DataStore["store"]["chains"];
  SET_STORE_CHAIN_ID: DataStore["store"]["chainId"];
  SET_STORE_OPTION: Partial<{
    showClosedStore: DataStore["store"]["showClosedStore"];
  }>;
  SET_WORK_TIME_YEAR_MONTH_DATE: {
    year?: DataStore["workTime"]["year"];
    month?: DataStore["workTime"]["month"];
    date?: DataStore["workTime"]["date"];
  };
  SET_LIST:
    { type: "report", list: RecordListReport[] } |
    { type: "instruction", list: RecordListInstruction[] } |
    { type: "store", list: RecordListStore[] } |
    { type: "workTime", list: definitions["WorkTime"][] };
  SET_LIST_SORTS:
    { type: "report", sorts: Sort<RecordListReport>[] } |
    { type: "instruction", sorts: Sort<RecordListInstruction>[] } |
    { type: "store", sorts: Sort<RecordListStore>[] } |
    { type: "workTime", sorts: Sort<definitions["WorkTime"]>[] };
  SET_LIST_OPTION:
    { type: "report", parameter: Partial<RecordListParameter<RecordListReport>> } |
    { type: "instruction", parameter: Partial<RecordListParameter<RecordListInstruction>> } |
    { type: "store", parameter: Partial<RecordListParameter<RecordListStore>> } |
    { type: "workTime", parameter: Partial<RecordListParameter<definitions["WorkTime"]>> };
};

type ReducerActionType = keyof ReducerActionPayload;

type ReducerAction<T extends ReducerActionType> = {
  type: T;
  payload: ReducerActionPayload[T];
};

type DataStoreContext<T extends ReducerActionType> = {
  state: DataStore;
  dispatch: React.Dispatch<ReducerAction<T>>;
};

const sortList = <T extends Record>(list: T[], sorts: Sort<T>[]) => {
  sorts.forEach(({ key, order }) => {
    if (['storeName', 'chainName'].includes(key as string)) {
      order === sortType.asc
        ? list.sort((l: T, r: T) => (l[key] as string).localeCompare(r[key] as string, 'ja'))
        : list.sort((l: T, r: T) => (r[key] as string).localeCompare(l[key] as string, 'ja'));
    } else {
      order === sortType.asc
        ? list.sort((l: T, r: T) => l[key] < r[key] ? -1 : 1)
        : list.sort((l: T, r: T) => l[key] > r[key] ? -1 : 1);
    }
  });
};

export const reducer: <T extends ReducerActionType>(state: DataStore, action: ReducerAction<T>) => DataStore = (state, action) => {
  switch (action.type) {
    case "SET_USER_PROFILE":
      return { ...state, user: { ...state.user, profile: action.payload as ReducerActionPayload["SET_USER_PROFILE"] } };
    case "SET_USER_STAFF":
      return { ...state, user: { ...state.user, staff: action.payload as ReducerActionPayload["SET_USER_STAFF"] } };
    case "SET_REPORT_YEAR_MONTH":
      const initialReportState = { ...state.report };
      const { reports, ...reportState } = action.payload as ReducerActionPayload["SET_REPORT_YEAR_MONTH"];
      const listParameter = { ...state.report.listParameter, list: reports };
      sortList(reports, state.report.listParameter.sorts);
      return { ...state, report: { ...initialReportState, ...reportState, listParameter } };
    case "SET_REPORT_OPTION":
      return { ...state, report: { ...state.report, ...action.payload as ReducerActionPayload["SET_REPORT_OPTION"] } };
    case "SET_INSTRUCTION_YEAR_MONTH":
      return { ...state, instruction: { ...state.instruction, ...action.payload as ReducerActionPayload["SET_INSTRUCTION_YEAR_MONTH"] } };
    case "SET_STORE_CHAINS":
      return { ...state, store: { ...state.store, chains: action.payload as ReducerActionPayload["SET_STORE_CHAINS"] } };
    case "SET_STORE_CHAIN_ID":
      return { ...state, store: { ...state.store, chainId: action.payload as ReducerActionPayload["SET_STORE_CHAIN_ID"] } };
    case "SET_STORE_OPTION":
      return { ...state, store: { ...state.store, ...action.payload as ReducerActionPayload["SET_STORE_OPTION"] } };
    case "SET_WORK_TIME_YEAR_MONTH_DATE":
      return { ...state, workTime: { ...state.workTime, ...action.payload as ReducerActionPayload["SET_WORK_TIME_YEAR_MONTH_DATE"] } };
    case "SET_LIST":
      const { type: listType, list } = action.payload as ReducerActionPayload["SET_LIST"];
      return { ...state, [listType]: { ...state[listType], listParameter: { ...state[listType].listParameter, list } } };
    case "SET_LIST_SORTS":
      return (({ type, sorts }) => {
        type SortRecordType = RecordType<typeof type>;
        const listParameter = state[type].listParameter as RecordListParameter<SortRecordType>;
        listParameter.sorts = sorts as RecordListParameter<SortRecordType>["sorts"];
        sortList<SortRecordType>(listParameter.list, listParameter.sorts);
        return { ...state, [type]: { ...state[type], listParameter } };
      })(action.payload as ReducerActionPayload["SET_LIST_SORTS"]);
    case "SET_LIST_OPTION":
      const { type: optionType, parameter } = action.payload as ReducerActionPayload["SET_LIST_OPTION"];
      return { ...state, [optionType]: { ...state[optionType], listParameter: { ...state[optionType].listParameter, ...parameter } } };
    default:
      return state;
  }
};

const initRecordListParamter = () => ({
  list: [],
  sorts: [],
  page: 1,
  perPage: 30,
});

const initialState: DataStore = {
  user: {},
  report: {
    listParameter: { ...initRecordListParamter(), sorts: [{ order: sortType.asc, key: "dateType" }] },
    staffs: [],
    defaultStaffId: 0,
    showCheckedReport: true,
    chainId: 0,
    storeIds: [],
    storeSearchWord: "",
  },
  instruction: {
    listParameter: { ...initRecordListParamter() },
  },
  store: {
    chains: [],
    listParameter: { ...initRecordListParamter() },
    showClosedStore: false,
  },
  workTime: {
    listParameter: { ...initRecordListParamter() },
  },
};

export const Context = createContext<Partial<DataStoreContext<ReducerActionType>>>({});

export const DataStoreContextProvider = (props: Required<{ children: ReactNode }>): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <Context.Provider value={{ state, dispatch }}>
      {props.children}
    </Context.Provider>
  );
};
