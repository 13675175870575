import { request, methods } from "./request";
import { paths } from "../schema/api";

type PptxGetParam = paths["/pptx"]["get"]["parameters"]["query"];

class Pptx {
  get(
    reportImagePathIds: number[],
    textOption: { date: boolean; staffName: boolean; storeName: boolean },
    photosPerPage: number,
    token: string = ""
  ) {
    const params: PptxGetParam = {
      report_image_ids: reportImagePathIds.join(","),
      photos_per_page: photosPerPage,
    };
    if (textOption.date) {
      params.date = true;
    }
    if (textOption.staffName) {
      params.staff_name = true;
    }
    if (textOption.storeName) {
      params.store_name = true;
    }

    return request({
      token,
      params,
      method: methods.Get,
      resource: "pptx",
      headers: { Accept: "application/octet-stream" },
    });
  }
}

export { Pptx };
export type { PptxGetParam };
