import { request, methods } from "./request";
import { paths } from "../schema/api";

type ReportImagesPostParam =
  paths["/report_images"]["post"]["parameters"]["body"]["report_images"];
type ReportImagesPostResponse =
  paths["/report_images"]["post"]["responses"][200]["schema"];
type ReportImagesDeleteResponse =
  paths["/report_images"]["delete"]["responses"][200]["schema"];

class ReportImage {
  post(
    params: ReportImagesPostParam,
    token: string
  ): Promise<ReportImagesPostResponse> {
    return request({
      token,
      params,
      resource: `report_images`,
      method: methods.Post,
    });
  }
  deleteByIds(
    ids: number[],
    token: string
  ): Promise<ReportImagesDeleteResponse> {
    return request({
      token,
      resource: `report_images?report_image_ids=${ids.join(",")}`,
      method: methods.Delete,
    });
  }
}

export { ReportImage };
export type { ReportImagesPostParam, ReportImagesPostResponse };
