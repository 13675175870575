import * as ui from "@material-ui/core";

const labels = { year: "年" };

type YearSelectorProps = {
  onChanged: (year: number) => void;
  value: number;
  min: number;
  max: number;
};

function createYear(min: number, max: number): JSX.Element[] {
  const doms = [];
  for (let year = min; year <= max; year += 1) {
    doms.push(
      <ui.MenuItem key={`year${year}`} value={year}>
        {year}
      </ui.MenuItem>
    );
  }
  return doms;
}

function YearSelector(props: YearSelectorProps) {
  return (
    <>
      <ui.InputLabel id="year-id-label">{labels.year}</ui.InputLabel>
      <ui.Select
        labelId="year-id-label"
        id="year-id"
        name="year"
        value={props.value}
        onChange={(e: any) => props.onChanged(parseInt(e.target.value, 10))}
      >
        {createYear(props.min, props.max)}
      </ui.Select>
    </>
  );
}

export { YearSelector };
