import react from "react";
import * as ui from "@material-ui/core";
import * as auth from "../../auth";
import { CloseItem } from "./side_menu/close_item";
import { HomeItem } from "./side_menu/home_item";
import { LoginItem } from "./side_menu/login_item";
import { ResourceItem } from "./side_menu/resource_item";
import { resources } from "../../const/resource";
import { roles } from "../../const/role";

import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Description from "@material-ui/icons/Description";
import Edit from "@material-ui/icons/Edit";
import List from "@material-ui/icons/List";
import People from "@material-ui/icons/People";
import Business from "@material-ui/icons/Business";
import HomeWork from "@material-ui/icons/HomeWork";
import Storefront from "@material-ui/icons/Storefront";
import CalendarToday from "@material-ui/icons/CalendarToday";
import Image from "@material-ui/icons/Image";
import Assignment from "@material-ui/icons/Assignment";

const labels = {
  sessionTimeout: "セッションが切れました",
};

interface SideMenuProps {
  open: boolean;
  onToggled: (e: any) => void;
}

type SideMenuState = {
  open: {
    [resource: string]: boolean;
  };
};

function isHideMenu(resourceId: string, role: auth.Role | null): boolean {
  if (role && role.roleName === roles.staff.identifier) {
    if (resourceId === resources.staff.identifier) {
      return true;
    }
  }
  if (resourceId === resources.work_time.identifier) {
    return true;
  }
  return false;
}

function SideMenu(props: SideMenuProps) {
  const [state, setState] = react.useState<SideMenuState>({ open: {} });

  const onMenuToggled = (menu: string) => {
    state.open[menu] = !state.open[menu];
    setState({ ...state });
  };

  const authorizations = auth.getAuthorizations() || {};
  const resourceIdentifiers = Object.keys(authorizations);

  const reportImageIndex = resourceIdentifiers.indexOf(
    resources.report_image.identifier
  );
  const calendarIndex = resourceIdentifiers.indexOf(
    resources.calendar.identifier
  );

  // customer demands this order
  if (reportImageIndex !== -1 && calendarIndex !== -1) {
    resourceIdentifiers.splice(
      reportImageIndex,
      0,
      resources.calendar.identifier
    );
    const secondCalendar = resourceIdentifiers.lastIndexOf(
      resources.calendar.identifier
    );
    resourceIdentifiers.splice(secondCalendar, 1);
  }

  const resourceItems = [];
  for (let i = 0; i < resourceIdentifiers.length; i += 1) {
    const resourceId = resourceIdentifiers[i];
    if (isHideMenu(resourceId, auth.getRole())) {
      continue;
    }

    let icon;
    switch (resourceId) {
      case resources.instruction.identifier:
        icon = <Description />;
        break;
      case resources.report.identifier:
        icon = <Edit />;
        break;
      case resources.report_image.identifier:
        icon = <Image />;
        break;
      case resources.calendar.identifier:
        icon = <CalendarToday />;
        break;
      case resources.client_company.identifier:
        icon = <Business />;
        break;
      case resources.client.identifier:
      case resources.client_reader.identifier:
      case resources.staff.identifier:
        icon = <People />;
        break;
      case resources.chain.identifier:
        icon = <HomeWork />;
        break;
      case resources.store.identifier:
        icon = <Storefront />;
        break;
      case resources.notice_board.identifier:
        icon = <Assignment />;
        break;
      default:
        icon = <List />;
        break;
    }

    if (state.open[resourceId] === undefined) {
      state.open[resourceId] = false;
    }

    resourceItems.push(
      <ui.Container key={resourceId}>
        <ui.ListItem button onClick={() => onMenuToggled(resourceId)}>
          <ui.ListItem>
            <ui.ListItemIcon>{icon}</ui.ListItemIcon>
            <ui.ListItemText>
              {(resources as any)[resourceId]?.label || resourceId}
            </ui.ListItemText>
          </ui.ListItem>

          {state.open[resourceId] ? <ExpandMore /> : <ExpandLess />}
        </ui.ListItem>
        <ui.Collapse in={state.open[resourceId]} timeout="auto" unmountOnExit>
          <ResourceItem
            resource={resourceId}
            flag={authorizations[resourceId]}
            onClicked={(e) => {
              state.open[resourceId] = false;
              props.onToggled(e);
              setState({ ...state });
            }}
          />
        </ui.Collapse>
      </ui.Container>
    );
  }

  return (
    <ui.SwipeableDrawer
      container={window.document.body}
      variant="temporary"
      anchor="left"
      open={props.open}
      onClose={props.onToggled}
      onOpen={props.onToggled}
      ModalProps={{ keepMounted: true }}
    >
      <ui.List>
        <ui.Container key="close">
          <CloseItem onClicked={props.onToggled} />
        </ui.Container>

        <ui.Divider />

        {resourceIdentifiers.length > 0 ? (
          <ui.Container key="home">
            <HomeItem onClicked={props.onToggled} />
          </ui.Container>
        ) : (
          <ui.Container key="root">
            <ui.Typography variant="caption">
              {labels.sessionTimeout}
            </ui.Typography>
            <LoginItem
              onClicked={() => {
                window.location.href = "/";
              }}
            />
          </ui.Container>
        )}

        <ui.Divider />

        {resourceItems}
      </ui.List>
    </ui.SwipeableDrawer>
  );
}

export { SideMenu };
export type { SideMenuProps };
