import * as ui from "@material-ui/core";
import { definitions } from "../../../schema/api";
import {
  results,
  resultValueToLabel,
} from "../../../const/individual_report_result";
import { GridInput, GridInputRow } from "../../../components/grid_input";

const labels: { [key: string]: string } = {
  titleNotSet: "(タイトル未設定)",
  type: "指示種別",
  typeAchievement: "達成型(表)",
  typeContenuouse: "継続型",
  detail: "指示内容",
  resultStatus: "ステータス",
  resultStatusDone: "完了",
  resultStatusUndone: "未完了",
  resultStatusPend: "保留",
  resultStatusNotRequired: "対象外",
  comment: "コメント",
  before: "作業前画像",
  after: "作業後画像",
  prev: "前へ",
  next: "次へ",
};

interface IndividualInstructionReportProps {
  confirm?: boolean;
  instruction: definitions["Instruction"];
  individualReport: definitions["IndividualReport"];
  onChanged: (individualReport: definitions["IndividualReport"]) => void;
}

function instructionTypeToString(type: number): string {
  switch (type) {
    case 1:
      return labels.typeAchievement;
    case 2:
      return labels.typeContenuouse;
  }
  return "";
}

function IndividualInstructionReport(props: IndividualInstructionReportProps) {
  if (props.instruction.type === 1) {
    const { individual_report_list_items = [] } = props.individualReport;
    individual_report_list_items.forEach((row) => {
      row.order = props.instruction.instruction_list_items
        ?.find((item) => item.id === row.instruction_list_item_id)
        ?.order || 0;
    });
    individual_report_list_items.sort((row1, row2) => (row1.order || 0) - (row2.order || 0));
  }

  const onNumberPropertyChanged = (name: "result", event: any) => {
    props.individualReport[name] = parseInt(event.target.value, 10);
    props.onChanged(props.individualReport);
  };
  const onStringPropertyChanged = (name: "comment", event: any) => {
    props.individualReport[name] = event.target.value;
    props.onChanged(props.individualReport);
  };

  const onRowsChanged = (rows: GridInputRow[]) => {
    const items = rows
      .filter((row) => !!row.instruction_list_item_id)
      .map((row) => ({
        ...row,
        id: 0,
        individual_report_id: 0,
      } as definitions["IndividualReport"]["individual_report_list_items"][number]));
    props.individualReport.individual_report_list_items = items;
    props.onChanged(props.individualReport);
  };

  return (
    <ui.Card variant="outlined">
      <ui.CardContent>
        <ui.Grid
          container
          spacing={4}
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
        >
          <ui.Grid item xs={12}>
            <ui.Typography variant="h6">
              {props.instruction.title || labels.titleNotSet}
            </ui.Typography>
          </ui.Grid>

          <ui.Grid item xs={12} sm={6}>
            <ui.InputLabel
              id={`report-detail-type-${props.individualReport.id}`}
            >
              {labels.type}
            </ui.InputLabel>
            <ui.Typography variant="h6">
              {instructionTypeToString(props.instruction.type)}
            </ui.Typography>
          </ui.Grid>

          <ui.Grid item xs={12} sm={6}>
            <ui.InputLabel
              id={`report-detail-resultstatus-${props.individualReport.id}`}
            >
              {labels.resultStatus}
            </ui.InputLabel>
            {props.confirm ? (
              <ui.Typography variant="h6">
                {resultValueToLabel(props.individualReport.result)}
              </ui.Typography>
            ) : (
              <ui.RadioGroup
                row
                aria-label="resultstatus"
                name="resultstatus"
                value={parseInt(
                  (props.individualReport.result as any) || 0,
                  10
                )}
                defaultValue={props.individualReport.result || 0}
                onChange={(e) => onNumberPropertyChanged("result", e)}
              >
                {Object.keys(results).map((key) => (
                  <ui.FormControlLabel
                    key={`report-detail-resultstatus-${key}`}
                    value={results[key].value}
                    control={<ui.Radio />}
                    label={results[key].label}
                  />
                ))}
              </ui.RadioGroup>
            )}
          </ui.Grid>

          <ui.Grid item xs={12} sm={6}>
            <ui.InputLabel
              id={`report-detail-comment-${props.individualReport.id}`}
            >
              {labels.comment}
            </ui.InputLabel>
            {props.confirm ? (
              <ui.Typography
                variant="h6"
                display="block"
                style={{ whiteSpace: "pre-wrap" }}
              >
                {props.individualReport.comment}
              </ui.Typography>
            ) : (
              <ui.TextField
                multiline
                fullWidth
                rows={6}
                value={props.individualReport.comment}
                variant="outlined"
                name="comment"
                onChange={(e) => onStringPropertyChanged("comment", e)}
              />
            )}
          </ui.Grid>

          <ui.Grid item xs={12} sm={6}>
            {props.instruction.type === 1 ? (
              <div>
                <GridInput
                  confirm={props.confirm}
                  reporterMode={true}
                  rows={
                    props.individualReport.individual_report_list_items || []
                  }
                  onRowsChanged={onRowsChanged}
                />
              </div>
            ) : (
              <div />
            )}
          </ui.Grid>
        </ui.Grid>
      </ui.CardContent>
    </ui.Card>
  );
}

export { IndividualInstructionReport };
export type { IndividualInstructionReportProps };
