import react from "react";
import * as ui from "@material-ui/core";
import { definitions } from "../schema/api";
import { createWorkTime } from "../const/definitions/work_time";
import { weekDayToText, isZeroDate } from "../datetime";
import * as auth from "../auth";
import * as rest from "../rest";

import Schedule from "@material-ui/icons/Schedule";

const labels = {
  title: "勤怠",
  recordBegin: "出勤打刻",
  recordEnd: "退勤打刻",
  noRecord: "--:--",
  begin: "出勤",
  end: "退勤",
  loading: "読込中...",
  errorNotStaff: "スタッフではないため打刻できません",
  ymd: (date: Date): string => {
    return `${date.getFullYear()}/${
      date.getMonth() + 1
    }/${date.getDate()} (${weekDayToText(date.getDay())})`;
  },
  hhmm: (date: Date): string => {
    return `${date.getHours()}:${date.getMinutes()}`;
  },
};

type WorkTimeState = {
  staffId: number;
  workTime: definitions["WorkTime"];
  initialized: boolean;
};

function WorkTime() {
  const [state, setState] = react.useState<WorkTimeState>({
    staffId: 0,
    workTime: createWorkTime(),
    initialized: false,
  });

  const today = new Date();

  const onRecord = () => {
    const now = new Date();
    if (now.getDate() !== today.getDate()) {
      window.location.href = "";
      return;
    }

    let recordType: "begin" | "end" = "begin";
    if (!isZeroDate(state.workTime.begin!)) {
      recordType = "end";
    }

    const hh = `00${now.getHours()}`.slice(-2);
    const mm = `00${now.getMinutes()}`.slice(-2);

    new rest.WorkTime()
      .postYearMonthDate(
        now.getFullYear(),
        now.getMonth() + 1,
        now.getDate(),
        recordType,
        {
          staff_id: state.staffId,
          time: `${hh}:${mm}`,
        },
        auth.getToken()
      )
      .then((json) => {
        state.workTime = json;
        setState({ ...state });
      });
  };

  react.useEffect(() => {
    if (state.initialized) {
      return;
    }

    state.initialized = true;
    new rest.User()
      .getMe(["Staff"], auth.getToken())
      .then((response: any) => {
        if (response.staff) {
          state.staffId = response.staff.id;
        }
      })
      .then(() => {
        if (state.staffId === 0) {
          return Promise.resolve();
        }
        return new rest.WorkTime()
          .getYearMonthDate(
            today.getFullYear(),
            today.getMonth() + 1,
            today.getDate(),
            { staff_id: state.staffId },
            auth.getToken()
          )
          .then((response) => {
            state.workTime = response;
          });
      })
      .then(() => {
        setState({ ...state });
      });
  });

  return (
    <ui.Card>
      <ui.CardHeader
        avatar={<Schedule fontSize="large" />}
        title={`${labels.title} ${labels.ymd(today)}`}
        titleTypographyProps={{ variant: "body1" }}
        action={
          state.staffId === 0 ||
          (!isZeroDate(state.workTime.begin!) &&
            !isZeroDate(state.workTime.end!)) ? null : (
            <ui.Button color="secondary" onClick={onRecord}>
              {isZeroDate(state.workTime.begin!)
                ? labels.recordBegin
                : labels.recordEnd}
            </ui.Button>
          )
        }
      />
      <ui.CardContent>
        {state.staffId === 0 ? (
          state.initialized ? (
            <ui.Typography variant="body1">
              {labels.errorNotStaff}
            </ui.Typography>
          ) : (
            <ui.Typography variant="subtitle1">{labels.loading}</ui.Typography>
          )
        ) : (
          <ui.Grid container spacing={2}>
            <ui.Grid item xs={6}>
              <ui.Typography variant="body1">{labels.begin}</ui.Typography>
              {isZeroDate(state.workTime.begin!) ? (
                <ui.Typography variant="subtitle1">
                  {labels.noRecord}
                </ui.Typography>
              ) : (
                <ui.Typography variant="h6">
                  {labels.hhmm(new Date(state.workTime.begin!))}
                </ui.Typography>
              )}
            </ui.Grid>
            <ui.Grid item xs={6}>
              <ui.Typography variant="body1">{labels.end}</ui.Typography>
              {isZeroDate(state.workTime.end!) ? (
                <ui.Typography variant="subtitle1">
                  {labels.noRecord}
                </ui.Typography>
              ) : (
                <ui.Typography variant="h6">
                  {labels.hhmm(new Date(state.workTime.end!))}
                </ui.Typography>
              )}
            </ui.Grid>
          </ui.Grid>
        )}
      </ui.CardContent>
    </ui.Card>
  );
}

export { WorkTime };
