import react from "react";
import * as ui from "@material-ui/core";
import { definitions } from "../../schema/api";
import * as auth from "../../auth";
import * as rest from "../../rest";
import { resources, controls } from "../../const/resource";
import { FormattedTextField } from "../../components/formatted_text_field";
import { PrefectureSelector } from "../../components/prefecture_selector";
import { PostCodeInput } from "../../components/post_code_input";
import { ChainSelector } from "../../components/chain_selector";
import { NavigatorBack } from "../../components/navigator_back";
import { Loading } from "../../components/loading";
import { createFormValidator, FormValidator } from "../../validation/form";

const labels: { [key: string]: string } = {
  list: "一覧",
  titleCreate: "店舗登録",
  titleUpdate: "店舗更新",
  name: "店舗名",
  nameKana: "店舗名カナ",
  phone: "電話番号",
  fax: "FAX",
  postCode: "郵便番号",
  address1: "市区町村",
  address2: "町名番地",
  address3: "建物・部屋番号",
  remarks: "備考",
  isClosed: "閉店フラグ",
  submit: "登録",
  noChain:
    "店舗を紐つけるチェーンが登録されていません、先にチェーンを登録してください",
};

type FormState = {
  validator: FormValidator;
  chains: definitions["Chain"][];
  initialized: boolean;
};

type FormProps = {
  store: definitions["Store"];
  onChange: (store: definitions["Store"]) => void;
  onSubmit: () => void;
};

function Form(props: FormProps) {
  const validator = createFormValidator("store");
  if (props.store.id !== 0) {
    validator.validateAll(props.store);
  }
  validator.touch("prefecture");

  const [state, setState] = react.useState<FormState>({
    validator,
    chains: [],
    initialized: false,
  });

  const onStringValueChanged = (
    key:
      | "name"
      | "name_kana"
      | "phone"
      | "fax"
      | "address_1"
      | "address_2"
      | "address_3"
      | "remarks",
    value: string
  ) => {
    props.store[key] = value;
    props.onChange(props.store);
  };

  const onPostCodeChanged = (postCode: string) => {
    props.store.post_code = postCode;
    props.onChange(props.store);
  };
  const onPrefectureChanged = (prefecture: string) => {
    props.store.prefecture = prefecture;
    props.onChange(props.store);
  };

  const onIsClosedChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.store.is_closed = !(parseInt(event.target.value, 10) === 1);
    props.onChange(props.store);
  };

  const onChainChanged = (id: number) => {
    props.store.chain_id = id;
    props.onChange(props.store);
  };

  const onSubmit = (_: React.SyntheticEvent) => {
    props.onSubmit();
  };

  react.useEffect(() => {
    if (!state.initialized) {
      state.initialized = true;
      new rest.Chain()
        .getAll(auth.getToken())
        .then((json: rest.ChainsGetResponse) => {
          state.chains = json;
          if (state.chains.length > 0) {
            state.validator.touch("chain_id");
            if (props.store.chain_id === 0) {
              props.store.chain_id = json[0].id;
            }
          }
          setState({ ...state });
        });
    }
  });

  if (!state.initialized) {
    return <Loading />;
  }

  if (state.chains.length === 0) {
    return (
      <ui.Grid
        container
        spacing={0}
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        <ui.Grid container spacing={4}>
          <ui.Grid item xs={12} />
          <ui.Grid item xs={12}>
            <ui.Typography variant="h6">{labels.title}</ui.Typography>
          </ui.Grid>
          <ui.Grid item xs={12}>
            <ui.Typography variant="h6">{labels.noChain}</ui.Typography>
          </ui.Grid>
        </ui.Grid>
      </ui.Grid>
    );
  }

  return (
    <ui.Grid
      container
      spacing={0}
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
    >
      <ui.Grid container spacing={4}>
        <ui.Grid container item xs={12} spacing={0}>
          <NavigatorBack
            xs={12}
            sm={12}
            label={labels.list}
            href={`/${resources.store.identifier}/${controls.retrieve.identifier}`}
          />

          <ui.Grid item xs={12}>
            <ui.Typography variant="h6">
              {props.store.id !== 0 ? labels.titleUpdate : labels.titleCreate}
            </ui.Typography>
          </ui.Grid>
        </ui.Grid>

        <ui.Grid item xs={6}>
          <ChainSelector
            chains={state.chains}
            selectedValue={props.store.chain_id}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              state.validator.validate("chain_id", event.target.value);
              onChainChanged(parseInt(event.target.value, 10));
            }}
          />
        </ui.Grid>
        <ui.Grid item xs={12} sm={6}>
          <ui.FormControlLabel
            control={
              <ui.Checkbox
                value={props.store.is_closed ? 1 : 0}
                checked={props.store.is_closed}
                onChange={onIsClosedChanged}
              />
            }
            label={labels.isClosed}
          />
        </ui.Grid>

        <ui.Grid item xs={12} sm={6}>
          <FormattedTextField
            {...state.validator.formProps.name}
            trim
            singleByteSpace
            id="store-name-id"
            label={labels.name}
            onBlur={(e: any) => {
              state.validator.validate(e.target.value, "name");
              onStringValueChanged("name", e.target.value);
            }}
            fullWidth
            rows={6}
            defaultValue={props.store.name}
            variant="outlined"
          />
        </ui.Grid>
        <ui.Grid item xs={12} sm={6}>
          <FormattedTextField
            {...state.validator.formProps.name_kana}
            trim
            singleByteSpace
            id="store-namekana-id"
            label={labels.nameKana}
            onBlur={(e: any) => {
              state.validator.validate(e.target.value, "name_kana");
              onStringValueChanged("name_kana", e.target.value);
            }}
            fullWidth
            rows={6}
            defaultValue={props.store.name_kana}
            variant="outlined"
          />
        </ui.Grid>

        <ui.Grid item xs={12} sm={6}>
          <FormattedTextField
            {...state.validator.formProps.phone}
            phone
            id="store-phone-id"
            label={labels.phone}
            onBlur={(e: any) => {
              state.validator.validate(e.target.value, "phone");
              onStringValueChanged("phone", e.target.value);
            }}
            fullWidth
            rows={6}
            defaultValue={props.store.phone}
            variant="outlined"
          />
        </ui.Grid>
        <ui.Grid item xs={12} sm={6}>
          <FormattedTextField
            {...state.validator.formProps.fax}
            phone
            id="store-fax-id"
            label={labels.fax}
            onBlur={(e: any) => {
              state.validator.validate(e.target.value, "fax");
              onStringValueChanged("fax", e.target.value);
            }}
            fullWidth
            rows={6}
            defaultValue={props.store.fax}
            variant="outlined"
          />
        </ui.Grid>

        <ui.Grid item xs={6} sm={3}>
          <PostCodeInput
            value={props.store.post_code}
            onChanged={onPostCodeChanged}
          />
        </ui.Grid>
        <ui.Grid item xs={6} sm={3}>
          <PrefectureSelector
            selectedValue={props.store.prefecture}
            onChanged={onPrefectureChanged}
          />
        </ui.Grid>
        <ui.Grid item xs={12} sm={6}>
          <FormattedTextField
            {...state.validator.formProps.address_1}
            trim
            singleByteSpace
            id="store-address1-id"
            label={labels.address1}
            onBlur={(e: any) => {
              state.validator.validate(e.target.value, "address_1");
              onStringValueChanged("address_1", e.target.value);
            }}
            fullWidth
            rows={6}
            defaultValue={props.store.address_1}
            variant="outlined"
          />
        </ui.Grid>

        <ui.Grid item xs={12} sm={6}>
          <FormattedTextField
            {...state.validator.formProps.address_2}
            trim
            singleByteSpace
            id="store-address2-id"
            label={labels.address2}
            onBlur={(e: any) => {
              state.validator.validate(e.target.value, "address_2");
              onStringValueChanged("address_2", e.target.value);
            }}
            fullWidth
            rows={6}
            defaultValue={props.store.address_2}
            variant="outlined"
          />
        </ui.Grid>
        <ui.Grid item xs={12} sm={6}>
          <FormattedTextField
            {...state.validator.formProps.address_3}
            trim
            singleByteSpace
            id="store-address3-id"
            label={labels.address3}
            onBlur={(e: any) => {
              state.validator.validate(e.target.value, "address_3");
              onStringValueChanged("address_3", e.target.value);
            }}
            fullWidth
            rows={6}
            defaultValue={props.store.address_3}
            variant="outlined"
          />
        </ui.Grid>

        <ui.Grid item xs={12} sm={6}>
          <ui.TextField
            {...state.validator.formProps.remarks}
            id="store-remarks-id"
            label={labels.remarks}
            onBlur={(e: any) => {
              state.validator.validate(e.target.value, "remarks");
              onStringValueChanged("remarks", e.target.value);
            }}
            fullWidth
            rows={6}
            defaultValue={props.store.remarks}
            variant="outlined"
          />
        </ui.Grid>

        <ui.Grid item xs={12}>
          <ui.Button
            {...state.validator.submitButtonProps}
            variant="contained"
            color="secondary"
            type="button"
            onClick={onSubmit}
          >
            {labels.submit}
          </ui.Button>
        </ui.Grid>
      </ui.Grid>
    </ui.Grid>
  );
}

export { Form };
