import react from "react";
import * as ui from "@material-ui/core";
import { useParams } from "react-router-dom";
import * as auth from "../../auth";
import * as rest from "../../rest";
import { definitions } from "../../schema/api";
import { createClientStore } from "../../const/definitions/client_store";
import { ChainStoreSelector } from "../../components/chain_store_selector";
import { Loading } from "../../components/loading";

const labels: { [key: string]: string } = {
  submit: "登録",
  succeed: "登録しました",
  failed: "登録に失敗しました",
};

type StoreUpdateState = {
  chains: definitions["Chain"][];
  stores: definitions["Store"][];
  client: definitions["Client"];
  storesIdsByChainId: { [chainId: number]: number[] };
  storeCodeByStoreId: { [storeId: number]: string };
  objectiveVisitCountByStoreId: { [storeId: number]: number | null };
  initialized: boolean;
};
type StoreUpdateProps = {
  showGlobalNotification?: (message: string) => void;
};

type StoreUpdateParam = {
  id: string;
};

function StoreUpdate(props: StoreUpdateProps) {
  const params = useParams<StoreUpdateParam>();
  const clientId = parseInt(params.id as string, 10);

  const [state, setState] = react.useState<StoreUpdateState>({
    chains: [],
    stores: [],
    client: {
      id: 0,
      user_id: 0,
      client_company_id: 0,
      name: "",
      name_kana: "",
      phone: "",
      remarks: "",
      client_stores: [],
    },
    storesIdsByChainId: {},
    storeCodeByStoreId: {},
    objectiveVisitCountByStoreId: {},
    initialized: false,
  });

  const sortChains = (chains: definitions["Chain"][], storesIdsByChainId: StoreUpdateState["storesIdsByChainId"]) => {
    chains.sort((A, B) => {
      const existsA = storesIdsByChainId[A.id] ? 1 : -1;
      const existsB = storesIdsByChainId[B.id] ? 1 : -1;

      return existsA === existsB ? A.id - B.id : existsB - existsA;
    });

    chains.forEach((chain) => {
      const storeIds = storesIdsByChainId[chain.id];
      if (storeIds) {
        chain.stores && chain.stores.sort((A, B) => {
          const existsA = storeIds.indexOf(A.id) > -1 ? 1 : -1;
          const existsB = storeIds.indexOf(B.id) > -1 ? 1 : -1;

          return existsA === existsB ? A.id - B.id : existsB - existsA;
        });
      }
    });
  };

  react.useEffect(() => {
    (async () => {
      const authToken = auth.getToken();
      state.client = await (new rest.Client()).get(clientId, ["ClientCompany", "Chains", "ClientStores.Store"], authToken);
      if (state.client.client_company_id) {
        state.chains = await (new rest.Chain()).getByClientCompanyId(state.client.client_company_id, authToken);
        state.client!.client_company!.chains = state.chains;
        if (state.chains.length) {
          const chainIds = state.chains.map((chain) => chain.id);
          state.stores = await (new rest.Store()).getAllByChainIds(chainIds, authToken);
          state.chains.forEach((chain) => {
            chain.stores = [];
          });
          state.stores.forEach((store) => {
            const chain = state.chains.find(({ id }) => id === store.chain_id);
            chain?.stores?.push(store);
          });
        }
      }

      (state.client?.client_stores || []).forEach((clientStore) => {
        const { store, store_code, objective_visit_count } = clientStore;
        if (!store) {
          return;
        }
        if (!state.storesIdsByChainId[store.chain_id]) {
          state.storesIdsByChainId[store.chain_id] = [];
        }
        if (!store.is_closed) {
          state.storesIdsByChainId[store.chain_id].push(store.id);
        }
        state.storeCodeByStoreId[store.id] = store_code;
        state.objectiveVisitCountByStoreId[store.id] = objective_visit_count || null;
      });

      sortChains(state.chains, state.storesIdsByChainId);
      setState({ ...state, initialized: true });
    })();
  }, []);

  const onChainStoreChanged = (
    storeCodes: { [storeId: number]: string },
    objectiveVisitCounts: { [storeId: number]: number | null }
  ) => {
    const ids = Object.keys(storeCodes);
    state.client.client_stores = ids.map((id) => {
      const clientStore = createClientStore();
      clientStore.client_id = state.client.id;
      clientStore.store_id = parseInt(id, 10);
      clientStore.store_code = storeCodes[clientStore.store_id];
      if (objectiveVisitCounts[clientStore.store_id]) {
        clientStore.objective_visit_count = objectiveVisitCounts[
          clientStore.store_id
        ] as number;
      } else {
        delete clientStore.objective_visit_count;
      }
      state.storeCodeByStoreId[clientStore.store_id] = clientStore.store_code;
      state.objectiveVisitCountByStoreId[clientStore.store_id] =
        clientStore.objective_visit_count || null;
      return clientStore;
    });

    setState({ ...state });
  };

  const onSubmit = (event: React.SyntheticEvent) => {
    const client = { ...state.client };
    delete client.client_company;

    new rest.Client()
      .post(clientId, client, auth.getToken())
      .then((ret: object) => {
        props.showGlobalNotification &&
          props.showGlobalNotification(labels.succeed);
      })
      .catch(() => {
        props.showGlobalNotification &&
          props.showGlobalNotification(labels.failed);
      });
  };

  if (!state.initialized) {
    return <Loading />;
  }

  return (
    <ui.Container>
      <ui.Grid
        container
        spacing={0}
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        <ui.Grid container spacing={4}>
          <ui.Grid item xs={12} />
          <ui.Grid item xs={12}>
            <ui.Typography variant="h6">{`${
              state.client.client_company?.name || ""
            } | ${state.client.name}`}</ui.Typography>
          </ui.Grid>

          <ui.Grid item xs={12}>
            <ChainStoreSelector
              chains={state.client.client_company?.chains || []}
              onChange={onChainStoreChanged}
              selectedStoreIdsByChainId={state.storesIdsByChainId}
              storeCodeByStoreId={state.storeCodeByStoreId}
              objectiveVisitCountByStoreId={state.objectiveVisitCountByStoreId}
              showobjectiveVisitCountConfirm
              showobjectiveVisitCountInput
            />
          </ui.Grid>

          <ui.Grid item xs={12}>
            <ui.Button
              variant="contained"
              color="secondary"
              type="submit"
              onClick={onSubmit}
            >
              {labels.submit}
            </ui.Button>
          </ui.Grid>
        </ui.Grid>
      </ui.Grid>
    </ui.Container>
  );
}

export { StoreUpdate };
