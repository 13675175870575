import { request, methods } from "./request";
import { paths } from "../schema/api";

type ChainGetResponse = paths["/chain/{id}"]["get"]["responses"][200]["schema"];
type ChainPostParam =
  paths["/chain/{id}"]["post"]["parameters"]["body"]["chain"];
type ChainsGetParam = paths["/chains"]["get"]["parameters"]["query"];
type ChainsGetResponse = paths["/chains"]["get"]["responses"][200]["schema"];
type ChainsPutParam = paths["/chains"]["put"]["parameters"]["body"]["chains"];

class Chain {
  get(id: number, token: string = ""): Promise<ChainGetResponse> {
    return request({
      token,
      method: methods.Get,
      resource: `chain/${id}`,
    });
  }
  getAll(
    arg1: string | string[],
    arg2: string = ""
  ): Promise<ChainsGetResponse> {
    const params: { scope?: string } = {};
    let token = "";
    if (typeof arg1 === "string") {
      token = arg1;
    } else {
      params.scope = arg1.join(",");
      token = arg2;
    }

    return request({
      token,
      params,
      method: methods.Get,
      resource: "chains",
    });
  }
  getByIds(ids: number[], token: string = ""): Promise<ChainsGetResponse> {
    return request({
      token,
      method: methods.Get,
      resource: "chains",
      params: { ids: ids.join(",") },
    });
  }
  getByClientId(
    clientId: number,
    arg2: string | string[],
    arg3: string = ""
  ): Promise<ChainsGetResponse> {
    const params: ChainsGetParam = { client_id: clientId };
    let token = "";
    if (typeof arg2 === "string") {
      token = arg2;
    } else {
      params.scope = arg2.join(",");
      token = arg3;
    }
    return request({
      token,
      params,
      method: methods.Get,
      resource: "chains",
    });
  }
  getByClientCompanyId(
    clientCompanyId: number,
    token: string = ""
  ): Promise<ChainsGetResponse> {
    return request({
      token,
      method: methods.Get,
      resource: "chains",
      params: { client_company_id: clientCompanyId },
    });
  }
  put(chain: ChainsPutParam, token: string = "") {
    return request({
      token,
      method: methods.Put,
      resource: "chains",
      params: chain,
    });
  }
  post(id: number, chain: ChainPostParam, token: string = "") {
    return request({
      token,
      method: methods.Post,
      resource: `chain/${id}`,
      params: chain,
    });
  }
}

export { Chain };
export type { ChainGetResponse, ChainsGetResponse, ChainsPutParam };
