import * as auth from "../auth";
import { downloadAsFile } from "../window";
import { utf8bom } from "../const/file";
import { Buffer } from "buffer";

const apiEndpoint =
  process.env.REACT_APP_API_ENDPOINT || "http://127.0.0.1:8364";
const apiVersion = process.env.REACT_APP_API_VERSION || "v1";

interface RequestOption {
  method: string;
  resource: string;
  headers?: { [key: string]: string };
  params?: string | object;
  token?: string;
}

const methods = Object.freeze({
  Get: "GET",
  Post: "POST",
  Put: "PUT",
  Delete: "DELETE",
});

const mimeJson = "application/json";
const mimeCsv = "text/csv";
const mimeOctetStream = "application/octet-stream";

const baseHeader = {
  "Content-Type": mimeJson,
  Accept: mimeJson,
};

const authHeader = "Authorization";
function authHeaderValue(token: string): string {
  return `Bearer ${token}`;
}

const responseHandlers: { [status: number]: (response: any) => void } = {};

const setResponseHandler = (
  status: number,
  handler: (response: any) => void
) => {
  responseHandlers[status] = handler;
};

const request = ({
  method,
  resource,
  headers,
  params,
  token,
}: RequestOption): Promise<any> => {
  let query = "";
  let body = params;
  if (method === methods.Get && body) {
    if (typeof body === "object") {
      const keys = Object.keys(body);
      const values = [];
      for (let i = 0; i < keys.length; i += 1) {
        const key: string = keys[i];
        const value = (body as { [key: string]: string })[key];
        values.push(`${key}=${value}`);
      }
      if (values.length > 0) {
        query = `?${values.join("&")}`;
      }
      body = undefined;
    } else {
      query = `?${body}`;
    }
  } else {
    if (body) {
      body = JSON.stringify(body);
    }
  }

  let mergedHeaders = { ...baseHeader };
  if (token) {
    mergedHeaders = Object.assign(mergedHeaders, {
      [authHeader]: authHeaderValue(token),
    });
  }

  if (headers) {
    mergedHeaders = Object.assign(mergedHeaders, headers);
  }

  return fetch(`${apiEndpoint}/${apiVersion}/${resource}${query}`, {
    method,
    headers: mergedHeaders,
    mode: "cors",
    cache: "no-cache", // TODO: GET
    body: body as BodyInit,
  })
    .then(async (response) => {
      if (response.status !== 200) {
        if (responseHandlers[response.status]) {
          responseHandlers[response.status](response);
        }
        if (response.status === 500) {
          try {
            return Promise.reject(await response.text());
          } catch (e) {
            return Promise.reject();
          }
        }
      }
      if (token) {
        auth.extendToken();
      }
      switch (mergedHeaders.Accept) {
        case mimeJson: {
          return response.json();
        }
        case mimeOctetStream: {
          return response.blob();
        }
        case mimeCsv: {
          saveCsvReponse(response, `${resource}.csv`);
          break;
        }
        default: {
          console.log("Unknown Accept header:", mergedHeaders.Accept);
        }
      }
    })
    .catch((e) => {
      throw e;
    });
};

async function saveCsvReponse(response: any, fileName: string) {
  const utf8CsvBuffer = Buffer.concat([
    Buffer.from(utf8bom),
    Buffer.from(await response.text(), "utf-8"),
  ]);
  downloadAsFile(utf8CsvBuffer, fileName);
}

export { request, methods, setResponseHandler, downloadAsFile };
