import react from "react";
import * as ui from "@material-ui/core";
import * as auth from "../../auth";
import * as rest from "../../rest";
import { definitions } from "../../schema/api";
import { resources } from "../../const/resource";
import { utf8bom } from "../../const/file";
import { createClient } from "../../const/definitions/client";
import { downloadAsFile } from "../../window";
import {
  CalendarGrid,
  CalendarGridSource,
  createRows,
  defaultCols,
} from "./retrieve/calendar_grid";
import { ClientSelector } from "../../components/client_selector";
import { StaffSelector } from "../../components/staff_selector";
import { roles } from "../../const/role";
import { Buffer } from "buffer";

import GetApp from "@material-ui/icons/GetApp";

const labels: { [key: string]: string } = {
  title: "予定確認",
  staff: "スタッフ",
  year: "年",
  month: "月",
  submit: "登録",
  succeed: "登録しました",
  failed: "登録に失敗しました",
  download: "ダウンロード",
};

type RetrieveState = {
  initialized: boolean;
  client: definitions["Client"];
  clients: definitions["Client"][];
  staffId: number;
  staffs: definitions["Staff"][];
  isRoot: boolean;
  isClientReader: boolean;
  year: number;
  month: number;
  calendarRows: CalendarGridSource;
  calendarReportSummary: rest.CalendarReportSummaryGetYearMonthResponse;
  calendars: definitions["Calendar"][];
  instructions: definitions["Instruction"][];
  workdayChecked: {
    [date: string]: {
      [id: number]: boolean;
    };
  };
};

const firstYear = 2021;

function createYear(years: number): JSX.Element[] {
  const doms = [];
  const today = new Date();
  const thisYear = today.getFullYear();
  for (let year = firstYear; year < thisYear + years; year += 1) {
    doms.push(
      <ui.MenuItem key={`year${year}`} value={year}>
        {year}
      </ui.MenuItem>
    );
  }
  return doms;
}

function createMonth(): JSX.Element[] {
  const doms = [];
  for (let month = 1; month <= 12; month += 1) {
    doms.push(
      <ui.MenuItem key={`month${month}`} value={month}>
        {month}
      </ui.MenuItem>
    );
  }
  return doms;
}

function Retrieve() {
  const today = new Date();
  const [state, setState] = react.useState<RetrieveState>({
    initialized: false,
    isRoot: false,
    isClientReader: false,
    client: createClient(),
    clients: [],
    staffId: 0,
    staffs: [],
    year: today.getFullYear(),
    month: today.getMonth() + 1,
    calendarRows: [],
    calendarReportSummary: [],
    calendars: [],
    instructions: [],
    workdayChecked: {},
  });

  const onYearChanged = (year: number) => {
    state.year = year;
    refreshYearMonth().then(() => setState({ ...state }));
  };
  const onMonthChanged = (month: number) => {
    state.month = month;
    refreshYearMonth().then(() => setState({ ...state }));
  };

  const onClientChanged = (clientId: number) => {
    new Promise<definitions["Client"]>((resolve, reject) => {
      if (state.clients && state.clients.length > 0) {
        const client = state.clients.find((client) => client.id === clientId);
        return client ? resolve(client) : reject();
      }
      return new rest.Client()
        .get(clientId, ["Chains"], auth.getToken())
        .then(resolve);
    })
      .then((client: definitions["Client"]) => {
        state.client = client;
      })
      .then(() =>
        new rest.Staff().getByClientId(
          state.client.id,
          ["Stores"],
          auth.getToken()
        )
      )
      .then((json) => {
        state.staffs = json;
        if (state.staffs.length > 0) {
          state.staffId = state.staffs[0].id;
        }
      })
      .then(() => refreshYearMonth())
      .then(() => setState({ ...state }));
  };

  const onStaffIdChanged = (staffId: number) => {
    state.staffId = staffId;
    setState({ ...state });
  };

  const refreshYearMonth = () => {
    return new rest.Calendar()
      .getReportSummaryYearMonth(
        state.year,
        state.month,
        state.client.id,
        auth.getToken()
      )
      .then((summaries) => {
        const storesByStoreId: { [storeId: number]: definitions["Store"] } = {};
        state.staffs
          .map((staff) => staff.stores || [])
          .flat()
          .forEach((store) => {
            storesByStoreId[store.id] = store;
          });

        state.calendarRows = summaries.filter(
          (summary) => !storesByStoreId[summary.store_id]?.is_closed
        );
      });
  };

  const onDownloadClicked = () => {
    const lastDate = new Date(state.year, state.month, 0).getDate();
    const rows = createRows(
      state.calendarRows.filter((row) => row.staff_id === state.staffId),
      lastDate,
      ""
    );

    const cols = defaultCols();
    const lines = [];

    const header = [];
    for (let i = 0; i < cols.length; i += 1) {
      header.push(cols[i].label);
    }
    for (let i = 1; i <= lastDate; i += 1) {
      header.push(`${i}`);
    }

    lines.push(header.join(","));

    for (let i = 0; i < rows.length; i += 1) {
      const row = rows[i];
      const line = [];
      for (let j = 0; j < cols.length; j += 1) {
        const col = cols[j];
        line.push((row as any)[col.identifier].value);
      }
      for (let j = 1; j <= lastDate; j += 1) {
        line.push((row as any)[j].value);
      }
      lines.push(line.join(","));
    }

    const utf8CsvBuffer = Buffer.concat([
      Buffer.from(utf8bom),
      Buffer.from(lines.join("\n"), "utf-8"),
    ]);
    downloadAsFile(
      utf8CsvBuffer,
      `calendar_${state.year}_${state.month}_staff${state.staffId}.csv`
    );
  };

  react.useEffect(() => {
    (async () => {
      if (state.initialized) {
        return;
      }
      state.initialized = true;

      const roleName = (auth.getRole())?.roleName;
      state.isRoot = roleName === roles.root.identifier;
      state.isClientReader = roleName === roles.client_reader.identifier;
      const needeedAuthorization = roleName === roles.client_reader.identifier
        ? resources.calendar.identifier
        : resources.client.identifier;
      const authenticated = auth.isAuthorized(
        needeedAuthorization,
        auth.resourceCrudFlag.retrieve
      );
      if (!authenticated) {
        return;
      }

      const user: rest.UserGetIdResponse = await (new rest.User()).getMe(["Client"], auth.getToken());
      const clientId = user.client?.id || 0;
      if (clientId > 0) {
        onClientChanged(clientId);
      } else {
        state.clients = await (new rest.Client()).getAll(["Chains"], auth.getToken());
        if (state.clients.length <= 0) {
          return;
        }

        onClientChanged(state.clients[0].id);
      }

      setState({ ...state });
    })();
  }, []);

  return (
    <ui.Container>
      <ui.Grid container spacing={4}>
        <ui.Grid item xs={12} />
        <ui.Grid item xs={12}>
          <ui.Typography variant="h6">{labels.title}</ui.Typography>
        </ui.Grid>

        {(state.isRoot || state.isClientReader) && (
          <ui.Grid item xs={12} sm={3}>
            <ClientSelector
              clients={state.clients}
              selectedValue={state.client.id}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onClientChanged(parseInt(event.target.value, 10))
              }
            />
          </ui.Grid>
        )}

        <ui.Grid item xs={12} sm={(state.isRoot || state.isClientReader) ? 3 : 6}>
          <StaffSelector
            staffs={state.staffs}
            selectedValue={state.staffId || 0}
            onChanged={onStaffIdChanged}
          />
        </ui.Grid>

        <ui.Grid item xs={3} sm={1}>
          <ui.InputLabel id="year-id-label">{labels.year}</ui.InputLabel>
          <ui.Select
            labelId="year-id-label"
            id="year-id"
            name="year"
            value={state.year}
            onChange={(e: any) => onYearChanged(parseInt(e.target.value, 10))}
          >
            {createYear(2)}
          </ui.Select>
        </ui.Grid>
        <ui.Grid item xs={3} sm={1}>
          <ui.InputLabel id="month-id-label">{labels.month}</ui.InputLabel>
          <ui.Select
            labelId="month-id-label"
            id="month-id"
            name="month"
            value={state.month}
            onChange={(e: any) => onMonthChanged(parseInt(e.target.value, 10))}
          >
            {createMonth()}
          </ui.Select>
        </ui.Grid>

        <ui.Grid container item xs={6} sm={4} justifyContent="flex-end">
          <ui.IconButton onClick={onDownloadClicked}>
            <GetApp />
            <ui.Typography variant="body2">{labels.download}</ui.Typography>
          </ui.IconButton>
        </ui.Grid>

        <ui.Grid item xs={12}>
          <CalendarGrid
            year={state.year}
            month={state.month}
            src={state.calendarRows.filter(
              (row) => row.staff_id === state.staffId
            )}
          />
        </ui.Grid>
      </ui.Grid>
    </ui.Container>
  );
}

export { Retrieve };
