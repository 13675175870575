import { definitions } from "../../schema/api";

const createInstruction = (): definitions["Instruction"] => {
  return {
    id: 0,
    client_id: 0,
    type: 2,
    year: 0,
    month: 0,
    from_date: "",
    to_date: "",
    title: "",
    detail: "",
    instruction_list_items: [],
  };
};

export { createInstruction };
