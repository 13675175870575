import * as ui from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      "background-color": "rgba(0, 0, 0, 0.40)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  })
);

interface ImagePreviewProps {
  open: boolean;
  content: JSX.Element | null;
  onPreviewClose: () => void;
}

function ImagePreview(props: ImagePreviewProps) {
  const classes = useStyles();

  const content = props.content ? props.content : <div />;

  return (
    <ui.Popover
      id="preview"
      open={props.open}
      onClose={props.onPreviewClose}
      anchorReference="none"
      anchorOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      className={classes.popover}
    >
      {content}
    </ui.Popover>
  );
}

export { ImagePreview };
export type { ImagePreviewProps };
