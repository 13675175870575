import * as ui from "@material-ui/core";
import { Link } from "react-router-dom";
import * as auth from "../../../auth";
import { resources, controls, Names } from "../../../const/resource";
import { roles } from "../../../const/role";

const labels: { [key: string]: string } = {
  staffClaendarCreate: "確認",
  staffWorkTimeRetrieve: "出退勤時間",
  clientStoreBulkInsert: "担当店舗一括登録",
};

interface ResourceItemProps {
  flag: number;
  resource: string;
  onClicked?: (e: any) => void;
}

function hasFlas(flags: number, flag: number): boolean {
  return (flags & flag) === flag;
}

function isHideMenu(
  resourceId: string,
  flag: number,
  role: auth.Role | null
): boolean {
  if (resourceId === resources.report_image.identifier) {
    if (flag === auth.resourceCrudFlag.create) {
      return true;
    }
  }

  if (
    resourceId === resources.calendar.identifier &&
    flag === auth.resourceCrudFlag.retrieve &&
    role?.roleName === roles.staff.identifier
  ) {
    return true;
  }
  return false;
}

function irregularLabel(
  role: auth.Role | null,
  resource: string,
  control: Names
): string {
  if (role && roles.staff.identifier === role.roleName) {
    if (
      resource === resources.calendar.identifier &&
      control.identifier === controls.create.identifier
    ) {
      return labels.staffClaendarCreate;
    }
  }
  if (
    resource === resources.staff.identifier &&
    control.identifier === controls.work_time_retrieve.identifier
  ) {
    return labels.staffWorkTimeRetrieve;
  }

  return control.label;
}

function createItem(
  role: auth.Role | null,
  resource: string,
  control: Names,
  callback?: (e: any) => void
): JSX.Element {
  return (
    <ui.ListItem
      key={`${resource}-${control.identifier}`}
      button
      component={Link}
      to={`/${resource}/${control.identifier}`}
      onClick={callback}
    >
      {irregularLabel(role, resource, control)}
    </ui.ListItem>
  );
}

function ResourceItem(props: ResourceItemProps) {
  const role = auth.getRole();
  const items: JSX.Element[] = [];
  if ([roles.root.identifier, roles.client.identifier].includes(role?.roleName || '') && props.resource === resources.staff.identifier) {
    items.push(
      createItem(role, resources.staff.identifier, controls.work_time_retrieve, props.onClicked)
    );
  }
  if (hasFlas(props.flag, auth.resourceCrudFlag.retrieve)) {
    if (!isHideMenu(props.resource, auth.resourceCrudFlag.retrieve, role)) {
      items.push(
        createItem(role, props.resource, controls.retrieve, props.onClicked)
      );
    }
  }
  if (hasFlas(props.flag, auth.resourceCrudFlag.create)) {
    if (!isHideMenu(props.resource, auth.resourceCrudFlag.create, role)) {
      items.push(
        createItem(role, props.resource, controls.create, props.onClicked)
      );

      switch (props.resource) {
        case resources.store.identifier:
        case resources.chain.identifier:
        case resources.staff.identifier: {
          items.push(
            createItem(
              role,
              props.resource,
              controls.bulk_insert,
              props.onClicked
            )
          );
          break;
        }
        case resources.client.identifier: {
          items.push(
            <ui.ListItem
              key={`${resources.client.identifier}-${resources.store.identifier}-${controls.bulk_insert.identifier}`}
              button
              component={Link}
              to={`/${resources.client.identifier}/${resources.store.identifier}/${controls.bulk_insert.identifier}`}
              onClick={props.onClicked}
            >
              {labels.clientStoreBulkInsert}
            </ui.ListItem>
          );
          break;
        }
      }
    }
  }

  return <ui.List>{items}</ui.List>;
}

export { ResourceItem };
export type { ResourceItemProps };
