import react from "react";
import { useParams } from "react-router-dom";
import * as ui from "@material-ui/core";
import { resources } from "../../const/resource";
import * as auth from "../../auth";
import * as rest from "../../rest";
import { definitions } from "../../schema/api";
import { Loading } from "../../components/loading";
import { Form } from "./form";

type CopyState = {
  client?: definitions["Client"];
  initialized: boolean;
};
type CopyProps = {
  showGlobalNotification?: (message: string) => void;
};

type CopyParams = {
  id: string;
};

function Copy(props: CopyProps) {
  const params = useParams<CopyParams>();
  const instructionId = parseInt(params.id as string, 10);
  const [state, setState] = react.useState<CopyState>({
    initialized: false,
  });

  react.useEffect(() => {
    (async () => {
      if (state.initialized) {
        return;
      }
      state.initialized = true;
      const json = await (new rest.User()).getMe(["Client"], auth.getToken());
      if (!json || !auth.isAuthorized(resources.instruction.identifier, auth.resourceCrudFlag.create)) {
        return;
      }
      state.client = json.client;
      setState({ ...state });
    })();
  }, []);

  return state.initialized
    ?
      <ui.Container>
        <Form
          type="copy"
          userClient={state.client}
          instructionId={instructionId}
          showGlobalNotification={props.showGlobalNotification}
        />
      </ui.Container>
  :
    <Loading />;
}

export { Copy };
