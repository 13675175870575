import react from "react";
import * as ui from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { definitions } from "../schema/api";
import { FormattedTextField } from "./formatted_text_field";

import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const labels: { [key: string]: string } = {
  // customer wants use this word
  listHeader: "企業・店舗選択",
  listConfirmHeader: "店舗選択確認",
  storeSearch: "店舗検索",
  storeCode: "店舗コード",
  objectiveVisitCount: "目標訪問数",
  objectiveVisitCountNotDefined: "未設定",
  storeSelected: "件 選択中",
};

type ChainStoreSelectorState = {
  open: { [chainId: number]: boolean };
  storeSearchWords: { [chainId: number]: string };
  selectedChainIds: number[];
  selectedStoreIdsByChainId: { [chainId: number]: number[] };
  storeCodeByStoreId: { [storeId: number]: string };
  objectiveVisitCountByStoreId: { [storeId: number]: number | null };
};

type ChainStoreSelectorProps = {
  direction?: "row" | "column";
  checkbox?: boolean;
  chains: definitions["Chain"][];
  selectedStoreIdsByChainId?: { [chainId: number]: number[] };
  storeCodeByStoreId?: { [storeId: number]: string };
  objectiveVisitCountByStoreId?: { [storeId: number]: number | null };
  showobjectiveVisitCountInput?: boolean;
  showobjectiveVisitCountConfirm?: boolean;
  showClosedStore?: boolean;
  onChange: (
    storeCodes: { [storeId: number]: string },
    objectiveVisitCounts: { [storeId: number]: number | null }
  ) => void;
};

function ChainStoreSelector(props: ChainStoreSelectorProps) {
  const classes = useStyles();

  const [state, setState] = react.useState<ChainStoreSelectorState>({
    open: {},
    storeSearchWords: {},
    selectedChainIds: props.selectedStoreIdsByChainId
      ? Object.keys(props.selectedStoreIdsByChainId).map((id) =>
          parseInt(id, 10)
        )
      : [],
    selectedStoreIdsByChainId: props.selectedStoreIdsByChainId || {},
    storeCodeByStoreId: props.storeCodeByStoreId || {},
    objectiveVisitCountByStoreId: props.objectiveVisitCountByStoreId || {},
  });

  const toggleChainOpen = (chainId: number) => {
    state.open[chainId] = !state.open[chainId];
    setState({ ...state });
  };
  const toggleChainId = (chainId: number) => {
    const index = state.selectedChainIds.indexOf(chainId);
    const chain = props.chains.find((chain) => chain.id === chainId);

    if (!state.selectedStoreIdsByChainId[chainId]) {
      state.selectedStoreIdsByChainId[chainId] = [];
    }

    const storeIds = state.selectedStoreIdsByChainId[chainId];
    const stores = chain
      ? state.storeSearchWords[chain.id || -1]
        ? chain.stores?.filter((store) =>
            store.name.includes(state.storeSearchWords[chain.id])
          ) || []
        : chain.stores || []
      : [];

    if (index === -1) {
      state.open[chainId] = true;
      state.selectedChainIds.push(chainId);
      if (chain?.stores) {
        for (let i = 0; i < stores.length; i += 1) {
          const store = stores[i];
          const storeIndex = storeIds.indexOf(store.id);
          if (storeIndex === -1) {
            storeIds.push(store.id);
          }
        }
      }
    } else {
      state.selectedChainIds.splice(index, 1);
      if (chain?.stores) {
        for (let i = 0; i < stores.length; i += 1) {
          const store = stores[i];
          const storeIndex = storeIds.indexOf(store.id);
          if (storeIndex !== -1) {
            storeIds.splice(storeIndex, 1);
          }
        }
      }
    }

    setState({ ...state });

    onChange();
  };
  const toggleStoreId = (store: definitions["Store"]) => {
    if (!state.selectedStoreIdsByChainId[store.chain_id]) {
      state.selectedStoreIdsByChainId[store.chain_id] = [];
    }

    const storeIds = state.selectedStoreIdsByChainId[store.chain_id];
    const index = storeIds.indexOf(store.id);
    if (index === -1) {
      storeIds.push(store.id);
    } else {
      storeIds.splice(index, 1);
    }

    setState({ ...state });

    onChange();
  };
  const onObjectiveVisitCountChanged = (storeId: number, count: number) => {
    state.objectiveVisitCountByStoreId[storeId] = count;
    setState({ ...state });
    onChange();
  };
  const onStoreCodeChanged = (storeId: number, code: string) => {
    state.storeCodeByStoreId[storeId] = code;
    setState({ ...state });
    onChange();
  };

  const onSearchWordChanged = (chainId: number, word: string) => {
    state.storeSearchWords[chainId] = word;
    setState({ ...state });
  };

  const onChange = () => {
    const storeCodes: { [storeId: number]: string } = {};
    const objectiveVisitCounts: { [storeId: number]: number | null } = {};
    const keys = Object.keys(state.selectedStoreIdsByChainId);
    for (let i = 0; i < keys.length; i += 1) {
      const chainId = parseInt(keys[i], 10);
      const chainStoreIds = state.selectedStoreIdsByChainId[chainId];

      if (chainStoreIds) {
        for (let j = 0; j < chainStoreIds.length; j += 1) {
          storeCodes[chainStoreIds[j]] =
            state.storeCodeByStoreId[chainStoreIds[j]] || "";
          objectiveVisitCounts[chainStoreIds[j]] =
            state.objectiveVisitCountByStoreId[chainStoreIds[j]] || null;
        }
      }
    }

    props.onChange(storeCodes, objectiveVisitCounts);
  };

  const showobjectiveVisitCountInput =
    props.objectiveVisitCountByStoreId && props.showobjectiveVisitCountInput;
  const showobjectiveVisitCountConfirm =
    props.objectiveVisitCountByStoreId && props.showobjectiveVisitCountConfirm;

  const storesByStoreId: { [storeId: number]: definitions["Store"] } = {};
  const chainWithOpenStores: (definitions["Chain"] & {
    openStores?: definitions["Store"][];
  })[] = props.chains;
  props.chains
    .map((chain, i) => {
      chainWithOpenStores[i].openStores = [];
      if (chain.stores) {
        chainWithOpenStores[i].openStores = chain.stores.filter(
          (store) => !store.is_closed
        );
      }
      return chain.stores || [];
    })
    .flat()
    .forEach((store) => (storesByStoreId[store.id] = store));

  return (
    <ui.Grid container>
      <ui.Grid
        item
        xs={12}
        sm={props.direction ? (props.direction === "row" ? 12 : 6) : 6}
      >
        <ui.InputLabel id="chain-store-selector-label">
          {labels.listHeader}
        </ui.InputLabel>
        <ui.List>
          {chainWithOpenStores.map((chain, index) => (
            <ui.Box key={`chain-select-${index}`}>
              <ui.Divider variant="middle" />
              <ui.ListItem>
                {(props.checkbox === undefined || props.checkbox) && (
                  <ui.ListItemIcon>
                    <ui.Checkbox
                      edge="start"
                      onChange={() => toggleChainId(chain.id)}
                      checked={state.selectedChainIds.indexOf(chain.id) !== -1}
                      tabIndex={-1}
                      disableRipple
                    />
                  </ui.ListItemIcon>
                )}
                <ui.ListItemText
                  primary={chain.name}
                  secondary={`${
                    state.selectedStoreIdsByChainId[chain.id]?.length || 0
                  } / ${
                    (props.showClosedStore
                      ? chain.stores?.length
                      : chain.openStores?.length) || 0
                  } ${labels.storeSelected}`}
                />
                <ui.IconButton onClick={() => toggleChainOpen(chain.id)}>
                  {state.open[chain.id] ? <ExpandLess /> : <ExpandMore />}
                </ui.IconButton>
              </ui.ListItem>
              <ui.Collapse
                in={state.open[chain.id]}
                timeout="auto"
                unmountOnExit
              >
                <ui.List dense component="div" disablePadding>
                  <ui.ListItem
                    button
                    key={`store-search-${chain.id}`}
                    className={classes.nested}
                  >
                    <ui.TextField
                      fullWidth
                      label={`${chain.name} ${labels.storeSearch}`}
                      InputLabelProps={{ shrink: true }}
                      onChange={(e: any) =>
                        onSearchWordChanged(chain.id, e.target.value)
                      }
                    />
                  </ui.ListItem>
                  {(props.showClosedStore
                    ? chain.stores || []
                    : chain.openStores || []
                  ).map((store) => {
                    if (
                      state.storeSearchWords[chain.id] &&
                      !store.name.includes(state.storeSearchWords[chain.id])
                    ) {
                      return null;
                    }

                    return (
                      <ui.ListItem
                        key={`store-select-${store.id}`}
                        className={classes.nested}
                      >
                        <ui.ListItemIcon>
                          <ui.Checkbox
                            edge="start"
                            onClick={() => toggleStoreId(store)}
                            checked={
                              (state.selectedStoreIdsByChainId[chain.id] &&
                                state.selectedStoreIdsByChainId[
                                  chain.id
                                ].indexOf(store.id) !== -1) ||
                              false
                            }
                            tabIndex={-1}
                            disableRipple
                          />
                        </ui.ListItemIcon>

                        <ui.Grid container spacing={1}>
                          <ui.Grid
                            container
                            item
                            alignItems="center"
                            xs={
                              props.storeCodeByStoreId
                                ? showobjectiveVisitCountInput
                                  ? 6
                                  : 9
                                : showobjectiveVisitCountInput
                                ? 9
                                : 12
                            }
                          >
                            <ui.ListItemText primary={store.name} />
                          </ui.Grid>

                          {showobjectiveVisitCountInput && (
                            <ui.Grid item xs={props.storeCodeByStoreId ? 3 : 6}>
                              <FormattedTextField
                                numeric
                                label={labels.objectiveVisitCount}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e: any) =>
                                  onObjectiveVisitCountChanged(
                                    store.id,
                                    parseInt(e.target.value, 10)
                                  )
                                }
                                value={
                                  typeof state.objectiveVisitCountByStoreId[
                                    store.id
                                  ] === "number"
                                    ? `${
                                        state.objectiveVisitCountByStoreId[
                                          store.id
                                        ]
                                      }`
                                    : ""
                                }
                              />
                            </ui.Grid>
                          )}

                          {props.storeCodeByStoreId && (
                            <ui.Grid
                              item
                              xs={showobjectiveVisitCountInput ? 3 : 6}
                            >
                              <FormattedTextField
                                alphanumericbar
                                label={labels.storeCode}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e: any) =>
                                  onStoreCodeChanged(store.id, e.target.value)
                                }
                                value={state.storeCodeByStoreId[store.id] || ""}
                              />
                            </ui.Grid>
                          )}
                        </ui.Grid>
                      </ui.ListItem>
                    );
                  })}
                </ui.List>
              </ui.Collapse>
            </ui.Box>
          ))}
        </ui.List>
      </ui.Grid>

      <ui.Grid
        item
        xs={12}
        sm={props.direction ? (props.direction === "row" ? 12 : 6) : 6}
      >
        <ui.InputLabel id="chain-store-selector-label">
          {labels.listConfirmHeader}
        </ui.InputLabel>
        <ui.Paper>
          <ui.List dense>
            {chainWithOpenStores.map((chain) => {
              const storeIds = state.selectedStoreIdsByChainId[chain.id];
              if (!storeIds || storeIds.length === 0) {
                return null;
              }

              return (
                <ui.Box key={`chain-confirm-${chain.id}`}>
                  <ui.Divider variant="middle" />
                  <ui.ListItem
                    key={`chain-store-list-confirm-chain-${chain.id}`}
                  >
                    <ui.ListItemText primary={chain.name} />
                  </ui.ListItem>
                  {(props.showClosedStore
                    ? storeIds
                    : storeIds.filter(
                        (storeId) => !storesByStoreId[storeId]?.is_closed
                      )
                  ).map((storeId) => (
                    <ui.ListItem
                      key={`store-confirm-${storeId}`}
                      className={classes.nested}
                    >
                      <ui.ListItemText
                        primary={
                          (storesByStoreId[storeId]?.name || "") +
                          ((props.storeCodeByStoreId &&
                            props.storeCodeByStoreId[storeId] &&
                            ` [${props.storeCodeByStoreId[storeId]}]`) ||
                            "")
                        }
                        secondary={
                          (showobjectiveVisitCountConfirm &&
                            typeof state.objectiveVisitCountByStoreId[
                              storeId
                            ] === "number" &&
                            `${labels.objectiveVisitCount}: ${state.objectiveVisitCountByStoreId[storeId]}`) ||
                          `${labels.objectiveVisitCount}: ${labels.objectiveVisitCountNotDefined}`
                        }
                      />
                    </ui.ListItem>
                  ))}
                </ui.Box>
              );
            })}
          </ui.List>
        </ui.Paper>
      </ui.Grid>
    </ui.Grid>
  );
}

export { ChainStoreSelector };
