import { Rule, rules } from "./rules";
import { Validator, validators } from "./validator";

type FormProp = {
  error: boolean;
  helperText: string;
};
type ButtonProp = {
  disabled: boolean;
};
type FormValidator = {
  rule: Rule;
  errors: { [key: string]: string[] };
  ignore: { [key: string]: boolean };
  formProps: { [key: string]: FormProp };
  submitButtonProps: ButtonProp;
  requiredFieldsTouchedFlags: { [key: string]: boolean };
  add: (key: string, validatorArray: Validator[]) => void;
  touch: (key: string) => void;
  setIgnore: (key: string, ignore: boolean) => void;
  validate: (subject: any, key: string) => void;
  validateAll: (subject: any) => void;
  hasError: () => boolean;
  onValidated?: (errors: { [key: string]: string[] }) => void;
};

function createFormValidator(type: string): FormValidator {
  const rule = rules[type];
  const errors: { [key: string]: string[] } = {};

  const ignore: { [key: string]: boolean } = {};
  const formProps: { [key: string]: FormProp } = {};
  const submitButtonProps: ButtonProp = { disabled: true };
  const requiredFieldsTouchedFlags: { [key: string]: boolean } = {};
  const keys = Object.keys(rule);
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    errors[key] = [];
    formProps[key] = {
      error: false,
      helperText: "",
    };
    ignore[key] = false;
    if (
      rule[key].find(
        (validator) =>
          validator.identifier === validators.required.identifier ||
          validator.identifier === validators.requiredArray.identifier ||
          validator.identifier === validators.notZero.identifier
      )
    ) {
      requiredFieldsTouchedFlags[key] = false;
    }
  }

  const validator: FormValidator = {
    rule,
    errors,
    ignore,
    formProps,
    submitButtonProps,
    requiredFieldsTouchedFlags,
    add(key: string, validatorArray: Validator[]): void {
      this.formProps[key] = {
        error: false,
        helperText: "",
      };
      this.rule[key] = validatorArray;
      this.ignore[key] = false;
      if (
        !!validatorArray.find(
          (validator) =>
            validator.identifier === validators.required.identifier ||
            validator.identifier === validators.requiredArray.identifier ||
            validator.identifier === validators.notZero.identifier
        )
      ) {
        requiredFieldsTouchedFlags[key] = false;
      }
    },
    touch(key: string): void {
      this.requiredFieldsTouchedFlags[key] = true;
    },
    setIgnore(key: string, ignore: boolean): void {
      this.ignore[key] = ignore;
    },
    validate(subject: any, key: string) {
      if (!this.formProps[key]) {
        return;
      }
      this.errors[key] = [];
      this.formProps[key].error = false;
      this.formProps[key].helperText = "";

      if (this.requiredFieldsTouchedFlags[key] !== undefined) {
        this.requiredFieldsTouchedFlags[key] = true;
      }

      const validatorRules = this.rule[key];
      for (let i = 0; i < validatorRules.length; i += 1) {
        const validator = validatorRules[i];
        if (!validator.validate(subject)) {
          this.formProps[key].error = true;
          this.formProps[key].helperText = validator.errorMessage;
          this.errors[key].push(validator.identifier);
        }
      }

      this.submitButtonProps.disabled = this.hasError();
      if (!this.submitButtonProps.disabled) {
        this.submitButtonProps.disabled = !!Object.keys(
          this.requiredFieldsTouchedFlags
        ).find((key) => !this.requiredFieldsTouchedFlags[key]);
      }

      this.onValidated && this.onValidated(this.errors);
    },
    validateAll(subject: any) {
      const keys = Object.keys(this.rule);
      for (let i = 0; i < keys.length; i += 1) {
        const key = keys[i];
        if (!this.formProps[key]) {
          continue;
        }

        this.errors[key] = [];
        this.formProps[key].error = false;
        this.formProps[key].helperText = "";

        if (this.requiredFieldsTouchedFlags[key] !== undefined) {
          this.requiredFieldsTouchedFlags[key] = true;
        }

        if (subject[key] === undefined) {
          continue;
        }

        const validatorRules = this.rule[key];
        for (let i = 0; i < validatorRules.length; i += 1) {
          const validator = validatorRules[i];
          if (!validator.validate(subject[key])) {
            this.formProps[key].error = true;
            this.formProps[key].helperText = validator.errorMessage;
            this.errors[key].push(validator.identifier);
          }
        }
      }

      this.submitButtonProps.disabled = this.hasError();
      if (!this.submitButtonProps.disabled) {
        this.submitButtonProps.disabled = !!Object.keys(
          this.requiredFieldsTouchedFlags
        ).find((key) => !this.requiredFieldsTouchedFlags[key]);
      }

      this.onValidated && this.onValidated(this.errors);
    },
    hasError(): boolean {
      return !!Object.keys(this.formProps).find(
        (key) =>
          this.formProps[key].error &&
          (this.ignore[key] === undefined ||
            (this.ignore[key] !== undefined && !this.ignore[key]))
      );
    },
  };

  return validator;
}

export { createFormValidator };
export type { FormValidator, ButtonProp };
