import react from "react";
import { useNavigate } from "react-router-dom";
import * as ui from "@material-ui/core";
import * as rest from "../../rest";
import * as auth from "../../auth";
import { definitions } from "../../schema/api";
import { resources, controls } from "../../const/resource";
import * as datetime from "../../datetime";
import { createFormValidator, FormValidator } from "../../validation/form";
import { ClientSelector } from "../../components/client_selector";
import { NavigatorBack } from "../../components/navigator_back";
import { Loading } from "../../components/loading";
import { roles } from "../../const/role";

import Close from "@material-ui/icons/Close";

const labels: { [key: string]: string } = {
  list: "一覧",
  titleCreate: "掲示板作成",
  titleUpdate: "掲示板更新",
  title: "タイトル",
  content: "内容",
  important: "月またぎで表示する",
  fileButton: "ファイルを追加...",
  submitCreate: "保存",
  delete: "削除",
  submitUpdate: "更新",
  deleteConfirm: "この書き込みを削除しますか？",
};

type FormState = {
  validator: FormValidator;
  noticeBoard: definitions["NoticeBoard"];
  files: File[];
  noticeBoardFileIdsBeforeUpdate: number[];
  clients?: definitions["Client"][];
  initialized: boolean;
};

type FormProps = {
  onSubmit: (
    noticeBoard: definitions["NoticeBoard"],
    files: File[],
    noticeBoardFileIdsBeforeUpdate: number[]
  ) => void;
  noticeBoardId?: number;
};

function Form(props: FormProps) {
  const validator = createFormValidator("notice_board");

  const navigate = useNavigate();

  const [state, setState] = react.useState<FormState>({
    validator,
    files: [],
    noticeBoardFileIdsBeforeUpdate: [],
    noticeBoard: {
      id: props.noticeBoardId || 0,
      client_id: 0,
      title: "",
      content: "",
      date: datetime.toRFC3339(new Date()),
    },
    initialized: false,
  });

  const onNoticeBoardStringValueChanged = (
    name: "title" | "content",
    event: any
  ) => {
    state.noticeBoard[name] = event.target.value;
    setState({ ...state });
  };

  const onClientIdChanged = (event: any) => {
    state.noticeBoard.client_id = parseInt(event.target.value, 10);
    setState({ ...state });
  };

  const onImportantChanged = (value: boolean) => {
    state.noticeBoard.important = value;
    setState({ ...state });
  }

  const onFileChanged = (event: any) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }

    state.files.push(file);
    setState({ ...state });
  };
  const onRegisteredFileDeleteClicked = (index: number) => {
    state.noticeBoard.notice_board_files &&
      state.noticeBoard.notice_board_files.splice(index, 1);
    setState({ ...state });
  };
  const onTemporaryFileDeleteClicked = (index: number) => {
    state.files.splice(index, 1);
    setState({ ...state });
  };

  const onSubmit = () => {
    props.onSubmit(
      state.noticeBoard,
      state.files,
      state.noticeBoardFileIdsBeforeUpdate
    );
  };

  const onDeleteClicked = () => {
    if (window.confirm(labels.deleteConfirm)) {
      new rest.NoticeBoard()
        .delete(state.noticeBoard.id, auth.getToken())
        .then(() => {
          navigate(`/${resources.notice_board.identifier}/${controls.retrieve.identifier}`);
        });
    }
  };

  react.useEffect(() => {
    if (state.initialized) {
      return;
    }

    state.initialized = true;
    const promises = [];

    if (auth.getRole()?.roleName === roles.root.identifier) {
      state.clients = [];
      promises.push(
        new rest.Client().getAll(auth.getToken()).then((json) => {
          state.clients = json;
        })
      );
    } else {
      promises.push(
        new rest.User().getMe(["Client"], auth.getToken()).then((json) => {
          if (json.client) {
            state.noticeBoard.client_id = json.client.id;
          }
        })
      );
    }

    if (props.noticeBoardId) {
      promises.push(
        new rest.NoticeBoard()
          .get(props.noticeBoardId, auth.getToken())
          .then((json: rest.NoticeBoardGetResponse) => {
            state.noticeBoard = json;
            if (
              state.noticeBoard.notice_board_files &&
              state.noticeBoard.notice_board_files.length > 0
            ) {
              for (
                let i = 0;
                i < state.noticeBoard.notice_board_files.length;
                i += 1
              ) {
                state.noticeBoardFileIdsBeforeUpdate.push(
                  state.noticeBoard.notice_board_files[i].id
                );
              }
            }
            state.validator.validateAll(state.noticeBoard);
          })
      );
    }

    Promise.all(promises).then(() => setState({ ...state }));
  });

  if (!state.initialized) {
    return <Loading />;
  }

  const roleName = auth.getRole()?.roleName || "";

  const deletable =
    state.noticeBoard.id > 0 &&
    (roleName === roles.client.identifier ||
      roleName === roles.root.identifier);

  return (
    <ui.Grid
      container
      spacing={4}
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
    >
      <ui.Grid container item xs={12} spacing={0}>
        <NavigatorBack
          xs={12}
          sm={12}
          label={labels.list}
          href={`/${resources.notice_board.identifier}/${controls.retrieve.identifier}`}
        />

        <ui.Grid item xs={12}>
          <ui.Typography variant="h6">
            {props.noticeBoardId ? labels.titleUpdate : labels.titleCreate}
          </ui.Typography>
        </ui.Grid>
      </ui.Grid>

      <ui.Grid container item xs={12} direction="row">
        {state.clients && (
          <ui.Grid item xs={12}>
            <ClientSelector
              clients={state.clients}
              onChange={onClientIdChanged}
              selectedValue={state.noticeBoard.client_id}
            />
          </ui.Grid>
        )}
        <ui.Grid item xs={12}>
          <ui.TextField
            {...state.validator.formProps.title}
            fullWidth
            id="notice_board-title-id"
            label={labels.title}
            defaultValue={state.noticeBoard.title}
            name="title"
            onBlur={(e) => {
              state.validator.validate(e.target.value, "title");
              onNoticeBoardStringValueChanged("title", e);
            }}
          />
        </ui.Grid>
        <ui.Grid item xs={12}>
          <ui.TextField
            {...state.validator.formProps.content}
            fullWidth
            id="notice_board-content-id"
            label={labels.content}
            defaultValue={state.noticeBoard.content}
            name="content"
            multiline
            rows={6}
            onBlur={(e) => {
              state.validator.validate(e.target.value, "content");
              onNoticeBoardStringValueChanged("content", e);
            }}
          />
        </ui.Grid>
      </ui.Grid>

      <ui.Grid container item xs={12} justifyContent="flex-start">
        <ui.FormControlLabel
          key={`notice_board-important`}
          control={
            <ui.Checkbox
              onChange={() =>
                onImportantChanged(!state.noticeBoard.important)
              }
              checked={state.noticeBoard.important}
            />
          }
          label={labels.important}
        />
      </ui.Grid>

      <ui.Grid
        container
        item
        xs={12}
        alignItems="center"
        justifyContent="flex-start"
      >
        <ui.Grid item>
          <ui.Button variant="contained" component="label">
            {labels.fileButton}
            <form encType="multipart/form-data" action="">
              <input type="file" onChange={onFileChanged} hidden />
            </form>
          </ui.Button>
        </ui.Grid>
      </ui.Grid>
      <ui.Grid
        container
        item
        xs={12}
        alignItems="flex-start"
        justifyContent="flex-start"
        direction="column"
      >
        <>
          {(state.noticeBoard.notice_board_files || []).map((f, i) => (
            <ui.Grid
              container
              item
              direction="row"
              alignItems="center"
              key={`registered-file-${i}`}
            >
              <ui.Grid item>
                <ui.IconButton onClick={() => onRegisteredFileDeleteClicked(i)}>
                  <Close />
                </ui.IconButton>
              </ui.Grid>
              <ui.Grid item>
                <ui.Link
                  href={`/${f.file_path}`}
                  target="_blank"
                  rel="noopener"
                >
                  {f.file_name}
                </ui.Link>
              </ui.Grid>
            </ui.Grid>
          ))}
        </>
        <>
          {state.files.map((file, i) => (
            <ui.Grid
              container
              item
              direction="row"
              alignItems="center"
              key={`temporary-file-${i}`}
            >
              <ui.Grid item>
                <ui.IconButton onClick={() => onTemporaryFileDeleteClicked(i)}>
                  <Close />
                </ui.IconButton>
              </ui.Grid>
              <ui.Grid item>{file.name}</ui.Grid>
            </ui.Grid>
          ))}
        </>
      </ui.Grid>

      <ui.Grid container item xs={12} spacing={2}>
        <ui.Grid item>
          <ui.Button
            {...state.validator.submitButtonProps}
            variant="contained"
            color="primary"
            onClick={onSubmit}
          >
            {props.noticeBoardId ? labels.submitUpdate : labels.submitCreate}
          </ui.Button>
        </ui.Grid>
        {deletable && (
          <ui.Grid item>
            <ui.Button
              variant="contained"
              color="secondary"
              type="submit"
              onClick={onDeleteClicked}
            >
              {labels.delete}
            </ui.Button>
          </ui.Grid>
        )}
      </ui.Grid>
    </ui.Grid>
  );
}

export { Form };
