import { AuthRouter } from "./layout/auth_router";
import { BrowserRouter } from "react-router-dom";
import { DataStoreContextProvider } from "./context/DataStoreContext";

const App = () =>
  <div className="App">
    <DataStoreContextProvider>
      <BrowserRouter>
        <AuthRouter />
      </BrowserRouter>
    </DataStoreContextProvider>
  </div>;

export default App;
