import react, { useContext } from "react";
import * as ui from "@material-ui/core";
import { Navigate, Route, Routes } from "react-router-dom";

import { api } from "../const/api";
import { resources, controls } from "../const/resource";
import * as auth from "../auth";
import * as rest from "../rest";
import { RoleMenu } from "./role_menu";
import { Login } from "../pages/login";
import { Home } from "../pages/home";
import * as client_company from "../pages/client_company";
import * as client from "../pages/client";
import * as client_reader from "../pages/client_reader";
import * as staff from "../pages/staff";
import * as chain from "../pages/chain";
import * as store from "../pages/store";
import * as instruction from "../pages/instruction";
import * as report from "../pages/report";
import * as report_image from "../pages/report_image";
import * as calendar from "../pages/calendar";
import * as work_time from "../pages/work_time";
import * as notice_board from "../pages/notice_board";
import { Context } from "../context/DataStoreContext";

import { Loading } from "../components/loading";
import { definitions } from "../schema/api";

const labels: { [key: string]: string } = {
  login: "ログイン",
  home: "ホーム",
  notFound: "該当するデータがありません",
  anyError: "サーバエラーが発生しました",
  invalidParameter: "送信された値が不正です",
  loginFailed: "ログイン情報が正しくありません",
  duplicateEmail: "既に別のユーザーで登録されているメールアドレスです",
  operationIsLockedError:
    "この操作は並行して行う事はできません、しばらく経ってから再度試してください",
  multipleStaffsOnTheSameStoreError:
    "同じ店舗に複数のスタッフが割り当てられています",
  differentStoreCodesForSameStore:
    "同じ店舗に異なる店舗コードが割り当てられています",
  duplicateCombinationOfStaffAndStoreAndClient:
    "スタッフと店舗と管理者が同じ組み合わせの行が存在しています",
  invalidAssociation: "登録しようとした行の列の組み合わせが不正です",
  isolatedStaff: "管理者に紐つかないスタッフが発生します",
  duplicateStoreNameOfSameChain: "同じチェーンに同じ店舗名で登録されています",
  duplicateStorePhone: "同じ電話番号の店舗が登録されています",
  duplicateChainName: "同じ名前のチェーンが登録されています",
  duplicateClientName: "同じ名前の管理者が登録されています",
  quittedStaff: "退職済みのスタッフが送信されました",
  exceededMaxImageCount: "ダウンロード可能な最大サイズを超えています",
  parallelReportImageDownload:
    "ただいま別のユーザーがダウンロード中です、しばらく経ってから再度お試しください",
  associatedIndividualReportExists:
    "日報が提出されている指示書を削除することはできません",
  clientStoreUpdateTitle: "担当店舗更新",
  clientStoreBulkUpdateTitle: "担当店舗一括登録",
};

const routings: {
  [resource: string]: {
    [control: string]: any;
  };
} = {
  client_company: {
    create: client_company.Create,
    retrieve: client_company.Retrieve,
    update: client_company.Update,
  },
  client: {
    create: client.Create,
    retrieve: client.Retrieve,
    update: client.Update,
  },
  client_reader: {
    create: client_reader.Create,
    retrieve: client_reader.Retrieve,
    update: client_reader.Update,
  },
  staff: {
    create: staff.Create,
    retrieve: staff.Retrieve,
    update: staff.Update,
    bulk_insert: staff.BulkInsert,
    work_time_retrieve: staff.WorkTimeRetrieve,
  },
  chain: {
    create: chain.Create,
    retrieve: chain.Retrieve,
    update: chain.Update,
    bulk_insert: chain.BulkInsert,
  },
  store: {
    create: store.Create,
    retrieve: store.Retrieve,
    update: store.Update,
    bulk_insert: store.BulkInsert,
  },
  instruction: {
    copy: instruction.Copy,
    create: instruction.Create,
    retrieve: instruction.Retrieve,
    show: instruction.Show,
    update: instruction.Update,
  },
  report: {
    create: report.Create,
    retrieve: report.Retrieve,
    show: report.Show,
    update: report.Update,
  },
  report_image: {
    retrieve: report_image.Retrieve,
  },
  calendar: {
    retrieve: calendar.Retrieve,
    create: calendar.Create,
  },
  work_time: {
    retrieve: work_time.Retrieve,
  },
  notice_board: {
    create: notice_board.Create,
    retrieve: notice_board.Retrieve,
    show: notice_board.Show,
    update: notice_board.Update,
  },
};

type AuthRouterState = {
  userName?: string;
  roleName?: string;
  initialized: boolean;
  snackbarOpen: boolean;
  snackbarMessage: string;
};

function AuthRouter() {
  const [state, setState] = react.useState<AuthRouterState>({
    initialized: false,
    snackbarOpen: false,
    snackbarMessage: "",
  });
  rest.setResponseHandler(401, () => {
    console.log("401 unauthorized");
    auth.logout({ redirect: false });
    setState({ ...state });
  });
  rest.setResponseHandler(404, (response: definitions["Response404"]) => {
    console.log("404 not found");
    state.snackbarMessage = labels.notFound;
    state.snackbarOpen = true;
    setState({ ...state });
  });

  rest.setResponseHandler(500, async (response: any) => {
    console.log("500 internal server error");
    let obj = null;
    try {
      obj = await response.json();
    } catch (e) {
      return;
    }

    state.snackbarMessage = labels.anyError;
    if (obj.code) {
      switch (obj.code) {
        case api.errorCodes.invalidParameter: {
          state.snackbarMessage = labels.invalidParameter;
          break;
        }
        case api.errorCodes.userAuthenticationFailed: {
          state.snackbarMessage = labels.loginFailed;
          break;
        }
        case api.errorCodes.userEmailDuplication: {
          state.snackbarMessage = labels.duplicateEmail;
          break;
        }
        case api.errorCodes.operationIsLocked: {
          state.snackbarMessage = labels.operationIsLockedError;
          break;
        }
        case api.errorCodes.multipleStaffsOnTheSameStore: {
          state.snackbarMessage = `${labels.multipleStaffsOnTheSameStoreError}\n${obj.message}`;
          break;
        }
        case api.errorCodes.differentStoreCodesForSameStore: {
          state.snackbarMessage = `${labels.differentStoreCodesForSameStore}\n${obj.message}`;
          break;
        }

        case api.errorCodes.duplicateCombinationOfStaffAndStoreAndClient: {
          state.snackbarMessage = `${labels.duplicateCombinationOfStaffAndStoreAndClient}\n${obj.message}`;
          break;
        }
        case api.errorCodes.invalidAssociation: {
          state.snackbarMessage = `${labels.invalidAssociation}\n${obj.message}`;
          break;
        }
        case api.errorCodes.isolatedStaff: {
          state.snackbarMessage = labels.isolatedStaff;
          break;
        }
        case api.errorCodes.duplicateStoreNameOfSameChain: {
          state.snackbarMessage = labels.duplicateStoreNameOfSameChain;
          break;
        }
        case api.errorCodes.duplicateStorePhone: {
          state.snackbarMessage = labels.duplicateStorePhone;
          break;
        }
        case api.errorCodes.duplicateChainName: {
          state.snackbarMessage = labels.duplicateChainName;
          break;
        }
        case api.errorCodes.quittedStaff: {
          state.snackbarMessage = labels.quittedStaff;
          break;
        }
        case api.errorCodes.duplicateClientName: {
          state.snackbarMessage = labels.duplicateClientName;
          break;
        }
        case api.errorCodes.exceededMaxImageCount: {
          state.snackbarMessage = labels.exceededMaxImageCount;
          break;
        }
        case api.errorCodes.associatedIndividualReportExists: {
          state.snackbarMessage = labels.associatedIndividualReportExists;
          break;
        }
        case api.errorCodes.parallelReportImageDownload: {
          state.snackbarMessage = labels.parallelReportImageDownload;
          break;
        }
      }
    }

    state.snackbarOpen = true;
    setState({ ...state });
  });

  const { state: contextState } = useContext(Context);
  const userState = contextState?.user;
  const userProfile = userState?.profile;

  react.useEffect(() => {
    const role = auth.getRole();
    if (role) {
      state.userName = role.userName;
      state.roleName = role.roleName;
    }

    state.initialized = true;
    setState({ ...state });
  }, [userProfile]);

  if (!state.initialized) {
    return <Loading />;
  }

  const onSnackbarShow = (message: string) => {
    state.snackbarOpen = true;
    state.snackbarMessage = message;
    setState({ ...state });
  };
  const onSnackbarClose = () => {
    state.snackbarMessage = "";
    state.snackbarOpen = false;
    setState({ ...state });
  };

  const snackBar = (
    <ui.Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={state.snackbarOpen}
      onClose={onSnackbarClose}
      autoHideDuration={5000}
      message={state.snackbarMessage}
    />
  );
  if (!auth.hasToken()) {
    return (
      <ui.Box>
        <Routes>
          <Route
            key="root"
            path="/"
            element={
              <RoleMenu title={labels.login}>
                <Login />
                {snackBar}
              </RoleMenu>
            }
          />
          <Route
            key="wildcard"
            path="*"
            element={
              <react.Fragment>
                <Navigate to="/" replace />
                {snackBar}
              </react.Fragment>
            }
          />
        </Routes>
      </ui.Box>
    );
  }

  const elements: JSX.Element[] = [];
  const roleMenuProps = {
    authorized: true,
    userName: state.userName,
    roleName: state.roleName,
  };
  const pageProps = {
    showGlobalNotification: onSnackbarShow,
  };

  const routingResources = Object.keys(routings);
  for (let i = 0; i < routingResources.length; i += 1) {
    const resourceKey = routingResources[i];

    const allowedControls = Object.keys(routings[resourceKey]);
    for (let j = 0; j < allowedControls.length; j += 1) {
      const controlKey = allowedControls[j];

      const resource = resources[resourceKey];
      if (!resource) {
        console.log(`resource not found: ${resourceKey}`);
        break;
      }
      const control = controls[controlKey];
      if (!control) {
        console.log(`control not found: ${controlKey}`);
        break;
      }

      const component = routings[resourceKey][controlKey];

      let path = `/${resource.identifier}/${control.identifier}`;
      if ([
        controls.copy.identifier,
        controls.update.identifier,
        controls.show.identifier,
      ].includes(control.identifier)) {
        path = `${path}/:id`;
      } else if (
        resource.identifier === resources.work_time.identifier &&
        control.identifier === controls.retrieve.identifier
      ) {
        path = `${path}/:id`;
      }

      elements.push(
        <Route
          key={`${resourceKey}-${controlKey}`}
          path={path}
          element={
            <RoleMenu resource={resource} control={control} {...roleMenuProps}>
              {react.createElement(component, pageProps)}
              {snackBar}
            </RoleMenu>
          }
        />
      );
    }
  }

  // relations

  elements.push(
    <Route
      key={`${resources.client.identifier}-${resources.store.identifier}-${controls.update.identifier}`}
      path={`/${resources.client.identifier}/${resources.store.identifier}/${controls.update.identifier}/:id`}
      element={
        <RoleMenu
          title={`${resources.client.label} | ${labels.clientStoreUpdateTitle}`}
          resource={resources.client}
          control={controls.update}
          {...roleMenuProps}
        >
          <client.StoreUpdate {...pageProps} />
          {snackBar}
        </RoleMenu>
      }
    />
  );
  elements.push(
    <Route
      key={`${resources.client.identifier}-${resources.store.identifier}-${controls.bulk_insert.identifier}`}
      path={`/${resources.client.identifier}/${resources.store.identifier}/${controls.bulk_insert.identifier}`}
      element={
        <RoleMenu
          title={`${resources.client.label} | ${labels.clientStoreBulkUpdateTitle}`}
          resource={resources.client}
          control={controls.update}
          {...roleMenuProps}
        >
          <client.StoreBulkInsert {...pageProps} />
          {snackBar}
        </RoleMenu>
      }
    />
  );
  elements.push(
    <Route
      key={`${resources.staff.identifier}-${resources.store.identifier}-${controls.update.identifier}`}
      path={`/${resources.staff.identifier}/${resources.store.identifier}/${controls.update.identifier}/:id`}
      element={
        <RoleMenu
          title={`${resources.staff.label} | ${resources.store.label} | ${controls.update.label}`}
          resource={resources.staff}
          control={controls.update}
          {...roleMenuProps}
        >
          <staff.StoreUpdate {...pageProps} />
          {snackBar}
        </RoleMenu>
      }
    />
  );
  elements.push(
    <Route
      key={`${resources.staff.identifier}-${resources.store.identifier}-${controls.inherit.identifier}`}
      path={`/${resources.staff.identifier}/${resources.store.identifier}/${controls.inherit.identifier}/:id`}
      element={
        <RoleMenu
          title={`${resources.staff.label} | ${resources.store.label} | ${controls.inherit.label}`}
          resource={resources.staff}
          control={controls.inherit}
          {...roleMenuProps}
        >
          <staff.StoreInherit {...pageProps} />
          {snackBar}
        </RoleMenu>
      }
    />
  );

  elements.push(
    <Route
      key={`${resources.report.identifier}-${controls.retrieve.identifier}-ym`}
      path={`/${resources.report.identifier}/${controls.retrieve.identifier}/:year/:month`}
      element={
        <RoleMenu
          title={`${resources.report.label} | ${controls.retrieve.label}`}
          resource={resources.report}
          control={controls.retrieve}
          {...roleMenuProps}
        >
          <report.Retrieve {...pageProps} />
          {snackBar}
        </RoleMenu>
      }
    />
  );
  elements.push(
    <Route
      key={`${resources.report.identifier}-${controls.retrieve.identifier}-ym-staff_id`}
      path={`/${resources.report.identifier}/${controls.retrieve.identifier}/:year/:month/staff/:staffId`}
      element={
        <RoleMenu
          title={`${resources.report.label} | ${controls.retrieve.label}`}
          resource={resources.report}
          control={controls.retrieve}
          {...roleMenuProps}
        >
          <report.Retrieve {...pageProps} />
          {snackBar}
        </RoleMenu>
      }
    />
  );
  elements.push(
    <Route
      key={`${resources.staff.identifier}-${controls.work_time_retrieve.identifier}-ym`}
      path={`/${resources.staff.identifier}/${controls.work_time_retrieve.identifier}/:year/:month/:date`}
      element={
        <RoleMenu
          title={`${resources.staff.label} | ${controls.work_time_retrieve.label}`}
          resource={resources.staff}
          control={controls.work_time_retrieve}
          {...roleMenuProps}
        >
          {react.createElement(staff.WorkTimeRetrieve, pageProps)}
          {snackBar}
        </RoleMenu>
      }
    />
  );

  elements.push(
    <Route
      key="root"
      path="/"
      element={<Navigate to="/home" replace />}
    />
  );
  elements.push(
    <Route
      key="home"
      path="/home"
      element={
        <RoleMenu title={labels.home} {...roleMenuProps}>
          <Home />
          {snackBar}
        </RoleMenu>
      }
    />
  );

  return <Routes>{elements}</Routes>;
}

export { AuthRouter };
