import react, { useContext } from "react";
import * as ui from "@material-ui/core";
import { useParams, useNavigate } from "react-router-dom";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import * as auth from "../../auth";
import * as rest from "../../rest";
import { definitions } from "../../schema/api";
import { utf8bom } from "../../const/file";
import { downloadAsFile } from "../../window";
import { isZeroDate, toTextInputCalendarFormat } from "../../datetime";
import { RecordList } from "../../components/record_list";
import { Context } from "../../context/DataStoreContext";
import { resources, controls } from "../../const/resource";
import { Buffer } from "buffer";

import GetApp from "@material-ui/icons/GetApp";
import Edit from "@material-ui/icons/Edit";

const labels = {
  date: "日時",
  download: "ダウンロード",
  staff: "スタッフ",
  work: "勤怠",
  workBegin: "出勤時刻",
  workEnd: "退勤時刻",
  notFound: "打刻データがありませんでした",
  noRecord: "--:--",
  edit: "変更",
  cancel: "取消",
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    saturday: {
      color: "#6666ff",
    },
    sunday: {
      color: "#ff4444",
    },
    unregisterText: {
      color: "#aaaaaa",
    },
  })
);

const hourItems: JSX.Element[] = Array(24).fill(0).map(
  (h, i) =>
    <ui.MenuItem key={`month${h + i}`} value={h + i}>
      {(h + i).toString().padStart(2, '0')}
    </ui.MenuItem>
);

const minuteItems: JSX.Element[] = Array(60).fill(0).map(
  (m, i) =>
    <ui.MenuItem key={`month${m + i}`} value={m + i}>
      {(m + i).toString().padStart(2, '0')}
    </ui.MenuItem>
);

type WorkTimeRow = {
  workBegin: string;
  workEnd: string;
  staffId: number;
  staffName: string;
};

type RetrieveState = {
  initialized: boolean;
  workTimes: WorkTimeRow[];
  editStaffId: number,
  editKey: "begin" | "end";
  editHour?: number;
  editMinute?: number;
};

type RetrieveParams = {
  year: string;
  month: string;
  date: string;
};

const toDateFormat = (dateString?: string) => {
  if (dateString && !isZeroDate(dateString)) {
    const date = new Date(dateString);
    const hh = date.getHours().toString().padStart(2, '0');
    const mm = date.getMinutes().toString().padStart(2, '0');

    return `${hh}:${mm}`;
  }

  return labels.noRecord;
};

const toWorkTimeRow = (workTime: definitions["WorkTime"]): WorkTimeRow => ({
  staffId: workTime.staff_id,
  staffName: workTime.staff?.name || '',
  workBegin: toDateFormat(workTime.begin),
  workEnd: toDateFormat(workTime.end),
});

function WorkTimeRetrieve() {
  const params = useParams<RetrieveParams>();
  const today = new Date();
  const year = parseInt(params.year || '') || today.getFullYear();
  const month = parseInt(params.month || '') || (today.getMonth() + 1);
  const date = parseInt(params.date || '') || today.getDate();
  const classes = useStyles();
  const navigate = useNavigate();
  const { state: contextState, dispatch } = useContext(Context);
  const workTimeState = contextState?.workTime;
  const { listParameter } = workTimeState || {};
  const { list: workTimes = [] } = listParameter || {};

  const [state, setState] = react.useState<RetrieveState>({
    initialized: false,
    workTimes: [],
    editStaffId: 0,
    editKey: "begin",
  });

  const onDateValueChanged = (e: any) => {
    const changeDate = new Date(e.target.value);
    const changeYear = changeDate.getFullYear();
    const changeMonth = changeDate.getMonth() + 1;
    const changeDay = changeDate.getDate();
    navigate(`/${resources.staff.identifier}/${controls.work_time_retrieve.identifier}/${changeYear}/${changeMonth}/${changeDay}`);
  };

  const onEditSubmit = async () => {
    const hh = state.editHour?.toString().padStart(2, "0");
    const mm = state.editMinute?.toString().padStart(2, "0");
    const time = `${hh}:${mm}`;
    const json = await (new rest.WorkTime())
    .postYearMonthDate(year, month, date, state.editKey, { staff_id: state.editStaffId, time }, auth.getToken());
    const existsWorkTimeIndex = workTimes.findIndex((item) => item.staff_id === state.editStaffId);
    if (existsWorkTimeIndex >= 0) {
      const { staff } = workTimes[existsWorkTimeIndex];
      workTimes.splice(existsWorkTimeIndex, 1, { ...json, staff });
      dispatch && dispatch({ type: "SET_LIST", payload: { type: "workTime", list: workTimes } });
      state.workTimes = workTimes.map((workTime) => toWorkTimeRow(workTime));
    }
    state.editStaffId = 0;
    setState({ ...state });
  };

  const onEditCancel = () => {
    state.editStaffId = 0;
    setState({ ...state });
  };

  const onDownload = () => {
    const rows = [
      `${labels.staff},${labels.workBegin},${labels.workEnd}`,
      ...state.workTimes.map((workTime) => [
        workTime.staffName,
        workTime.workBegin === labels.noRecord ? "" : workTime.workBegin,
        workTime.workEnd === labels.noRecord ? "" : workTime.workEnd,
      ].join(",")),
    ];

    const utf8CsvBuffer = Buffer.concat([
      Buffer.from(utf8bom),
      Buffer.from(rows.join("\n"), "utf-8"),
    ]);

    const fileName = `${labels.work}_${year}${month.toString().padStart(2, '0')}${date.toString().padStart(2, '0')}.csv`;
    downloadAsFile(utf8CsvBuffer, fileName);
  };

  react.useEffect(() => {
    (async () => {
      const json: definitions["WorkTime"][] = await (new rest.WorkTime()).getYearMonthDateList(year, month, date, {}, auth.getToken());
      dispatch && dispatch({ type: "SET_WORK_TIME_YEAR_MONTH_DATE", payload: { year, month, date } });
      dispatch && dispatch({ type: "SET_LIST", payload: { type: "workTime", list: json } });
    })();
  }, [year, month, date]);

  react.useEffect(() => {
    const workTimeRows = workTimes?.map((workTime) => toWorkTimeRow(workTime));
    setState({ ...state,  workTimes: workTimeRows });
    console.log(workTimes);
  }, [workTimes]);

  const onEditClicked = (row: WorkTimeRow, key: RetrieveState["editKey"]) => {
    const time = key === "begin"
      ? row.workBegin
      : row.workEnd;
    const editHour = parseInt(time.replace(/:.*/, ""));
    const editMinute = parseInt(time.replace(/.*:/, ""));
    setState({
      ...state,
      editStaffId: row.staffId,
      editKey: key,
      editHour,
      editMinute,
    });
  };

  const workTimePart = (row: WorkTimeRow, key: RetrieveState["editKey"]) => {
    const time = key === "begin"
      ? row.workBegin
      : row.workEnd;
    const klass = time === labels.noRecord
      ? classes.unregisterText
      : undefined;
    const hasEditPermission = auth.isAuthorized(
      resources.staff.identifier,
      auth.resourceCrudFlag.update
    );

    return state.editStaffId === row.staffId && state.editKey === key ? (
      <ui.Grid container spacing={2}>
        <ui.Grid item>
          <ui.Select
            labelId="hour-id-label"
            id="hour-id"
            name="hour"
            value={state.editHour}
            onChange={(e: any) => setState({ ...state, editHour: parseInt(e.target.value, 10) })}
          >
            {hourItems}
          </ui.Select>
          ：
          <ui.Select
            labelId="minute-id-label"
            id="minute-id"
            name="minute"
            value={state.editMinute}
            onChange={(e: any) => setState({ ...state, editMinute: parseInt(e.target.value, 10) })}
          >
            {minuteItems}
          </ui.Select>
        </ui.Grid>
        <ui.Grid item>
          <ui.Button variant="contained" color="primary" type="button" onClick={onEditSubmit}>
            {labels.edit}
          </ui.Button>
        </ui.Grid>
        <ui.Grid item>
          <ui.Button variant="contained" color="secondary" type="button" onClick={onEditCancel}>
            {labels.cancel}
          </ui.Button>
        </ui.Grid>
      </ui.Grid>
    ) : (
      <>
        <span className={klass}>{time}</span>
        {hasEditPermission && (
          <ui.Button color="primary" onClick={() => onEditClicked(row, key)}><Edit />{labels.edit}</ui.Button>
        )}
      </>
    );
  };

  return (
    <ui.Container>
      <ui.Grid container spacing={4}>
        <ui.Grid item xs={12}></ui.Grid>
        <ui.Grid item xs={12} sm={2}>
          <ui.TextField
            id="date"
            fullWidth
            label={labels.date}
            type="date"
            name="date"
            defaultValue={toTextInputCalendarFormat(new Date(year, month - 1, date))}
            InputLabelProps={{ shrink: true }}
            onChange={onDateValueChanged}
          />
        </ui.Grid>
        <ui.Grid container item xs={7} sm={8} />
        <ui.Grid container item xs={12} sm={2} alignItems="center">
          <ui.IconButton onClick={onDownload}>
            <GetApp />
            <ui.Typography variant="body1">{labels.download}</ui.Typography>
          </ui.IconButton>
        </ui.Grid>
        <ui.Grid item xs={12}>
          <RecordList<WorkTimeRow>
            defaultText={labels.notFound}
            cols={[
              { label: labels.staff, key: "staffName" },
              { label: labels.workBegin, key: "workBegin" },
              { label: labels.workEnd, key: "workEnd" },
            ]}
            items={state.workTimes}
            extraKeyItemCallbacks={{
              workBegin: (row) => workTimePart(row, "begin"),
              workEnd: (row) => workTimePart(row, "end"),
            }}
          />
        </ui.Grid>
      </ui.Grid>
    </ui.Container>
  );
}

export { WorkTimeRetrieve };
