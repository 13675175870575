import { definitions } from "../../schema/api";

const createCalendar = (): definitions["Calendar"] => {
  return {
    date: 0,
    memo: "",
    id: 0,
    staff_id: 0,
    year: 0,
    month: 0,
    activity_id: 0,
  };
};

export { createCalendar };
