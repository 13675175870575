type ReportImageType = {
  identifier: string;
  label: string;
  value: number;
};

const types: { [type: string]: ReportImageType } = Object.freeze({
  before: {
    identifier: "before",
    label: "作業前",
    value: 1,
  },
  after: {
    identifier: "after",
    label: "作業後",
    value: 2,
  },
  competitive: {
    identifier: "competitive",
    label: "競合",
    value: 3,
  },
  other: {
    identifier: "other",
    label: "その他",
    value: 4,
  },
});

const typeValueToLabel = (typeValue: number): string => {
  const targetKey = Object.keys(types).find((key) => {
    const type = types[key];
    if (type.value === typeValue) {
      return key;
    }
    return null;
  });
  return targetKey ? types[targetKey].label : "";
};

const defaultType = types.before;

export { types, typeValueToLabel, defaultType };
