import react from "react";
import * as rest from "../../rest";
import * as auth from "../../auth";
import { definitions } from "../../schema/api";
import { resources, controls } from "../../const/resource";
import { storage } from "../../const/storage";
import * as cookie from "../../storage/cookie";
import { Form } from "./form";

const labels = {
  failure: "作成に失敗しました",
};

function removeDataUrlPrefix(dataUrl: string): string {
  return dataUrl.replace(/^data:image\/\w+;base64,/, "");
}

type CreateState = {
  submitting: boolean;
};

type CreateProps = {
  showGlobalNotification?: (message: string) => void;
};

function Create(props: CreateProps) {
  const [state, setState] = react.useState<CreateState>({
    submitting: false,
  });

  const onSubmit = (report: definitions["Report"]) => {
    setState({ submitting: true });

    const postReport = { ...report };
    postReport.report_images = [];
    if (postReport.is_meeting || postReport.is_training) {
      delete postReport.store_id;
      delete postReport.chain_id;
      delete postReport.store;
      delete postReport.chain;
      postReport.individual_reports = [];
    }

    const targetDate = new Date(report.date);
    return new rest.Report()
      .putYearMonthDate(
        targetDate.getFullYear(),
        targetDate.getMonth() + 1,
        targetDate.getDate(),
        [postReport],
        auth.getToken()
      )
      .then((json: rest.ReportsPutYearMonthDateResponse) => {
        return new rest.ReportImage().post(
          report.report_images.map((image) => {
            const record: definitions["ReportImage"] = {
              id: 0,
              report_id: json[0].id,
              path: "",
              content: removeDataUrlPrefix(image.content),
              type: image.type,
              name: image.name,
              comment: image.comment,
            };
            if (image.instruction_id && image.instruction_id > 0) {
              record.instruction_id = image.instruction_id;
            }
            return record;
          }),
          auth.getToken()
        );
      })
      .then(() => {
        cookie.save(
          storage.successNotification.key,
          "1",
          storage.successNotification.expires
        );
        const path = `/${resources.report.identifier}/${controls.retrieve.identifier}`;
        const query = `success=${controls.create.identifier}`;
        window.location.href = `${path}?${query}`;
      })
      .catch(() => {
        props.showGlobalNotification &&
          props.showGlobalNotification(labels.failure);
        setState({ submitting: false });
      });
  };

  return (
    <Form
      submitting={state.submitting}
      onSubmit={onSubmit}
      showGlobalNotification={props.showGlobalNotification}
    />
  );
}

export { Create };
