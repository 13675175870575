import react from "react";
import { useParams } from "react-router-dom";
import * as ui from "@material-ui/core";
import * as c from "../../const";
import * as auth from "../../auth";
import * as rest from "../../rest";
import { definitions } from "../../schema/api";
import { Loading } from "../../components/loading";
import { resources } from "../../const/resource";
import { Form } from "./form";

const labels: { [key: string]: string } = {
  title: "クライアントユーザー登録",
  email: "ログイン用Eメール (確認中)",
  password: "パスワード",
  updateLoginInfo: "ログイン情報を更新する",
  succeed: "登録しました",
  failed: "登録に失敗しました",
};

type UpdateState = {
  initialized: boolean;
  updateLoginInfo: boolean;
  user: definitions["User"];
  clientReader: definitions["ClientReader"];
  clientCompanies: definitions["ClientCompany"][];
  userCalendarAuthorize: boolean;
  userCalendarExtendedAuthorization?: definitions["definitionsUserExtendedAuthorization"];
};
type UpdateProps = {
  showGlobalNotification?: (message: string) => void;
};

type UpdateParams = {
  id: string;
};

function hasFlas(flags: number, flag: number): boolean {
  return (flags & flag) === flag;
}

function Update(props: UpdateProps) {
  const params = useParams<UpdateParams>();
  const clientReaderId = parseInt(params.id as string, 10);

  const [state, setState] = react.useState<UpdateState>({
    initialized: false,
    updateLoginInfo: false,
    user: {
      id: c.api.newRecordId,
      name: "",
      password: "",
      email: "",
    },
    clientReader: {
      id: 0,
      user_id: 0,
      client_company_id: 0,
      name: "",
      name_kana: "",
      phone: "",
    },
    clientCompanies: [],
    userCalendarAuthorize: false,
  });

  const onEmailChanged = (email: string) => {
    state.user.email = email;
    setState({ ...state });
  };
  const onPasswordChanged = (password: string) => {
    state.user.password = password;
    setState({ ...state });
  };
  const onUserFormEnabled = () => {
    state.updateLoginInfo = true;
    setState({ ...state });
  };
  const onUserCalendarAuthorizeChanged = (set: boolean) => {
    setState({ ...state, userCalendarAuthorize: set });
  };

  const onChange = (
    user: definitions["User"],
    clientReader: definitions["ClientReader"]
  ) => {
    state.user = user;
    state.clientReader = clientReader;
    setState({ ...state });
  };

  const onSubmit = async (
    user: definitions["User"],
    clientReader: definitions["ClientReader"]
  ) => {
    state.user = user;
    state.clientReader = clientReader;

    const postClientReader = { ...state.clientReader };
    const postUser = { ...state.user };
    delete postClientReader.client_company;
    delete postUser.admin;
    delete postUser.client;
    delete postUser.client_reader;
    delete postUser.role;
    delete postUser.staff;

    const authorizationPayload = {
      resource_name: resources.calendar.identifier,
      crud: auth.resourceCrudFlag.retrieve,
    };
    const payload: rest.ClientReaderPostParam = { user: postUser, client_reader: postClientReader };
    if (state.userCalendarAuthorize) {
      payload.set_user_extended_authorizations = [authorizationPayload];
    } else {
      payload.unset_user_extended_authorizations = [authorizationPayload];
    }

    try {
      await (new rest.ClientReader()).postSensitive(
        state.clientReader.id,
        state.updateLoginInfo,
        payload,
        auth.getToken(),
      );
      props.showGlobalNotification?.(labels.succeed);
    } catch (_) {
      props.showGlobalNotification?.(labels.failed);
    }
  };

  react.useEffect(() => {
    if (state.initialized) {
      return;
    }
    (async () => {
      state.initialized = true;

      const responses = await Promise.all([
        (new rest.ClientReader()).get(clientReaderId, [], auth.getToken()),
        (new rest.ClientCompany()).getAll(auth.getToken()),
      ]);
      state.clientReader = responses[0];
      state.clientCompanies = responses[1];

      const userId = state.clientReader.user_id;
      const scopes = ["UserExtendedAuthorizations"];
      state.user = await (new rest.User()).getSensitive(userId, true, scopes, auth.getToken());

      state.userCalendarExtendedAuthorization = (state.user.user_extended_authorizations || []).find((authorization) => (
        authorization.resource_name === resources.calendar.identifier &&
        hasFlas(authorization.crud, auth.resourceCrudFlag.retrieve)
      ));
      state.userCalendarAuthorize = !!state.userCalendarExtendedAuthorization;

      setState({ ...state });
    })();
  });

  if (!state.initialized) {
    return <Loading />;
  }

  return (
    <ui.Container>
      <ui.Grid
        container
        spacing={0}
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        <Form
          user={state.user}
          clientReader={state.clientReader}
          clientCompanies={state.clientCompanies}
          userCalendarAuthorize={state.userCalendarAuthorize}
          onUserFormEnabled={onUserFormEnabled}
          onEmailChanged={onEmailChanged}
          onPasswordChanged={onPasswordChanged}
          onUserCalendarAuthorizeChanged={onUserCalendarAuthorizeChanged}
          onSubmit={onSubmit}
          onChange={onChange}
        />
      </ui.Grid>
    </ui.Container>
  );
}

export { Update };
