import react from "react";
import * as ui from "@material-ui/core";
import * as auth from "../../auth";
import * as rest from "../../rest";
import { definitions } from "../../schema/api";
import { Loading } from "../../components/loading";
import { Form } from "./form";

const labels: { [key: string]: string } = {
  succeed: "登録しました",
  failed: "登録に失敗しました",
};

type CreateState = {
  clientCompany: definitions["ClientCompany"];
  chains: rest.ChainsGetResponse;
  initialized: boolean;
};
type CreateProps = {
  showGlobalNotification?: (message: string) => void;
};

function Create(props: CreateProps) {
  const [state, setState] = react.useState<CreateState>({
    clientCompany: {
      id: 0,
      name: "",
      name_kana: "",
      phone: "",
      fax: "",
      admin_name: "",
      post_code: "",
      address: "",
      chain_ids: [],
    },
    chains: [],
    initialized: false,
  });

  const onClientCompanyChanged = (
    name:
      | "name"
      | "name_kana"
      | "admin_name"
      | "address"
      | "phone"
      | "fax"
      | "url_1"
      | "url_2"
      | "remarks",
    value: string
  ) => {
    state.clientCompany[name] = value;
    setState({ ...state });
  };

  const onPostCodeChanged = (postCode: string) => {
    state.clientCompany.post_code = postCode;
    setState({ ...state });
  };

  const onChainChanged = (selectedValues: number[]) => {
    state.clientCompany.chain_ids = selectedValues;
    setState({ ...state });
  };

  const onSubmit = () => {
    const clientCompany = new rest.ClientCompany();
    clientCompany
      .put([state.clientCompany], auth.getToken())
      .then((ret: object) => {
        props.showGlobalNotification &&
          props.showGlobalNotification(labels.succeed);
      })
      .catch(() => {
        props.showGlobalNotification &&
          props.showGlobalNotification(labels.failed);
      });
  };

  react.useEffect(() => {
    if (!state.initialized) {
      state.initialized = true;
      new rest.Chain()
        .getAll(auth.getToken())
        .then((json: rest.ChainsGetResponse) => {
          state.chains = json;
          setState({ ...state });
        });
    }
  });

  if (!state.initialized) {
    return <Loading />;
  }

  return (
    <ui.Container>
      <ui.Grid
        container
        spacing={0}
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        <Form
          clientCompany={state.clientCompany}
          chains={state.chains}
          onNameChanged={(v) => onClientCompanyChanged("name", v)}
          onNameKanaChanged={(v) => onClientCompanyChanged("name_kana", v)}
          onAdminNameChanged={(v) => onClientCompanyChanged("admin_name", v)}
          onAddressChanged={(v) => onClientCompanyChanged("address", v)}
          onPhoneChanged={(v) => onClientCompanyChanged("phone", v)}
          onFaxChanged={(v) => onClientCompanyChanged("fax", v)}
          onUrl1Changed={(v) => onClientCompanyChanged("url_1", v)}
          onUrl2Changed={(v) => onClientCompanyChanged("url_2", v)}
          onRemarksChanged={(v) => onClientCompanyChanged("remarks", v)}
          onPostCodeChanged={onPostCodeChanged}
          onChainChanged={onChainChanged}
          onSubmit={onSubmit}
        />
      </ui.Grid>
    </ui.Container>
  );
}

export { Create };
