import { request, methods } from "./request";
import { paths } from "../schema/api";

type NoticeBoardGetResponse =
  paths["/notice_board/{id}"]["get"]["responses"][200]["schema"];
type NoticeBoardsGetYearMonthResponse =
  paths["/notice_boards/{year}/{month}"]["get"]["responses"][200]["schema"];
type NoticeBoardPutParam =
  paths["/notice_board/{year}/{month}/{date}"]["put"]["parameters"]["body"]["notice_board"];
type NoticeBoardPutResponse =
  paths["/notice_board/{year}/{month}/{date}"]["put"]["responses"][200]["schema"];
type NoticeBoardPostParam =
  paths["/notice_board/{id}"]["post"]["parameters"]["body"]["notice_board"];

class NoticeBoard {
  get(id: number, token: string = "") {
    return request({
      token,
      method: methods.Get,
      resource: `notice_board/${id}`,
    });
  }
  getYearMonth(
    year: number,
    month: number,
    token: string = ""
  ): Promise<NoticeBoardsGetYearMonthResponse> {
    return request({
      token,
      resource: `notice_boards/${year}/${month}`,
      method: methods.Get,
    });
  }

  post(id: number, noticeBoard: NoticeBoardPostParam, token: string = "") {
    return request({
      token,
      method: methods.Post,
      resource: `notice_board/${id}`,
      params: noticeBoard,
    });
  }

  put(
    noticeBoards: NoticeBoardPutParam,
    year: number,
    month: number,
    date: number,
    token: string = ""
  ) {
    return request({
      token,
      method: methods.Put,
      resource: `notice_board/${year}/${month}/${date}`,
      params: noticeBoards,
    });
  }

  delete(id: number, token: string = "") {
    return request({
      token,
      resource: `notice_board/${id}`,
      method: methods.Delete,
    });
  }
}

export { NoticeBoard };
export type {
  NoticeBoardGetResponse,
  NoticeBoardPutResponse,
  NoticeBoardsGetYearMonthResponse,
};
