import * as ui from "@material-ui/core";
import { Navigate } from "react-router-dom";
import * as auth from "../auth";
import { HomeMenu } from "../layout/role_menu/home_menu";

function Home() {
  const env = process.env.REACT_APP_ENVIRONMENT || "unknown";
  // most page triggers rest request that requires jwt validation
  // home has no request so manual token validation is needed.
  return !auth.hasToken() ? (
    <Navigate to="/" replace />
  ) : (
    <ui.Box className="Home">
      <ui.Container>
        {env === "production" ? "" : `environment: ${env}`}
      </ui.Container>
      <ui.Container>
        <HomeMenu />
      </ui.Container>
    </ui.Box>
  );
}

export { Home };
