import react from "react";
import { useParams } from "react-router-dom";
import * as ui from "@material-ui/core";
import * as c from "../../const";
import * as auth from "../../auth";
import * as rest from "../../rest";
import { definitions } from "../../schema/api";
import { Form } from "./form";
import { Loading } from "../../components/loading";

const labels: { [key: string]: string } = {
  succeed: "登録しました",
  failed: "登録に失敗しました",
};

type UpdateState = {
  initialized: boolean;
  chain: definitions["Chain"];
};
type UpdateProps = {
  showGlobalNotification?: (message: string) => void;
};

type UpdateParams = {
  id: string;
};

function Update(props: UpdateProps) {
  const params = useParams<UpdateParams>();
  const chainId = parseInt(params.id as string, 10);

  const [state, setState] = react.useState<UpdateState>({
    initialized: false,
    chain: {
      id: c.api.newRecordId,
      name: "",
      name_kana: "",
    },
  });

  react.useEffect(() => {
    if (!state.initialized) {
      new rest.Chain()
        .get(chainId, auth.getToken())
        .then((json: rest.ChainGetResponse) => {
          state.initialized = true;
          state.chain = json;
          setState({ ...state });
        });
    }
  });

  const onNameChanged = (value: string) => {
    state.chain.name = value;
    setState({ ...state });
  };
  const onNameKanaChanged = (value: string) => {
    state.chain.name_kana = value;
    setState({ ...state });
  };

  const onSubmit = () => {
    new rest.Chain()
      .post(state.chain.id, state.chain, auth.getToken())
      .then((ret: object) => {
        props.showGlobalNotification &&
          props.showGlobalNotification(labels.succeed);
      })
      .catch(() => {
        props.showGlobalNotification &&
          props.showGlobalNotification(labels.failed);
      });
  };

  if (!state.initialized) {
    return <Loading />;
  }

  return (
    <ui.Container>
      <Form
        chain={state.chain}
        onNameChanged={onNameChanged}
        onNameKanaChanged={onNameKanaChanged}
        onSubmit={onSubmit}
      />
    </ui.Container>
  );
}

export { Update };
