import * as ui from "@material-ui/core";
import { ButtonProp } from "../validation/form";

import CircularProgress from "@material-ui/core/CircularProgress";

type IndicatorButtonProp = {
  showIndicator?: boolean;
  indicator: {
    label: string;
  };
  button: ButtonProp & {
    onClick: (e: React.SyntheticEvent) => void;
    label: string;
  };
};

function IndicatorButton(props: IndicatorButtonProp) {
  return (
    <ui.Grid item>
      {props.showIndicator ? (
        <ui.Grid
          container
          item
          spacing={2}
          justifyContent="flex-start"
          alignItems="center"
        >
          <ui.Grid item>
            <CircularProgress />
          </ui.Grid>
          <ui.Grid item>{props.indicator.label}</ui.Grid>
        </ui.Grid>
      ) : (
        <ui.Grid item>
          <ui.Button
            variant="contained"
            color="primary"
            onClick={props.button.onClick}
            disabled={!!props.button.disabled}
          >
            {props.button.label}
          </ui.Button>
        </ui.Grid>
      )}
    </ui.Grid>
  );
}

export { IndicatorButton };
