import react from "react";
import { useParams } from "react-router-dom";
import * as auth from "../../auth";
import * as rest from "../../rest";
import { createReport } from "../../const/definitions/report";
import { resources, controls } from "../../const/resource";
import { storage } from "../../const/storage";
import { definitions } from "../../schema/api";
import { Loading } from "../../components/loading";
import * as cookie from "../../storage/cookie";
import { Form } from "./form";

const labels = {
  failure: "作成に失敗しました",
};

type UpdateState = {
  initialized: boolean;
  report: definitions["Report"];
  baseReportImageIds: number[];
  submitting: boolean;
};
type UpdateProps = {
  showGlobalNotification?: (message: string) => void;
};

type UpdateParams = {
  id: string;
};

function removeDataUrlPrefix(dataUrl: string): string {
  return dataUrl.replace(/^data:image\/\w+;base64,/, "");
}

function Update(props: UpdateProps) {
  const params = useParams<UpdateParams>();
  const reportId = parseInt(params.id as string, 10);

  const [state, setState] = react.useState<UpdateState>({
    initialized: false,
    report: createReport(),
    baseReportImageIds: [],
    submitting: false,
  });

  const onSubmit = (report: definitions["Report"]) => {
    state.submitting = true;
    setState({ ...state });

    state.report = report;
    const postReport = { ...state.report };
    postReport.report_images = [];
    if (postReport.is_meeting || postReport.is_training) {
      delete postReport.store_id;
      delete postReport.chain_id;
      delete postReport.store;
      delete postReport.chain;
      postReport.individual_reports = [];
    }

    return new rest.Report()
      .post(report.id, postReport, auth.getToken())
      .then((json: rest.ReportPostIdResponse) => {
        if (
          state.report.report_images.length === 0 &&
          state.baseReportImageIds.length > 0
        ) {
          return new rest.ReportImage()
            .deleteByIds(state.baseReportImageIds, auth.getToken())
            .then(() => Promise.resolve());
        }
        {
          const postReportImages = state.report.report_images.map((image) => {
            const record: definitions["ReportImage"] = {
              id: image.id,
              report_id: state.report.id,
              content: image.content ? removeDataUrlPrefix(image.content) : "",
              path: image.path || "",
              type: image.type,
              name: image.name,
              comment: image.comment,
            };

            if (image.instruction_id && image.instruction_id > 0) {
              record.instruction_id = image.instruction_id;
            }

            return record;
          });

          return new rest.ReportImage()
            .post(postReportImages, auth.getToken())
            .then(() => Promise.resolve());
        }
      })
      .then(() => {
        cookie.save(
          storage.successNotification.key,
          "1",
          storage.successNotification.expires
        );
        const path = `/${resources.report.identifier}/${controls.retrieve.identifier}`;
        const query = `success=${controls.update.identifier}`;
        window.location.href = `${path}?${query}`;
      })
      .catch(() => {
        props.showGlobalNotification &&
          props.showGlobalNotification(labels.failure);

        state.submitting = true;
        setState({ ...state });
      });
  };

  react.useEffect(() => {
    if (state.initialized) {
      return;
    }
    state.initialized = true;

    new rest.Report()
      .get(
        reportId,
        [
          "ReportImages",
          "IndividualReports",
          "IndividualReports.IndividualReportListItems",
        ],
        auth.getToken()
      )
      .then((json: rest.ReportGetResponse | definitions["Response404"]) => {
        if (!(json as definitions["Response404"]).message) {
          state.report = json as rest.ReportGetResponse;
          state.baseReportImageIds = state.report.report_images.map(
            (image) => image.id
          );
        }
      })
      .then(() => setState({ ...state }));
  });

  if (!state.initialized) {
    return <Loading />;
  }

  return (
    <Form
      submitting={state.submitting}
      report={state.report}
      onSubmit={onSubmit}
      showGlobalNotification={props.showGlobalNotification}
    />
  );
}

export { Update };
