import react from "react";
import { useNavigate } from "react-router-dom";
import * as ui from "@material-ui/core";
import * as rest from "../../rest";
import * as auth from "../../auth";
import { definitions } from "../../schema/api";
import { api } from "../../const/api";
import { resources, controls } from "../../const/resource";
import * as datetime from "../../datetime";
import { validators } from "../../validation/validator";
import { createFormValidator, FormValidator } from "../../validation/form";
import { GridInput, GridInputRow } from "../../components/grid_input";
import { ClientCompanySelector } from "../../components/client_company_selector";
import { MultipleChainSelector } from "../../components/multiple_chain_selector";
import { ClientSelector } from "../../components/client_selector";
import { NavigatorBack } from "../../components/navigator_back";
import { Loading } from "../../components/loading";
import { roles } from "../../const/role";

type FormType = "create" | "copy" | "update";

const labels = {
  list: "一覧",
  title: {
    create: "指示書作成",
    copy: "指示書複製",
    update: "指示書更新",
  },
  year: "指示書年",
  month: "指示書月",
  span: "実施期間",
  from_date: "開始日",
  to_date: "終了日",
  instructionTitle: "タイトル",
  minute: "分",
  type: "指示種別",
  typeAchievement: "達成型(表組)",
  typeContenuouse: "継続型",
  detail: "指示詳細",
  pdfButton: "pdf を添付...",
  submitCreate: "保存",
  submitUpdate: "更新",
  copy: "複製",
  delete: "削除",
  reset: "リセット",
  deleteConfirm: "この指示書を削除しますか？",
  success: {
    create: "指示書を作成しました",
    copy: "指示書を作成しました",
    update: "指示書を更新しました",
  },
  failure: {
    create: "作成に失敗しました",
    copy: "作成に失敗しました",
    update: "更新に失敗しました",
  },
  instructionListItemValidationError: "1つ以上有効な項目を設定してください",
};

function createMonth(): JSX.Element[] {
  const doms = [];
  for (let month = 1; month <= 12; month += 1) {
    doms.push(
      <ui.MenuItem key={`month${month}`} value={month}>
        {month}
      </ui.MenuItem>
    );
  }
  return doms;
}

function createYear(today: Date, years: number): JSX.Element[] {
  const doms = [];

  for (
    let year = today.getFullYear();
    year < today.getFullYear() + years;
    year += 1
  ) {
    doms.push(
      <ui.MenuItem key={`year${year}`} value={year}>
        {year}
      </ui.MenuItem>
    );
  }
  return doms;
}

type FormState = {
  validator: FormValidator;
  instruction: definitions["Instruction"];
  clients: definitions["Client"][];
  chains: definitions["Chain"][];
  chainsById: { [id: number]: definitions["Chain"] };
  clientCompanies: definitions["ClientCompany"][];
  clientCompanyId: number;
  pdf?: File;
  initialized: boolean;
};

type FormProps = {
  userClient?: definitions["Client"];
  instructionId?: number;
  type: FormType;
  showGlobalNotification?: (message: string) => void;
};

function initializeClientUserForm(
  client: definitions["Client"],
  state: FormState
): Promise<void> {
  const baseClient = client;
  state.clients = [baseClient];
  state.clientCompanyId = baseClient.client_company_id;
  state.instruction.client_id = baseClient.id;

  return new rest.ClientCompany()
    .get(baseClient.client_company_id, auth.getToken())
    .then((json: rest.ClientCompanyGetResponse) => {
      state.clientCompanies = [json];
      state.clientCompanyId = json.id;
    })
    .then(() => {
      if (baseClient.chains) {
        state.chains = baseClient.chains;
      } else {
        return refreshCahinsByClientId(state, baseClient.id);
      }
    });
}

function initializeAdminUserUpdateForm(state: FormState): Promise<void> {
  return new rest.Client()
    .getAll(auth.getToken())
    .then((json: rest.ClientsGetResponse) => {
      state.clients = json;
    })
    .then(() => {
      const baseClient = state.clients.find(
        (client) => client.id === state.instruction.client_id
      );
      const baseClientId = baseClient
        ? baseClient.id
        : state.clients.length > 0
        ? state.clients[0].id
        : 0;
      if (baseClientId !== 0) {
        return refreshCahinsByClientId(state, baseClientId).then(
          () => baseClient
        );
      }
      return Promise.resolve(baseClient);
    })
    .then((baseClient?: definitions["Client"]) =>
      new rest.ClientCompany()
        .getAll(auth.getToken())
        .then((json: rest.ClientCompaniesGetResponse) => {
          state.clientCompanies = json;
          if (!baseClient) {
            return;
          }
          const baseClientCompany = state.clientCompanies.find(
            (clientCompany) => clientCompany.id === baseClient.client_company_id
          );
          if (baseClientCompany) {
            state.clientCompanyId = baseClientCompany.id;
          }
        })
    );
}

function refreshCahinsByClientId(state: FormState, clientId: number) {
  return new rest.Chain()
    .getByClientId(clientId, auth.getToken())
    .then((json: rest.ChainsGetResponse) => {
      state.chains = json;
      for (let i = 0; i < state.chains.length; i += 1) {
        state.chainsById[state.chains[i].id] = state.chains[i];
      }
    });
}

function Form(props: FormProps) {
  const navigate = useNavigate();
  const validator = createFormValidator("instruction");
  validator.add("chainIds", [validators.requiredArray]);

  const today = new Date();
  const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);
  const nextMonthLastDay = new Date(
    nextMonth.getFullYear(),
    nextMonth.getMonth() + 1,
    0
  );
  const nextMonthStr = datetime.toTextInputCalendarFormat(nextMonth);
  const nextMonthLastDayStr =
    datetime.toTextInputCalendarFormat(nextMonthLastDay);

  const [state, setState] = react.useState<FormState>({
    validator,
    instruction: {
      id: (props.type === "update" && props.instructionId) || 0,
      client_id: 0,
      type: 2,
      year: nextMonth.getFullYear(),
      month: nextMonth.getMonth() + 1,
      from_date: datetime.toRFC3339(new Date(nextMonthStr)),
      to_date: datetime.toRFC3339(new Date(nextMonthLastDayStr)),
      title: "",
      detail: "",
      instruction_list_items: [],
    },
    clients: [],
    clientCompanies: [],
    clientCompanyId: 0,
    chains: [],
    chainsById: {},
    initialized: false,
  });

  const onInstructionDateValueChanged = (
    name: "from_date" | "to_date",
    event: any
  ) => {
    state.instruction[name] = datetime.toRFC3339(new Date(event.target.value));
    setState({ ...state });
  };

  const onInstructionStringValueChanged = (
    name: "title" | "detail",
    event: any
  ) => {
    state.instruction[name] = event.target.value;
    setState({ ...state });
  };

  const onInstructionNumberValueChanged = (
    name: "year" | "month" | "client_id" | "type",
    event: any
  ) => {
    state.instruction[name] = parseInt(event.target.value, 10);
    setState({ ...state });
  };

  const onChainIdsChanged = (ids: number[]) => {
    state.validator.validate(ids, "chainIds");
    state.instruction.chains = [];
    for (let i = 0; i < ids.length; i += 1) {
      state.instruction.chains.push(state.chainsById[ids[i]]);
    }
    setState({ ...state });
  };

  const onGridInputRowsChanged = (rows: GridInputRow[]) => {
    state.instruction.instruction_list_items = rows;
    setState({ ...state });
  };

  const onClientIdChanged = (event: any) => {
    state.instruction.client_id = parseInt(event.target.value, 10);
    if (state.instruction.client_id) {
      return new rest.Client()
        .get(state.instruction.client_id, ["Chains"], auth.getToken())
        .then((json: rest.ClientGetResponse) => {
          state.chains = json.chains!;
          for (let i = 0; i < state.chains.length; i += 1) {
            state.chainsById[state.chains[i].id] = state.chains[i];
          }
          state.instruction.chains = [];
        })
        .then(() => {
          setState({ ...state });
        });
    }
    setState({ ...state });
  };

  const onClientCompanyIdChanged = (id: number) => {
    if (state.clientCompanyId === id) {
      return;
    }
    state.clientCompanyId = id;
    refreshClients().then(() => setState({ ...state }));
  };

  const refreshClients = () => {
    return new rest.Client()
      .getAllByClientCompanyId(
        state.clientCompanyId,
        ["Chains"],
        auth.getToken()
      )
      .then((json: rest.ClientsGetResponse) => {
        state.clients = json;
        if (state.clients && state.clients.length > 0) {
          state.instruction.client_id = state.clients[0].id;
          state.chains = state.clients[0].chains!;
          for (let i = 0; i < state.chains.length; i += 1) {
            state.chainsById[state.chains[i].id] = state.chains[i];
          }
          state.instruction.chains = [];
        }
      });
  };

  const onPdfChanged = (event: any) => {
    event.preventDefault();
    const file = event.target.files[0];
    if (file) {
      setState({ ...state, pdf: file });
    }
  };

  const onReset = (event: React.SyntheticEvent) => {
    window.location.href = "";
  };

  const onSubmit = async () => {
    if (state.instruction.type === api.instructionType.table) {
      state.instruction.instruction_list_items = (state.instruction.instruction_list_items || []).filter((row) => !!row.key);
      if (!state.instruction.instruction_list_items.length) {
        props.showGlobalNotification?.(labels.instructionListItemValidationError);

        return;
      }
    } else {
      state.instruction.instruction_list_items = [];
    }

    try {
      if (props.type === "update") {
        await (new rest.Instruction())
          .post(state.instruction.id, state.instruction, auth.getToken());
      } else {
        const [json]: rest.InstructionsPutResponse = await (new rest.Instruction())
          .put([state.instruction], auth.getToken());
        state.instruction.id = json.id;
      }
      if (state.pdf) {
        await (new rest.Pdf()).post("instruction", state.instruction.id, state.pdf, auth.getToken());
      }
      props.showGlobalNotification?.(labels.success[props.type]);
    } catch (_) {
      props.showGlobalNotification?.(labels.failure[props.type]);
    }
  };

  const onDeleteClicked = (event: React.SyntheticEvent) => {
    if (window.confirm(labels.deleteConfirm)) {
      new rest.Instruction()
        .delete(state.instruction.id, auth.getToken())
        .then((a) => {
          navigate(`/${resources.instruction.identifier}/${controls.retrieve.identifier}`);
        })
        .catch(console.log);
    }
  };

  react.useEffect(() => {
    if (state.initialized) {
      return;
    }

    state.initialized = true;

    // this initializer must initialize following state's properties
    //
    // - state.clientCompanies: to show selectable client companies or fixed client company name
    // - state.clientCompanyId: to set selected client company id
    // - state.clients:         to show selectable clients or fixed client name
    // - state.chains:          to show selectable chains
    // - state.initialized:     to prevent infinite loop

    const isClient = props.userClient && props.userClient.id > 0;

    (async () => {
      if (props.instructionId) {
        const json: rest.InstructionGetResponse = await (new rest.Instruction()).get(
          props.instructionId,
          ["InstructionListItems", "Chains"],
          auth.getToken()
        );
        if (props.type === "copy") {
          const { year, month, from_date, to_date } = state.instruction;
          json.id = 0;
          json.year = year;
          json.month = month;
          json.from_date = from_date;
          json.to_date = to_date;
          json.instruction_list_items?.forEach((item) => {
            item.id = 0;
          });
        }
        state.instruction = json;
        isClient
          ? validator.validateAll(state.instruction)
          : state.validator.validateAll(state.instruction);
      }
      isClient
        ? await initializeClientUserForm(props.userClient!, state)
        : await initializeAdminUserUpdateForm(state);

      setState({ ...state });
    })();
  }, []);

  if (!state.initialized) {
    return <Loading />;
  }

  const deletable =
    state.instruction.id > 0 &&
    (auth.getRole()?.roleName === roles.client.identifier ||
      auth.getRole()?.roleName === roles.root.identifier);

  return (
    <ui.Grid
      container
      spacing={4}
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
    >
      <ui.Grid container item xs={12} spacing={0}>
        <NavigatorBack
          xs={12}
          sm={12}
          label={labels.list}
          href={`/${resources.instruction.identifier}/${controls.retrieve.identifier}`}
        />

        <ui.Grid item xs={12}>
          <ui.Typography variant="h6">{labels.title[props.type]}</ui.Typography>
        </ui.Grid>
      </ui.Grid>

      <ui.Grid item xs={12} sm={6}>
        {props.userClient && props.userClient.id > 0 ? (
          <ui.Typography variant="h6">
            {state.clientCompanies[0]?.name || ""}
          </ui.Typography>
        ) : (
          <ClientCompanySelector
            clientCompanies={state.clientCompanies || []}
            selectedValue={state.clientCompanyId}
            onChanged={onClientCompanyIdChanged}
          />
        )}
      </ui.Grid>

      <ui.Grid item xs={12} sm={6}>
        {props.userClient && props.userClient.id > 0 ? (
          <ui.Typography variant="h6">{props.userClient.name}</ui.Typography>
        ) : (
          <ClientSelector
            clients={state.clients || []}
            selectedValue={state.instruction.client_id}
            onChange={onClientIdChanged}
          />
        )}
      </ui.Grid>

      <ui.Grid item xs={12} sm={6}>
        <ui.Grid container spacing={4}>
          <ui.Grid item xs={6} sm={2}>
            <ui.InputLabel id="year-id-label">{labels.year}</ui.InputLabel>
            <ui.Select
              labelId="year-id-label"
              id="year-id"
              name="year"
              value={state.instruction.year}
              onChange={(e) => onInstructionNumberValueChanged("year", e)}
            >
              {createYear(today, 2)}
            </ui.Select>
          </ui.Grid>
          <ui.Grid item xs={6} sm={2}>
            <ui.InputLabel id="month-id-label">{labels.month}</ui.InputLabel>
            <ui.Select
              labelId="month-id-label"
              id="month-id"
              name="month"
              value={state.instruction.month}
              onChange={(e) => onInstructionNumberValueChanged("month", e)}
            >
              {createMonth()}
            </ui.Select>
          </ui.Grid>
          <ui.Grid item xs={6} sm={4}>
            <ui.TextField
              id="fromdate"
              fullWidth
              label={labels.from_date}
              type="date"
              name="from_date"
              value={datetime.toTextInputCalendarFormat(
                new Date(state.instruction.from_date)
              )}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => onInstructionDateValueChanged("from_date", e)}
            />
          </ui.Grid>
          <ui.Grid item xs={6} sm={4}>
            <ui.TextField
              id="todate"
              fullWidth
              label={labels.to_date}
              type="date"
              name="to_date"
              value={datetime.toTextInputCalendarFormat(
                new Date(state.instruction.to_date)
              )}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => onInstructionDateValueChanged("to_date", e)}
            />
          </ui.Grid>
        </ui.Grid>
      </ui.Grid>

      <ui.Grid item xs={12} sm={6}>
        <ui.InputLabel id="type-id-label">{labels.type}</ui.InputLabel>
        <ui.RadioGroup
          row
          aria-label="type"
          name="type"
          value={parseInt(state.instruction.type as any, 10)}
          onChange={(e) => onInstructionNumberValueChanged("type", e)}
        >
          <ui.FormControlLabel
            value={2}
            control={<ui.Radio />}
            label={labels.typeContenuouse}
          />
          <ui.FormControlLabel
            value={1}
            control={<ui.Radio />}
            label={labels.typeAchievement}
          />
        </ui.RadioGroup>
      </ui.Grid>

      <ui.Grid container item xs={12} sm={6} direction="row">
        <ui.Grid item xs={12}>
          <ui.TextField
            {...state.validator.formProps.title}
            fullWidth
            id="instructiontitle-id"
            label={labels.instructionTitle}
            defaultValue={state.instruction.title}
            name="title"
            onBlur={(e) => {
              state.validator.validate(e.target.value, "title");
              onInstructionStringValueChanged("title", e);
            }}
          />
        </ui.Grid>
        <ui.Grid item xs={12}>
          <ui.TextField
            fullWidth
            id="instructiondetail-id"
            label={labels.detail}
            defaultValue={state.instruction.detail}
            name="detail"
            multiline
            rows={6}
            onBlur={(e) => onInstructionStringValueChanged("detail", e)}
          />
        </ui.Grid>
      </ui.Grid>

      <ui.Grid container item xs={12} sm={6} direction="row">
        <ui.Grid item xs={12}>
          <MultipleChainSelector
            {...state.validator.formProps.chainIds}
            showAllSelector
            chains={state.chains || []}
            selectedValues={
              state.instruction?.chains?.map((chain) => chain.id) || []
            }
            onChange={onChainIdsChanged}
          />
        </ui.Grid>
        <ui.Grid item xs={12}>
          {state.instruction.type === 1 ? (
            <GridInput
              rows={state.instruction.instruction_list_items || []}
              onRowsChanged={onGridInputRowsChanged}
            />
          ) : (
            <div />
          )}
        </ui.Grid>
      </ui.Grid>

      <ui.Grid
        container
        item
        xs={12}
        spacing={2}
        alignItems="center"
        justifyContent="flex-start"
      >
        <ui.Grid item>
          <ui.Button variant="contained" component="label">
            {labels.pdfButton}
            <form encType="multipart/form-data" action="">
              <input
                accept="application/pdf"
                type="file"
                onChange={onPdfChanged}
                hidden
              />
            </form>
          </ui.Button>
        </ui.Grid>
        <ui.Grid item>
          {state.pdf?.name ? (
            state.pdf.name
          ) : state.instruction.file_path ? (
            <ui.Link
              href={`/${state.instruction.file_path}`}
              target="_blank"
              rel="noopener"
            >
              {state.instruction.file_name}
            </ui.Link>
          ) : (
            ""
          )}
        </ui.Grid>
      </ui.Grid>

      <ui.Grid container item xs={12} spacing={2} justifyContent="flex-start">
        <ui.Grid item>
          <ui.Button
            {...state.validator.submitButtonProps}
            variant="contained"
            color="primary"
            onClick={onSubmit}
          >
            {props.type === "update" ? labels.submitUpdate : labels.submitCreate}
          </ui.Button>
        </ui.Grid>
        {props.type === "update" && (
          <ui.Grid item>
            <ui.Button
              variant="contained"
              type="submit"
              href={`/${resources.instruction.identifier}/${controls.copy.identifier}/${props.instructionId}`}
            >
              {labels.copy}
            </ui.Button>
          </ui.Grid>
        )}
        {deletable && (
          <ui.Grid item>
            <ui.Button
              variant="contained"
              color="secondary"
              type="submit"
              onClick={onDeleteClicked}
            >
              {labels.delete}
            </ui.Button>
          </ui.Grid>
        )}
        {!props.instructionId && (
          <ui.Grid item>
            <ui.Button
              variant="contained"
              color="secondary"
              onClick={onReset}
            >
              {labels.reset}
            </ui.Button>
          </ui.Grid>
        )}
      </ui.Grid>
    </ui.Grid>
  );
}

export { Form };
