import { request, methods } from "./request";
import { paths } from "../schema/api";

type CalendarActivitiesGetResponse =
  paths["/calendar_activities"]["get"]["responses"][200]["schema"];

class CalendarActivity {
  getAll(token: string = ""): Promise<CalendarActivitiesGetResponse> {
    return request({
      token,
      method: methods.Get,
      resource: "calendar_activities",
    });
  }
}

export { CalendarActivity };
export type { CalendarActivitiesGetResponse };
