import react from "react";
import * as ui from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";

import ArrowDropUp from "@material-ui/icons/ArrowDropUp";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";

const labels: { [key: string]: string } = {
  notFound: "データが見つかりませんでした",
  displayCount: "表示件数",
  all: "全",
  dataUnit: "件",
};

const defaultItemsPerPage = 30;
const defaultPerPageCandidates = [30, 50, 100];

type Sort = 1 | 2;

const sort: { [key: string]: Sort } = {
  asc: 1,
  desc: 2,
};

type Column = {
  label: string;
  key: string;
};
type RecordListProps<T> = {
  cols: Column[];
  items: T[];
  defaultText?: string;
  defaultSort?: { order: Sort; col: string };
  onRowClicked?: (item: T) => void;
  customStyle?: (item: T) => { [key: string]: string } | undefined;
  onPageChanged?: (page: number) => void;
  extraKeyItemCallbacks?: { [key: string]: (item: T) => JSX.Element };
  perPageCandidates?: number[];
  itemsPerPage?: number;
  sort?: boolean;
};

type RecordListState = {
  page: number;
  itemsPerPage: number;
  sorts: { [key: string]: number };
};

function RecordList<T>(props: RecordListProps<T>) {
  const sorts: { [key: string]: Sort } = {};
  for (let i = 0; i < props.cols.length; i += 1) {
    sorts[props.cols[i].key] = sort.asc;
  }
  if (props.defaultSort) {
    sorts[props.defaultSort.col] = props.defaultSort.order;
  }

  const perPageCandidates = props.perPageCandidates
    ? [...props.perPageCandidates]
    : [...defaultPerPageCandidates];

  if (
    props.itemsPerPage &&
    perPageCandidates.indexOf(props.itemsPerPage) === -1
  ) {
    for (let i = 0; i < perPageCandidates.length; i += 1) {
      if (perPageCandidates[i] > props.itemsPerPage) {
        perPageCandidates.splice(i, 0, props.itemsPerPage);
        break;
      }
    }
  }

  const [state, setState] = react.useState<RecordListState>({
    sorts,
    itemsPerPage: props.itemsPerPage || defaultItemsPerPage,
    page: 1,
  });

  const toggleSort = (key: string) => {
    const keys = Object.keys(state.sorts);
    for (let i = 0; i < keys.length; i += 1) {
      state.sorts[keys[i]] =
        key === keys[i]
          ? state.sorts[keys[i]] === sort.asc
            ? sort.desc
            : sort.asc
          : sort.asc;
    }

    reorder(key);

    setState({ ...state });
  };
  const reorder = (key: string) => {
    state.sorts[key] === sort.asc
      ? props.items.sort((l: any, r: any) => l[key] < r[key] ? -1 : 1)
      : props.items.sort((l: any, r: any) => l[key] > r[key] ? -1 : 1);
  };

  const onPaginated = (e: React.ChangeEvent<unknown>, page: number) => {
    state.page = page;
    props.onPageChanged && props.onPageChanged(state.page);
    setState({ ...state });
  };

  let itemKey = 0;

  react.useEffect(() => {
    if (props.defaultSort) {
      reorder(props.defaultSort.col);
      setState({ ...state });
    }
  }, []);

  return (
    <>
      <ui.Grid
        container
        item
        xs={12}
        justifyContent="flex-end"
        alignItems="center"
        style={{ paddingBottom: "8px" }}
      >
        <ui.Grid
          container
          item
          spacing={2}
          justifyContent="flex-end"
          alignItems="center"
        >
          <ui.Grid item>
            <ui.Typography variant="caption">
              {labels.displayCount}
            </ui.Typography>
          </ui.Grid>
          {perPageCandidates.map((count) => (
            <ui.Grid item key={`perpage-${count}`}>
              {count === state.itemsPerPage ? (
                <b>{count}</b>
              ) : (
                <ui.Link
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    state.itemsPerPage = count;
                    setState({ ...state });
                  }}
                >
                  {count}
                </ui.Link>
              )}
            </ui.Grid>
          ))}
          <ui.Grid item>
            <ui.Typography variant="caption">|</ui.Typography>
          </ui.Grid>
          <ui.Grid item>
            <Pagination
              count={
                Math.ceil(props.items.length / state.itemsPerPage) as number
              }
              page={state.page}
              onChange={onPaginated}
            />
          </ui.Grid>
          <ui.Grid item>
            <ui.Typography variant="caption">
              {`${labels.all} ${props.items.length} ${labels.dataUnit}`}{" "}
            </ui.Typography>
          </ui.Grid>
        </ui.Grid>
      </ui.Grid>
      <ui.TableContainer component={ui.Paper}>
        <ui.Table stickyHeader aria-label="record-list-table">
          <ui.TableHead>
            <ui.TableRow>
              {props.cols.map((col: Column) => (
                <ui.TableCell key={`head-${col.key}`}>
                  {col.label}
                  {col.label && (props.sort || props.sort === undefined) && (
                    <ui.IconButton
                      style={{ paddingBottom: "4px", paddingTop: "4px" }}
                      onClick={() => toggleSort(col.key)}
                    >
                      {state.sorts[col.key] === sort.asc ? (
                        <ArrowDropDown />
                      ) : (
                        <ArrowDropUp />
                      )}
                    </ui.IconButton>
                  )}
                </ui.TableCell>
              ))}
            </ui.TableRow>
          </ui.TableHead>
          <ui.TableBody>
            {props.items.length === 0 ? (
              <ui.TableRow key="0">
                <ui.TableCell>
                  {props.defaultText || labels.notFound}
                </ui.TableCell>
              </ui.TableRow>
            ) : (
              props.items
                .slice(
                  (state.page - 1) * state.itemsPerPage,
                  (state.page - 1) * state.itemsPerPage + state.itemsPerPage
                )
                .map((item: T) => {
                  itemKey += 1;
                  let style = {
                    cursor: props.onRowClicked ? "pointer" : "default",
                  };
                  if (props.customStyle) {
                    const customStyle = props.customStyle(item);
                    if (customStyle) {
                      style = Object.assign(style, customStyle);
                    }
                  }
                  return (
                    <ui.TableRow
                      key={itemKey}
                      hover={!!props.onRowClicked}
                      style={style}
                      onClick={(e: any) =>
                        props.onRowClicked && props.onRowClicked(item)
                      }
                    >
                      {props.cols.map((col: Column) => (
                        <ui.TableCell key={`row-${col.key}-${itemKey}`}>
                          {props.extraKeyItemCallbacks &&
                          props.extraKeyItemCallbacks[col.key]
                            ? props.extraKeyItemCallbacks[col.key](item)
                            : (item as any)[col.key]}
                        </ui.TableCell>
                      ))}
                    </ui.TableRow>
                  );
                })
            )}
          </ui.TableBody>
        </ui.Table>
      </ui.TableContainer>
    </>
  );
}

export { RecordList, sort };
export type { Column };
