import react from "react";
import * as ui from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import {
  activities,
  identifierToLabel,
} from "../../../const/calendar_activity";
import {
  createMeetingCalendarVisit,
  createTrainingCalendarVisit,
} from "../../../const/definitions/calendar_visit";
import { definitions } from "../../../schema/api";
import { ChainStoreSelector } from "../../../components/chain_store_selector";

import Close from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  title: {
    margin: 0,
    padding: theme.spacing(2),
  },
  dayoffText: {
    color: "#ff4444",
  },
}));

const labels: { [key: string]: string } = {
  year: "年",
  month: "月",
  day: "日",
  activity: "予定",
  memo: "メモ",
  save: "保存",
  monday: "月",
  tuesday: "火",
  wednesday: "水",
  thursday: "木",
  friday: "金",
  saturday: "土",
  sunday: "日",
  errorActivityType: "必要な予定種別が登録されていません",
};

type ScheduleProps = {
  calendar: definitions["Calendar"];
  calendarActivities: definitions["CalendarActivity"][];
  chains: definitions["Chain"][];
  objectiveVisitCountByStoreId: { [storeId: number]: number };
  onClose: () => void;
  onSubmit: (calendar: definitions["Calendar"]) => void;
};

const jsDayLabels = [
  labels.sunday,
  labels.monday,
  labels.tuesday,
  labels.wednesday,
  labels.thursday,
  labels.friday,
  labels.saturday,
];

type ScheduleState = {
  calendar: definitions["Calendar"];
  checkedActivities: { [activityId: number]: boolean };
  activityOptions: {
    dayoff: definitions["CalendarActivity"];
    meeting: definitions["CalendarActivity"];
    training: definitions["CalendarActivity"];
    willwork: definitions["CalendarActivity"];
  };
};

function Schedule(props: ScheduleProps) {
  const classes = useStyles();

  const checkedActivities = {};
  const visits = props.calendar.visits || [];

  const tmpActivityOptions: any = {};
  for (let i = 0; i < props.calendarActivities.length; i += 1) {
    const activity = props.calendarActivities[i];
    switch (activity.name) {
      case activities.dayoff.identifier: {
        tmpActivityOptions.dayoff = activity;
        (checkedActivities as any)[activity.id] =
          props.calendar.activity_id === activities.dayoff.value;
        break;
      }
      case activities.meeting.identifier: {
        tmpActivityOptions.meeting = activity;
        (checkedActivities as any)[activity.id] = !!visits.find(
          (v) => v.is_meeting
        );
        break;
      }
      case activities.training.identifier: {
        tmpActivityOptions.training = activity;
        (checkedActivities as any)[activity.id] = !!visits.find(
          (v) => v.is_training
        );
        break;
      }
      case activities.willwork.identifier: {
        tmpActivityOptions.willwork = activity;
        (checkedActivities as any)[activity.id] =
          props.calendar.activity_id === activities.willwork.value ||
          !!visits.find((v) => !v.is_training && !v.is_meeting);
        break;
      }
    }
  }

  if (
    !tmpActivityOptions.dayoff ||
    !tmpActivityOptions.meeting ||
    !tmpActivityOptions.training ||
    !tmpActivityOptions.willwork
  ) {
    return <>{labels.errorActivityType}</>;
  }

  const calendar = { ...props.calendar };
  if (props.calendar.visits) {
    calendar.visits = [...props.calendar.visits];
  } else {
    calendar.visits = [];
  }

  const [state, setState] = react.useState<ScheduleState>({
    checkedActivities,
    calendar,
    activityOptions: tmpActivityOptions,
  });

  const onActivityChanged = (activityId: number) => {
    if (!state.calendar.visits) {
      state.calendar.visits = [];
    }

    // update checkedActivities
    (state.checkedActivities as any)[activityId] = !(
      state.checkedActivities as any
    )[activityId];

    if (state.checkedActivities[state.activityOptions.dayoff.id]) {
      if (activityId === state.activityOptions.dayoff.id) {
        const keys = Object.keys(state.checkedActivities);
        for (let i = 0; i < keys.length; i += 1) {
          const key = parseInt(keys[i], 10);
          if (key !== state.activityOptions.dayoff.id) {
            state.checkedActivities[key] = false;
          }
        }
      } else {
        state.checkedActivities[state.activityOptions.dayoff.id] = false;
      }
    }

    // update activity_id
    if (state.checkedActivities[state.activityOptions.dayoff.id]) {
      state.calendar.activity_id = state.activityOptions.dayoff.id;
    } else {
      if (state.checkedActivities[state.activityOptions.willwork.id]) {
        state.calendar.activity_id = state.activityOptions.willwork.id;
      } else if (state.checkedActivities[state.activityOptions.training.id]) {
        state.calendar.activity_id = state.activityOptions.training.id;
      } else if (state.checkedActivities[state.activityOptions.meeting.id]) {
        state.calendar.activity_id = state.activityOptions.meeting.id;
      } else {
        state.calendar.activity_id = activities.unregistered.value;
      }
    }

    // update visits
    const checked = state.checkedActivities[activityId];
    switch (activityId) {
      case state.activityOptions.dayoff.id: {
        if (checked) {
          state.calendar.visits = [];
        }
        break;
      }
      case state.activityOptions.willwork.id: {
        if (!checked) {
          for (let i = state.calendar.visits.length - 1; i >= 0; i -= 1) {
            if (
              !state.calendar.visits[i].is_meeting &&
              !state.calendar.visits[i].is_training
            ) {
              state.calendar.visits.splice(i, 1);
            }
          }
        }
        break;
      }
      case state.activityOptions.meeting.id: {
        let meetingVisitIndex = -1;
        for (let i = state.calendar.visits.length - 1; i >= 0; i -= 1) {
          if (state.calendar.visits[i].is_meeting) {
            meetingVisitIndex = i;
            break;
          }
        }
        if (checked) {
          if (meetingVisitIndex === -1) {
            const visit = createMeetingCalendarVisit();
            visit.calendar_id = props.calendar.id;
            state.calendar.visits.push(visit);
          }
        } else {
          if (meetingVisitIndex >= 0) {
            state.calendar.visits.splice(meetingVisitIndex, 1);
          }
        }
        break;
      }
      case state.activityOptions.training.id: {
        let trainingVisitIndex = -1;
        for (let i = state.calendar.visits.length - 1; i >= 0; i -= 1) {
          if (state.calendar.visits[i].is_training) {
            trainingVisitIndex = i;
            break;
          }
        }
        if (checked) {
          if (trainingVisitIndex === -1) {
            const visit = createTrainingCalendarVisit();
            visit.calendar_id = props.calendar.id;
            state.calendar.visits.push(visit);
          }
        } else {
          if (trainingVisitIndex >= 0) {
            state.calendar.visits.splice(trainingVisitIndex, 1);
          }
        }
        break;
      }
    }

    setState({ ...state });
  };

  const onStoreIdsChanged = (storeIds: { [storeId: number]: string }) => {
    if (!state.calendar.visits) {
      state.calendar.visits = [];
    }
    for (let i = state.calendar.visits.length - 1; i >= 0; i -= 1) {
      if (
        !state.calendar.visits[i].is_meeting &&
        !state.calendar.visits[i].is_training
      ) {
        state.calendar.visits.splice(i, 1);
      }
    }

    const ids = Object.keys(storeIds).map((id) => parseInt(id, 10));
    for (let i = 0; i < props.chains.length; i += 1) {
      const chain = props.chains[i];
      if (!chain.stores) {
        continue;
      }

      for (let j = 0; j < chain.stores.length; j += 1) {
        const store = chain.stores[j];
        if (ids.indexOf(store.id) !== -1) {
          state.calendar.visits.push({
            id: 0,
            calendar_id: props.calendar.id,
            chain_id: chain.id,
            store_id: store.id,
            is_meeting: false,
            is_training: false,
          });
        }
      }
    }

    setState({ ...state });
  };

  const onMemoChanged = (memo: string) => {
    state.calendar.memo = memo;
    setState({ ...state });
  };

  const onClose = () => {
    props.onClose();
  };

  const onSubmit = () => {
    props.onSubmit(state.calendar);
  };

  const selectedStoreIds: { [chainId: number]: number[] } = {};
  if (state.calendar.visits) {
    for (let i = 0; i < state.calendar.visits.length; i += 1) {
      const visit = state.calendar.visits[i];
      if (!visit.chain_id || !visit.store_id) {
        continue;
      }
      if (!selectedStoreIds[visit.chain_id]) {
        selectedStoreIds[visit.chain_id] = [];
      }
      selectedStoreIds[visit.chain_id].push(visit.store_id);
    }
  }

  return (
    <ui.Container>
      <ui.Dialog fullWidth maxWidth="sm" aria-labelledby="dialog-title" open>
        <ui.DialogTitle className={classes.title}>
          <ui.DialogTitle id="dialog-title">
            <ui.Grid container>
              <ui.Grid
                container
                item
                xs={10}
                justifyContent="flex-start"
                alignItems="center"
              >
                <ui.Typography variant="h6">
                  {`${state.calendar.year}/${state.calendar.month}/${
                    state.calendar.date
                  } (${
                    jsDayLabels[
                      new Date(
                        state.calendar.year,
                        state.calendar.month - 1,
                        state.calendar.date
                      ).getDay()
                    ]
                  })`}
                </ui.Typography>
              </ui.Grid>
              <ui.Grid
                container
                item
                xs={2}
                justifyContent="flex-end"
                alignItems="center"
              >
                <ui.IconButton aria-label="close" onClick={onClose}>
                  <Close />
                </ui.IconButton>
              </ui.Grid>
            </ui.Grid>
          </ui.DialogTitle>
        </ui.DialogTitle>
        <ui.DialogContent dividers>
          <ui.Grid container direction="row" spacing={4}>
            <ui.Grid item xs={12}>
              <ui.InputLabel id="activity_id">{labels.activity}</ui.InputLabel>

              {Object.keys(state.activityOptions).map((key) => {
                const calendarActivity = (state.activityOptions as any)[key];
                return (
                  <ui.FormControlLabel
                    key={`activity-${calendarActivity.id}`}
                    control={
                      <ui.Checkbox
                        value={calendarActivity.id}
                        checked={state.checkedActivities[calendarActivity.id]}
                        onChange={(e) =>
                          onActivityChanged(parseInt(e.target.value, 10))
                        }
                      />
                    }
                    label={
                      <span
                        className={
                          calendarActivity.id === activities.dayoff.value
                            ? classes.dayoffText
                            : undefined
                        }
                      >
                        {identifierToLabel(calendarActivity.name)}
                      </span>
                    }
                  />
                );
              })}
            </ui.Grid>
            {state.activityOptions.willwork &&
              state.checkedActivities[state.activityOptions.willwork.id] && (
                <ui.Grid item xs={12}>
                  <ChainStoreSelector
                    checkbox={false}
                    direction="row"
                    chains={props.chains}
                    selectedStoreIdsByChainId={selectedStoreIds}
                    objectiveVisitCountByStoreId={
                      props.objectiveVisitCountByStoreId
                    }
                    showobjectiveVisitCountConfirm
                    onChange={onStoreIdsChanged}
                  />
                </ui.Grid>
              )}
            <ui.Grid item xs={12}>
              <ui.InputLabel id="calendar-memo">{labels.memo}</ui.InputLabel>
              <ui.TextField
                multiline
                fullWidth
                rows={3}
                variant="outlined"
                name="memo"
                defaultValue={state.calendar.memo}
                onBlur={(e) => onMemoChanged(e.target.value)}
              />
            </ui.Grid>
          </ui.Grid>
        </ui.DialogContent>
        <ui.DialogActions>
          <ui.Box textAlign="center">
            <ui.Button
              color="secondary"
              type="button"
              onClick={(_) => onSubmit()}
            >
              {labels.save}
            </ui.Button>
          </ui.Box>
        </ui.DialogActions>
      </ui.Dialog>
    </ui.Container>
  );
}

export { Schedule };
