import react from "react";
import * as ui from "@material-ui/core";
import * as c from "../const";

const labels: { [key: string]: string } = {
  prefecture: "都道府県",
};

interface PrefectureSelectorProps {
  selectedValue: string;
  onChanged: (e: any) => void;
}

function PrefectureSelector(props: PrefectureSelectorProps) {
  const selectedValue =
    c.prefectures.indexOf(props.selectedValue) === -1
      ? c.prefectures[0]
      : props.selectedValue;

  react.useEffect(() => {
    if (props.selectedValue !== selectedValue) {
      props.onChanged(selectedValue);
    }
  });

  return (
    <ui.Box>
      <ui.InputLabel id="prefecture-id-label">
        {labels.prefecture}
      </ui.InputLabel>
      <ui.Select
        fullWidth
        labelId="prefecture-id-label"
        id="prefecture-id"
        name="prefecture"
        value={selectedValue}
        onChange={(e: any) => props.onChanged(e.target.value)}
      >
        {c.prefectures.map((prefecture) => {
          return (
            <ui.MenuItem key={prefecture} value={prefecture}>
              {prefecture}
            </ui.MenuItem>
          );
        })}
      </ui.Select>
    </ui.Box>
  );
}

export { PrefectureSelector };
