type CalendarActivity = {
  identifier: string;
  label: string;
  symbol: string;
  value: number;
};

const activities: { [type: string]: CalendarActivity } = Object.freeze({
  dayoff: {
    identifier: "dayoff",
    label: "休暇",
    symbol: "休",
    value: 1,
  },
  willwork: {
    identifier: "willwork",
    label: "出勤予定",
    symbol: "予",
    value: 2,
  },
  unregistered: {
    identifier: "unregistered",
    label: "未登録",
    symbol: "-",
    value: 3,
  },
  worked: {
    identifier: "worked",
    label: "出勤",
    symbol: "出",
    value: 4,
  },
  meeting: {
    identifier: "meeting",
    label: "会議",
    symbol: "会",
    value: 5,
  },
  training: {
    identifier: "training",
    label: "研修",
    symbol: "研",
    value: 6,
  },
});

const valueToLabel = (typeValue: number): string => {
  const target = Object.keys(activities).find((key) => {
    return activities[key].value === typeValue;
  });
  return target && activities[target] ? activities[target].label : "";
};
const identifierToLabel = (identifier: string): string => {
  return activities[identifier] ? activities[identifier].label : "";
};
const identifierToSymbol = (identifier: string): string => {
  return activities[identifier] ? activities[identifier].symbol : "";
};

export { activities, valueToLabel, identifierToLabel, identifierToSymbol };
