import { definitions } from "../../schema/api";

const createClient = (): definitions["Client"] => {
  return {
    id: 0,
    user_id: 0,
    client_company_id: 0,
    name: "",
    name_kana: "",
    phone: "",
    remarks: "",
  };
};

export { createClient };
