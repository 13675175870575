import * as ui from "@material-ui/core";
import * as formatter from "../strings/format";

type FormattedTextFieldProps = ui.TextFieldProps & {
  trim?: boolean;
  phone?: boolean;
  email?: boolean;
  singleByteSpace?: boolean;
  numeric?: boolean;
  alphanumericbar?: boolean;
};

function FormattedTextField(props: FormattedTextFieldProps) {
  const baseProps = { ...props };
  delete baseProps.onChange;
  delete baseProps.onBlur;

  delete baseProps.trim;
  delete baseProps.phone;
  delete baseProps.email;
  delete baseProps.singleByteSpace;
  delete baseProps.numeric;
  delete baseProps.alphanumericbar;

  const format = (s: string): string => {
    let result = s;

    if (props.trim) {
      result = formatter.trim(result);
    }
    if (props.phone) {
      result = formatter.phone(result);
    }
    if (props.email) {
      result = formatter.looseEmail(result);
    }
    if (props.singleByteSpace) {
      result = formatter.singleByteSpace(result);
    }
    if (props.numeric) {
      result = formatter.numeric(result);
    }
    if (props.alphanumericbar) {
      result = formatter.alphanumericbar(result);
    }

    return result;
  };

  baseProps.onChange = props.onChange
    ? (e: any) => {
        e.target.value = format(e.target.value);
        props.onChange!(e);
      }
    : undefined;
  baseProps.onBlur = props.onBlur
    ? (e: any) => {
        e.target.value = format(e.target.value);
        props.onBlur!(e);
      }
    : undefined;
  return <ui.TextField {...baseProps} />;
}

export { FormattedTextField };
