import { definitions } from "../schema/api";

type ReportType = {
  identifier: string;
  label: string;
};

const reportTypes: { [type: string]: ReportType } = Object.freeze({
  visit: {
    identifier: "visit",
    label: "店舗訪問",
  },
  meeting: {
    identifier: "meeting",
    label: "会議",
  },
  training: {
    identifier: "training",
    label: "研修",
  },
});

const labelFromReport = (report: definitions["Report"]): string => {
  if (report.is_meeting) {
    return reportTypes.meeting.label;
  }
  if (report.is_training) {
    return reportTypes.training.label;
  }
  return reportTypes.visit.label;
};

export { reportTypes, labelFromReport };
