import react from "react";
import * as ui from "@material-ui/core";
import * as c from "../../const";
import * as auth from "../../auth";
import * as rest from "../../rest";
import { definitions } from "../../schema/api";
import { Loading } from "../../components/loading";
import { resources } from "../../const/resource";
import { Form } from "./form";

const labels: { [key: string]: string } = {
  title: "クライアントユーザー登録",
  email: "ログイン用Eメール (確認中)",
  password: "パスワード",
  succeed: "登録しました",
  failed: "登録に失敗しました",
};

type CreateState = {
  initialized: boolean;
  user: definitions["User"];
  clientReader: definitions["ClientReader"];
  clientCompanies: definitions["ClientCompany"][];
  userCalendarAuthorize: boolean;
};
type CreateProps = {
  showGlobalNotification?: (message: string) => void;
};

function Create(props: CreateProps) {
  const [state, setState] = react.useState<CreateState>({
    initialized: false,
    user: {
      id: c.api.newRecordId,
      name: "",
      password: "",
      email: "",
    },
    clientReader: {
      id: 0,
      user_id: 0,
      client_company_id: 0,
      name: "",
      name_kana: "",
      phone: "",
    },
    clientCompanies: [],
    userCalendarAuthorize: false,
  });

  const onEmailChanged = (email: string) => {
    state.user.email = email;
    setState({ ...state });
  };
  const onPasswordChanged = (password: string) => {
    state.user.password = password;
    setState({ ...state });
  };
  const onUserCalendarAuthorizeChanged = (set: boolean) => {
    setState({ ...state, userCalendarAuthorize: set });
  };

  const onChange = (
    user: definitions["User"],
    clientReader: definitions["ClientReader"]
  ) => {
    state.user = user;
    state.clientReader = clientReader;
    setState({ ...state });
  };

  const onSubmit = async (
    user: definitions["User"],
    clientReader: definitions["ClientReader"]
  ) => {
    state.user = user;
    state.clientReader = clientReader;

    const authorizationPayload = {
      resource_name: resources.calendar.identifier,
      crud: auth.resourceCrudFlag.retrieve,
    };
    const payloads: rest.ClientReadersPutParam = [{
      client_reader: state.clientReader,
      user: {
        id: c.api.newRecordId,
        name: state.user.name,
        email: state.user.email,
        password: state.user.password || "",
      },
      ...(state.userCalendarAuthorize ? {
          set_user_extended_authorizations: [{
          resource_name: resources.calendar.identifier,
          crud: auth.resourceCrudFlag.retrieve,
        }],
      } : {})
    }];

    try {
      await (new rest.ClientReader()).put(
        payloads,
        auth.getToken()
      );
      props.showGlobalNotification?.(labels.succeed);
    } catch (_) {
      props.showGlobalNotification?.(labels.failed);

    }
  };

  react.useEffect(() => {
    if (!state.initialized) {
      state.initialized = true;
      new rest.ClientCompany()
        .getAll(auth.getToken())
        .then((json: rest.ClientCompaniesGetResponse) => {
          state.clientCompanies = json;
          if (state.clientCompanies && state.clientCompanies.length > 0) {
            state.clientReader.client_company_id = state.clientCompanies[0].id;
          }
          setState({ ...state });
        });
    }
  });

  if (!state.initialized) {
    return <Loading />;
  }

  return (
    <ui.Container>
      <ui.Grid
        container
        spacing={0}
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        <Form
          user={state.user}
          clientReader={state.clientReader}
          clientCompanies={state.clientCompanies}
          userCalendarAuthorize={state.userCalendarAuthorize}
          onEmailChanged={onEmailChanged}
          onPasswordChanged={onPasswordChanged}
          onUserCalendarAuthorizeChanged={onUserCalendarAuthorizeChanged}
          onSubmit={onSubmit}
          onChange={onChange}
        />
      </ui.Grid>
    </ui.Container>
  );
}

export { Create };
