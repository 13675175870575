import react from "react";
import * as ui from "@material-ui/core";
import { definitions } from "../../schema/api";
import { resources, controls } from "../../const/resource";
import { UserForm } from "../../components/user_form";
import { ClientCompanySelector } from "../../components/client_company_selector";
import { ClientSelector } from "../../components/client_selector";
import { FormattedTextField } from "../../components/formatted_text_field";
import { NavigatorBack } from "../../components/navigator_back";
import { createFormValidator, FormValidator } from "../../validation/form";

const labels: { [key: string]: string } = {
  list: "一覧",
  titleCreate: "スタッフ登録",
  titleUpdate: "スタッフ更新",
  name: "スタッフ名",
  nameKana: "スタッフ名(カナ)",
  email: "ログイン用Eメール (確認中)",
  password: "パスワード",
  phone: "電話番号",
  emailPc: "Eメール(PC)",
  group: "所属グループ",
  area: "居住エリア",
  isQuitted: "退職フラグ",
  showLogin: "ログイン情報を確認",
  submit: "登録",
};

type FormState = {
  validator: FormValidator;
  showUserForm: boolean;
  userFormError: boolean;
};

type FormProps = {
  clientCompanyId: number;
  clients: definitions["Client"][];
  client?: definitions["Client"];
  clientCompany?: definitions["ClientCompany"];
  user: definitions["User"];
  staff: definitions["Staff"];
  onUserFormEnabled?: () => void;
  onClientCompanyChanged: (id: number) => void;
  onClientChanged: (id: number) => void;
  onNameChanged: (name: string) => void;
  onNameKanaChanged: (nameKana: string) => void;
  onGroupChanged: (group: string) => void;
  onAreaChanged: (area: string) => void;
  onEmailPcChanged: (emailPc: string) => void;
  onPhoneChanged: (phone: string) => void;
  onIsQuittedChanged: (quitted: boolean) => void;
  onEmailChanged: (email: string) => void;
  onPasswordChanged: (password: string) => void;
  onSubmit: () => void;
};

function Form(props: FormProps) {
  const validator = createFormValidator("staff");
  if (props.staff.id !== 0) {
    validator.validateAll(props.staff);
  }
  const [state, setState] = react.useState<FormState>({
    validator,
    showUserForm: props.user.id === 0,
    userFormError: false,
  });

  const onUpdateLoginInfoClicked = () => {
    state.showUserForm = true;
    setState({ ...state });
    props.onUserFormEnabled && props.onUserFormEnabled();
  };

  return (
    <ui.Grid container spacing={4}>
      <ui.Grid container item xs={12} spacing={0}>
        <NavigatorBack
          xs={12}
          sm={12}
          label={labels.list}
          href={`/${resources.staff.identifier}/${controls.retrieve.identifier}`}
        />
        <ui.Grid item xs={12}>
          <ui.Typography variant="h6">
            {props.staff.id !== 0 ? labels.titleUpdate : labels.titleCreate}
          </ui.Typography>
        </ui.Grid>
      </ui.Grid>

      <ui.Grid item xs={6}>
        {props.clientCompany ? (
          <ui.Typography variant="h6">{props.clientCompany.name}</ui.Typography>
        ) : (
          <ClientCompanySelector
            selectedValue={props.clientCompanyId}
            onChanged={props.onClientCompanyChanged}
          />
        )}
      </ui.Grid>

      <ui.Grid item xs={6}>
        {props.client ? (
          <ui.Typography variant="h6">{props.client.name}</ui.Typography>
        ) : (
          <ClientSelector
            clients={props.clients}
            selectedValue={props.staff.client_id}
            onChange={(e) =>
              props.onClientChanged(parseInt(e.target.value, 10))
            }
          />
        )}
      </ui.Grid>

      <UserForm
        show={state.showUserForm}
        user={props.user}
        onEmailChanged={props.onEmailChanged}
        onPasswordChanged={props.onPasswordChanged}
        onShowToggled={onUpdateLoginInfoClicked}
        onErrorUpdated={(hasError) => {
          state.userFormError = hasError;
          setState({ ...state });
        }}
      />

      <ui.Grid item xs={6}>
        <FormattedTextField
          {...state.validator.formProps.name}
          trim
          singleByteSpace
          id="staff-name-id"
          label={labels.name}
          onBlur={(e) => {
            state.validator.validate(e.target.value, "name");
            props.onNameChanged(e.target.value);
          }}
          fullWidth
          rows={6}
          defaultValue={props.staff.name}
          variant="outlined"
          name="name"
        />
      </ui.Grid>

      <ui.Grid item xs={6}>
        <FormattedTextField
          {...state.validator.formProps.name_kana}
          trim
          singleByteSpace
          id="staff-namekana-id"
          label={labels.nameKana}
          onBlur={(e) => {
            state.validator.validate(e.target.value, "name_kana");
            props.onNameKanaChanged(e.target.value);
          }}
          fullWidth
          rows={6}
          defaultValue={props.staff.name_kana}
          variant="outlined"
          name="name_kana"
        />
      </ui.Grid>

      <ui.Grid item xs={6}>
        <FormattedTextField
          {...state.validator.formProps.phone}
          phone
          id="staff-phone-id"
          label={labels.phone}
          onBlur={(e) => {
            state.validator.validate(e.target.value, "phone");
            props.onPhoneChanged(e.target.value);
          }}
          fullWidth
          rows={6}
          defaultValue={props.staff.phone}
          variant="outlined"
          name="phone"
        />
      </ui.Grid>

      <ui.Grid item xs={6}>
        <FormattedTextField
          {...state.validator.formProps.email_pc}
          trim
          email
          id="staff-emailpc-id"
          label={labels.emailPc}
          onBlur={(e) => {
            state.validator.validate(e.target.value, "email_pc");
            props.onEmailPcChanged(e.target.value);
          }}
          fullWidth
          rows={6}
          defaultValue={props.staff.email_pc}
          variant="outlined"
          name="email_pc"
        />
      </ui.Grid>

      <ui.Grid item xs={6}>
        <FormattedTextField
          {...state.validator.formProps.group}
          trim
          singleByteSpace
          id="staff-group-id"
          label={labels.group}
          onBlur={(e) => {
            state.validator.validate(e.target.value, "group");
            props.onGroupChanged(e.target.value);
          }}
          fullWidth
          rows={6}
          defaultValue={props.staff.group}
          variant="outlined"
          name="group"
        />
      </ui.Grid>

      <ui.Grid item xs={6}>
        <FormattedTextField
          {...state.validator.formProps.area}
          trim
          singleByteSpace
          id="staff-area-id"
          label={labels.area}
          onBlur={(e) => {
            state.validator.validate(e.target.value, "area");
            props.onAreaChanged(e.target.value);
          }}
          fullWidth
          rows={6}
          defaultValue={props.staff.area}
          variant="outlined"
          name="area"
        />
      </ui.Grid>

      {props.staff.id !== 0 && (
        <ui.Grid item xs={12} sm={6}>
          <ui.FormControlLabel
            control={
              <ui.Checkbox
                value={props.staff.is_quitted ? 1 : 0}
                checked={props.staff.is_quitted}
                onChange={(e) =>
                  props.onIsQuittedChanged(
                    !(parseInt(e.target.value, 10) === 1)
                  )
                }
              />
            }
            label={labels.isQuitted}
          />
        </ui.Grid>
      )}

      <ui.Grid item xs={12}>
        <ui.Button
          disabled={
            state.userFormError || state.validator.submitButtonProps.disabled
          }
          variant="contained"
          color="secondary"
          type="button"
          onClick={props.onSubmit}
        >
          {labels.submit}
        </ui.Button>
      </ui.Grid>
    </ui.Grid>
  );
}

export { Form };
