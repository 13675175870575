import react from "react";
import * as ui from "@material-ui/core";
import { useParams } from "react-router-dom";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import * as auth from "../../auth";
import * as rest from "../../rest";
import { definitions } from "../../schema/api";
import { createStaff } from "../../const/definitions/staff";
import { utf8bom } from "../../const/file";
import { downloadAsFile } from "../../window";
import { weekDayToText, isZeroDate } from "../../datetime";
import { YearSelector } from "../../components/year_selector";
import { MonthSelector } from "../../components/month_selector";
import { RecordList } from "../../components/record_list";
import { Buffer } from "buffer";

import GetApp from "@material-ui/icons/GetApp";

const labels = {
  year: "年",
  month: "月",
  download: "ダウンロード",
  date: "日付",
  work: "勤怠",
  workBegin: "出勤時間",
  workEnd: "退勤時間",
  notFound: "打刻データがありませんでした",
  noRecord: "--:--",
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    saturday: {
      color: "#6666ff",
    },
    sunday: {
      color: "#ff4444",
    },
    unregisterText: {
      color: "#aaaaaa",
    },
  })
);

type WorkTimeRow = {
  date: string;
  day: number;
  workBegin: string;
  workEnd: string;
};

type RetrieveState = {
  initialized: boolean;
  staffId: number;
  staff: definitions["Staff"];
  year: number;
  month: number;
  workTimes: WorkTimeRow[];
};

type RetrieveParams = {
  id: string;
};

function Retrieve() {
  const params = useParams<RetrieveParams>();
  const staffId = parseInt(params.id as string, 10);
  const classes = useStyles();

  const today = new Date();
  const [state, setState] = react.useState<RetrieveState>({
    staffId,
    initialized: false,
    staff: createStaff(),
    year: today.getFullYear(),
    month: today.getMonth() + 1,
    workTimes: [],
  });

  const refreshWorkTimes = () => {
    return new rest.WorkTime()
      .getAllYearMonth(
        state.year,
        state.month,
        { staff_id: state.staffId },
        auth.getToken()
      )
      .then((json) => {
        const lastOfMonth = new Date(state.year, state.month, 0);
        const lastDate = lastOfMonth.getDate();
        state.workTimes = [];
        // keep positive to avoid negative index
        const lastDay = lastOfMonth.getDay() + 35;

        for (let i = 1; i <= lastDate; i += 1) {
          const day = Math.abs((lastDay - (lastDate - i)) % 7);
          const workTime = json.find((workTime) => workTime.date === i);
          const row = {
            day,
            date: `${state.year}/${state.month}/${i}`,
            workBegin: labels.noRecord,
            workEnd: labels.noRecord,
          };
          if (workTime) {
            if (workTime.begin && !isZeroDate(workTime.begin)) {
              const begin = new Date(workTime.begin);
              row.workBegin = `${begin.getHours()}:${begin.getMinutes()}`;
            }
            if (workTime.end && !isZeroDate(workTime.end)) {
              const end = new Date(workTime.end);
              row.workEnd = `${end.getHours()}:${end.getMinutes()}`;
            }
          }

          state.workTimes[i] = row;
        }
      });
  };

  const onYearChanged = (year: number) => {
    state.year = year;
    refreshWorkTimes().then(() => setState({ ...state }));
  };
  const onMonthChanged = (month: number) => {
    state.month = month;
    refreshWorkTimes().then(() => setState({ ...state }));
  };

  const onDownload = () => {
    const rows = [];
    for (let i = 1; i < state.workTimes.length; i += 1) {
      const workTime = state.workTimes[i];
      rows.push(
        [
          workTime.date,
          workTime.workBegin === labels.noRecord ? "" : workTime.workBegin,
          workTime.workEnd === labels.noRecord ? "" : workTime.workEnd,
        ].join(",")
      );
    }

    const utf8CsvBuffer = Buffer.concat([
      Buffer.from(utf8bom),
      Buffer.from(rows.join("\n"), "utf-8"),
    ]);

    downloadAsFile(
      utf8CsvBuffer,
      `${labels.work}_${state.year}${`00${state.month}`.slice(-2)}_${
        state.staff.name
      }.csv`
    );
  };

  react.useEffect(() => {
    if (!state.initialized) {
      state.initialized = true;
      Promise.all([
        new rest.Staff().get(state.staffId, auth.getToken()).then((json) => {
          state.staff = json;
        }),
        refreshWorkTimes(),
      ]).then(() => setState({ ...state }));
    }
  });

  return (
    <ui.Container>
      <ui.Grid container spacing={4}>
        <ui.Grid item xs={12}></ui.Grid>
        <ui.Grid item xs={3} sm={1}>
          <YearSelector
            onChanged={onYearChanged}
            value={state.year}
            min={2022}
            max={today.getFullYear()}
          />
        </ui.Grid>
        <ui.Grid item xs={2} sm={1}>
          <MonthSelector onChanged={onMonthChanged} value={state.month} />
        </ui.Grid>
        <ui.Grid container item xs={7} sm={8} alignItems="center">
          <ui.Typography variant="h5">{state.staff.name}</ui.Typography>
        </ui.Grid>
        <ui.Grid container item xs={12} sm={2} alignItems="center">
          <ui.IconButton onClick={onDownload}>
            <GetApp />
            <ui.Typography variant="body1">{labels.download}</ui.Typography>
          </ui.IconButton>
        </ui.Grid>

        <ui.Grid item xs={12}>
          <RecordList<WorkTimeRow>
            defaultText={labels.notFound}
            cols={[
              { label: labels.date, key: "date" },
              { label: labels.workBegin, key: "workBegin" },
              { label: labels.workEnd, key: "workEnd" },
            ]}
            items={state.workTimes}
            extraKeyItemCallbacks={{
              date: (row) => {
                let klass = undefined;
                if (row.day === 0) {
                  klass = classes.sunday;
                } else if (row.day === 6) {
                  klass = classes.saturday;
                }
                return (
                  <>
                    {row.date} (
                    <span className={klass}>{weekDayToText(row.day)}</span>)
                  </>
                );
              },
              workBegin: (row) => {
                let klass = undefined;
                if (row.workBegin === labels.noRecord) {
                  klass = classes.unregisterText;
                }
                return <span className={klass}>{row.workBegin}</span>;
              },
              workEnd: (row) => {
                let klass = undefined;
                if (row.workEnd === labels.noRecord) {
                  klass = classes.unregisterText;
                }
                return <span className={klass}>{row.workEnd}</span>;
              },
            }}
          />
        </ui.Grid>
      </ui.Grid>
    </ui.Container>
  );
}

export { Retrieve };
