import react from "react";
import * as ui from "@material-ui/core";
import { definitions } from "../../../schema/api";
import { types, defaultType } from "../../../const/report_image_type";
import { createReportImage } from "../../../const/definitions/report_image";
import { ReportImageTypeSelector } from "./report_image_type_selector";
import { ImagePreview } from "../../../components/image_preview";

import Delete from "@material-ui/icons/Delete";

const labels: { [key: string]: string } = {
  button: "画像を追加...",
  instructionIdTitle: "対象指示",
  unknownInstructionTitle: "(不明な指示です)",
  visitPhotoTitle: "訪問時写真",
  comment: "コメント",
};

interface ReportImageInputProps {
  confirm?: boolean;
  reportId?: number;
  images: definitions["ReportImage"][];
  instructions: definitions["Instruction"][];
  onFileChanged: (images: definitions["ReportImage"][]) => void;
}

type ReportImageInputState = {
  images: definitions["ReportImage"][];
  preview: JSX.Element | null;
};

function typeLabel(image: definitions["ReportImage"]) {
  const key = Object.keys(types).find((key) => types[key].value === image.type);
  return key ? types[key].label : "";
}

function getInstructionTitleById(
  instructions: definitions["Instruction"][],
  id: number
): string {
  if (id === 0) {
    return labels.visitPhotoTitle;
  }

  const targetInstruction = instructions.find(
    (instruction) => id === instruction.id
  );
  if (!targetInstruction) {
    return labels.unknownInstructionTitle;
  }

  return targetInstruction.title;
}

function ReportImageInput(props: ReportImageInputProps) {
  const [state, setState] = react.useState<ReportImageInputState>({
    images: props.images,
    preview: null,
  });
  const onInstructionIdChange = (instructionId: number, index: number) => {
    if (instructionId === 0) {
      delete props.images[index].instruction_id;
    } else {
      props.images[index].instruction_id = instructionId;
    }
    props.onFileChanged(props.images);
  };
  const onCommentChange = (comment: string, index: number) => {
    props.images[index].comment = comment;
    props.onFileChanged(props.images);
  };
  const onTypeChange = (index: number, type: number) => {
    props.images[index].type = type;
    props.onFileChanged(props.images);
  };
  const onFileChange = (event: any) => {
    if (event.target.files.length === 0) {
      event.target.value = null;
      return;
    }
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    }).then((data: any) => {
      const reportImage = createReportImage();
      reportImage.name = event.target.files[0].name;
      reportImage.content = data;
      reportImage.type = defaultType.value;
      reportImage.report_id = props.reportId || 0;
      props.images.push(reportImage);
      event.target.value = null;
      props.onFileChanged(props.images);
    });
  };
  const onFileDelete = (index: number) => {
    if (props.images[index]) {
      props.images.splice(index, 1);
    }
    props.onFileChanged(props.images);
  };
  const onPreviewOpen = (index: number) => {
    state.preview = (
      <ui.Box>
        <img
          src={
            props.images[index].path
              ? `/${props.images[index].path}`
              : props.images[index].content
          }
          width="100%"
          alt={`reportimage-preview-${index}`}
        />
      </ui.Box>
    );
    setState({ ...state });
  };
  const onPreviewClose = () => {
    state.preview = null;
    setState({ ...state });
  };

  return (
    <ui.Grid container spacing={4}>
      {props.images.map((image, i) => (
        <ui.Grid
          key={`reportimage-preview-grid-${i}`}
          item
          container
          spacing={2}
          xs={12}
          sm={6}
          justifyContent="center"
          direction="row"
        >
          <ui.Grid item>
            <ui.ImageList rowHeight={160} cols={1}>
              <ui.ImageListItem key={`reportimage-preview-${i}`} cols={1}>
                <img
                  src={image.path ? `/${image.path}` : image.content}
                  id={`thumb-${i}`}
                  alt="reportimage-thumbnail"
                  onClick={(e) => onPreviewOpen(i)}
                />
              </ui.ImageListItem>
            </ui.ImageList>
          </ui.Grid>

          {props.confirm ? (
            <>
              <ui.Grid item xs={9}>
                <ui.Typography variant="body2">
                  {getInstructionTitleById(
                    props.instructions,
                    image.instruction_id || 0
                  )}
                </ui.Typography>
              </ui.Grid>
              <ui.Grid container item xs={3}>
                <ui.Typography variant="body2">
                  {typeLabel(image)}
                </ui.Typography>
              </ui.Grid>
              <ui.Grid item xs={12}>
                <ui.Typography variant="body2">{image.comment}</ui.Typography>
              </ui.Grid>
            </>
          ) : (
            <>
              <ui.Grid container item xs={8} sm={10}>
                <ui.InputLabel id="instruction-photo-type-label">
                  {labels.instructionIdTitle}
                </ui.InputLabel>
                <ui.Select
                  fullWidth
                  id={`reportimage-instruction_id-${i}`}
                  value={image.instruction_id || 0}
                  onChange={(e) =>
                    onInstructionIdChange(
                      parseInt(e.target.value as string, 10),
                      i
                    )
                  }
                >
                  <ui.MenuItem key={"instruction_id-0"} value="0">
                    {labels.visitPhotoTitle}
                  </ui.MenuItem>
                  {props.instructions.map(
                    (instruction: definitions["Instruction"]) => (
                      <ui.MenuItem
                        key={`instruction_id-${instruction.id}`}
                        value={instruction.id}
                      >
                        {instruction.title}
                      </ui.MenuItem>
                    )
                  )}
                </ui.Select>
              </ui.Grid>

              <ui.Grid container item xs={4} sm={2}>
                <ReportImageTypeSelector
                  confirm={props.confirm}
                  type={props.images[i]?.type || defaultType.value}
                  onTypeChange={(type) => onTypeChange(i, type)}
                />
              </ui.Grid>

              <ui.Grid item xs={10} sm={10}>
                <ui.TextField
                  id={`reportimage-comment-${i}`}
                  fullWidth
                  label={labels.comment}
                  value={image.comment}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => onCommentChange(e.target.value, i)}
                />
              </ui.Grid>

              <ui.Grid container item xs={2} sm={2} justifyContent="flex-end">
                <ui.IconButton
                  component="label"
                  onClick={() => onFileDelete(i)}
                >
                  <Delete />
                </ui.IconButton>
              </ui.Grid>
            </>
          )}
        </ui.Grid>
      ))}

      <ui.Grid item xs={12}>
        {!props.confirm && (
          <ui.Button fullWidth variant="contained" component="label">
            {labels.button}
            <input
              type="file"
              accept="image/png,image/jpeg"
              hidden
              onChange={onFileChange}
            />
          </ui.Button>
        )}
      </ui.Grid>

      <ImagePreview
        open={!!state.preview}
        content={state.preview}
        onPreviewClose={() => onPreviewClose()}
      />
    </ui.Grid>
  );
}

export { ReportImageInput };
export type { ReportImageInputProps };
