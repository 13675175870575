import * as ui from "@material-ui/core";
import { Link } from "react-router-dom";
import { resources, controls } from "../../../const/resource";
import Schedule from "@material-ui/icons/Schedule";

import ViewList from "@material-ui/icons/ViewList";

function ResourceItemStaffWorkTimeRetrieve() {
  return (
    <ui.Card>
      <ui.CardHeader
        avatar={<Schedule fontSize="large" />}
        title={controls.work_time_retrieve.label}
        titleTypographyProps={{ variant: "body1" }}
        action={
          <ui.IconButton
            key={`${resources.staff.identifier}-${controls.work_time_retrieve.identifier}`}
            component={Link}
            to={`/${resources.staff.identifier}/${controls.work_time_retrieve.identifier}`}
          >
            <ViewList fontSize="large" />
          </ui.IconButton>
        }
      />
    </ui.Card>
  );
}

export { ResourceItemStaffWorkTimeRetrieve };
