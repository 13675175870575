import react from "react";
import * as ui from "@material-ui/core";
import * as auth from "../../auth";
import * as rest from "../../rest";
import { definitions } from "../../schema/api";
import { resources, controls } from "../../const/resource";
import { RecordList } from "../../components/record_list";
import { YearSelector } from "../../components/year_selector";
import { MonthSelector } from "../../components/month_selector";
import { Loading } from "../../components/loading";

import NoteAdd from "@material-ui/icons/NoteAdd";
import Edit from "@material-ui/icons/Edit";
import Assignment from "@material-ui/icons/Assignment";

const labels: { [key: string]: string } = {
  year: "年",
  month: "月",
  create: "新規作成",
  date: "日付",
  title: "タイトル",
  file: "添付ファイル",
  edit: "編集",
  show: "詳細",
  notFound: "指定した月に書き込みがありません",
};

type RetrieveState = {
  initialized: boolean;
  noticeBoards: definitions["NoticeBoard"][];
  year: number;
  month: number;
  minYear: number;
  maxYear: number;
};

function Retrieve() {
  const today = new Date();
  const [state, setState] = react.useState<RetrieveState>({
    initialized: false,
    noticeBoards: [],
    year: today.getFullYear(),
    month: today.getMonth() + 1,
    minYear: today.getFullYear(),
    maxYear: today.getFullYear(),
  });

  const onYearChanged = (year: number) => {
    state.year = year;
    refreshNoticeBoards(state.year, state.month).then(() =>
      setState({ ...state })
    );
  };
  const onMonthChanged = (month: number) => {
    state.month = month;
    refreshNoticeBoards(state.year, state.month).then(() =>
      setState({ ...state })
    );
  };

  const refreshNoticeBoards = (year: number, month: number) => {
    return new rest.NoticeBoard()
      .getYearMonth(year, month, auth.getToken())
      .then((json: rest.NoticeBoardsGetYearMonthResponse) => {
        state.noticeBoards = json;
      });
  };

  react.useEffect(() => {
    if (!state.initialized) {
      state.initialized = true;

      refreshNoticeBoards(state.year, state.month).then(() =>
        setState({ ...state })
      );
    }
  });

  if (!state.initialized) {
    return <Loading />;
  }

  return (
    <ui.Container>
      <ui.Grid container spacing={4}>
        <ui.Grid item xs={12}></ui.Grid>
        <ui.Grid item xs={3} sm={1}>
          <YearSelector
            onChanged={onYearChanged}
            value={state.year}
            min={state.minYear}
            max={state.maxYear}
          />
        </ui.Grid>
        <ui.Grid item xs={2} sm={1}>
          <MonthSelector onChanged={onMonthChanged} value={state.month} />
        </ui.Grid>

        <ui.Grid item xs={7} sm={10}>
          {auth.isAuthorized(
            resources.notice_board.identifier,
            auth.resourceCrudFlag.create
          ) && (
            <ui.Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              height="100%"
            >
              <ui.IconButton
                onClick={(e: any) =>
                  (window.location.href = `/${resources.notice_board.identifier}/${controls.create.identifier}`)
                }
              >
                <NoteAdd />
                <ui.Typography variant="body2">{labels.create}</ui.Typography>
              </ui.IconButton>
            </ui.Box>
          )}
        </ui.Grid>

        <ui.Grid item xs={6}></ui.Grid>
        <ui.Grid item xs={12}>
          <RecordList<definitions["NoticeBoard"]>
            defaultText={labels.notFound}
            cols={[
              { label: labels.date, key: "strDate" },
              { label: labels.title, key: "title" },
              { label: labels.file, key: "file" },
              { label: "", key: "control" },
            ]}
            items={state.noticeBoards}
            extraKeyItemCallbacks={{
              strDate: (noticeBoard) => {
                const date = new Date(noticeBoard.date!);
                return (
                  <>{`${date.getFullYear()}/${
                    date.getMonth() + 1
                  }/${date.getDate()}`}</>
                );
              },
              file: (noticeBoard) => {
                if (
                  noticeBoard.notice_board_files &&
                  noticeBoard.notice_board_files.length > 0
                ) {
                  const file = noticeBoard.notice_board_files[0];
                  return (
                    <ui.Link
                      style={{ cursor: "pointer" }}
                      href={`/${file.file_path}`}
                      target="_blank"
                    >
                      {file.file_name}
                    </ui.Link>
                  );
                }
                return <>-</>;
              },
              control: (noticeBoard) => {
                if (
                  auth.isAuthorized(
                    resources.client.identifier,
                    auth.resourceCrudFlag.update
                  )
                ) {
                  return (
                    <ui.Grid container spacing={1} justifyContent="flex-end">
                      <ui.Grid item>
                        <ui.Button
                          color="primary"
                          onClick={(e: any) => {
                            window.location.href = `/${resources.notice_board.identifier}/${controls.update.identifier}/${noticeBoard.id}`;
                          }}
                        >
                          <ui.Grid container spacing={1}>
                            <ui.Grid item>
                              <Edit />
                            </ui.Grid>
                            <ui.Grid item>{labels.edit}</ui.Grid>
                          </ui.Grid>
                        </ui.Button>
                      </ui.Grid>
                      <ui.Grid item>
                        <ui.Button
                          color="primary"
                          onClick={(e: any) => {
                            window.location.href = `/${resources.notice_board.identifier}/${controls.show.identifier}/${noticeBoard.id}`;
                          }}
                        >
                          <ui.Grid container spacing={1}>
                            <ui.Grid item>
                              <Assignment />
                            </ui.Grid>
                            <ui.Grid item>{labels.show}</ui.Grid>
                          </ui.Grid>
                        </ui.Button>
                      </ui.Grid>
                    </ui.Grid>
                  );
                }
                return (
                  <ui.Grid item>
                    <ui.Button
                      color="primary"
                      onClick={(e: any) => {
                        window.location.href = `/${resources.notice_board.identifier}/${controls.show.identifier}/${noticeBoard.id}`;
                      }}
                    >
                      <ui.Grid container spacing={1}>
                        <ui.Grid item>
                          <Assignment />
                        </ui.Grid>
                        <ui.Grid item>{labels.show}</ui.Grid>
                      </ui.Grid>
                    </ui.Button>
                  </ui.Grid>
                );
              },
            }}
          />
        </ui.Grid>
      </ui.Grid>
    </ui.Container>
  );
}

export { Retrieve };
