import react, { useContext, useEffect } from "react";
import * as ui from "@material-ui/core";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import * as auth from "../../auth";
import { resources, controls } from "../../const/resource";
import { labelFromReport } from "../../const/report_type";
import * as datetime from "../../datetime";
import * as rest from "../../rest";
import { definitions } from "../../schema/api";
import { storage } from "../../const/storage";
import { currentLanguageCode } from "../../const/locale";
import * as cookie from "../../storage/cookie";
import { ClientSelector } from "../../components/client_selector";
import { StaffSelector } from "../../components/staff_selector";
import { RecordList } from "../../components/record_list_2";
import { Loading } from "../../components/loading";
import { Context, ReducerActionPayload } from "../../context/DataStoreContext";

import NoteAdd from "@material-ui/icons/NoteAdd";
import GetApp from "@material-ui/icons/GetApp";
import Edit from "@material-ui/icons/Edit";
import Assignment from "@material-ui/icons/Assignment";
import { roles } from "../../const/role";

const labels: { [key: string]: string } = {
  year: "年",
  month: "月",
  date: "報告日",
  any: "全て",
  showCheckedReport: "既読表示",
  staffName: "スタッフ",
  chain: "チェーン",
  chainName: "チェーン名",
  storeSearch: "店舗検索",
  store: "店舗",
  reportType: "報告種別",
  storeName: "店舗名",
  is_important: "要回答",
  notFound: "指定した月にレポートがありません",
  create: "新規作成",
  download: "CSV ダウンロード",
  createSucceeded: "日報を作成しました",
  updateSucceeded: "日報を更新しました",
  na: "-",
  edit: "編集",
  show: "閲覧",
  succeededDownload: "ダウンロードが完了しました",
  failedDownload: "ダウンロードに失敗しました",
};

type RecordListReport = definitions["Report"] & {
  dateType: Date;
  staffName: string;
  reportType: string;
  chainName: string;
  storeName: string;
};

type RetrieveState = {
  initialized: boolean;
  minYear: number;
  maxYear: number;
  chains: definitions["Chain"][];
  stores: definitions["Store"][];
};
type RetrieveProps = {
  showGlobalNotification?: (message: string) => void;
};

type RetirveParams = {
  year?: string;
  month?: string;
  staffId?: string;
};

function useQuery() {
  const { search } = useLocation();

  return react.useMemo(() => new URLSearchParams(search), [search]);
}

function createYear(min: number, max: number): JSX.Element[] {
  const doms = [];
  for (let year = min; year <= max; year += 1) {
    doms.push(
      <ui.MenuItem key={`year${year}`} value={year}>
        {year}
      </ui.MenuItem>
    );
  }
  return doms;
}

function createMonth(): JSX.Element[] {
  const doms = [];
  for (let month = 1; month <= 12; month += 1) {
    doms.push(
      <ui.MenuItem key={`month${month}`} value={month}>
        {month}
      </ui.MenuItem>
    );
  }
  return doms;
}

function Retrieve(props: RetrieveProps) {
  const params = useParams<RetirveParams>();
  const query = useQuery();
  const { state: contextState, dispatch } = useContext(Context);
  const reportState = contextState?.report;
  const userState = contextState?.user;
  const userStaff = userState?.staff;
  const defaultStaffId = reportState?.staffId;
  const currentClientId = reportState?.currentClientId;
  const reports = reportState?.listParameter.list || [];
  const staffs = reportState?.staffs || [];
  const clients = reportState?.clients || [];
  const chainId = reportState?.chainId || 0;
  const storeIds = reportState?.storeIds || [];
  const storeSearchWord = reportState?.storeSearchWord || "";
  const showCheckedReport = reportState?.showCheckedReport ?? true;
  const role = auth.getRole();

  const today = new Date();
  let year = parseInt(params?.year || "") || today.getFullYear();
  const month = parseInt(params?.month || "") || (today.getMonth() + 1);
  let staffId = parseInt(params?.staffId || "") || (
    defaultStaffId !== undefined ? defaultStaffId :
    (params?.month || role?.roleName !== roles.staff.identifier) ? 0 : userStaff?.id || 0
  );
  if (userStaff && !staffs.find((staff) => staff.id === userStaff.id)) {
    staffs.push(userStaff);
    staffs.sort((l, r) =>
      l.name_kana.localeCompare(r.name_kana, currentLanguageCode())
    );
  }

  const navigate = useNavigate();
  const [state, setState] = react.useState<RetrieveState>({
    initialized: false,
    minYear: today.getFullYear(),
    maxYear: today.getFullYear(),
    chains: [],
    stores: [],
  });

  const onRowClicked = (report: definitions["Report"]) => {
    navigate(`/${resources.report.identifier}/${controls.show.identifier}/${report.id}`);
  };

  const onYearChanged = (inputYear: number) => {
    const staffQuery = staffId === 0 ? "" : `/staff/${staffId}`;
    navigate(`/${resources.report.identifier}/${controls.retrieve.identifier}/${inputYear}/${month}${staffQuery}`);
  };
  const onMonthChanged = (inputMonth: number) => {
    const staffQuery = staffId === 0 ? "" : `/staff/${staffId}`;
    navigate(`/${resources.report.identifier}/${controls.retrieve.identifier}/${year}/${inputMonth}${staffQuery}`);
  };

  const refreshReports = async (year: number, month: number) => {
    const json: rest.ReportsGetYearMonthResponse = await (new rest.Report()).getYearMonth(
      year,
      month,
      {
        scope: [
          "Staff.Client",
          "Chain",
          "IndividualReports",
          "IndividualReports.IndividualReportListItems",
          "ReportImages",
          "Store",
          "ReportChecks",
        ].join(","),
      },
      auth.getToken()
    );
    const payload: ReducerActionPayload["SET_REPORT_YEAR_MONTH"] = {
      reports: [],
      staffs: [],
      clients: [],
      currentClientId: 0,
      year,
      month,
    };
    if (year !== reportState?.year || month !== reportState?.month) {
      payload.page = 1;
    }
    state.chains = [];
    state.stores = [];
    const addedStaffIds: number[] = [];
    const addedClientIds: number[] = [];
    const clients: definitions["Client"][] = [];
    const uniqueChainIds: number[] = [];
    const uniqueStoreIds: number[] = [];
    json.forEach((item) => {
      if (item.staff) {
        if (addedStaffIds.indexOf(item.staff.id) === -1) {
          addedStaffIds.push(item.staff.id);
          payload.staffs.push(item.staff);
          if (item.staff.client) {
            if (addedClientIds.indexOf(item.staff.client.id) === -1) {
              addedClientIds.push(item.staff.client.id);
              clients.push(item.staff.client);
            }
          }
        }
      }
      payload.reports.push({
        ...item,
        dateType: new Date(item.date),
        staffName: item.staff?.name || "",
        reportType: labelFromReport(item),
        chainName: item.chain?.name || "",
        storeName: item.store?.name || "",
      });
      if (item.chain) {
        if (!uniqueChainIds.includes(item.chain.id)) {
          state.chains.push(item.chain);
          uniqueChainIds.push(item.chain.id);
        }
      }
      if (item.store) {
        if (!uniqueStoreIds.includes(item.store.id)) {
          state.stores.push(item.store);
          uniqueStoreIds.push(item.store.id);
        }
      }
    });

    const role = auth.getRole();
    if (role?.roleName === roles.root.identifier) {
      payload.clients = clients;
      payload.currentClientId = currentClientId === 0 || payload.clients.find(({ id }) => id === currentClientId)
        ? currentClientId
        : payload.clients.length > 0 ? payload.clients[0].id : 0;
    }

    payload.staffs.sort((l, r) =>
      l.name_kana.localeCompare(r.name_kana, currentLanguageCode())
    );

    const options = {
      chainId: uniqueChainIds.includes(chainId) ? chainId : 0,
      storeIds: storeIds.filter((storeId) => uniqueStoreIds.includes(storeId)),
    };

    setState({ ...state });
    dispatch && dispatch({ type: "SET_REPORT_YEAR_MONTH", payload });
    dispatch && dispatch({ type: "SET_REPORT_OPTION", payload: options });
  };

  const onStaffIdChanged = (id: number) => {
    dispatch && dispatch({ type: "SET_REPORT_OPTION", payload: { staffId: id } });
    dispatch && dispatch({ type: "SET_LIST_OPTION", payload: { type: "report", parameter: { page: 1 } } });
    navigate(`/${resources.report.identifier}/${controls.retrieve.identifier}/${year}/${month}${id ? `/staff/${id}` : ''}`);
  };

  const onClientIdChanged = (e: any) => {
    dispatch && dispatch({ type: "SET_REPORT_OPTION", payload: { currentClientId: parseInt(e.target.value) } });
  };

  const onChainIdChanged = (value: number) => {
    dispatch && dispatch({ type: "SET_REPORT_OPTION", payload: { chainId: value } });
  };

  const onStoreSearchWordChanged = (value: string) => {
    dispatch && dispatch({ type: "SET_REPORT_OPTION", payload: { storeSearchWord: value } });
  };

  const onStoreIdsChanged = (values: number[]) => {
    if (values.includes(0)) {
      values.splice(0);
    }
    dispatch && dispatch({ type: "SET_REPORT_OPTION", payload: { storeIds: values } });
  };

  const onDownloadClicked = () => {
    const params: rest.ReportsGetYearMonthParam = {
      format: "csv",
      scope: [
        "Staff",
        "Staff.Client.ClientStores",
        "Chain",
        "IndividualReports",
        "IndividualReports.Instruction",
        "IndividualReports.IndividualReportListItems",
        "ReportImages",
        "Store",
      ].join(","),
    };
    if (staffId !== 0) {
      params.staff_id = staffId;
    }
    return new rest.Report()
      .getYearMonth(year, month, params, auth.getToken())
      .then((ret: object) => {
        props.showGlobalNotification &&
          props.showGlobalNotification(labels.succeededDownload);
      })
      .catch(() => {
        props.showGlobalNotification &&
          props.showGlobalNotification(labels.failedDownload);
      });
  };

  useEffect(() => {
    setState({ ...state, initialized: false });
    (async () => {
      if (role?.roleName === roles.staff.identifier) {
        const json = await ((new rest.User()).getMe(["Staff"], auth.getToken()));
        if (json.staff) {
          dispatch && dispatch({ type: "SET_USER_STAFF", payload: json.staff });
          if (defaultStaffId === undefined) {
            dispatch && dispatch({ type: "SET_REPORT_OPTION", payload: { staffId: json.staff.id } });
            dispatch && dispatch({ type: "SET_LIST_OPTION", payload: { type: "report", parameter: { page: 1 } } });
          }
        }
      }

      await Promise.all([
        refreshReports(year, month),
        (async () => {
          const json: rest.ReportsGetYearMonthRangeResponse = await ((new rest.Report()).getRange({}, auth.getToken()));
          if (json.min.year > 0) {
            state.minYear = json.min.year;
          }
          if (json.max.year > 0) {
            state.maxYear = json.max.year;
          }
          if (year < state.minYear) {
            year = state.minYear;
          } else if (year > state.maxYear) {
            year = state.maxYear;
          }
        })(),
      ])
      const identifier = query.get("success");
      if (identifier && cookie.get(storage.successNotification.key)) {
        cookie.remove(storage.successNotification.key);
        props.showGlobalNotification &&
          props.showGlobalNotification(
            identifier === controls.create.identifier
              ? labels.createSucceeded
              : labels.updateSucceeded
          );
      }
      state.initialized = true;
      setState({ ...state });
      console.log('YM');
    })();
  }, [year, month]);

  const oEditClicked = (id: number) => {
    navigate(`/${resources.report.identifier}/${controls.update.identifier}/${id}`);
  };
  const onShowClicked = (id: number) => {
    navigate(`/${resources.report.identifier}/${controls.show.identifier}/${id}`);
  };

  const changeShowCheckedReport = () => {
    dispatch && dispatch({ type: "SET_REPORT_OPTION", payload: { showCheckedReport: !showCheckedReport } });
  };

  if (!state.initialized) {
    return <Loading />;
  }

  const extraKeyItemCallbacks: {
    dateType: (report: RecordListReport) => JSX.Element;
    is_important: (report: RecordListReport) => JSX.Element;
    control?: (report: RecordListReport) => JSX.Element;
  } = {
    dateType: (report: RecordListReport) => (
      <>{datetime.toTextInputCalendarFormat(report.dateType)}</>
    ),
    is_important: (report: RecordListReport) => (
      <>{report.is_important ? "要回答" : ""}</>
    ),
  };

  const updatable = auth.isAuthorized(
    resources.report.identifier,
    auth.resourceCrudFlag.update
  );

  if (updatable) {
    extraKeyItemCallbacks.control = (report: RecordListReport) => (
      <ui.Grid container spacing={1} justifyContent="flex-end">
        <ui.Grid item>
          {updatable &&
            (!defaultStaffId ||
              defaultStaffId === report.staff_id) && (
              <ui.Button
                color="primary"
                onClick={(e: any) => oEditClicked(report.id)}
              >
                <ui.Grid container spacing={1}>
                  <ui.Grid item>
                    <Edit />
                  </ui.Grid>
                  <ui.Grid item>{labels.edit}</ui.Grid>
                </ui.Grid>
              </ui.Button>
            )}
        </ui.Grid>
        <ui.Grid item>
          <ui.Button
            color="primary"
            onClick={(e: any) => onShowClicked(report.id)}
          >
            <ui.Grid container spacing={1}>
              <ui.Grid item>
                <Assignment />
              </ui.Grid>
              <ui.Grid item>{labels.show}</ui.Grid>
            </ui.Grid>
          </ui.Button>
        </ui.Grid>
      </ui.Grid>
    );
  }

  const showDownload =
    auth.getRole()?.roleName !== roles.staff.identifier &&
    auth.getRole()?.roleName !== roles.client_reader.identifier;

  const filters: ((report: definitions["Report"]) => boolean)[] = [];
  if (staffId !== 0) {
    filters.push((report) => report.staff_id === staffId);
  }
  if (currentClientId) {
    filters.push((report) => report.staff?.client?.id === currentClientId);
  }
  if (!showCheckedReport) {
    filters.push((report) => !report.report_checks || report.report_checks.length === 0);
  }
  if (chainId) {
    filters.push((report) => chainId === report.chain_id);
  }
  if (storeIds.length) {
    filters.push(
      (report) =>
        storeIds.indexOf(0) > -1 ||
        storeIds.indexOf(report.store_id || -1) > -1
    );
  }
  const filteredReports: RecordListReport[] =
    filters.length === 0
      ? reports
      : reports.filter((report) => !filters.find((f) => !f(report)));
  const filteredStores = state.stores.filter(
    (store) =>
      (!chainId || chainId === store.chain_id) &&
      (!storeSearchWord || store.name.indexOf(storeSearchWord) >= 0)
  );

  return (
    <ui.Container>
      <ui.Grid container spacing={4}>
        <ui.Grid item xs={12}></ui.Grid>

        <ui.Grid item xs={3} sm={1}>
          <ui.InputLabel id="year-id-label">{labels.year}</ui.InputLabel>
          <ui.Select
            labelId="year-id-label"
            id="year-id"
            name="year"
            value={year}
            onChange={(e: any) => onYearChanged(parseInt(e.target.value, 10))}
          >
            {createYear(state.minYear, state.maxYear)}
          </ui.Select>
        </ui.Grid>
        <ui.Grid item xs={3} sm={1}>
          <ui.InputLabel id="month-id-label">{labels.month}</ui.InputLabel>
          <ui.Select
            labelId="month-id-label"
            id="month-id"
            name="month"
            value={month}
            onChange={(e: any) => onMonthChanged(parseInt(e.target.value, 10))}
          >
            {createMonth()}
          </ui.Select>
        </ui.Grid>

        <ui.Grid item xs={3} sm={2}>
          <StaffSelector
            all
            staffs={staffs}
            selectedValue={staffId}
            onChanged={(staffId: number) => onStaffIdChanged(staffId)}
          />
        </ui.Grid>
        <ui.Grid item xs={6} sm={2}>
          {role?.roleName === roles.root.identifier && (
            <ClientSelector
              all
              clients={clients}
              selectedValue={currentClientId || 0}
              onChange={(clientId: number) => onClientIdChanged(clientId)}
            />
          )}
        </ui.Grid>

        <ui.Grid item xs={6} sm={2}>
          <ui.FormControlLabel
            control={
              <ui.Checkbox
                value={showCheckedReport ? 1 : 0}
                checked={showCheckedReport}
                onChange={changeShowCheckedReport}
              />
            }
            label={labels.showCheckedReport}
          />
        </ui.Grid>

        <ui.Grid item xs={6} sm={2}>
          <ui.Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            height="100%"
          >
            {showDownload && (
              <ui.IconButton onClick={onDownloadClicked}>
                <GetApp />
                <ui.Typography variant="body2">{labels.download}</ui.Typography>
              </ui.IconButton>
            )}

            {auth.isAuthorized(
              resources.report.identifier,
              auth.resourceCrudFlag.create
            ) && (
              <ui.IconButton
                onClick={(e: any) =>
                  (window.location.href = `/${resources.report.identifier}/${controls.create.identifier}`)
                }
              >
                <NoteAdd />
                <ui.Typography variant="body2">{labels.create}</ui.Typography>
              </ui.IconButton>
            )}
          </ui.Box>
        </ui.Grid>

        {role?.roleName !== roles.staff.identifier && <>
          <ui.Grid item xs={6} sm={3}>
            <ui.InputLabel id="chain-id-label">{labels.chain}</ui.InputLabel>
            <ui.Select
              fullWidth
              labelId="chain-id-label"
              value={chainId}
              onChange={(e: any) => {
                onChainIdChanged(parseInt(e.target.value, 10));
              }}
            >
              <ui.MenuItem key={"chain_id-0"} value={0}>
                {labels.any}
              </ui.MenuItem>
              {state.chains.map((chain: definitions["Chain"]) => (
                <ui.MenuItem key={`chain_id-${chain.id}`} value={chain.id}>
                  {chain.name}
                </ui.MenuItem>
              ))}
            </ui.Select>
          </ui.Grid>

          <ui.Grid item xs={6} sm={3}>
            <ui.TextField
              label={labels.storeSearch}
              onChange={(e) => onStoreSearchWordChanged(e.target.value)}
              fullWidth
              value={storeSearchWord}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </ui.Grid>

          <ui.Grid item xs={6} sm={3}>
            <ui.InputLabel id="store-id-label">{labels.store}</ui.InputLabel>
            <ui.Select
              fullWidth
              multiple
              labelId="store-id-label"
              value={storeIds}
              onChange={(e: any) => {
                onStoreIdsChanged(
                  e.target.value.map((v: any) => parseInt(v, 10))
                );
              }}
            >
              <ui.MenuItem key={"store_id-0"} value={0}>
                {labels.any}
              </ui.MenuItem>
                {filteredStores.map((store) => (
                  <ui.MenuItem key={`store_id-${store.id}`} value={store.id}>
                    {store.name}
                  </ui.MenuItem>
                ))}
            </ui.Select>
          </ui.Grid>
        </>}

        <ui.Grid item xs={12}>
          <RecordList
            type="report"
            defaultText={labels.notFound}
            cols={[
              { label: labels.date, key: "dateType" },
              { label: labels.storeName, key: "storeName" },
              { label: labels.chainName, key: "chainName" },
              { label: labels.staffName, key: "staffName" },
              { label: labels.reportType, key: "reportType" },
              { label: labels.is_important, key: "is_important" },
              { label: "", key: "control" },
            ]}
            onRowClicked={
              auth.isAuthorized(
                resources.report.identifier,
                auth.resourceCrudFlag.update
              )
                ? undefined
                : onRowClicked
            }
            items={filteredReports}
            customStyle={(report: RecordListReport) => {
              if (report.report_checks && report.report_checks.length > 0) {
                return { backgroundColor: "#dddddd" };
              }
            }}
            extraKeyItemCallbacks={extraKeyItemCallbacks}
          />
        </ui.Grid>
      </ui.Grid>
    </ui.Container>
  );
}

export { Retrieve };
