import * as ui from "@material-ui/core";
import {
  Theme,
  makeStyles,
  createStyles,
  useTheme,
} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { roles } from "../../const/role";

import Menu from "@material-ui/icons/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";

const labels: { [key: string]: string } = {
  logoAlt: "StoreLink",
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    name: {
      marginRight: theme.spacing(2),
    },
    section: {
      display: "flex",
    },
  })
);

interface HeaderProps {
  title: string;
  name?: string;
  role?: string;
  menuButton?: boolean;
  accountButton?: boolean;
  onAccountToggled?: (e: any) => void;
  onMenuToggled?: (e: any) => void;
}

function Header(props: HeaderProps) {
  const classes = useStyles();

  const theme = useTheme();
  const isXsSize = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <ui.Toolbar>
      <ui.Grid container spacing={0} direction="row" justifyContent="center">
        <ui.Grid container item xs={7} sm={3} alignItems="center">
          <ui.Grid
            container
            item
            xs={3}
            sm={4}
            justifyContent="flex-start"
            direction="row"
            alignItems="center"
          >
            {props.menuButton ? (
              <ui.IconButton
                className={classes.menuButton}
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={(e) => props.onMenuToggled && props.onMenuToggled(e)}
              >
                <Menu />
              </ui.IconButton>
            ) : (
              <div className={classes.menuButton} />
            )}
          </ui.Grid>
          <ui.Grid
            container
            item
            xs={9}
            sm={8}
            justifyContent="flex-start"
            direction="row"
            alignItems="center"
          >
            {isXsSize ? (
              <ui.Typography variant="h6">{props.title}</ui.Typography>
            ) : (
              <ui.Button onClick={() => (window.location.href = "/")}>
                <img
                  src="/storelink_logo_w.png"
                  alt={labels.logoAlt}
                  width="140px"
                />
              </ui.Button>
            )}
          </ui.Grid>
        </ui.Grid>
        <ui.Grid
          container
          item
          xs={1}
          sm={5}
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
        >
          {!isXsSize && (
            <ui.Typography variant="h6">{props.title}</ui.Typography>
          )}
        </ui.Grid>
        <ui.Grid
          item
          container
          xs={4}
          sm={4}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <ui.Box className={classes.section}>
            {props.accountButton ? (
              <ui.IconButton
                aria-label="account of current user"
                aria-controls="primary-search-account-menu"
                aria-haspopup="true"
                color="inherit"
                onClick={(e) =>
                  props.onAccountToggled && props.onAccountToggled(e)
                }
              >
                {isXsSize ? (
                  <div />
                ) : (
                  <ui.Typography className={classes.name} variant="h6">
                    {`${props.role ? roles[props.role]?.label || "" : ""} / ${
                      props.name || ""
                    }`}
                  </ui.Typography>
                )}
                <AccountCircle />
              </ui.IconButton>
            ) : (
              <div />
            )}
          </ui.Box>
        </ui.Grid>
      </ui.Grid>
    </ui.Toolbar>
  );
}

export { Header };
export type { HeaderProps };
