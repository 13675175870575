import react from "react";
import { useParams } from "react-router-dom";
import * as ui from "@material-ui/core";
import * as c from "../../const";
import * as auth from "../../auth";
import * as rest from "../../rest";
import { definitions } from "../../schema/api";
import { Form } from "./form";
import { Loading } from "../../components/loading";

const labels: { [key: string]: string } = {
  succeed: "登録しました",
  failed: "登録に失敗しました",
};

type UpdateState = {
  clientCompanies: definitions["ClientCompany"][];
  user: definitions["User"];
  client: definitions["Client"];
  updateLoginInfo: boolean;
  initialized: boolean;
};
type UpdateProps = {
  showGlobalNotification?: (message: string) => void;
};

type UpdateParams = {
  id: string;
};

function Update(props: UpdateProps) {
  const params = useParams<UpdateParams>();
  const clientId = parseInt(params.id as string, 10);

  const [state, setState] = react.useState<UpdateState>({
    clientCompanies: [],
    user: {
      id: c.api.newRecordId,
      name: "",
      password: "",
      email: "",
    },
    client: {
      id: 0,
      user_id: 0,
      client_company_id: 0,
      name: "",
      name_kana: "",
      phone: "",
      remarks: "",
    },
    updateLoginInfo: false,
    initialized: false,
  });

  react.useEffect(() => {
    if (!state.initialized) {
      Promise.all([
        new rest.ClientCompany()
          .getAll(["Chains"], auth.getToken())
          .then((json: rest.ClientCompaniesGetResponse) => {
            state.clientCompanies = json;
          }),
        new rest.Client()
          .get(clientId, ["Chains", "Stores"], auth.getToken())
          .then((json: rest.ClientGetResponse) => {
            state.client = json;
          }),
      ])
        .then(() =>
          new rest.User()
            .getSensitive(state.client.user_id, true, [], auth.getToken())
            .then((json: rest.UserGetIdResponse) => {
              state.user = json;
            })
        )
        .then(() => {
          state.initialized = true;
          setState({ ...state });
        });
    }
  });

  const onUserFormEnabled = () => {
    state.updateLoginInfo = true;
    setState({ ...state });
  };

  const onNameChanged = (name: string) => {
    state.user.name = name;
    state.client.name = name;
    setState({ ...state });
  };
  const onEmailChanged = (email: string) => {
    state.user.email = email;
    setState({ ...state });
  };
  const onPasswordChanged = (password: string) => {
    state.user.password = password;
    setState({ ...state });
  };
  const onClientStringValueChanged = (
    name: "name_kana" | "phone" | "remarks",
    value: string
  ) => {
    state.client[name] = value;
    setState({ ...state });
  };

  const onClientCompanyChanged = (id: number) => {
    state.client.client_company_id = id;
    setState({ ...state });
  };

  const onSubmit = () => {
    const client = { ...state.client };
    const user = { ...state.user };
    delete client.client_company;
    delete user.admin;
    delete user.client;
    delete user.client_reader;
    delete user.role;
    delete user.staff;

    new rest.Client()
      .postSensitive(
        clientId,
        state.updateLoginInfo,
        { client, user },
        auth.getToken()
      )
      .then((ret: object) => {
        props.showGlobalNotification &&
          props.showGlobalNotification(labels.succeed);
      })
      .catch(() => {
        props.showGlobalNotification &&
          props.showGlobalNotification(labels.failed);
      });
  };

  if (!state.initialized) {
    return <Loading />;
  }

  return (
    <ui.Container>
      <ui.Grid
        container
        spacing={0}
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        <Form
          user={state.user}
          client={state.client}
          clientCompanies={state.clientCompanies}
          onUserFormEnabled={onUserFormEnabled}
          onEmailChanged={onEmailChanged}
          onPasswordChanged={onPasswordChanged}
          onClientCompanyChanged={onClientCompanyChanged}
          onNameChanged={onNameChanged}
          onNameKanaChanged={(v) => onClientStringValueChanged("name_kana", v)}
          onPhoneChanged={(v) => onClientStringValueChanged("phone", v)}
          onRemarksChanged={(v) => onClientStringValueChanged("remarks", v)}
          onSubmit={onSubmit}
        />
      </ui.Grid>
    </ui.Container>
  );
}

export { Update };
