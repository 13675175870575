import react from "react";
import * as ui from "@material-ui/core";
import { definitions } from "../schema/api";
import { resources, controls } from "../const/resource";
import * as datetime from "../datetime";
import * as auth from "../auth";
import * as rest from "../rest";

import Assignment from "@material-ui/icons/Assignment";

const labels = {
  title: "勤怠",
  noticeBoard: "最新掲示板",
  toList: "一覧",
  noEntry: "今月の掲示板への書き込みはありません",
  loading: "読み込み中...",
};

type NoticeBoardState = {
  noticeBoards: definitions["NoticeBoard"][];
  initialized: boolean;
};

function NoticeBoard() {
  const [state, setState] = react.useState<NoticeBoardState>({
    noticeBoards: [],
    initialized: false,
  });

  const now = new Date();

  react.useEffect(() => {
    if (state.initialized) {
      return;
    }

    state.initialized = true;

    new rest.NoticeBoard()
      .getYearMonth(now.getFullYear(), now.getMonth() + 1, auth.getToken())
      .then((json) => {
        json.sort((l, r) => {
          if (new Date(l.date!).getTime() > new Date(r.date!).getTime()) {
            return -1;
          }
          return 1;
        });
        state.noticeBoards = json;
      })
      .then(() => setState({ ...state }));
  });

  return (
    <ui.Card>
      <ui.CardHeader
        avatar={<Assignment fontSize="large" />}
        title={`${now.getFullYear()}/${now.getMonth() + 1} ${
          labels.noticeBoard
        }`}
        titleTypographyProps={{ variant: "body1" }}
        action={
          state.noticeBoards.length > 1 ? (
            <ui.Button
              color="secondary"
              onClick={() => {
                window.location.href = `/${resources.notice_board.identifier}/${controls.retrieve.identifier}`;
              }}
            >
              {labels.toList}
            </ui.Button>
          ) : (
            <></>
          )
        }
      />
      <ui.CardContent>
        {state.initialized ? (
          state.noticeBoards.length > 0 ? (
            <>
              <ui.Grid container spacing={2} alignItems="center">
                <ui.Grid item>
                  <ui.Typography variant="body1">
                    {datetime.fromRFC3339ToMd(state.noticeBoards[0].date!)}
                  </ui.Typography>
                </ui.Grid>
                <ui.Grid item>
                  <ui.Typography variant="h6">
                    {state.noticeBoards[0].title}
                  </ui.Typography>
                </ui.Grid>
              </ui.Grid>
              <ui.Grid item>
                <ui.Typography variant="body2">
                  {state.noticeBoards[0].content}
                </ui.Typography>
              </ui.Grid>
            </>
          ) : (
            <ui.Typography variant="subtitle1">{labels.noEntry}</ui.Typography>
          )
        ) : (
          <ui.Typography variant="subtitle1">{labels.loading}</ui.Typography>
        )}
      </ui.CardContent>
    </ui.Card>
  );
}

export { NoticeBoard };
