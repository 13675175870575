import * as ui from "@material-ui/core";
import { types } from "../../../const/report_image_type";

const labels: { [key: string]: string } = {
  title: "画像種別",
};

interface ReportImageTypeSelectorProps {
  confirm?: boolean;
  type: number;
  onTypeChange: (type: number) => void;
}

function ReportImageTypeSelector(props: ReportImageTypeSelectorProps) {
  return (
    <ui.Box>
      <ui.InputLabel id="instruction-photo-type-label">
        {labels.title}
      </ui.InputLabel>
      {props.confirm ? (
        props.type === types.before.value ? (
          types.before.label
        ) : props.type === types.after.value ? (
          types.after.label
        ) : props.type === types.competitive.value ? (
          types.competitive.label
        ) : props.type === types.other.value ? (
          types.other.label
        ) : (
          ""
        )
      ) : (
        <ui.Select
          fullWidth
          labelId="instruction-photo-type-label"
          id="instruction-photo-type-id"
          name="instruction_photo_type"
          value={props.type || types.before.value}
          defaultValue={props.type || types.before.value}
          onChange={(e: any) =>
            props.onTypeChange(parseInt(e.target.value, 10))
          }
        >
          <ui.MenuItem
            key={"instruction-photo-type-1"}
            value={types.before.value}
          >
            {types.before.label}
          </ui.MenuItem>
          <ui.MenuItem
            key={"instruction-photo-type-2"}
            value={types.after.value}
          >
            {types.after.label}
          </ui.MenuItem>
          <ui.MenuItem
            key={"instruction-photo-type-3"}
            value={types.competitive.value}
          >
            {types.competitive.label}
          </ui.MenuItem>
          <ui.MenuItem
            key={"instruction-photo-type-4"}
            value={types.other.value}
          >
            {types.other.label}
          </ui.MenuItem>
        </ui.Select>
      )}
    </ui.Box>
  );
}

export { ReportImageTypeSelector };
