import { request, methods } from "./request";
import { paths } from "../schema/api";

type PdfPostParam = paths["/pdf/{resource}/{id}"]["post"]["parameters"];

class Pdf {
  post(resource: string, resourceId: number, pdf: File, token: string = "") {
    return new Promise<{ name: string; content: string }>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const data = e.target.result;
        const base64 = data.split(",")[1];
        resolve({
          name: pdf.name,
          content: base64,
        });
      };
      reader.onerror = (error) => {
        console.log(error);
        reject(error);
      };
      reader.readAsDataURL(pdf);
    }).then(({ name, content }) => {
      return request({
        token,
        method: methods.Post,
        resource: `pdf/${resource}/${resourceId}`,
        params: { name, content },
      });
    });
  }
}

export { Pdf };
export type { PdfPostParam };
