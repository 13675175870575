import { definitions } from "../../schema/api";

const createStore = (): definitions["Store"] => {
  return {
    id: 0,
    name: "",
    name_kana: "",
    chain_id: 0,
    phone: "",
    fax: "",
    post_code: "",
    prefecture: "",
    address_1: "",
    address_2: "",
    address_3: "",
    remarks: "",
    is_closed: false,
  };
};

export { createStore };
