import * as ui from "@material-ui/core";
import * as datetime from "../../../datetime";
import { definitions } from "../../../schema/api";
import { FormValidator } from "../../../validation/form";
import { StaffSelector } from "../../../components/staff_selector";

const labels: { [key: string]: string } = {
  list: "一覧",
  titleCreate: "報告書作成",
  titleUpdate: "報告書更新",
  titleConfirm: "報告書確認",
  client: "会社名",
  staff: "スタッフ氏名",
  visit: "訪問日",
  reportType: "報告種別",
  selectorDefault: "選択してください",
  chain: "チェーン",
  store: "店舗",
  visitTime: "訪問時間",
  leaveTime: "退店時間",
  minute: "分",
  important: "要回答",
  yes: "はい",
  no: "いいえ",
  comment: "コメント(任意)",
  next: "次へ",
  errorNoStore: "担当店舗がありません",
};

interface CommonInstructionReportProps {
  validator: FormValidator;
  confirm?: boolean;
  report: definitions["Report"];
  staffs?: definitions["Staff"][];
  staff?: definitions["Staff"];
  onDateChanged: (a: any) => void;
  onStaffChanged: (a: any) => void;
  onReportChanged: (report: definitions["Report"]) => void;
  onReportChangedWithInstructionChanges: (
    report: definitions["Report"]
  ) => void;
}

const reportTypes = Object.freeze({
  visit: {
    value: 1,
    label: "店舗訪問",
  },
  meeting: {
    value: 2,
    label: "会議",
  },
  training: {
    value: 3,
    label: "研修",
  },
});
function reportTypeValueFromReport(report: definitions["Report"]): number {
  if (report.is_meeting) {
    return reportTypes.meeting.value;
  }
  if (report.is_training) {
    return reportTypes.training.value;
  }
  return reportTypes.visit.value;
}
function reportTypeLabelFromReport(report: definitions["Report"]): string {
  if (report.is_meeting) {
    return reportTypes.meeting.label;
  }
  if (report.is_training) {
    return reportTypes.training.label;
  }
  return reportTypes.visit.label;
}

function createTimePicker(
  name: string,
  hourValue: string,
  minuteValue: string,
  onHourChange: (hour: string) => void,
  onMinuteChange: (minute: string) => void
): JSX.Element {
  return (
    <ui.Grid container item spacing={1}>
      <ui.Grid item>
        <ui.Select
          fullWidth
          labelId={`${name}-hour-id-label`}
          id={`${name}-hour-id`}
          value={hourValue}
          onChange={(e: any) => onHourChange(e.target.value)}
        >
          {[
            4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
          ].map((hour) => (
            <ui.MenuItem key={`hour-${hour}`} value={`0${hour}`.slice(-2)}>
              {`0${hour}`.slice(-2)}
            </ui.MenuItem>
          ))}
        </ui.Select>
      </ui.Grid>
      <ui.Grid item>:</ui.Grid>
      <ui.Grid item>
        <ui.Select
          fullWidth
          labelId={`${name}-minute-id-label`}
          id={`${name}-minute-id`}
          value={minuteValue}
          onChange={(e: any) => onMinuteChange(e.target.value)}
        >
          {[0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55].map((minute) => (
            <ui.MenuItem
              key={`minute-${minute}`}
              value={`0${minute}`.slice(-2)}
            >
              {`0${minute}`.slice(-2)}
            </ui.MenuItem>
          ))}
        </ui.Select>
      </ui.Grid>
    </ui.Grid>
  );
}

function CommonInstructionReport(props: CommonInstructionReportProps) {
  const staffChains = props.staff?.client?.chains || [];
  const staffStores = props.staff?.stores || [];
  const chains = staffChains.filter(({ id }) => staffStores.find((store) => store.chain_id === id));

  props.validator.touch("date");

  const onChainChanged = (chainId: number) => {
    const chain = staffChains.find(({ id }) => id === chainId);
    if (!chain) {
      return;
    }
    props.report.chain_id = chain.id;

    const store = staffStores.find(({ chain_id }) => chain_id === chain.id);
    if (store) {
      props.report.store_id = store.id;
      props.validator.touch("store_id");
    }

    props.validator.validate(props.report.chain_id, "chain_id");
    props.validator.validate(props.report.chain_id, "store_id");
    props.onReportChangedWithInstructionChanges(props.report);
  };

  const onStoreIdChanged = (value: number) => {
    props.report.store_id = value;
    props.validator.validate(value, "store_id");
    props.onReportChangedWithInstructionChanges(props.report);
  };

  const onBoolValueChanged = (name: "is_important", value: boolean) => {
    props.report[name] = value;
    props.onReportChanged(props.report);
  };
  const onStringTimeChanged = (
    name: "visit_time" | "leave_time",
    value: { hour?: string; minute?: string }
  ) => {
    let hourMinute = props.report[name].split(":");
    if (hourMinute.length !== 2) {
      hourMinute = ["10", "00"];
    }
    if (value.hour) {
      hourMinute[0] = `0${value.hour}`.slice(-2);
    }
    if (value.minute) {
      hourMinute[1] = `0${value.minute}`.slice(-2);
    }
    props.report[name] = hourMinute.join(":");
    props.onReportChanged(props.report);
  };
  const onStringValueChanged = (name: "comment", value: string) => {
    props.report[name] = value;
    props.onReportChanged(props.report);
  };
  const onReportTypeChanged = (value: number) => {
    props.report.is_meeting = false;
    props.report.is_training = false;
    if (value === reportTypes.meeting.value) {
      props.report.is_meeting = true;
    } else if (value === reportTypes.training.value) {
      props.report.is_training = true;
    }

    props.validator.setIgnore(
      "chain_id",
      props.report.is_meeting || props.report.is_training
    );
    props.validator.setIgnore(
      "store_id",
      props.report.is_meeting || props.report.is_training
    );
    props.validator.validate(props.report.chain_id, "chain_id");
    props.validator.validate(props.report.store_id, "store_id");

    props.onReportChanged(props.report);
  };

  const onDateValueChanged = (e: any) => {
    props.validator.validate(e.target.value, "date");
    props.onDateChanged(new Date(e.target.value));
  };

  if (props.report.id === 0 && props.report.staff_id === 0 && props.staff) {
    props.report.staff_id = props.staff.id;
  }

  if (props.confirm) {
    return (
      <ui.Grid container spacing={4}>
        <ui.Grid container item xs={12} spacing={0}>
          <ui.Grid item xs={12}>
            <ui.Typography variant="h6">
              {props.confirm
                ? labels.titleConfirm
                : props.report.id === 0
                ? labels.titleCreate
                : labels.titleUpdate}
            </ui.Typography>
          </ui.Grid>
        </ui.Grid>

        <ui.Grid container item spacing={2}>
          <ui.Grid item xs={12} sm={6}>
            <ui.Box>
              <ui.InputLabel id="staff-id-label">{labels.staff}</ui.InputLabel>
              <ui.Typography variant="h6">{props.staff?.name}</ui.Typography>
            </ui.Box>
          </ui.Grid>

          <ui.Grid item xs={12} sm={6}>
            <ui.InputLabel id="staff-id-label">{labels.client}</ui.InputLabel>
            <ui.Typography variant="h6">
              {props.staff?.client?.client_company?.name || ""}
            </ui.Typography>
          </ui.Grid>

          <ui.Grid item xs={12} sm={2}>
            <ui.Box>
              <ui.InputLabel id="staff-visit-label">
                {labels.visit}
              </ui.InputLabel>
              <ui.Typography variant="h6">
                {new Date(props.report.date).toLocaleDateString()}
              </ui.Typography>
            </ui.Box>
          </ui.Grid>

          <ui.Grid item xs={6} sm={2}>
            <ui.InputLabel id="visit_time-id-label">
              {labels.visitTime}
            </ui.InputLabel>
            <ui.Typography variant="h6">
              {props.report.visit_time}
            </ui.Typography>
          </ui.Grid>
          <ui.Grid item xs={6} sm={2}>
            <ui.InputLabel id="leave_time-id-label">
              {labels.leaveTime}
            </ui.InputLabel>
            <ui.Typography variant="h6">
              {props.report.leave_time}
            </ui.Typography>
          </ui.Grid>

          <ui.Grid item xs={12} sm={3}>
            <ui.InputLabel id="is_impportant">{labels.important}</ui.InputLabel>
            <ui.Typography variant="h6">
              {props.report.is_important ? labels.yes : labels.no}
            </ui.Typography>
          </ui.Grid>

          <ui.Grid item xs={12} sm={3}>
            <ui.InputLabel id="report_type">{labels.reportType}</ui.InputLabel>
            <ui.Typography variant="h6">
              {reportTypeLabelFromReport(props.report)}
            </ui.Typography>
          </ui.Grid>

          {!props.report.is_meeting && !props.report.is_training && (
            <>
              <ui.Grid item xs={12} sm={6}>
                <ui.InputLabel id="chain-id-label">
                  {labels.chain}
                </ui.InputLabel>
                <ui.Typography variant="h6">
                  {staffChains.find((chain) => chain.id === props.report.chain_id)?.name || ""}
                </ui.Typography>
              </ui.Grid>
              <ui.Grid item xs={12} sm={6}>
                <ui.InputLabel id="store-id-label">
                  {labels.store}
                </ui.InputLabel>
                <ui.Typography variant="h6">
                  {props.report.store?.name || ""}
                </ui.Typography>
              </ui.Grid>
            </>
          )}

          <ui.Grid item xs={12} sm={6}>
            <ui.Typography variant="h6" style={{ whiteSpace: "pre-wrap" }}>
              {props.report.comment}
            </ui.Typography>
          </ui.Grid>
        </ui.Grid>
      </ui.Grid>
    );
  }

  return (
    <ui.Grid container spacing={4}>
      <ui.Grid container item xs={12} spacing={0}>
        <ui.Grid item xs={12}>
          <ui.Typography variant="h6">
            {props.report.id === 0 ? labels.titleCreate : labels.titleUpdate}
          </ui.Typography>
        </ui.Grid>
      </ui.Grid>

      <ui.Grid item xs={12} sm={6}>
        {props.staffs ? (
          <StaffSelector
            staffs={props.staffs}
            selectedValue={props.staff?.id || 0}
            onChanged={props.onStaffChanged}
          />
        ) : (
          <ui.Box>
            <ui.InputLabel id="staff-id-label">{labels.staff}</ui.InputLabel>
            <ui.Typography variant="h6">{props.staff?.name}</ui.Typography>
          </ui.Box>
        )}
      </ui.Grid>

      <ui.Grid item xs={12} sm={6}>
        <ui.InputLabel id="staff-id-label">{labels.client}</ui.InputLabel>
        <ui.Typography variant="h6">
          {props.staff?.client?.client_company?.name || ""}
        </ui.Typography>
      </ui.Grid>

      <ui.Grid item xs={12} sm={2}>
        {props.report.id > 0 ? (
          <ui.Box>
            <ui.InputLabel id="staff-visit-label">{labels.visit}</ui.InputLabel>
            <ui.Typography variant="h6">
              {new Date(props.report.date).toLocaleDateString()}
            </ui.Typography>
          </ui.Box>
        ) : (
          <ui.TextField
            id="date"
            {...props.validator.formProps.date}
            fullWidth
            label={labels.visit}
            type="date"
            name="date"
            defaultValue={datetime.toTextInputCalendarFormat(
              props.report.date ? new Date(props.report.date) : new Date()
            )}
            InputLabelProps={{ shrink: true }}
            onChange={onDateValueChanged}
          />
        )}
      </ui.Grid>

      <ui.Grid item xs={6} sm={2}>
        <ui.InputLabel id="visit_time-id-label">
          {labels.visitTime}
        </ui.InputLabel>
        {createTimePicker(
          "visit_time",
          props.report.visit_time.split(":")[0] || "10",
          props.report.visit_time.split(":")[1] || "00",
          (hour) => onStringTimeChanged("visit_time", { hour }),
          (minute) => onStringTimeChanged("visit_time", { minute })
        )}
      </ui.Grid>
      <ui.Grid item xs={6} sm={2}>
        <ui.InputLabel id="leave_time-id-label">
          {labels.leaveTime}
        </ui.InputLabel>
        {createTimePicker(
          "leave_time",
          props.report.leave_time.split(":")[0] || "11",
          props.report.leave_time.split(":")[1] || "00",
          (hour) => onStringTimeChanged("leave_time", { hour }),
          (minute) => onStringTimeChanged("leave_time", { minute })
        )}
      </ui.Grid>

      <ui.Grid item xs={12} sm={3}>
        <ui.InputLabel id="is_important">{labels.important}</ui.InputLabel>
        <ui.FormControlLabel
          key={`report-is_important`}
          control={
            <ui.Checkbox
              onChange={() =>
                onBoolValueChanged("is_important", !props.report.is_important)
              }
              checked={props.report.is_important}
            />
          }
          label=""
        />
      </ui.Grid>

      <ui.Grid item xs={12} sm={3}>
        <ui.InputLabel id="report_type">{labels.reportType}</ui.InputLabel>
        {props.report.id > 0 ? (
          <ui.Typography variant="h6">
            {reportTypeLabelFromReport(props.report)}
          </ui.Typography>
        ) : (
          <ui.RadioGroup
            row
            aria-label="report_type"
            value={reportTypeValueFromReport(props.report)}
            defaultValue={reportTypeValueFromReport(props.report)}
            onChange={(e) => onReportTypeChanged(parseInt(e.target.value, 10))}
          >
            {Object.keys(reportTypes).map((key) => (
              <ui.FormControlLabel
                key={`activity-${(reportTypes as any)[key].value}`}
                value={(reportTypes as any)[key].value}
                control={<ui.Radio />}
                label={(reportTypes as any)[key].label}
              />
            ))}
          </ui.RadioGroup>
        )}
      </ui.Grid>

      {!props.report.is_meeting && !props.report.is_training && (
        <>
          <ui.Grid item xs={12} sm={6}>
            <ui.InputLabel id="chain-id-label">{labels.chain}</ui.InputLabel>
            {props.report.id > 0 ? (
              <ui.Typography variant="h6">
                {staffChains.find((chain) => chain.id === props.report.chain_id)?.name || ""}
              </ui.Typography>
            ) : (
              <ui.Select
                {...props.validator.formProps.chain_id}
                fullWidth
                labelId="chain-id-label"
                id="chain-id"
                name="chain_id"
                value={props.report.chain_id || 0}
                onChange={(e: any) =>
                  onChainChanged(parseInt(e.target.value, 10))
                }
              >
                <ui.MenuItem key={`chain0`} value={0}>
                  {labels.selectorDefault}
                </ui.MenuItem>
                {chains.map((chain) => (
                  <ui.MenuItem key={`chain${chain.id}`} value={chain.id}>
                    {chain.name}
                  </ui.MenuItem>
                ))}
              </ui.Select>
            )}
          </ui.Grid>
          <ui.Grid item xs={12} sm={6}>
            <ui.InputLabel id="store-id-label">{labels.store}</ui.InputLabel>
            {props.report.id > 0 ? (
              <ui.Typography variant="h6">
                {props.report.store?.name || ""}
              </ui.Typography>
            ) : props.staff?.stores && props.staff?.stores.length > 0 ? (
              <ui.Select
                {...props.validator.formProps.store_id}
                fullWidth
                labelId="store-id-label"
                id="store-id"
                name="store_id"
                value={props.report.store_id || 0}
                onChange={(e: any) =>
                  onStoreIdChanged(parseInt(e.target.value, 10))
                }
              >
                <ui.MenuItem key={`store0`} value={0}>
                  {labels.selectorDefault}
                </ui.MenuItem>
                {props.staff?.stores
                  .filter(
                    (store) =>
                      store.chain_id === props.report.chain_id &&
                      !store.is_closed
                  )
                  .map((store) => (
                    <ui.MenuItem key={`store${store.id}`} value={store.id}>
                      {store.name}
                    </ui.MenuItem>
                  ))}
              </ui.Select>
            ) : (
              labels.errorNoStore
            )}
          </ui.Grid>
        </>
      )}

      <ui.Grid item xs={12} sm={6}>
        <ui.TextField
          id="comment-id"
          label={labels.comment}
          multiline
          fullWidth
          rows={6}
          defaultValue={props.report.comment}
          variant="outlined"
          name="comment"
          onBlur={(e) => onStringValueChanged("comment", e.target.value)}
        />
      </ui.Grid>
    </ui.Grid>
  );
}

export { CommonInstructionReport };
export type { CommonInstructionReportProps };
