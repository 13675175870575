import { prefectures } from "../const/prefecture";

const numericRegex = /^[0-9]+$/;
const alphaNumericUnderScoreRegex = /^[A-Za-z0-9_]+$/;
const alphaNumericHiphenUnderScoreRegex = /^[A-Za-z0-9_-]+$/;
const asciiRegex = /^[A-Za-z0-9_\-!"#$%&'()=~|^¥@`[{}\];:/.,?+*<>]+$/;
const kanaRegex = /^[\u30a0-\u30ff]+$/;
const kanaSpaceRegex = /^[\u30a0-\u30ff\s]+$/;
const postalCodeRegex = /^[0-9]{3}-[0-9]{4}$/;
const phoneRegex = /^[0-9]+(-[0-9]+)+$/;
const looseMailRegex = /^[a-z0-9A-Z\-_+.]+@[a-z0-9A-Z\-_+.]+[a-z0-9A-Z\-_+.]$/;

type InvalidReason = string;

type ValidationRule = {
  [propertyName: string]: Validator[];
};

type FieldValidationResult = {
  valid: boolean;
  reason?: InvalidReason;
};

type ValidationResult = {
  [field: string]: FieldValidationResult;
};

type Validator = {
  identifier: string;
  errorMessage: InvalidReason;
  sample: string;
  validate: (value: any) => boolean;
};

const validators: { [name: string]: Validator } = {
  required: {
    identifier: "required",
    errorMessage: "必須項目です",
    sample: "自由入力です",
    validate: (value: string | number): boolean => {
      return value !== "" && value !== 0;
    },
  },
  requiredArray: {
    identifier: "requiredArray",
    errorMessage: "最低一つは必須入力です",
    sample: "",
    validate: (value: any[]): boolean => {
      return value.length > 0;
    },
  },
  notZero: {
    identifier: "notZero",
    errorMessage: "有効な値を入力してください",
    sample: "1",
    validate: (value: string | number): boolean => {
      if (typeof value === "string") {
        return value !== "0" && value !== "";
      }
      return value !== 0;
    },
  },
  numeric: {
    identifier: "numeric",
    errorMessage: "半角数字のみ入力できます",
    sample: "1234567890",
    validate: (value: string | number): boolean => {
      if (value === "") {
        return true;
      }
      if (typeof value === "number") {
        return true;
      }
      return numericRegex.test(value);
    },
  },
  alphaNumericUnderScore: {
    identifier: "alphaNumericUnderScore",
    errorMessage: "半角英数字、アンダースコアのみ入力できます",
    sample: "ABC_abc_123",
    validate: (value: string): boolean => {
      if (value === "") {
        return true;
      }
      return alphaNumericUnderScoreRegex.test(value);
    },
  },
  alphaNumericHiphenUnderScore: {
    identifier: "alphaNumericHiphenUnderScore",
    errorMessage: "半角英数字、ハイフン、アンダースコアのみ入力できます",
    sample: "ABC_abc-123",
    validate: (value: string): boolean => {
      if (value === "") {
        return true;
      }
      return alphaNumericHiphenUnderScoreRegex.test(value);
    },
  },
  ascii: {
    identifier: "ascii",
    errorMessage: "半角文字のみ入力できます",
    sample: "aA1!_#",
    validate: (value: string): boolean => {
      if (value === "") {
        return true;
      }
      return asciiRegex.test(value);
    },
  },
  kana: {
    identifier: "kana",
    errorMessage: "カタカナで入力してください",
    sample: "カタカナニュウリョク",
    validate: (value: string): boolean => {
      if (value === "") {
        return true;
      }
      return kanaRegex.test(value);
    },
  },
  kanaSpace: {
    identifier: "kanaSpace",
    errorMessage: "カタカナ(スペース可)で入力してください",
    sample: "カタカナ ニュウリョク",
    validate: (value: string): boolean => {
      if (value === "") {
        return true;
      }
      return kanaSpaceRegex.test(value);
    },
  },
  postalCode: {
    identifier: "postalCode",
    errorMessage: "郵便番号は「000-0000」の形式で入力してください",
    sample: "000-0000",
    validate: (value: string): boolean => {
      if (value === "") {
        return true;
      }
      return postalCodeRegex.test(value);
    },
  },
  phone: {
    identifier: "phone",
    errorMessage: "電話番号の形式が正しくありません",
    sample: "000-0000-0000",
    validate: (value: string): boolean => {
      if (value === "") {
        return true;
      }
      return phoneRegex.test(value);
    },
  },
  looseMail: {
    identifier: "mail",
    errorMessage: "メールアドレスの形式が正しくありません",
    sample: "mail@domain.com",
    validate: (value: string): boolean => {
      if (value === "") {
        return true;
      }
      return looseMailRegex.test(value);
    },
  },
  japanesePrefecture: {
    identifier: "japanesePrefecture",
    errorMessage: "都道府県が正しくありません",
    sample: "東京都",
    validate: (value: string): boolean => {
      if (value === "") {
        return true;
      }
      return prefectures.indexOf(value) !== -1;
    },
  },
  bool: {
    identifier: "bool",
    errorMessage: "データ種別が不正です",
    sample: "何か入力されていれば有効とみなします",
    validate: (value: any): boolean => {
      return value === true || value === false;
    },
  },
  dateUntilToday: {
    identifier: "dateUntilToday",
    errorMessage: "未来の日付が入力されています",
    sample: "2022/05/14",
    validate: (value: string | Date): boolean => {
      const date = typeof value === "string" ? new Date(value) : value;
      const today = new Date();
      if (date.getFullYear() > today.getFullYear()) {
        return false;
      }
      if (
        date.getFullYear() === today.getFullYear() &&
        date.getMonth() > today.getMonth()
      ) {
        return false;
      }
      if (
        date.getFullYear() === today.getFullYear() &&
        date.getMonth() === today.getMonth() &&
        date.getDate() > today.getDate()
      ) {
        return false;
      }

      return true;
    },
  },
};

function validate(obj: any, rules: ValidationRule): ValidationResult {
  const results: ValidationResult = {};

  const propertyNames = Object.keys(rules);
  for (let i = 0; i < propertyNames.length; i += 1) {
    const propertyName = propertyNames[i];
    const rule = rules[propertyName];
    const subject = obj[propertyName];
    // skip if not required and is empty
    if (!subject) {
      if (
        rule
          .map((validator) => validator.identifier)
          .indexOf(validators.required.identifier) === -1
      ) {
        continue;
      }
    }
    for (let j = 0; j < rule.length; j += 1) {
      const validator = rule[j];
      const valid = validator.validate(subject);
      results[propertyName] = { valid };
      if (!valid) {
        results[propertyName].reason = validator.errorMessage;
        break;
      }
    }
  }

  return results;
}

export { validate, validators };
export type {
  Validator,
  ValidationRule,
  ValidationResult,
  FieldValidationResult,
};
