import react from "react";
import * as ui from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import * as auth from "../../auth";
import * as rest from "../../rest";
import { definitions } from "../../schema/api";
import { resources, controls } from "../../const/resource";
import { RecordList } from "../../components/record_list";
import { Loading } from "../../components/loading";

import NoteAdd from "@material-ui/icons/NoteAdd";

const labels: { [key: string]: string } = {
  name: "チェーン名",
  nameKana: "チェーン名カナ",
  create: "新規登録",
  notFound: "登録されているチェーンがありません",
};

type RetrieveState = {
  initialized: boolean;
  chains: definitions["Chain"][];
};

function Retrieve() {
  const navigate = useNavigate();
  const [state, setState] = react.useState<RetrieveState>({
    initialized: false,
    chains: [],
  });

  const onRowClicked = auth.isAuthorized(
    resources.chain.identifier,
    auth.resourceCrudFlag.update
  )
    ? (chain: definitions["Chain"]) => navigate(`/chain/update/${chain.id}`)
    : undefined;

  react.useEffect(() => {
    if (!state.initialized) {
      state.initialized = true;
      new rest.Chain()
        .getAll(auth.getToken())
        .then((json: rest.ChainsGetResponse) => {
          state.chains = json;
          setState(Object.assign({}, state));
        });
    }
  });

  if (!state.initialized) {
    return <Loading />;
  }

  return (
    <ui.Container>
      <ui.Grid container spacing={4}>
        <ui.Grid item xs={12}></ui.Grid>
        <ui.Grid item xs={12}>
          {auth.isAuthorized(
            resources.chain.identifier,
            auth.resourceCrudFlag.create
          ) && (
            <ui.Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              height="100%"
            >
              <ui.IconButton
                onClick={(e: any) =>
                  (window.location.href = `/${resources.chain.identifier}/${controls.create.identifier}`)
                }
              >
                <NoteAdd />
                <ui.Typography variant="body2">{labels.create}</ui.Typography>
              </ui.IconButton>
            </ui.Box>
          )}
        </ui.Grid>
        <ui.Grid item xs={12}>
          <RecordList<definitions["Chain"]>
            defaultText={labels.notFound}
            cols={[
              { label: labels.name, key: "name" },
              { label: labels.nameKana, key: "name_kana" },
            ]}
            items={state.chains}
            onRowClicked={onRowClicked}
          />
        </ui.Grid>
      </ui.Grid>
    </ui.Container>
  );
}

export { Retrieve };
