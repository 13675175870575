import * as ui from "@material-ui/core";
import { definitions } from "../schema/api";
import { makeStyles } from "@material-ui/core/styles";

const labels: { [key: string]: string } = {
  chain: "チェーン選択",
  all: "全てのチェーン",
};

const useStyles = makeStyles((theme) => ({
  error: {
    color: "#ff6666",
  },
}));

interface MultipleChainSelectorProps {
  error?: boolean;
  helperText?: string;
  showAllSelector?: boolean;
  chains: definitions["Chain"][];
  selectedValues: number[];
  onChange: (selectedValues: number[]) => void;
}

function chainsToMenuItems(
  chains: definitions["Chain"][],
  selectedValues: number[],
  callback: (e: any) => void
): JSX.Element[] {
  const doms = [];

  for (let i = 0; i < chains.length; i += 1) {
    const chain = chains[i];
    const checked = selectedValues.indexOf(chain.id) !== -1;
    doms.push(
      <ui.FormControlLabel
        key={`chain${chain.id}`}
        control={
          <ui.Checkbox
            value={chain.id}
            checked={checked}
            onChange={callback}
            name="chain_id"
          />
        }
        label={chain.name}
      />
    );
  }
  return doms;
}

function MultipleChainSelector(props: MultipleChainSelectorProps) {
  const classes = useStyles();

  const onChange = (event: any) => {
    const currentSelectedValues = props.selectedValues;
    const value = parseInt(event.target.value, 10);
    const index = currentSelectedValues.indexOf(value);
    if (event.target.checked) {
      if (index === -1) {
        currentSelectedValues.push(value);
      }
    } else {
      if (index !== -1) {
        currentSelectedValues.splice(index, 1);
      }
    }
    currentSelectedValues.sort();
    props.onChange(currentSelectedValues);
  };

  const onAllChange = () => {
    if (props.selectedValues.length === props.chains.length) {
      props.onChange([]);
    } else {
      props.onChange(props.chains.map((chain) => chain.id));
    }
  };

  return (
    <ui.Box>
      <ui.InputLabel
        className={props.error ? classes.error : undefined}
        id="chain-id-label"
      >
        {labels.chain}
      </ui.InputLabel>
      <ui.Box>
        {props.helperText && (
          <ui.Typography className={classes.error} variant="caption">
            {props.helperText}
          </ui.Typography>
        )}
      </ui.Box>
      <ui.FormControl required component="fieldset">
        <ui.FormGroup row>
          {props.showAllSelector && (
            <ui.FormControlLabel
              key={`chain-all`}
              control={
                <ui.Checkbox
                  value={1}
                  checked={props.selectedValues.length === props.chains.length}
                  onChange={(e) => onAllChange()}
                />
              }
              label={labels.all}
            />
          )}
          {chainsToMenuItems(props.chains, props.selectedValues, onChange)}
        </ui.FormGroup>
      </ui.FormControl>
    </ui.Box>
  );
}

export { MultipleChainSelector };
