function trim(s: string) {
  return s.trim();
}
function phone(s: string) {
  return s
    .replace(/[^0-9-]+/g, "")
    .replace(/^[-]+/g, "")
    .replace(/[-]+$/g, "");
}
function looseEmail(s: string) {
  return s.replace(/[^0-9a-zA-Z@.\-_+]+/g, "");
}
function singleByteSpace(s: string) {
  return s.replace(/\s+/g, " ");
}
function numeric(s: string) {
  return s.replace(/[^0-9]+/g, "");
}
function alphanumericbar(s: string) {
  return s.replace(/[^0-9a-zA-Z-_]+/g, "");
}

export { trim, phone, looseEmail, singleByteSpace, numeric, alphanumericbar };
