import react from "react";
import * as ui from "@material-ui/core";
import * as auth from "../../auth";
import * as rest from "../../rest";
import { definitions } from "../../schema/api";
import { Form } from "./form";

const labels: { [key: string]: string } = {
  succeed: "登録しました",
  failed: "登録に失敗しました",
};

type CreateState = {
  chain: definitions["Chain"];
};
type CreateProps = {
  showGlobalNotification?: (message: string) => void;
};

function Create(props: CreateProps) {
  const [state, setState] = react.useState<CreateState>({
    chain: {
      id: 0,
      name: "",
      name_kana: "",
    },
  });

  const onNameChanged = (value: string) => {
    state.chain.name = value;
    setState({ ...state });
  };
  const onNameKanaChanged = (value: string) => {
    state.chain.name_kana = value;
    setState({ ...state });
  };

  const onSubmit = () => {
    const chain = new rest.Chain();
    chain
      .put([state.chain], auth.getToken())
      .then((ret: object) => {
        props.showGlobalNotification &&
          props.showGlobalNotification(labels.succeed);
      })
      .catch(() => {
        props.showGlobalNotification &&
          props.showGlobalNotification(labels.failed);
      });
  };

  return (
    <ui.Container>
      <Form
        chain={state.chain}
        onNameChanged={onNameChanged}
        onNameKanaChanged={onNameKanaChanged}
        onSubmit={onSubmit}
      />
    </ui.Container>
  );
}

export { Create };
