import * as ui from "@material-ui/core";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";

const labels = {
  back: "戻る",
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    center: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    contextText: {
      color: "#aaaaaa",
      textAlign: "center",
      fontWeight: "bold",
    },
  })
);

type ErrorDisplayProps = {
  message: string;
};

function ErrorDisplay(props: ErrorDisplayProps) {
  const classes = useStyles();

  return (
    <>
      <ui.Box sx={{ height: 180 }} className={classes.center}>
        <ui.Typography variant="h4" className={classes.contextText}>
          {props.message}
        </ui.Typography>
      </ui.Box>
      <ui.Box className={classes.center}>
        <ui.Link
          style={{ cursor: "pointer" }}
          onClick={() => window.history.back()}
        >
          <ui.Typography variant="h5">{labels.back}</ui.Typography>
        </ui.Link>
      </ui.Box>
    </>
  );
}

export { ErrorDisplay };
