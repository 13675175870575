const api = Object.freeze({
  newRecordId: 0,
  instructionType: {
    table: 1,
    continuous: 2,
  },
  calendarActivityNames: {
    willWork: "willwork",
    dayoff: "dayoff",
    worked: "worked",
    meeting: "meeting",
    training: "training",
    unregistered: "unregistered",
  },
  errorCodes: {
    none: -1,
    undefined: 0,
    missingParameter: 10,
    invalidParameter: 11,
    notSupportedFormat: 20,
    userAuthenticationFailed: 1000,
    userEmailDuplication: 1001,
    operationIsLocked: 2000,
    multipleStaffsOnTheSameStore: 10001,
    differentStoreCodesForSameStore: 10002,
    duplicateCombinationOfStaffAndStoreAndClient: 10003,
    invalidAssociation: 10004,
    isolatedStaff: 10005,
    duplicateStoreNameOfSameChain: 10006,
    duplicateStorePhone: 10007,
    duplicateChainName: 10008,
    quittedStaff: 10009,
    duplicateClientName: 10010,
    exceededMaxImageCount: 11000,
    parallelReportImageDownload: 11001,
    associatedIndividualReportExists: 12001,
    workTimeRegisteringEndWithoutBegin: 20001,
  },
});
export { api };
