import * as ui from "@material-ui/core";

const labels: { [key: string]: string } = {
  postCode: "郵便番号",
};

interface PostCodeInputProps {
  value: string;
  onChanged: (postCode: string) => void;
}

function PostCodeInput(props: PostCodeInputProps) {
  const onChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const postCode = event.target.value;
    const codes = postCode
      .split("-")
      .map((s) => s.replace(/[^0-9]+/g, ""))
      .slice(0, 2);

    if (codes[0]) {
      if (codes[0].length > 3) {
        codes[1] = codes[0].slice(3);
      }
      codes[0] = codes[0].slice(0, 3);
    }
    if (codes[1]) {
      codes[1] = codes[1].slice(0, 4);
    }

    props.onChanged(codes.join("-"));
  };

  return (
    <ui.TextField
      id="postcode-id"
      label={labels.postCode}
      onChange={onChanged}
      fullWidth
      rows={2}
      value={props.value}
      variant="outlined"
      name="post_code"
    />
  );
}

export { PostCodeInput };
