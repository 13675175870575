import react from "react";
import * as ui from "@material-ui/core";
import { definitions } from "../../schema/api";
import { resources, controls } from "../../const/resource";
import { UserForm } from "../../components/user_form";
import { ClientCompanySelector } from "../../components/client_company_selector";
import { FormattedTextField } from "../../components/formatted_text_field";
import { NavigatorBack } from "../../components/navigator_back";
import { createFormValidator, FormValidator } from "../../validation/form";

const labels: { [key: string]: string } = {
  list: "一覧",
  titleCreate: "クライアント担当者登録",
  titleUpdate: "クライアント担当者更新",
  name: "氏名",
  nameKana: "氏名(カナ)",
  calendarAuthorize: "訪問計画表閲覧権限",
  available: "あり",
  notAvailable: "なし",
  submit: "登録",
};

type FormState = {
  validator: FormValidator;
  showUserForm: boolean;
  userFormError: boolean;
};

type FormProps = {
  user: definitions["User"];
  clientReader: definitions["ClientReader"];
  clientCompanies: definitions["ClientCompany"][];
  userCalendarAuthorize?: boolean;
  onUserFormEnabled?: () => void;
  onEmailChanged: (email: string) => void;
  onPasswordChanged: (password: string) => void;
  onUserCalendarAuthorizeChanged: (set: boolean) => void;
  onChange: (
    user: definitions["User"],
    clientReader: definitions["ClientReader"]
  ) => void;
  onSubmit: (
    user: definitions["User"],
    clientReader: definitions["ClientReader"]
  ) => void;
};

function Form(props: FormProps) {
  const validator = createFormValidator("client_reader");
  if (props.clientReader.id !== 0) {
    validator.validateAll(props.clientReader);
  }

  const [state, setState] = react.useState<FormState>({
    validator,
    showUserForm: props.user.id === 0,
    userFormError: false,
  });

  const onUpdateLoginInfoClicked = () => {
    state.showUserForm = true;
    setState({ ...state });
    props.onUserFormEnabled && props.onUserFormEnabled();
  };

  const onNameChanged = (name: string) => {
    props.user.name = name;
    props.clientReader.name = name;
    props.onChange(props.user, props.clientReader);
  };

  const onStringValueChanged = (
    attribute: "name_kana" | "phone",
    value: string
  ) => {
    props.clientReader[attribute] = value;
    props.onChange(props.user, props.clientReader);
  };

  const onClientCompanyChanged = (id: number) => {
    props.clientReader.client_company_id = id;
    props.onChange(props.user, props.clientReader);
  };

  const onSubmit = () => {
    props.onSubmit(props.user, props.clientReader);
  };

  return (
    <ui.Grid container spacing={4}>
      <ui.Grid container item xs={12} spacing={0}>
        <NavigatorBack
          xs={12}
          sm={12}
          label={labels.list}
          href={`/${resources.client_reader.identifier}/${controls.retrieve.identifier}`}
        />

        <ui.Grid item xs={12}>
          <ui.Typography variant="h6">
            {props.clientReader.id > 0
              ? labels.titleUpdate
              : labels.titleCreate}
          </ui.Typography>
        </ui.Grid>
      </ui.Grid>

      <UserForm
        show={state.showUserForm}
        user={props.user}
        onEmailChanged={props.onEmailChanged}
        onPasswordChanged={props.onPasswordChanged}
        onShowToggled={onUpdateLoginInfoClicked}
        onErrorUpdated={(hasError) => {
          state.userFormError = hasError;
          setState({ ...state });
        }}
      />

      <ui.Grid item xs={6}>
        <FormattedTextField
          {...state.validator.formProps.name}
          trim
          singleByteSpace
          id="clientreader-name-id"
          label={labels.name}
          onBlur={(e) => {
            state.validator.validate(e.target.value, "name");
            onNameChanged(e.target.value);
          }}
          fullWidth
          rows={6}
          defaultValue={props.clientReader.name}
          variant="outlined"
          name="name"
        />
      </ui.Grid>

      <ui.Grid item xs={6}>
        <FormattedTextField
          {...state.validator.formProps.name_kana}
          trim
          singleByteSpace
          id="clientreader-namekana-id"
          label={labels.nameKana}
          onBlur={(e) => {
            state.validator.validate(e.target.value, "name_kana");
            onStringValueChanged("name_kana", e.target.value);
          }}
          fullWidth
          rows={6}
          defaultValue={props.clientReader.name_kana}
          variant="outlined"
          name="name_kana"
        />
      </ui.Grid>

      <ui.Grid item xs={6}>
        <FormattedTextField
          {...state.validator.formProps.phone}
          phone
          id="clientreader-phone-id"
          label="電話番号"
          onBlur={(e) => {
            state.validator.validate(e.target.value, "phone");
            onStringValueChanged("phone", e.target.value);
          }}
          fullWidth
          rows={6}
          defaultValue={props.clientReader.phone}
          variant="outlined"
          name="phone"
        />
      </ui.Grid>

      <ui.Grid item xs={6}>
        <ClientCompanySelector
          clientCompanies={props.clientCompanies}
          selectedValue={props.clientReader.client_company_id}
          onChanged={onClientCompanyChanged}
        />
      </ui.Grid>

      <ui.Grid item xs={12} sm={6}>
        <ui.InputLabel>{labels.calendarAuthorize}</ui.InputLabel>
        <ui.RadioGroup
          row
          aria-label="resultstatus"
          name="resultstatus"
          value={props.userCalendarAuthorize ? "1" : "0"}
          defaultValue="0"
          onChange={(e) => {
            props.onUserCalendarAuthorizeChanged(e.target.value === "1");
          }}
        >
          <ui.FormControlLabel
            value="1"
            control={<ui.Radio />}
            label={labels.available}
          />
          <ui.FormControlLabel
            value="0"
            control={<ui.Radio />}
            label={labels.notAvailable}
          />
        </ui.RadioGroup>
      </ui.Grid>

      <ui.Grid item xs={12}>
        <ui.Button
          disabled={
            state.userFormError || state.validator.submitButtonProps.disabled
          }
          variant="contained"
          color="secondary"
          type="button"
          onClick={onSubmit}
        >
          {labels.submit}
        </ui.Button>
      </ui.Grid>
    </ui.Grid>
  );
}

export { Form };
