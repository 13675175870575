import * as ui from "@material-ui/core";
import * as auth from "../../auth";

import ExitToApp from "@material-ui/icons/ExitToApp";

const labels: { [key: string]: string } = {
  account: "アカウント",
  signout: "ログアウト",
};

interface AccountProps {
  anchor?: Element | ((element: Element) => Element) | null;
  name: string;
  open: boolean;
  onClose: (e: any) => void;
}

function Account(props: AccountProps) {
  return (
    <ui.Menu
      anchorEl={props.anchor}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id="account-menu"
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={props.open}
      onClose={props.onClose}
    >
      <ui.Container>
        <ui.ListItem>
          <ui.ListItemText>{props.name}</ui.ListItemText>
        </ui.ListItem>
      </ui.Container>
      <ui.Divider />
      <ui.MenuItem>
        <ui.ListItem button onClick={(e: any) => auth.logout()}>
          <ui.ListItemIcon>
            <ExitToApp />
          </ui.ListItemIcon>
          <ui.ListItemText>{labels.signout}</ui.ListItemText>
        </ui.ListItem>
      </ui.MenuItem>
    </ui.Menu>
  );
}

export { Account };
export type { AccountProps };
