import { definitions } from "../../schema/api";
import * as datetime from "../../datetime";

const createReport = (): definitions["Report"] => {
  return {
    id: 0,
    staff_id: 0,
    chain_id: 0,
    store_id: 0,
    is_meeting: false,
    is_training: false,
    date: datetime.toRFC3339(new Date()),
    visit_time: "10:00",
    leave_time: "11:00",
    is_important: false,
    comment: "",
    individual_reports: [],
    report_images: [],
  };
};

export { createReport };
