/** @jsxImportSource @emotion/react */
import react from "react";
import * as ui from "@material-ui/core";
import { css } from "@emotion/react";

const labels: { [key: string]: string } = {
  sheet: "シート",
  keyCol: "項目",
  valCol: "数値",
  keyPlaceholder: "項目...",
  add: "追加",
  delete: "選択行を削除",
};

type GridInputRow = {
  id: number;
  instruction_list_item_id?: number;
  key: string;
  value: number;
  order?: number;
};

type GridInputingRow = {
  instruction_list_item_id?: number;
  id?: number;
  key: string;
  value: number | string;
  checked: boolean;
};

interface GridInputProps {
  confirm?: boolean;
  reporterMode?: boolean;
  onRowsChanged: (rows: GridInputRow[]) => void;
  rows: GridInputRow[];
}

type GridInputState = {
  rows: GridInputingRow[];
  dragRowIndex: number;
  dragedRowIndex: number;
};

const tableStyle = (operationMode = false) => css({
  position: "relative",
  margin: operationMode ? "10px 0" : "10px 0 0",
  padding: "0",
  paddingInlineStart: "0",
  border: "solid 1px #ccc",
  borderRadius: "4px",
  fontSize: "16px",
  ["& li"]: {
    display: "grid",
    gridTemplateColumns: operationMode ? "20px auto 1fr 30%" : "70% 1fr",
    placeContent: "center",
    minHeight: "52px",
  },
  ["& li:last-of-type"]: {
    display: "block",
    minHeight: "0",
    position: "absolute",
    width: "100%",
    height: "1px",
  },
  ["& li:hover"]: {
    backgroundColor: "rgba(63, 81, 181, 0.12)",
  },
  ["& input"]: {
    border: "none",
    width: "100%",
    lineHeight: "52px",
    padding: "0 5px",
    fontSize: "16px",
    backgroundColor: "rgba(255, 255, 255, 0)",
  },
  ["& li > div"]: {
    display: "flex",
    alignItems: "center",
  },
  ["& li:first-of-type > div:nth-of-type(n+2)::before"]: {
    content: `""`,
    display: "inline-block",
    left: "-1px",
    borderLeft: "solid 2px #ddd",
    height: "20px",
    marginRight: "7px",
  },
  ["& li > div:last-of-type input"]: {
    textAlign: "right",
  },
  ...(operationMode ? {
    ["& li:nth-of-type(n+2)"]: {
      ["&:not(:last-of-type)"]: {
        borderTop: "solid 1px #ccc",
      },
      [`&[data-dragged="1"]`]: {
        borderTop: "solid 2px #888",
      },
      [`& > div:first-of-type`]: {
        cursor: "pointer",
      },
    },
  } : {
    "& li > div:first-of-type": {
      paddingLeft: "6px",
      wordBreak: "break-all",
    },
  }),
});

function GridInput(props: GridInputProps) {
  const [state, setState] = react.useState<GridInputState>({
    rows: props.rows.length
      ? props.rows
        .map((row) => ({ ...row, checked: false }))
        .sort((row1, row2) => (row1.order || 0) - (row2.order || 0))
      : [{
        instruction_list_item_id: 0,
        id: 0,
        key: "",
        value: 0,
        checked: false,
      }],
      dragRowIndex: -1,
      dragedRowIndex: -1,
  });

  const addRow = () => {
    const newRow: GridInputingRow = {
        instruction_list_item_id: 0,
        id: 0,
        key: "",
        value: 0,
        checked: false,
    };
    updateRows([...state.rows, newRow]);
  };

  const onChangeRow = <T extends keyof GridInputingRow>(row: GridInputingRow, key: T, value: GridInputingRow[T]): void => {
    const rowIndex = state.rows.findIndex((v) => v === row);
    if (rowIndex > -1) {
      const newRows = [...state.rows];
      newRows[rowIndex] = { ...row, [key]: value };
      updateRows(newRows);
    }
  };

  const changeAllRowCheck = (checked: boolean) => {
    const rows = state.rows.map((row) => ({ ...row, checked }));
    setState({ ...state, rows });
  };

  const deleteRows = () => {
    const changedRows = state.rows.filter(({ checked }) => !checked);
    updateRows(changedRows);
  };

  const updateRows = (rows: GridInputingRow[]) => {
    setState({ ...state, rows });
    props.onRowsChanged(rows.map((row, index) => ({
      id: row.id || 0,
      instruction_list_item_id: row.instruction_list_item_id,
      key: row.key,
      value: parseInt(`${row.value || "0"}`),
      order: index,
    })));
  };

  const onDraged = () => {
    const rows = [...state.rows];
    const { dragRowIndex, dragedRowIndex } = state;
    if (rows[dragRowIndex] && dragedRowIndex >= 0 && dragedRowIndex <= rows.length) {
      const insertRowIndex = dragedRowIndex - (dragRowIndex < dragedRowIndex ? 1 : 0);
      const [row] = rows.splice(dragRowIndex, 1);
      rows.splice(insertRowIndex, 0, row);
    }
    setState({ ...state, dragRowIndex: -1, dragedRowIndex: -1 });
    updateRows(rows);
  };

  return (
    <ui.Box>
      <ui.InputLabel id="chain-id-label">{labels.sheet}</ui.InputLabel>
      <ul css={tableStyle(!props.reporterMode)}>
        <li>
          {props.reporterMode || (
            <>
              <div />
              <ui.Checkbox onChange={(event) => changeAllRowCheck(event.target.checked)} />
            </>
          )}
          <div>{labels.keyCol}</div>
          <div>{labels.valCol}</div>
        </li>
        {state.rows.map((row, i) => (
          <li
            key={`item${i}`}
            data-dragged={i === state.dragedRowIndex ? "1" : "0"}
            draggable={!props.reporterMode}
            onDragStart={() => setState({ ...state, dragRowIndex: i })}
            onDragEnter={() => setState({ ...state, dragedRowIndex: i })}
            onDragOver={(event) => event.preventDefault()}
            onDragEnd={() => onDraged()}
          >
            {props.reporterMode || (
              <>
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="currentColor">
                    <path d="M11 18c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm-2-8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
                  </svg>
                </div>
                <ui.Checkbox
                  checked={row.checked}
                  onChange={(event) => onChangeRow(row, "checked", event.target.checked)}
                />
              </>
            )}
            {
              (props.confirm || props.reporterMode)
                ? <div>{row.key}</div>
                : (
                  <div>
                    <input type="text" value={row.key} onChange={(event) => onChangeRow(row, "key", event.target.value)} placeholder={labels.keyCol} />
                  </div>
                )
            }
            <div>
              <input type="number" value={row.value || ""} onChange={(event) => onChangeRow(row, "value", event.target.value)} placeholder="0" />
            </div>
          </li>
        ))}
        <li
          key={`item${state.rows.length}`}
          data-dragged={state.rows.length === state.dragedRowIndex ? "1" : "0"}
          onDragEnter={() => setState({ ...state, dragedRowIndex: state.rows.length })}
          onDragOver={(event) => event.preventDefault()}
        ></li>
      </ul>
      {props.reporterMode || props.confirm ? (
        <div />
      ) : (
        <ui.Grid container justifyContent="flex-end" spacing={2}>
          <ui.Grid item>
            <ui.Button variant="contained" component="label" onClick={addRow}>
              {labels.add}
            </ui.Button>
          </ui.Grid>
          <ui.Grid item>
            <ui.Button
              variant="contained"
              component="label"
              onClick={deleteRows}
            >
              {labels.delete}
            </ui.Button>
          </ui.Grid>
        </ui.Grid>
      )}
    </ui.Box>
  );
}

export { GridInput };
export type { GridInputProps, GridInputRow };
