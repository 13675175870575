import react from "react";
import { useLocation } from "react-router-dom";
import * as ui from "@material-ui/core";
import * as auth from "../../auth";
import * as rest from "../../rest";
import { definitions } from "../../schema/api";
import { createStore } from "../../const/definitions/store";

import { Form } from "./form";

const labels: { [key: string]: string } = {
  succeed: "登録しました",
  failed: "登録に失敗しました",
};

type CreateState = {
  store: definitions["Store"];
};
type CreateProps = {
  showGlobalNotification?: (message: string) => void;
};

function useQuery() {
  const { search } = useLocation();

  return react.useMemo(() => new URLSearchParams(search), [search]);
}

function Create(props: CreateProps) {
  const query = useQuery();
  const initialChainId = query.get("chain_id");
  const store = createStore();

  if (initialChainId) {
    store.chain_id = parseInt(initialChainId, 10);
  }

  const [state, setState] = react.useState<CreateState>({
    store,
  });

  const onChange = (store: definitions["Store"]) => {
    state.store = store;
    setState({ ...state });
  };

  const onSubmit = () => {
    const store = new rest.Store();
    store
      .put([state.store], auth.getToken())
      .then((ret: object) => {
        props.showGlobalNotification &&
          props.showGlobalNotification(labels.succeed);
      })
      .catch(() => {
        props.showGlobalNotification &&
          props.showGlobalNotification(labels.failed);
      });
  };

  return (
    <ui.Container>
      <Form store={state.store} onChange={onChange} onSubmit={onSubmit} />
    </ui.Container>
  );
}

export { Create };
