import { Validator, validators } from "./validator";

const {
  required,
  notZero,
  // numeric,
  alphaNumericHiphenUnderScore,
  ascii,
  kanaSpace,
  postalCode,
  phone,
  looseMail,
  japanesePrefecture,
  bool,
  dateUntilToday,
} = validators;

type Rule = { [key: string]: Validator[] };

const rules: { [key: string]: Rule } = {
  store: {
    name: [required],
    name_kana: [required, kanaSpace],
    chain_id: [required],
    manual_id: [alphaNumericHiphenUnderScore],
    post_code: [postalCode],
    phone: [required, phone],
    fax: [phone],
    prefecture: [required, japanesePrefecture],
    address_1: [required],
    is_closed: [bool],
  },

  chain: {
    name: [required],
    name_kana: [required, kanaSpace],
  },

  user: {
    email: [required, looseMail],
    password: [required, ascii],
    name: [required],
  },

  client: {
    name: [required],
    name_kana: [required, kanaSpace],
    phone: [required, phone],
  },

  staff: {
    name: [required],
    name_kana: [required, kanaSpace],
    phone: [required, phone],
    email_pc: [looseMail],
  },

  client_company: {
    name: [required],
    name_kana: [required, kanaSpace],
    address: [required],
    phone: [required, phone],
    fax: [phone],
    admin_name: [required],
  },

  client_reader: {
    name: [required],
    name_kana: [required, kanaSpace],
    phone: [required, phone],
  },

  instruction: {
    title: [required],
  },

  report: {
    chain_id: [required, notZero],
    store_id: [required, notZero],
    date: [required, dateUntilToday],
  },

  notice_board: {
    title: [required],
    content: [required],
  },
};

export { rules };
export type { Rule };
