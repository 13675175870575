import react from "react";
import * as ui from "@material-ui/core";
import { useParams } from "react-router-dom";
import { definitions } from "../../schema/api";
import * as auth from "../../auth";
import * as rest from "../../rest";
import * as datetime from "../../datetime";
import { NavigatorBack } from "../../components/navigator_back";
import { Loading } from "../../components/loading";
import { controls, resources } from "../../const/resource";
import { roles } from "../../const/role";

const labels = {
  list: "一覧",
  title: "掲示板確認",
  important: "月またぎで表示する",
  attachment: "添付ファイル",
};

type ShowState = {
  initialized: boolean;
  noticeBoard: definitions["NoticeBoard"];
};

type ShowParams = {
  id: string;
};

function Show() {
  const params = useParams<ShowParams>();
  const noticeBoardId = parseInt(params.id as string, 10);

  const [state, setState] = react.useState<ShowState>({
    noticeBoard: {
      id: 0,
      client_id: 0,
      title: "",
      content: "",
    },
    initialized: false,
  });

  react.useEffect(() => {
    if (state.initialized) {
      return;
    }

    state.initialized = true;

    new rest.NoticeBoard()
      .get(noticeBoardId, auth.getToken())
      .then((json: rest.NoticeBoardGetResponse) => (state.noticeBoard = json))
      .then(() => setState({ ...state }));
  });

  if (!state.initialized) {
    return <Loading />;
  }

  return (
    <ui.Container>
      <ui.Grid
        container
        spacing={4}
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        <ui.Grid container item xs={12} spacing={0}>
          <NavigatorBack
            xs={12}
            sm={12}
            label={labels.list}
            href={`/${resources.notice_board.identifier}/${controls.retrieve.identifier}`}
          />

          <ui.Grid item xs={12}>
            <ui.Typography variant="h6">{labels.title}</ui.Typography>
          </ui.Grid>
        </ui.Grid>

        <ui.Grid container item xs={12} direction="row">
          <ui.Grid item xs={12}>
            <ui.Typography variant="subtitle1">
              {state.noticeBoard.date &&
                datetime.fromRFC3339ToYmd(state.noticeBoard.date)}
            </ui.Typography>
          </ui.Grid>
          <ui.Grid item xs={12}>
            <ui.Typography variant="h4">
              {state.noticeBoard.title}
            </ui.Typography>
          </ui.Grid>
          <ui.Grid item xs={12}>
            <ui.Typography variant="body1" style={{ whiteSpace: "pre-wrap" }}>
              {state.noticeBoard.content}
            </ui.Typography>
          </ui.Grid>
          {auth.getRole()?.roleName === roles.client.identifier && state.noticeBoard.important && (
            <ui.Grid item xs={12}>
              <ui.Typography variant="h6">
                {labels.important}
              </ui.Typography>
            </ui.Grid>
          )}
        </ui.Grid>

        {state.noticeBoard.notice_board_files &&
          state.noticeBoard.notice_board_files.length > 0 && (
            <ui.Grid
              container
              item
              xs={12}
              alignItems="flex-start"
              justifyContent="flex-start"
              direction="column"
            >
              <ui.Grid item xs={12}>
                <ui.Typography variant="subtitle1">
                  {labels.attachment}
                </ui.Typography>
              </ui.Grid>
              <ui.List>
                {state.noticeBoard.notice_board_files.map((file) => (
                  <ui.ListItem>
                    <ui.Link
                      href={`/${file.file_path}`}
                      target="_blank"
                      rel="noopener"
                    >
                      {file.file_name}
                    </ui.Link>
                  </ui.ListItem>
                ))}
              </ui.List>
            </ui.Grid>
          )}
      </ui.Grid>
    </ui.Container>
  );
}

export { Show };
