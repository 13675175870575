import react, { useContext } from "react";
import * as ui from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import * as auth from "../../auth";
import * as datetime from "../../datetime";
import * as rest from "../../rest";
import { definitions } from "../../schema/api";
import { resources, controls } from "../../const/resource";
import { RecordList } from "../../components/record_list_2";
import { YearSelector } from "../../components/year_selector";
import { MonthSelector } from "../../components/month_selector";
import { Loading } from "../../components/loading";
import { Context, RecordListInstruction } from "../../context/DataStoreContext";

import NoteAdd from "@material-ui/icons/NoteAdd";

const labels: { [key: string]: string } = {
  year: "年",
  month: "月",
  create: "新規作成",
  title: "タイトル",
  chainCount: "チェーン件数",
  fromDate: "開始日",
  toDate: "終了日",
  chainUnit: "件",
  notFound: "指定した月に指示書がありません",
};

type RetrieveState = {
  initialized: boolean;
  minYear: number;
  maxYear: number;
};

function Retrieve() {
  const today = new Date();
  const navigate = useNavigate();
  const [state, setState] = react.useState<RetrieveState>({
    initialized: false,
    minYear: today.getFullYear() - 1,
    maxYear: today.getFullYear(),
  });
  const { state: contextState, dispatch } = useContext(Context);
  const instructionState = contextState?.instruction;
  const year = instructionState?.year || today.getFullYear();
  const month = instructionState?.month || (today.getMonth() + 1);

  const onRowClicked = auth.isAuthorized(
    resources.instruction.identifier,
    auth.resourceCrudFlag.update
  )
    ? (instruction: definitions["Instruction"]) => {
      navigate(`/${resources.instruction.identifier}/${controls.update.identifier}/${instruction.id}`);
      }
    : auth.isAuthorized(
        resources.instruction.identifier,
        auth.resourceCrudFlag.retrieve
      )
    ? (instruction: definitions["Instruction"]) => {
      navigate(`/${resources.instruction.identifier}/${controls.show.identifier}/${instruction.id}`);
      }
    : undefined;

  react.useEffect(() => {
    setState({ ...state, initialized: true });
    (async () => {
      if (dispatch) {
        const json: rest.InstructionsGetYearMonthRangeResponse = await (new rest.Instruction()).getRange({}, auth.getToken());
        if (json.min.year > 0) {
          state.minYear = json.min.year;
        }
        if (json.max.year > 0) {
          state.maxYear = json.max.year;
        }
        const yearMonthPayload = { year, month };
        const currentYear = year;
        if (yearMonthPayload.year < state.minYear) {
          yearMonthPayload.year = state.minYear;
          if (currentYear !== yearMonthPayload.year) {
            yearMonthPayload.month = json.min.month;
          }
        } else if (yearMonthPayload.year > state.maxYear) {
          yearMonthPayload.year = state.maxYear;
          if (currentYear !== yearMonthPayload.year) {
            yearMonthPayload.month = json.max.month;
          }
        }
        dispatch({ type: "SET_INSTRUCTION_YEAR_MONTH", payload: yearMonthPayload });
      }
    })();
  }, []);

  react.useEffect(() => {
    (async () => {
      if (dispatch) {
        const json: rest.InstructionsGetYearMonthResponse = await (new rest.Instruction()).getYearMonth(year, month, { scope: "Chains" }, auth.getToken());
        const instructions: RecordListInstruction[] = json.map((item) => ({
          ...item,
          chainCount: item.chains?.length || 0,
          fromDate: new Date(item.from_date),
          toDate: new Date(item.to_date),
        }));
        dispatch({ type: "SET_LIST", payload: { type: "instruction", list: instructions } });
      }
    })();
  }, [year, month]);

  if (!state.initialized) {
    return <Loading />;
  }

  return (
    <ui.Container>
      <ui.Grid container spacing={4}>
        <ui.Grid item xs={12}></ui.Grid>
        <ui.Grid item xs={3} sm={1}>
          <YearSelector
            onChanged={(v) => dispatch && dispatch({ type: "SET_INSTRUCTION_YEAR_MONTH", payload: { year: v } })}
            value={year}
            min={state.minYear}
            max={state.maxYear}
          />
        </ui.Grid>
        <ui.Grid item xs={2} sm={1}>
          <MonthSelector
            onChanged={(v) => dispatch && dispatch({ type: "SET_INSTRUCTION_YEAR_MONTH", payload: { month: v } })}
            value={month}
          />
        </ui.Grid>

        <ui.Grid item xs={7} sm={10}>
          {auth.isAuthorized(
            resources.instruction.identifier,
            auth.resourceCrudFlag.create
          ) && (
            <ui.Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              height="100%"
            >
              <ui.IconButton
                onClick={(e: any) =>
                  (window.location.href = `/${resources.instruction.identifier}/${controls.create.identifier}`)
                }
              >
                <NoteAdd />
                <ui.Typography variant="body2">{labels.create}</ui.Typography>
              </ui.IconButton>
            </ui.Box>
          )}
        </ui.Grid>

        <ui.Grid item xs={6}></ui.Grid>
        <ui.Grid item xs={12}>
          <RecordList
            type="instruction"
            defaultText={labels.notFound}
            cols={[
              { label: labels.title, key: "title" },
              { label: labels.chainCount, key: "chainCount" },
              { label: labels.fromDate, key: "fromDate" },
              { label: labels.toDate, key: "toDate" },
            ]}
            onRowClicked={onRowClicked}
            extraKeyItemCallbacks={{
              chainCount: (instruction) => (
                <>{`${instruction.chainCount} ${labels.chainUnit}`}</>
              ),
              fromDate: (instruction) => (
                <>{datetime.toTextInputCalendarFormat(instruction.fromDate)}</>
              ),
              toDate: (instruction) => (
                <>{datetime.toTextInputCalendarFormat(instruction.toDate)}</>
              ),
            }}
          />
        </ui.Grid>
      </ui.Grid>
    </ui.Container>
  );
}

export { Retrieve };
