import { request, methods } from "./request";
import { paths } from "../schema/api";

type UserGetIdResponse = paths["/user/{id}"]["get"]["responses"][200]["schema"];
type UserGetMeResponse = paths["/user/me"]["get"]["responses"][200]["schema"];

class User {
  get(id: number, scopes: string[] = [], token: string = "") {
    return this.getSensitive(id, false, scopes, token);
  }
  getSensitive(
    id: number,
    sensitive: boolean,
    scopes: string[] = [],
    token: string = ""
  ) {
    return request({
      token,
      method: methods.Get,
      resource: `user/${id}`,
      params: { sensitive, scope: scopes.join(",") },
    });
  }
  getMe(
    arg1: string | string[],
    arg2: string = ""
  ): Promise<UserGetMeResponse> {
    return this.getMeSensitive(false, arg1, arg2);
  }
  getMeSensitive(
    sensitive: boolean,
    arg1: string | string[],
    arg2: string = ""
  ) {
    let token = "";
    let scopes: string[] = [];
    if (typeof arg1 === "string") {
      token = arg1;
    } else {
      scopes = arg1;
      token = arg2;
    }
    return request({
      token,
      method: methods.Get,
      resource: `user/me`,
      params: { sensitive, scope: scopes.join(",") },
    });
  }
}

export { User };
export type { UserGetIdResponse, UserGetMeResponse };
