import react from "react";
import * as ui from "@material-ui/core";
import { useParams } from "react-router-dom";
import * as auth from "../../auth";
import * as rest from "../../rest";
import { definitions } from "../../schema/api";
import { createStaff } from "../../const/definitions/staff";
import { ChainStoreSelector } from "../../components/chain_store_selector";
import { Loading } from "../../components/loading";

const labels: { [key: string]: string } = {
  submit: "登録",
  succeed: "登録しました",
  failed: "登録に失敗しました",
};

type StoreUpdateState = {
  chains: definitions["Chain"][];
  stores: definitions["Store"][];
  storesByStoreId: { [storeId: number]: definitions["Store"] };
  staff: definitions["Staff"];
  client: definitions["Client"];
  storesIdsByChainId: { [chainId: number]: number[] };
  objectiveVisitCountByStoreId: { [storeId: number]: number | null };
  initialized: boolean;
};
type StoreUpdateProps = {
  showGlobalNotification?: (message: string) => void;
};

type StoreUpdateParam = {
  id: string;
};

function StoreUpdate(props: StoreUpdateProps) {
  const params = useParams<StoreUpdateParam>();
  const staffId = parseInt(params.id as string, 10);

  const [state, setState] = react.useState<StoreUpdateState>({
    chains: [],
    stores: [],
    storesByStoreId: {},
    staff: createStaff(),
    client: {
      id: 0,
      user_id: 0,
      client_company_id: 0,
      name: "",
      name_kana: "",
      phone: "",
      remarks: "",
      client_stores: [],
    },
    storesIdsByChainId: {},
    objectiveVisitCountByStoreId: {},
    initialized: false,
  });

  const makeStoresIdsByChainId = (staff: definitions["Staff"]) => {
    const storesIdsByChainId: { [chainId: number]: number[] } = {};
    (staff.stores || []).forEach((store) => {
      if (!storesIdsByChainId[store.chain_id]) {
        storesIdsByChainId[store.chain_id] = [];
      }
      if (!store.is_closed) {
        storesIdsByChainId[store.chain_id].push(store.id);
      }
    });

    return storesIdsByChainId;
  };

  const sortChains = (chains: definitions["Chain"][], storesIdsByChainId: StoreUpdateState["storesIdsByChainId"]) => {
    chains.sort((A, B) => {
      const existsA = storesIdsByChainId[A.id] ? 1 : -1;
      const existsB = storesIdsByChainId[B.id] ? 1 : -1;

      return existsA === existsB ? A.id - B.id : existsB - existsA;
    });

    chains.forEach((chain) => {
      const storeIds = storesIdsByChainId[chain.id];
      if (storeIds) {
        chain.stores && chain.stores.sort((A, B) => {
          const existsA = storeIds.indexOf(A.id) > -1 ? 1 : -1;
          const existsB = storeIds.indexOf(B.id) > -1 ? 1 : -1;

          return existsA === existsB ? A.id - B.id : existsB - existsA;
        });
      }
    });
  };

  react.useEffect(() => {
    (async () => {
      state.staff = await new rest.Staff().get(staffId, ["Stores"], auth.getToken());
      const [client, chains, stores] = await Promise.all([
        (new rest.Client()).get(state.staff.client_id, ["ClientCompany", "Chains", "ClientStores.Store"], auth.getToken()),
        (new rest.Chain()).getByClientId(state.staff.client_id, ["Stores"], auth.getToken()),
        (new rest.Store()).getByClientId(state.staff.client_id, auth.getToken()),
      ]);

      stores.forEach((store) => {
        state.storesByStoreId[store.id] = store;
      });

      // remove stores that are not assigned to the client
      chains.forEach((chain) => {
        for (let j = chain.stores!.length - 1; j >= 0; j -= 1) {
          const store = chain.stores![j];
          if (!state.storesByStoreId[store.id]) {
            chain.stores!.splice(j, 1);
          }
        }
      });

      (client.client_stores || []).forEach((clientStore) => {
        if (clientStore.store) {
          state.objectiveVisitCountByStoreId[clientStore.store.id] = clientStore.objective_visit_count || null;
        }
      });

      const storesIdsByChainId = makeStoresIdsByChainId(state.staff);
      sortChains(chains, storesIdsByChainId);
      setState({ ...state, client, chains, stores, storesIdsByChainId, initialized: true });
    })();
  }, []);

  const onChainStoreChanged = (storeIds: { [storeId: number]: string }) => {
    state.staff.stores = Object.keys(storeIds).map(
      (id) => (state.storesByStoreId as any)[id]
    );
    const storesIdsByChainId = makeStoresIdsByChainId(state.staff);

    setState({ ...state, storesIdsByChainId });
  };

  const onSubmit = () => {
    const postStaff = { ...state.staff };
    delete postStaff.client;

    new rest.Staff()
      .post(staffId, postStaff, auth.getToken())
      .then((ret: object) => {
        props.showGlobalNotification &&
          props.showGlobalNotification(labels.succeed);
        const { chains } = state;
        sortChains(chains, state.storesIdsByChainId);
        setState({ ...state, chains });
      })
      .catch((e) => {
        props.showGlobalNotification &&
          props.showGlobalNotification(labels.failed);
      });
  };

  if (!state.initialized) {
    return <Loading />;
  }

  return (
    <ui.Container>
      <ui.Grid
        container
        spacing={0}
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        <ui.Grid container spacing={4}>
          <ui.Grid item xs={12} />
          <ui.Grid item xs={12}>
            <ui.Typography variant="h6">{`${state.staff.name}`}</ui.Typography>
          </ui.Grid>

          <ui.Grid item xs={12}>
            <ChainStoreSelector
              chains={state.chains || []}
              onChange={onChainStoreChanged}
              selectedStoreIdsByChainId={state.storesIdsByChainId}
              objectiveVisitCountByStoreId={state.objectiveVisitCountByStoreId}
              showobjectiveVisitCountConfirm
            />
          </ui.Grid>

          <ui.Grid item xs={12}>
            <ui.Button
              variant="contained"
              color="secondary"
              type="submit"
              onClick={onSubmit}
            >
              {labels.submit}
            </ui.Button>
          </ui.Grid>
        </ui.Grid>
      </ui.Grid>
    </ui.Container>
  );
}

export { StoreUpdate };
