import { definitions } from "../../schema/api";

const createClientStore = (): definitions["ClientStore"] => {
  return {
    id: 0,
    client_id: 0,
    store_id: 0,
    store_code: "",
  };
};

export { createClientStore };
