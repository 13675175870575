import react from "react";
import * as ui from "@material-ui/core";
import { useParams } from "react-router-dom";
import * as rest from "../../rest";
import * as auth from "../../auth";
import { definitions } from "../../schema/api";
import { resources, controls } from "../../const/resource";
import { currentLocale } from "../../const/locale";
import { createClient } from "../../const/definitions/client";
import { createInstruction } from "../../const/definitions/instruction";
import * as datetime from "../../datetime";
import { GridInput } from "../../components/grid_input";
import { NavigatorBack } from "../../components/navigator_back";
import { Loading } from "../../components/loading";

const labels: { [key: string]: string } = {
  list: "一覧",
  title: "指示書確認",
  clientCompany: "企業名",
  client: "担当者名",
  yearMonth: "指示書年月",
  span: "実施期間",
  chain: "対象チェーン",
  instructionTitle: "指示書タイトル",
  type: "指示種別",
  typeAchievement: "達成型(表組)",
  typeContenuouse: "継続型",
  detail: "指示詳細",
};

type ShowState = {
  initialized: boolean;
  instruction: definitions["Instruction"];
  client: definitions["Client"];
};

type ShowParams = {
  id: string;
};

function Show() {
  const params = useParams<ShowParams>();
  const instructionId = parseInt(params.id as string, 10);

  const [state, setState] = react.useState<ShowState>({
    instruction: createInstruction(),
    client: createClient(),
    initialized: false,
  });

  react.useEffect(() => {
    if (state.initialized) {
      return;
    }

    state.initialized = true;

    new rest.Instruction()
      .get(
        instructionId,
        ["Client", "InstructionListItems", "Chains"],
        auth.getToken()
      )
      .then((json: rest.InstructionGetResponse) => (state.instruction = json))
      .then(() =>
        new rest.Client().get(
          state.instruction.client_id,
          ["ClientCompany"],
          auth.getToken()
        )
      )
      .then((json: rest.ClientGetResponse) => (state.client = json))
      .then(() => setState({ ...state }));
  });

  if (!state.initialized) {
    return <Loading />;
  }

  return (
    <ui.Container>
      <ui.Grid
        container
        spacing={4}
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        <ui.Grid container item xs={12} spacing={0}>
          <NavigatorBack
            xs={12}
            sm={12}
            label={labels.list}
            href={`/${resources.instruction.identifier}/${controls.retrieve.identifier}`}
          />

          <ui.Grid item xs={12}>
            <ui.Typography variant="h6">{labels.title}</ui.Typography>
          </ui.Grid>
        </ui.Grid>

        <ui.Grid item xs={12} sm={6}>
          <ui.InputLabel id="year-id-label">
            {labels.clientCompany}
          </ui.InputLabel>
          <ui.Typography variant="h6">
            {state.client.client_company?.name || ""}
          </ui.Typography>
        </ui.Grid>

        <ui.Grid item xs={12} sm={6}>
          <ui.InputLabel id="year-id-label">{labels.client}</ui.InputLabel>
          <ui.Typography variant="h6">
            {state.instruction.client?.name || ""}
          </ui.Typography>
        </ui.Grid>

        <ui.Grid item xs={12} sm={6}>
          <ui.Grid container spacing={4}>
            <ui.Grid item xs={12} sm={4}>
              <ui.InputLabel id="year-id-label">
                {labels.yearMonth}
              </ui.InputLabel>
              <ui.Typography variant="h6">
                {`${state.instruction.year}/${state.instruction.month}`}
              </ui.Typography>
            </ui.Grid>
            <ui.Grid item xs={12} sm={8}>
              <ui.InputLabel id="year-id-label">{labels.span}</ui.InputLabel>
              <ui.Typography variant="h6">
                {datetime.toLocaleFormat(
                  new Date(state.instruction.from_date),
                  currentLocale()
                )}
                -
                {datetime.toLocaleFormat(
                  new Date(state.instruction.to_date),
                  currentLocale()
                )}
              </ui.Typography>
            </ui.Grid>
          </ui.Grid>
        </ui.Grid>

        <ui.Grid item xs={12} sm={6}>
          <ui.InputLabel id="type-id-label">{labels.type}</ui.InputLabel>
          <ui.Typography variant="h6">
            {state.instruction.type === 1
              ? labels.typeAchievement
              : state.instruction.type === 2
              ? labels.typeContenuouse
              : ""}
          </ui.Typography>
        </ui.Grid>

        <ui.Grid item xs={12} sm={6}>
          <ui.InputLabel id="type-id-label">
            {labels.instructionTitle}
          </ui.InputLabel>
          <ui.Typography variant="h6">{state.instruction.title}</ui.Typography>
        </ui.Grid>

        <ui.Grid item xs={12} sm={6}>
          <ui.InputLabel id="type-id-label">{labels.chain}</ui.InputLabel>
          {state.instruction?.chains?.map((chain) => (
            <ui.Typography key={chain.id} variant="body1">
              {chain.name}
            </ui.Typography>
          ))}
        </ui.Grid>

        <ui.Grid item xs={12} sm={6}>
          <ui.InputLabel id="type-id-label">{labels.detail}</ui.InputLabel>
          <ui.Typography variant="h6">{state.instruction.detail}</ui.Typography>
        </ui.Grid>

        <ui.Grid item xs={12} sm={6}>
          {state.instruction.type === 1 ? (
            <GridInput
              confirm
              rows={state.instruction.instruction_list_items || []}
              onRowsChanged={() => {}}
            />
          ) : (
            <></>
          )}
        </ui.Grid>

        <ui.Grid
          container
          item
          xs={12}
          alignItems="center"
          justifyContent="flex-start"
        >
          <ui.Grid item>
            <ui.Container>
              {state.instruction.file_path ? (
                <ui.Link
                  href={`/${state.instruction.file_path}`}
                  target="_blank"
                  rel="noopener"
                >
                  {state.instruction.file_name}
                </ui.Link>
              ) : (
                ""
              )}
            </ui.Container>
          </ui.Grid>
        </ui.Grid>
      </ui.Grid>
    </ui.Container>
  );
}

export { Show };
