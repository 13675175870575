import { request, methods } from "./request";
import { paths } from "../schema/api";

type WorkTimesYearMonthGetParam =
  paths["/work_times/{year}/{month}"]["get"]["parameters"]["query"];
type WorkTimesYearMonthGetResponse =
  paths["/work_times/{year}/{month}"]["get"]["responses"][200]["schema"];

type WorkTimesYearMonthDateGetParam =
  paths["/work_times/{year}/{month}/{date}"]["get"]["parameters"]["query"];
type WorkTimesYearMonthDateGetResponse =
  paths["/work_times/{year}/{month}/{date}"]["get"]["responses"][200]["schema"];

type WorkTimeYearMonthDateGetParam =
  paths["/work_time/{year}/{month}/{date}"]["get"]["parameters"]["query"];
type WorkTimeYearMonthDateGetResponse =
  paths["/work_time/{year}/{month}/{date}"]["get"]["responses"][200]["schema"];

type WorkTimeYearMonthDateBeginPostParam =
  paths["/work_time/{year}/{month}/{date}/begin"]["post"]["parameters"]["body"]["begin"];
type WorkTimeYearMonthDateBeginPostResponse =
  paths["/work_time/{year}/{month}/{date}/begin"]["post"]["responses"][200]["schema"];
type WorkTimeYearMonthDateEndPostParam =
  paths["/work_time/{year}/{month}/{date}/end"]["post"]["parameters"]["body"]["end"];
type WorkTimeYearMonthDateEndPostResponse =
  paths["/work_time/{year}/{month}/{date}/end"]["post"]["responses"][200]["schema"];

class WorkTime {
  getAllYearMonth(
    year: number,
    month: number,
    params: WorkTimesYearMonthGetParam,
    token: string = ""
  ): Promise<WorkTimesYearMonthGetResponse> {
    return request({
      token,
      params,
      method: methods.Get,
      resource: `work_times/${year}/${month}`,
    });
  }
  getYearMonthDate(
    year: number,
    month: number,
    date: number,
    params: WorkTimeYearMonthDateGetParam,
    token: string = ""
  ): Promise<WorkTimeYearMonthDateGetResponse> {
    return request({
      token,
      params,
      method: methods.Get,
      resource: `work_time/${year}/${month}/${date}`,
    });
  }
  getYearMonthDateList(
    year: number,
    month: number,
    date: number,
    params: WorkTimesYearMonthDateGetParam,
    token: string = ""
  ): Promise<WorkTimesYearMonthDateGetResponse> {
    return request({
      token,
      params,
      method: methods.Get,
      resource: `work_times/${year}/${month}/${date}`,
    });
  }
  postYearMonthDate(
    year: number,
    month: number,
    date: number,
    recordType: "begin" | "end",
    params:
      | WorkTimeYearMonthDateBeginPostParam
      | WorkTimeYearMonthDateEndPostParam,
    token: string = ""
  ): Promise<
    | WorkTimeYearMonthDateBeginPostResponse
    | WorkTimeYearMonthDateEndPostResponse
  > {
    return request({
      token,
      params,
      method: methods.Post,
      resource: `work_time/${year}/${month}/${date}/${recordType}`,
    });
  }
}

export { WorkTime };
export type {
  WorkTimesYearMonthGetParam,
  WorkTimesYearMonthGetResponse,
  WorkTimeYearMonthDateGetParam,
  WorkTimeYearMonthDateGetResponse,
  WorkTimeYearMonthDateBeginPostParam,
  WorkTimeYearMonthDateBeginPostResponse,
  WorkTimeYearMonthDateEndPostParam,
  WorkTimeYearMonthDateEndPostResponse,
};
