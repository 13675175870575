import { request, methods } from "./request";
import { paths } from "../schema/api";

type CalendarsGetYearMonthParam =
  paths["/calendars/{year}/{month}"]["get"]["parameters"]["query"];
type CalendarsPostYearMonthParam =
  paths["/calendars/{year}/{month}"]["post"]["parameters"]["body"]["calendars"];
type CalendarPostYearMonthDateParam =
  paths["/calendar/{year}/{month}/{date}"]["post"]["parameters"]["body"]["calendar"];
type CalendarsGetYearMonthResponse =
  paths["/calendars/{year}/{month}"]["get"]["responses"][200]["schema"];
type CalendarReportSummaryGetYearMonthResponse =
  paths["/calendar_report_summary/{year}/{month}"]["get"]["responses"][200]["schema"];

class Calendar {
  getYearMonth(
    year: number,
    month: number,
    params: CalendarsGetYearMonthParam,
    arg4: string[] | string,
    arg5?: string
  ): Promise<CalendarsGetYearMonthResponse> {
    let scopes = arg4 as string[];
    let token = arg5;
    if (typeof arg4 === "string") {
      token = arg4 as string;
      scopes = [];
    }
    params.scope = scopes.join(",");
    return request({
      token,
      params,
      resource: `calendars/${year}/${month}`,
      method: methods.Get,
    });
  }
  getReportSummaryYearMonth(
    year: number,
    month: number,
    clientId: number,
    token: string = ""
  ): Promise<CalendarReportSummaryGetYearMonthResponse> {
    return request({
      token,
      params: { client_id: clientId },
      resource: `calendar_report_summary/${year}/${month}`,
      method: methods.Get,
    });
  }
  postMonth(
    year: number,
    month: number,
    params: CalendarsPostYearMonthParam,
    token: string = ""
  ) {
    return request({
      token,
      params,
      resource: `calendars/${year}/${month}`,
      method: methods.Post,
    });
  }
  post(
    year: number,
    month: number,
    date: number,
    calendar: CalendarPostYearMonthDateParam,
    token: string = ""
  ) {
    return request({
      token,
      params: calendar,
      resource: `calendar/${year}/${month}/${date}`,
      method: methods.Post,
    });
  }
  postMeeting(
    year: number,
    month: number,
    date: number,
    staffId: number,
    token: string
  ) {
    return request({
      token,
      resource: `/calendar/${year}/${month}/${date}/staff/${staffId}/meeting`,
      method: methods.Post,
    });
  }
  postTraining(
    year: number,
    month: number,
    date: number,
    staffId: number,
    token: string
  ) {
    return request({
      token,
      resource: `/calendar/${year}/${month}/${date}/staff/${staffId}/training`,
      method: methods.Post,
    });
  }
}

export { Calendar };
export type {
  CalendarsGetYearMonthParam,
  CalendarsPostYearMonthParam,
  CalendarsGetYearMonthResponse,
  CalendarReportSummaryGetYearMonthResponse,
};
