import react from "react";
import * as ui from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { resources, controls } from "../../const/resource";
import * as auth from "../../auth";
import * as rest from "../../rest";
import { definitions } from "../../schema/api";
import { ClientCompanySelector } from "../../components/client_company_selector";
import { RecordList } from "../../components/record_list";
import { Loading } from "../../components/loading";

import NoteAdd from "@material-ui/icons/NoteAdd";
import Edit from "@material-ui/icons/Edit";
import Storefront from "@material-ui/icons/Storefront";

const labels: { [key: string]: string } = {
  create: "新規登録",
  storeBulkInsert: "担当店舗一括登録",
  name: "氏名",
  nameKana: "氏名カナ",
  edit: "編集",
  relationStore: "担当店舗設定",
  notFound: "管理者が登録されていません",
};

type RetrieveState = {
  initialized: boolean;
  clients: definitions["Client"][];
  clientCompanies: definitions["ClientCompany"][];
  clientCompanyId: number;
};

function Retrieve() {
  const navigate = useNavigate();

  const [state, setState] = react.useState<RetrieveState>({
    initialized: false,
    clients: [],
    clientCompanies: [],
    clientCompanyId: 0,
  });

  const oEditClicked = (clientId: number) => {
    navigate(`/${resources.client.identifier}/${controls.update.identifier}/${clientId}`);
  };

  const onRelationClicked = (clientId: number) => {
    navigate(`/${resources.client.identifier}/${resources.store.identifier}/${controls.update.identifier}/${clientId}`);
  };

  const onClientCompanyChanged = (id: number) => {
    state.clientCompanyId = id;
    refreshClients().then(() => setState({ ...state }));
  };
  const refreshClients = () => {
    return new rest.Client()
      .getAllByClientCompanyId(state.clientCompanyId, auth.getToken())
      .then((json: rest.ClientsGetResponse) => {
        state.clients = json;
      });
  };

  react.useEffect(() => {
    if (!state.initialized) {
      state.initialized = true;
      new rest.ClientCompany()
        .getAll(auth.getToken())
        .then((json: rest.ClientCompaniesGetResponse) => {
          state.clientCompanies = json;
          if (state.clientCompanies && state.clientCompanies.length > 0) {
            state.clientCompanyId = state.clientCompanies[0].id;
          }
        })
        .then(() => refreshClients())
        .then(() => setState({ ...state }));
    }
  });

  if (!state.initialized) {
    return <Loading />;
  }

  return (
    <ui.Container>
      <ui.Grid container spacing={4}>
        <ui.Grid item xs={12}></ui.Grid>
        <ui.Grid item xs={6}>
          <ClientCompanySelector
            clientCompanies={state.clientCompanies}
            selectedValue={state.clientCompanyId}
            onChanged={onClientCompanyChanged}
          />
        </ui.Grid>
        <ui.Grid item xs={6}>
          <ui.Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            height="100%"
          >
            <ui.IconButton
              onClick={(e: any) =>
                (window.location.href = `/${resources.client.identifier}/${resources.store.identifier}/${controls.bulk_insert.identifier}`)
              }
            >
              <Storefront />
              <ui.Typography variant="body2">
                {labels.storeBulkInsert}
              </ui.Typography>
            </ui.IconButton>

            {auth.isAuthorized(
              resources.client.identifier,
              auth.resourceCrudFlag.create
            ) && (
              <ui.IconButton
                onClick={(e: any) =>
                  (window.location.href = `/${resources.client.identifier}/${controls.create.identifier}?client_company_id=${state.clientCompanyId}`)
                }
              >
                <NoteAdd />
                <ui.Typography variant="body2">{labels.create}</ui.Typography>
              </ui.IconButton>
            )}
          </ui.Box>
        </ui.Grid>
        <ui.Grid item xs={12}>
          <RecordList<definitions["Client"]>
            defaultText={labels.notFound}
            cols={[
              { label: labels.name, key: "name" },
              { label: labels.nameKana, key: "name_kana" },
              { label: "", key: "control" },
            ]}
            items={state.clients}
            extraKeyItemCallbacks={{
              control: (client) => {
                if (
                  auth.isAuthorized(
                    resources.client.identifier,
                    auth.resourceCrudFlag.update
                  )
                ) {
                  return (
                    <ui.Grid container spacing={1} justifyContent="flex-end">
                      <ui.Grid item>
                        <ui.Button
                          color="primary"
                          onClick={(e: any) => oEditClicked(client.id)}
                        >
                          <ui.Grid container spacing={1}>
                            <ui.Grid item>
                              <Edit />
                            </ui.Grid>
                            <ui.Grid item>{labels.edit}</ui.Grid>
                          </ui.Grid>
                        </ui.Button>
                      </ui.Grid>
                      <ui.Grid item>
                        <ui.Button
                          color="primary"
                          onClick={(e: any) => onRelationClicked(client.id)}
                        >
                          <ui.Grid container spacing={1}>
                            <ui.Grid item>
                              <Storefront />
                            </ui.Grid>
                            <ui.Grid item>{labels.relationStore}</ui.Grid>
                          </ui.Grid>
                        </ui.Button>
                      </ui.Grid>
                    </ui.Grid>
                  );
                }
                return <></>;
              },
            }}
          />
        </ui.Grid>
      </ui.Grid>
    </ui.Container>
  );
}

export { Retrieve };
