import react from "react";
import { useParams } from "react-router-dom";
import * as ui from "@material-ui/core";
import { resources } from "../../const/resource";
import * as auth from "../../auth";
import * as rest from "../../rest";
import { definitions } from "../../schema/api";
import { Form } from "./form";
import { Loading } from "../../components/loading";

type UpdateState = {
  initialized: boolean;
  client?: definitions["Client"];
};
type UpdateProps = {
  showGlobalNotification?: (message: string) => void;
};

type UpdateParams = {
  id: string;
};

function Update(props: UpdateProps) {
  const params = useParams<UpdateParams>();
  const instructionId = parseInt(params.id as string, 10);

  const [state, setState] = react.useState<UpdateState>({
    initialized: false,
  });

  react.useEffect(() => {
    if (state.initialized) {
      return;
    }

    state.initialized = true;
    new rest.User()
      .getMe(["Client"], auth.getToken())
      .then((json: rest.UserGetIdResponse) => {
        if (!json) {
          return;
        }
        if (
          !auth.isAuthorized(
            resources.instruction.identifier,
            auth.resourceCrudFlag.update
          )
        ) {
          return;
        }

        state.client = json.client;
      })
      .then(() => {
        setState({ ...state });
      });
  });

  if (!state.initialized) {
    return <Loading />;
  }

  return (
    <ui.Container>
      <Form
        type="update"
        userClient={state.client}
        instructionId={instructionId}
        showGlobalNotification={props.showGlobalNotification}
      />
    </ui.Container>
  );
}

export { Update };
