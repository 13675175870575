import * as ui from "@material-ui/core";
import { Link } from "react-router-dom";

import Home from "@material-ui/icons/Home";

const labels: { [key: string]: string } = {
  home: "ホーム",
};

interface HomeItemProps {
  onClicked: (e: any) => void;
}

function HomeItem(props: HomeItemProps) {
  return (
    <ui.ListItem button component={Link} to="/home" onClick={props.onClicked}>
      <ui.ListItem>
        <ui.ListItemIcon>
          <Home />
        </ui.ListItemIcon>
        <ui.ListItemText>{labels.home}</ui.ListItemText>
      </ui.ListItem>
    </ui.ListItem>
  );
}

export { HomeItem };
