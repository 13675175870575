import { definitions } from "../../schema/api";

const createUser = (): definitions["User"] => {
  return {
    id: 0,
    name: "",
    password: "",
    email: "",
  };
};

export { createUser };
