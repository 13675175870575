import react from "react";
import * as ui from "@material-ui/core";
import * as auth from "../auth";
import * as rest from "../rest";
import * as datetime from "../datetime";
import { definitions } from "../schema/api";

import Notifications from "@material-ui/icons/Notifications";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";

const labels = {
  title: "お知らせ",
  noRecords: "お知らせが登録されていません",
  deleteConfirm: "お知らせを削除しますか？",
  titleLabel: "タイトル",
  bodyLabel: "本文",
  save: "保存",
};

type SystemNotificationFormProps = {
  editable?: boolean;
  date?: boolean;
};

type SystemNotificationFormState = {
  initialized: boolean;
  edit: boolean;
  systemNotification: definitions["SystemNotification"];
  defaultSystemNotification: definitions["SystemNotification"];
};

function SystemNotificationForm(props: SystemNotificationFormProps) {
  const defaultSystemNotification = {
    id: 0,
    title: "",
    body: "",
    date: "",
  };
  const [state, setState] = react.useState<SystemNotificationFormState>({
    defaultSystemNotification: { ...defaultSystemNotification },
    initialized: false,
    edit: false,
    systemNotification: { ...defaultSystemNotification },
  });

  const toggleEdit = () => {
    state.edit = !state.edit;
    if (!state.edit) {
      state.systemNotification = { ...state.defaultSystemNotification };
    }
    setState({ ...state });
  };

  const onDelete = () => {
    if (window.confirm(labels.deleteConfirm)) {
      new rest.SystemNotification().delete(auth.getToken()).then(() => {
        state.defaultSystemNotification = { ...defaultSystemNotification };
        state.systemNotification = { ...defaultSystemNotification };
        setState({ ...state });
      });
    }
  };

  const onSave = () => {
    state.systemNotification.date = datetime.toRFC3339(new Date());
    new rest.SystemNotification()
      .post(Object.assign(state.systemNotification, { id: 0 }), auth.getToken())
      .then((json) => {
        state.systemNotification = json;
        state.defaultSystemNotification = { ...state.systemNotification };
        state.edit = false;
      })
      .finally(() => {
        setState({ ...state });
      });
  };

  if (!state.initialized) {
    state.initialized = true;
    new rest.SystemNotification()
      .get()
      .then((json) => {
        state.systemNotification = json;
        state.defaultSystemNotification = { ...state.systemNotification };
      })
      .finally(() => {
        setState({ ...state });
      });
  }

  if (!props.editable && state.systemNotification.id === 0) {
    return <></>;
  }

  return (
    <ui.Grid container item xs={12} direction="column">
      <ui.Card>
        <ui.CardHeader
          avatar={<Notifications />}
          title={labels.title}
          titleTypographyProps={{ variant: "body1" }}
          style={{ paddingBottom: "4px" }}
          action={
            props.editable && (
              <>
                {!state.edit && state.systemNotification.id > 0 && (
                  <ui.IconButton onClick={() => onDelete()}>
                    <Delete />
                  </ui.IconButton>
                )}
                <ui.IconButton onClick={() => toggleEdit()}>
                  {state.edit ? <Close /> : <Edit />}
                </ui.IconButton>
              </>
            )
          }
        />
        <ui.CardContent style={{ paddingTop: "4px" }}>
          {state.edit && (
            <ui.Grid container spacing={1}>
              <ui.Grid item xs={12}>
                <ui.TextField
                  fullWidth
                  label={labels.titleLabel}
                  defaultValue={state.systemNotification.title || ""}
                  onBlur={(e) => {
                    state.systemNotification.title = e.target.value;
                    setState({ ...state });
                  }}
                />
              </ui.Grid>
              <ui.Grid item xs={12}>
                <ui.TextField
                  fullWidth
                  multiline
                  label={labels.bodyLabel}
                  variant="outlined"
                  defaultValue={state.systemNotification.body || ""}
                  minRows={3}
                  onBlur={(e) => {
                    state.systemNotification.body = e.target.value;
                    setState({ ...state });
                  }}
                />
              </ui.Grid>
              <ui.Grid container item xs={12} justifyContent="flex-end">
                <ui.Button color="primary" onClick={onSave}>
                  {labels.save}
                </ui.Button>
              </ui.Grid>
            </ui.Grid>
          )}
          {!state.edit &&
            (state.systemNotification.id > 0 ? (
              <>
                {props.date && (
                  <ui.Typography variant="subtitle2">
                    {datetime.fromRFC3339ToYmd(state.systemNotification.date)}
                  </ui.Typography>
                )}
                {state.systemNotification.title && (
                  <ui.Typography variant="h6">
                    {state.systemNotification.title}
                  </ui.Typography>
                )}
                {state.systemNotification.body && (
                  <ui.Typography
                    variant="body1"
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                    {state.systemNotification.body}
                  </ui.Typography>
                )}
              </>
            ) : (
              <ui.Typography variant="subtitle2">
                {labels.noRecords}
              </ui.Typography>
            ))}
        </ui.CardContent>
      </ui.Card>
    </ui.Grid>
  );
}

export { SystemNotificationForm };
