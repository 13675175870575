import * as ui from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { definitions } from "../../../schema/api";
import { api } from "../../../const";
import { createCalendar } from "../../../const/definitions/calendar";
import {
  activities,
  identifierToLabel,
} from "../../../const/calendar_activity";
import * as datetime from "../../../datetime";

import Comment from "@material-ui/icons/Comment";

const labels: { [key: string]: string } = {
  day: "日",
  dayHead: "日付",
  activity: "予定",
  store: "店舗",
  completed: "🌸",
  notScheduled: "予定外",
  storeUnit: "件",
  storeUnregistered: "訪問店舗未設定",
  storeNotAssigned: "担当外店舗",
  storeCountUnknown: "件数未定",
  memo: "メモ",
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    saturday: {
      color: "#6666ff",
    },
    sunday: {
      color: "#ff4444",
    },
    dayoffText: {
      color: "#ff4444",
    },
    unregisterText: {
      color: "#aaaaaa",
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
    },
    overflowlist: {
      width: "100%",
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      overflow: "auto",
      maxHeight: "50vh",
      padding: "0px",
    },
  })
);

function getCalendarActivityElement(
  storesById: { [id: number]: definitions["Store"] },
  calendar: definitions["Calendar"] | null,
  reports: definitions["Report"][],
  calendarActivities: definitions["CalendarActivity"][]
): JSX.Element {
  if (calendar === null) {
    return <>{activities.unregistered.label}</>;
  }
  const activity = calendarActivities.find(
    (a) => a.id === calendar.activity_id
  );
  if (activity === undefined) {
    return <>{activities.unregistered.label}</>;
  }

  const activityLabels = [];
  let expectedStoreIds: number[] = [];
  let actualStoreIds: number[] = [];

  switch (activity.id) {
    case activities.dayoff.value: {
      activityLabels.push(identifierToLabel(activity.name));
      break;
    }
    case activities.willwork.value: {
      expectedStoreIds = (calendar?.visits || [])
        .map((v) => v.store_id)
        .filter((v) => !!v) as number[];
      actualStoreIds = reports.map((r) => r.store_id) as number[];

      const activityLabel =
        actualStoreIds.length > 0
          ? activities.worked.identifier
          : activity.name;

      activityLabels.push(
        `${identifierToLabel(activityLabel)} ${actualStoreIds.length} ${
          labels.storeUnit
        } / ${
          expectedStoreIds.length
            ? `${expectedStoreIds.length}${labels.storeUnit}`
            : labels.storeCountUnknown
        }`
      );

      for (let i = 0; i < (calendar.visits || []).length; i += 1) {
        if (calendar.visits![i].is_meeting) {
          activityLabels.push(identifierToLabel(activities.meeting.identifier));
        } else if (calendar.visits![i].is_training) {
          activityLabels.push(
            identifierToLabel(activities.training.identifier)
          );
        }
      }
      break;
    }
    case activities.training.value: {
      activityLabels.push(identifierToLabel(activities.training.identifier));
      for (let i = 0; i < (calendar.visits || []).length; i += 1) {
        if (calendar.visits![i].is_meeting) {
          activityLabels.push(identifierToLabel(activities.meeting.identifier));
        }
      }
      break;
    }
    case activities.meeting.value: {
      activityLabels.push(identifierToLabel(activities.meeting.identifier));
      for (let i = 0; i < (calendar.visits || []).length; i += 1) {
        if (calendar.visits![i].is_training) {
          activityLabels.push(
            identifierToLabel(activities.training.identifier)
          );
        }
      }
      break;
    }
    case activities.unregistered.value: {
      activityLabels.push(
        identifierToLabel(activities.unregistered.identifier)
      );
      break;
    }
  }

  return (
    <>
      <div>{activityLabels.join(", ")}</div>
      <div>
        {getVisitStoreCountElement(
          storesById,
          calendar,
          calendarActivities,
          expectedStoreIds,
          actualStoreIds
        )}
      </div>
    </>
  );
}

function getVisitStoreCountElement(
  storesById: { [id: number]: definitions["Store"] },
  calendar: definitions["Calendar"] | null,
  calendarActivities: definitions["CalendarActivity"][],
  expectedStoreIds: number[],
  actualStoreIds: number[]
): JSX.Element {
  if (!calendar) {
    return <></>;
  }
  const willworkActivity = calendarActivities.find(
    (activity) => activity.name === activities.willwork.identifier
  );
  if (!willworkActivity) {
    return <></>;
  }
  if (calendar.activity_id !== willworkActivity.id) {
    return <></>;
  }

  if (expectedStoreIds.length === 0) {
    return <>{`(${labels.storeUnregistered})`}</>;
  }

  const storeNames: JSX.Element[] = [];

  const notScheduledIds = actualStoreIds.filter(
    (v) => expectedStoreIds.indexOf(v) === -1
  );

  for (let i = 0; i < expectedStoreIds.length; i += 1) {
    const expectedStoreId = expectedStoreIds[i];

    const storeName =
      storesById[expectedStoreId]?.name ||
      `${labels.storeNotAssigned}(ID:${expectedStoreId})`;
    storeNames.push(
      actualStoreIds.indexOf(expectedStoreId) !== -1 ? (
        <div>{`${labels.completed} ${storeName}`}</div>
      ) : (
        <div>{storeName}</div>
      )
    );
  }

  for (let i = 0; i < notScheduledIds.length; i += 1) {
    const id = notScheduledIds[i];
    const storeName =
      storesById[id]?.name || `${labels.storeNotAssigned}(ID:${id})`;
    storeNames.push(
      <div>{`${labels.completed} [${labels.notScheduled}]${storeName}`}</div>
    );
  }

  return <>{storeNames}</>;
}

type CalendarListProps = {
  year: number;
  month: number;
  daysCheckboxValues: boolean[];
  storesById: { [id: number]: definitions["Store"] };
  holidays: string[];
  calendarsByDay: (definitions["Calendar"] | null)[];
  reportsByDay: definitions["Report"][][];
  calendarActivities: definitions["CalendarActivity"][];
  onCheckAllChanged: (checked: boolean) => void;
  onDayCheckChanged: (dayIndex: number, checked: boolean) => void;
  onDayClicked: (calendar: definitions["Calendar"]) => void;
};

function getActivityClassByActivityId(
  id: number | null,
  calendarActivities: definitions["CalendarActivity"][]
): string {
  if (id === null) {
    return "unregisterText";
  }
  const activity = calendarActivities.find((a) => a.id === id);
  if (
    activity === undefined ||
    activity.name === api.calendarActivityNames.unregistered
  ) {
    return "unregisterText";
  }
  if (activity.name === api.calendarActivityNames.dayoff) {
    return "dayoffText";
  }
  return "";
}

function CalendarList(props: CalendarListProps) {
  const classes = useStyles();
  const daysInMonth = new Date(props.year, props.month, 0).getDate();
  const lastDay = new Date(props.year, props.month - 1, 0).getDay();

  const onCheckAllClicked = () => {
    props.onCheckAllChanged(props.daysCheckboxValues.indexOf(false) !== -1);
  };
  const onCheckDayClicked = (day: number) => {
    const checked = props.daysCheckboxValues[day - 1];
    if (checked === undefined) {
      return;
    }
    props.onDayCheckChanged(day - 1, !checked);
  };

  const onDayClicked = (event: any, date: number) => {
    let calendar = props.calendarsByDay[date - 1];
    if (!calendar) {
      calendar = createCalendar();
      calendar.date = date;
    }
    props.onDayClicked(calendar);
  };

  return (
    <ui.List className={classes.overflowlist}>
      <ui.ListSubheader
        key="head"
        role={undefined}
        style={{ backgroundColor: "inherit" }}
      >
        <ui.Grid container alignItems="center">
          <ui.Grid item xs={2} sm={1}>
            <ui.ListItemIcon>
              <ui.Checkbox
                edge="start"
                checked={props.daysCheckboxValues.indexOf(false) === -1}
                tabIndex={-1}
                disableRipple
                onChange={(e: any) => onCheckAllClicked()}
              />
            </ui.ListItemIcon>
          </ui.Grid>
          <ui.Grid item xs={2} sm={1}>
            <ui.ListItemText primary={labels.dayHead} />
          </ui.Grid>
          <ui.Grid item xs={6} sm={9}>
            <ui.ListItemText
              primary={labels.activity}
              secondary={labels.memo}
            />
          </ui.Grid>
          <ui.Grid item xs={2} sm={1}>
            <ui.ListItemSecondaryAction></ui.ListItemSecondaryAction>
          </ui.Grid>
        </ui.Grid>
      </ui.ListSubheader>
      {((days: number) => {
        const rows = [];
        for (let day = 1; day <= days; day += 1) {
          const weekDay = (lastDay + day) % 7;
          rows.push(
            <ui.ListItem key={`day${day}`} role={undefined} dense>
              <ui.Grid container alignItems="center">
                <ui.Grid item xs={2} sm={1}>
                  <ui.ListItemIcon>
                    <ui.Checkbox
                      edge="start"
                      checked={props.daysCheckboxValues[day - 1] || false}
                      tabIndex={-1}
                      disableRipple
                      value={day}
                      onClick={(e: any) =>
                        onCheckDayClicked(parseInt(e.target.value, 10))
                      }
                    />
                  </ui.ListItemIcon>
                </ui.Grid>
                <ui.Grid item xs={2} sm={1}>
                  <ui.ListItemText
                    className={
                      props.holidays.length >= day &&
                      props.holidays[day - 1] !== ""
                        ? classes.sunday
                        : weekDay === 0
                        ? classes.sunday
                        : weekDay === 6
                        ? classes.saturday
                        : ""
                    }
                    primary={`${day}${labels.day}(${datetime.weekDayToText(
                      weekDay
                    )})`}
                  />
                </ui.Grid>
                <ui.Grid item xs={6} sm={9}>
                  <ui.ListItemText
                    primary={
                      <span
                        className={
                          (classes as any)[
                            getActivityClassByActivityId(
                              props.calendarsByDay![day - 1]?.activity_id ||
                                null,
                              props.calendarActivities
                            )
                          ] || ""
                        }
                      >
                        {getCalendarActivityElement(
                          props.storesById,
                          props.calendarsByDay[day - 1],
                          props.reportsByDay[day - 1] || [],
                          props.calendarActivities
                        )}
                      </span>
                    }
                    secondary={props.calendarsByDay[day - 1]?.memo || ""}
                  />
                </ui.Grid>
                <ui.Grid item xs={2} sm={1}>
                  <ui.ListItemSecondaryAction>
                    <ui.IconButton
                      edge="end"
                      aria-label="memos"
                      onClick={(e) => onDayClicked(e, day)}
                    >
                      <Comment />
                    </ui.IconButton>
                  </ui.ListItemSecondaryAction>
                </ui.Grid>
              </ui.Grid>
            </ui.ListItem>
          );
        }
        return rows;
      })(daysInMonth)}
    </ui.List>
  );
}

export { CalendarList };
