import react from "react";
import * as ui from "@material-ui/core";
import * as auth from "../../auth";
import * as rest from "../../rest";
import { definitions } from "../../schema/api";
import { createStore } from "../../const/definitions/store";
import { rules } from "../../validation/rules";
import { CsvTable } from "../../components/csv_table";
import * as format from "../../strings/format";

const labels = {
  chainName: "チェーン名",
  chainIdSample: "チェーンマスタに登録されているチェーン名を入力",
  chainIdInvalid: "(存在しないチェーン名)",
  closed: "閉店",
  succeed: "一括登録に成功しました",
  failed: "一括登録に失敗しました",
};

const storePropertyLabelMap: { [name: string]: string } = {
  chain_id: "チェーン名",
  name: "店舗名",
  name_kana: "店舗名カナ",
  phone: "電話番号",
  fax: "FAX",
  post_code: "郵便番号",
  prefecture: "都道府県",
  address_1: "住所1",
  address_2: "住所2",
  address_3: "住所3",
  remarks: "備考",
  is_closed: "閉店フラグ",
};

type BulkInsertState = {
  chains: definitions["Chain"][];
  chainIdMap: { [name: string]: number };
  chainNameMap: { [id: number]: string };
  initialized: boolean;
};
type BulkInsertProps = {
  showGlobalNotification?: (message: string) => void;
};

function renderColumnTitle(col: string): string {
  if (col === "chain_id") {
    return labels.chainName;
  }
  return (storePropertyLabelMap as any)[col];
}

function BulkInsert(props: BulkInsertProps) {
  const [state, setState] = react.useState<BulkInsertState>({
    chains: [],
    chainIdMap: {},
    chainNameMap: {},
    initialized: false,
  });

  react.useEffect(() => {
    if (!state.initialized) {
      state.initialized = true;
      new rest.Chain()
        .getAll(auth.getToken())
        .then((json: rest.ChainsGetResponse) => {
          state.chains = json;
          for (let i = 0; i < json.length; i += 1) {
            const chain = json[i];
            state.chainIdMap[chain.name] = chain.id;
            state.chainNameMap[chain.id] = chain.name;
          }
          setState({ ...state });
        });
    }
  });

  const storeTemplate: definitions["Store"] = createStore();

  const dataMediator = (
    col: string,
    val: string,
    ref: definitions["Store"]
  ) => {
    switch (col) {
      case "chain_id": {
        const chainId = state.chainIdMap[val];
        if (chainId) {
          ref.chain_id = chainId;
        }
        break;
      }
      case "name":
      case "name_kana":
      case "address_1":
      case "address_2":
      case "address_3": {
        (ref as any)[col] = format.trim(format.singleByteSpace(val));
        break;
      }
      case "phone":
      case "fax":
      case "post_code": {
        (ref as any)[col] = format.phone(val);
        break;
      }
      case "is_closed": {
        ref.is_closed = val !== "";
        break;
      }
      default: {
        (ref as any)[col] = val;
        break;
      }
    }
  };

  const renderSampleValue = (col: string) => {
    if (col === "chain_id") {
      return labels.chainIdSample;
    }
    return null;
  };

  const renderColumnData = (col: string, store: definitions["Store"]) =>
    col === "chain_id"
      ? store.chain_id && state.chainNameMap[store.chain_id]
        ? state.chainNameMap[store.chain_id]
        : labels.chainIdInvalid
      : col === "is_closed"
      ? store.is_closed
        ? labels.closed
        : ""
      : (store as any)[col];

  const onRegister = (stores: definitions["Store"][]) => {
    const store = new rest.Store();
    store
      .put(stores, auth.getToken())
      .then((ret: object) => {
        props.showGlobalNotification &&
          props.showGlobalNotification(labels.succeed);
      })
      .catch(() => {
        props.showGlobalNotification &&
          props.showGlobalNotification(labels.failed);
      });
  };

  return (
    <ui.Container>
      <ui.Grid container spacing={2} xs={12}>
        <ui.Grid item xs={12} />
        <CsvTable<definitions["Store"]>
          colLabels={storePropertyLabelMap}
          default={storeTemplate}
          rule={rules.store}
          titleMediator={renderColumnTitle}
          dataMediator={dataMediator}
          sampleMediator={renderSampleValue}
          dataRenderer={renderColumnData}
          onRegister={onRegister}
        />
      </ui.Grid>
    </ui.Container>
  );
}

export { BulkInsert };
