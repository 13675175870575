function downloadAsFile(
  content: Buffer | Blob | string,
  fileName: string
): void {
  const blob = new Blob([content]);
  const url = window.URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link.parentNode!.removeChild(link);

  window.URL.revokeObjectURL(url);
}

export { downloadAsFile };
