import react from "react";
import * as ui from "@material-ui/core";
import { definitions } from "../../../schema/api";
import {
  activities,
  identifierToSymbol,
} from "../../../const/calendar_activity";
import { makeStyles } from "@material-ui/core/styles";

import ArrowDropUp from "@material-ui/icons/ArrowDropUp";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";

type Sort = 1 | 2;

const sort: { [key: string]: Sort } = {
  asc: 1,
  desc: 2,
};

const widthStickyCol1 = 180;
const widthStickyCol2 = 180;
const widthStickyCol3 = 120;
const widthStickyCol4 = 64;
const widthStickyCol5 = 64;
const widthDays = 32;
const paddingSticky = 12;
const paddingDays = 4;

const useStyles = makeStyles((theme) => {
  return {
    saturday: {
      color: "#6666ff",
    },
    sunday: {
      color: "#ff4444",
    },
    dayoff: {
      color: "#ff4444",
    },
    table: {
      maxWidth: "100%",
      maxHeight: `${window.innerHeight - 300}px`,

      overflow: "scroll",

      "&::-webkit-scrollbar": {
        "&:horizontal": {
          height: "12px",
        },
        "&:vertical": {
          width: "12px",
        },
        "-webkit-appearance": "none",
        backgroundColor: theme.palette.background.default,
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "8px",
        border: "2px solid white",
        backgroundColor: "rgba(0, 0, 0, .5)",
      },
    },
    tableHeader: {
      top: 0,
      position: "sticky",
      backgroundColor: theme.palette.background.default,
    },
    tableStickyHeader: {
      zIndex: 3,
    },
    tableHorizontalSticky: {
      position: "sticky",
      paddingLeft: `${paddingSticky}px`,
      paddingRight: `${paddingSticky}px`,
    },
    tableRow: {
      zIndex: 2,
      backgroundColor: "#fff",
    },
    tableColDays: {
      textAlign: "center",
      width: `${widthDays}px`,
      paddingLeft: `${paddingDays}px`,
      paddingRight: `${paddingDays}px`,
      "&:last-child": {
        paddingRight: `${paddingDays}px`,
      },
    },
    tableColSticky1: {
      left: 0,
      width: `${widthStickyCol1}px`,
    },
    tableColSticky2: {
      left: `${widthStickyCol1 + paddingSticky * 2}px`,
      width: `${widthStickyCol2}px`,
    },
    tableColSticky3: {
      left: `${widthStickyCol1 + widthStickyCol2 + paddingSticky * 4}px`,
      width: `${widthStickyCol3}px`,
    },
    tableColSticky4: {
      left: `${
        widthStickyCol1 + widthStickyCol2 + widthStickyCol3 + paddingSticky * 6
      }px`,
      width: `${widthStickyCol4}px`,
      textAlign: "center",
    },
    tableColSticky5: {
      left: `${
        widthStickyCol1 +
        widthStickyCol2 +
        widthStickyCol3 +
        widthStickyCol4 +
        paddingSticky * 8
      }px`,
      width: `${widthStickyCol5}px`,
      textAlign: "center",
    },
  };
});

const labels: { [key: string]: string } = {
  instructionCol: "指示名",
  chainCol: "チェーン",
  storeCol: "店舗",
  staffCol: "スタッフ",
  dateCol: "日付",
  willworkCol: "予定",
  actualCol: "実績",
  targetCol: "目標",
  keyPlaceholder: "項目",
  valPlaceholder: "数量",
  meeting: "会議",
  training: "研修",
  na: "-",
};

type CalendarGridSource =
  definitions["ResponseCalendarReportSummaryYearMonth"][];

type Col = {
  component: react.ComponentType;
  classNames?: string[];
  identifier: string;
  label: string;
};

type RenderRow = {
  component: react.ComponentType;
  classNames?: string[];
  value: string;
};

type Row = {
  id: number;
  chain_id: number;
  store_id: number;
  store_name: RenderRow;
  chain_name: RenderRow;
  staff_name: RenderRow;
  target_count: RenderRow;
  actual_count: RenderRow;
} & { [day: number]: RenderRow };

type CalendarGridState = {
  sorts: { [key: string]: number };
  sortFunc: (a: Row, b: Row) => number;
};

interface CalendarGridProps {
  year: number;
  month: number;
  src: CalendarGridSource;
}

function createRows(
  src: CalendarGridSource,
  days: number,
  dayoffClass: string
): Row[] {
  const rows: Row[] = [];

  const dayoffByStaffId: { [staffId: number]: number[] } = {};

  for (let i = 0; i < src.length; i += 1) {
    const summary = src[i];
    if (!dayoffByStaffId[summary.staff_id]) {
      dayoffByStaffId[summary.staff_id] = [];
    }
    for (let dayIndex = 0; dayIndex < days; dayIndex += 1) {
      const calendar = summary.calendars[dayIndex];
      const day = dayIndex + 1;
      if (calendar.calendar_activity?.id === activities.dayoff.value) {
        dayoffByStaffId[summary.staff_id].push(day);
      }
    }
  }

  for (let i = 0; i < src.length; i += 1) {
    const summary = src[i];
    const row: Row = {
      id: i,
      chain_id: summary.chain_id,
      store_id: summary.store_id,
      chain_name: {
        component: ui.Box,
        value: summary.chain_name,
      },
      store_name: {
        component: ui.Box,
        value: summary.store_name,
      },
      staff_name: {
        component: ui.Box,
        value: summary.staff_name,
      },
      target_count: {
        component: ui.Box,
        value: `${summary.target_days || labels.na}`,
      },
      actual_count: {
        component: ui.Box,
        value: `${summary.actualwork_days}`,
      },
    };

    for (let dayIndex = 0; dayIndex < days; dayIndex += 1) {
      const calendar = summary.calendars[dayIndex];
      const day = dayIndex + 1;

      const dayOffIndex = dayoffByStaffId[summary.staff_id].indexOf(day);

      const rowDay: RenderRow = {
        component: ui.Box,
        value: "",
      };
      if (dayOffIndex !== -1) {
        rowDay.value = identifierToSymbol(activities.dayoff.identifier);
        rowDay.classNames = [dayoffClass];
      } else if (summary.is_meeting) {
        row.chain_name.value = labels.na;
        row.store_name.value = labels.meeting;
        row.chain_id = 9998;
        rowDay.value = identifierToSymbol(calendar.calendar_activity!.name);
        row.target_count.value = labels.na;
        row.actual_count.value = labels.na;
      } else if (summary.is_training) {
        row.chain_name.value = labels.na;
        row.store_name.value = labels.training;
        row.chain_id = 9999;
        rowDay.value = identifierToSymbol(calendar.calendar_activity!.name);
        row.target_count.value = labels.na;
        row.actual_count.value = labels.na;
      } else if (calendar.calendar_activity) {
        rowDay.value = identifierToSymbol(calendar.calendar_activity!.name);
      }

      (row as any)[day] = rowDay;
    }

    rows.push(row);
  }
  return rows;
}

const defaultCols = (): Col[] => {
  return [
    {
      identifier: "chain_name",
      component: ui.Box,
      label: labels.chainCol,
    },
    {
      identifier: "store_name",
      component: ui.Box,
      label: labels.storeCol,
    },
    {
      identifier: "staff_name",
      component: ui.Box,
      label: labels.staffCol,
    },
    {
      identifier: "target_count",
      component: ui.Box,
      label: labels.targetCol,
    },
    {
      identifier: "actual_count",
      component: ui.Box,
      label: labels.actualCol,
    },
  ];
};

function CalendarGrid(props: CalendarGridProps) {
  const classes = useStyles();

  const cols: Col[] = defaultCols();

  const sorts: { [key: string]: Sort } = {};
  for (let i = 0; i <= 2; i += 1) {
    sorts[cols[i].identifier] = sort.asc;
  }

  const [state, setState] = react.useState<CalendarGridState>({
    sorts,
    sortFunc: (l: Row, r: Row) => {
      return l.chain_id - r.chain_id || l.store_id - r.store_id;
    },
  });

  const toggleSort = (key: string) => {
    const keys = Object.keys(state.sorts);
    for (let i = 0; i < keys.length; i += 1) {
      state.sorts[keys[i]] =
        key === keys[i]
          ? state.sorts[keys[i]] === sort.asc
            ? sort.desc
            : sort.asc
          : sort.asc;
    }

    if (state.sorts[key] === sort.asc) {
      state.sortFunc = (l: any, r: any) => {
        return l[key] < r[key] ? -1 : 1;
      };
    } else {
      state.sortFunc = (l: any, r: any) => {
        return l[key] > r[key] ? -1 : 1;
      };
    }

    setState({ ...state });
  };

  const lastDate = new Date(props.year, props.month, 0).getDate();
  const lastDay = new Date(props.year, props.month - 1, 0).getDay();
  for (let day = 1; day <= lastDate; day += 1) {
    const weekDay = (lastDay + day) % 7;

    const classNames = [];
    if (weekDay === 0) {
      classNames.push(classes.sunday);
    } else if (weekDay === 6) {
      classNames.push(classes.saturday);
    }
    cols.push({
      classNames,
      identifier: `${day}`,
      component: ui.Box,
      label: `${day}`,
    });
  }

  const rows = createRows(props.src, lastDate, classes.dayoff);
  rows.sort(state.sortFunc);

  const renderHeader = (
    col: Col,
    index: number,
    classes: { [key: string]: string }
  ) => {
    const classNames = [classes.tableHeader];
    if (index <= 4) {
      classNames.push(classes.tableStickyHeader);
      classNames.push(classes.tableHorizontalSticky);
      switch (index) {
        case 0:
          classNames.push(classes.tableColSticky1);
          break;
        case 1:
          classNames.push(classes.tableColSticky2);
          break;
        case 2:
          classNames.push(classes.tableColSticky3);
          break;
        case 3:
          classNames.push(classes.tableColSticky4);
          break;
        default:
          classNames.push(classes.tableColSticky5);
          break;
      }
    } else {
      classNames.push(classes.tableColDays);
    }
    // tslint:disable-next-line:variable-name
    const Komponent = col.component as any;
    return (
      <ui.TableCell key={col.identifier} className={classNames.join(" ")}>
        <Komponent className={col.classNames}>
          {col.label}
          {index <= 2 && (
            <ui.IconButton onClick={() => toggleSort(col.identifier)}>
              {state.sorts[col.identifier] === sort.asc ? (
                <ArrowDropDown />
              ) : (
                <ArrowDropUp />
              )}
            </ui.IconButton>
          )}
        </Komponent>
      </ui.TableCell>
    );
  };
  const renderDays = (row: Row, classes: { [key: string]: string }) => {
    const keys = cols.map((col) => col.identifier);

    const summary: JSX.Element[] = [];
    for (let i = 0; i < keys.length; i += 1) {
      const name = keys[i];
      const classNames = [classes.tableRow];
      if (i <= 4) {
        classNames.push(classes.tableHorizontalSticky);
        switch (i) {
          case 0:
            classNames.push(classes.tableColSticky1);
            break;
          case 1:
            classNames.push(classes.tableColSticky2);
            break;
          case 2:
            classNames.push(classes.tableColSticky3);
            break;
          case 3:
            classNames.push(classes.tableColSticky4);
            break;
          default:
            classNames.push(classes.tableColSticky5);
            break;
        }
      } else {
        classNames.push(classes.tableColDays);
      }
      // tslint:disable-next-line:variable-name
      const Komponent = (row as any)[name].component;

      summary.push(
        <ui.TableCell
          key={`${name}-${row.id}`}
          className={classNames.join(" ")}
        >
          <Komponent
            className={(row as any)[name].classNames}
            children={(row as any)[name].value || labels.na}
          />
        </ui.TableCell>
      );
    }

    return <ui.TableRow key={row.id}>{summary}</ui.TableRow>;
  };

  return (
    <ui.TableContainer component={ui.Paper} className={classes.table}>
      <ui.Table
        aria-label="calendar-table"
        size="small"
        style={{
          minWidth: `${
            widthStickyCol1 +
            widthStickyCol2 +
            widthStickyCol3 +
            widthStickyCol4 +
            widthStickyCol5 +
            paddingSticky * 10 +
            widthDays * lastDate +
            paddingDays * lastDate * 2
          }px`,
        }}
      >
        <ui.TableHead>
          <ui.TableRow>
            {cols.map((col, i) => renderHeader(col, i, classes))}
          </ui.TableRow>
        </ui.TableHead>
        <ui.TableBody>
          {rows.map((row) => renderDays(row, classes))}
        </ui.TableBody>
      </ui.Table>
    </ui.TableContainer>
  );
}

export { CalendarGrid, createRows, defaultCols };
export type { CalendarGridProps, CalendarGridSource };
