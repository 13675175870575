import { definitions } from "../../schema/api";

const createReportImage = (): definitions["ReportImage"] => {
  return {
    id: 0,
    report_id: 0,
    type: 0,
    name: "",
    path: "",
    comment: "",
    content: "",
  };
};

export { createReportImage };
