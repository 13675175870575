import react from "react";
import * as ui from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import * as auth from "../../auth";
import * as rest from "../../rest";
import { createReport } from "../../const/definitions/report";
import { resources, controls } from "../../const/resource";
import { reportDeletableDuration } from "../../const/report";
import { roles } from "../../const/role";
import * as datetime from "../../datetime";
import { definitions } from "../../schema/api";
import { createFormValidator, FormValidator } from "../../validation/form";
import { CommonInstructionReport } from "./form/common_instruction_report";
import { IndividualInstructionReports } from "./form/individual_instruction_reports";
import { ReportImageInput } from "./form/report_image_input";
import { NavigatorBack } from "../../components/navigator_back";
import { IndicatorButton } from "../../components/indicator_button";
import { Loading } from "../../components/loading";

const labels: { [key: string]: string } = {
  list: "一覧",
  selectStaff: "[システム管理者メニュー]スタッフ選択",
  commonTitle: "共通項目",
  individualTitle: "指示書個別項目",
  reportSuccess: "作業報告を送信しました",
  staffNotRegistered: "スタッフが登録されていません",
  edit: "編集",
  confirm: "更新",
  submit: "送信",
  delete: "削除",
  submitting: "送信中...",
  deleteConfirm: "この日報を削除しますか？",
};

type FormState = {
  initialized: boolean;
  validator: FormValidator;
  confirm: boolean;
  isRoot: boolean;
  myStaffId?: number;
  currentStaff?: definitions["Staff"];
  staffs?: definitions["Staff"][];
  report: definitions["Report"];
  instructions: definitions["Instruction"][];
  reportImages: definitions["ReportImage"][];
  excludingFinishedInstruction: boolean;
};

type FormProps = {
  report?: definitions["Report"];
  onSubmit: (report: definitions["Report"]) => Promise<any>;
  showGlobalNotification?: (message: string) => void;
  submitting: boolean;
};

function Form(props: FormProps) {
  const [state, setState] = react.useState<FormState>({
    initialized: false,
    validator: createFormValidator("report"),
    confirm: false,
    isRoot: false,
    report: createReport(),
    instructions: [],
    reportImages: [],
    excludingFinishedInstruction: false,
  });

  const navigate = useNavigate();

  const defaultStoreId = props.report?.store_id || 0;

  const onCancel = () => {
    state.confirm = false;
    setState({ ...state });
  };

  const onSubmit = (event: React.SyntheticEvent) => {
    if (!state.confirm) {
      state.confirm = true;
      setState({ ...state });
      return;
    }

    props.onSubmit(state.report);
  };

  const onDeleteClicked = () => {
    if (window.confirm(labels.deleteConfirm)) {
      new rest.Report().delete(state.report.id, auth.getToken()).then(() => {
        navigate(`/${resources.report.identifier}/${controls.retrieve.identifier}`);
      });
    }
  };

  const refreshIndividualReports = (instructions: definitions["Instruction"][]) => {
    state.report.individual_reports = instructions.flatMap(
      (instruction) => instruction.is_finished ? [] : ({
        id: 0,
        report_id: 0,
        instruction_id: instruction.id,
        result: 0,
        comment: "",
        individual_report_list_items: (instruction.instruction_list_items || [])
          .map(({ id, key }) => ({
            id: 0,
            individual_report_id: 0,
            instruction_list_item_id: id,
            key,
            value: 0,
          })),
      })
    );
  };

  const refreshInstruction = async () => {
    const params: rest.InstructionsGetYearMonthDateParam = {
      staff_id: state.report.staff_id,
      chain_id: state.report.chain_id,
    };
    const excludingFinishedInstruction = !!state.report.store_id && defaultStoreId !== state.report.store_id;
    if (excludingFinishedInstruction) {
      params.store_id = state.report.store_id;
      params.filter_finished_instruction = true;
    }
    const date = new Date(state.report.date);
    const json: rest.InstructionsGetYearMonthDateResponse = await (new rest.Instruction()).getYearMonthDate(
      date.getFullYear(),
      date.getMonth() + 1,
      date.getDate(),
      params,
      ["InstructionListItems"],
      auth.getToken()
    );
    state.instructions = json;
    state.excludingFinishedInstruction = excludingFinishedInstruction;
  };

  const onDateChanged = async (date: Date) => {
    state.report.date = datetime.toRFC3339(date);
    await refreshInstruction();
    refreshIndividualReports(state.instructions);
  };

  const onStaffChanged = (staffId: number) => {
    if (!state.staffs) {
      return;
    }
    state.currentStaff = state.staffs.find((staff) => staff.id === staffId);
    if (!state.currentStaff) {
      return;
    }
    state.report.staff_id = state.currentStaff.id;
    if (!props.report) {
      state.report.chain_id = 0;
      state.report.store_id = 0;
    }
  };

  const onCommonReportChanged = (report: definitions["Report"]) => {
    state.report = report;
    setState({ ...state });
  };
  const onCommonReportChangedWithInstructionChanges = async (report: definitions["Report"]) => {
    state.report = report;
    await refreshInstruction();
    refreshIndividualReports(state.instructions);
    setState({ ...state });
  };
  const onIndividualReportChanged = (
    reports: definitions["IndividualReport"][]
  ) => {
    state.report.individual_reports = reports;
    setState({ ...state });
  };

  const onReportImagesChanged = (images: definitions["ReportImage"][]) => {
    state.reportImages = images;
    setState({ ...state });
  };

  react.useEffect(() => {
    if (state.initialized) {
      return;
    }

    (async () => {
      if (props.report) {
        state.report = { ...props.report };
      }

      const staff: rest.UserGetIdResponse = await (new rest.User()).getMe(["Staff"], auth.getToken());
      const authenticated = auth.isAuthorized(
        resources.report.identifier,
        auth.resourceCrudFlag.update
      );

      if (!authenticated) {
        return;
      }

      const staffId = staff.staff?.id || 0;
      // staff role has fixed staff
      if (staffId > 0) {
        state.myStaffId = staffId;
      } else {
        state.isRoot = true;
      }

      state.validator.validateAll(state.report);

      const instructionIds = state.report.individual_reports.map((report) => report.instruction_id);
      const instructionScopes = ["InstructionListItems"];
      const staffScopes = ["Client", "Client.ClientCompany", "Client.Chains", "Stores"];
      state.instructions = instructionIds.length
        ? await (new rest.Instruction()).getByIds(instructionIds, instructionScopes, auth.getToken())
        : [];
      if (staffId > 0) {
        state.currentStaff = await (new rest.Staff()).get(staffId, staffScopes, auth.getToken());
        state.report.staff_id = state.currentStaff.id;
        onStaffChanged(state.report.staff_id);
      } else {
        state.staffs = await (new rest.Staff()).getAll(staffScopes, auth.getToken()) || [];
        if (state.staffs.length) {
          onStaffChanged(state.report?.staff_id || state.staffs[0].id);
        } else {
          props.showGlobalNotification?.(labels.staffNotRegistered);
        }
      }

      if (props.report) {
        // get instructions by ids
        state.reportImages = props.report.report_images.map((reportImage) => ({
          id: reportImage.id,
          report_id: props.report!.id,
          instruction_id: reportImage.instruction_id,
          type: reportImage.type,
          name: reportImage.name,
          path: `/${reportImage.path}`,
          comment: reportImage.comment,
          content: "",
        }));
        state.validator.validateAll(state.report);
      } else {
        await onDateChanged(new Date(state.report.date));
        refreshIndividualReports(state.instructions);
      }

      if (state.report.id > 0 && state.report.store_id) {
        state.report.store = await (new rest.Store()).get(state.report.store_id, auth.getToken());
      }

      state.initialized = true;

      setState({ ...state });
    })();
  }, []);

  if (!state.initialized) {
    return <Loading />;
  }

  const roleName = auth.getRole()?.roleName;

  let deletable = auth.isAuthorized(
    resources.report.identifier,
    auth.resourceCrudFlag.delete
  );

  const seconds =
    (new Date().getTime() - new Date(state.report.date).getTime()) / 1000;

  if (roleName === roles.staff.identifier) {
    deletable =
      state.report.id > 0 &&
      state.report.staff_id === state.myStaffId &&
      seconds < reportDeletableDuration;
  }

  return (
    <ui.Container>
      <ui.Grid
        container
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        <ui.Grid item xs={12}></ui.Grid>
        <ui.Grid item xs={12}>
          <NavigatorBack xs={12} sm={12} label={labels.list} back />
        </ui.Grid>
        <ui.Grid item xs={12}>
          <CommonInstructionReport
            validator={state.validator}
            confirm={state.confirm}
            staffs={state.staffs}
            report={state.report}
            staff={state.currentStaff}
            onDateChanged={async (date: Date) => {
              await onDateChanged(date);
              setState({ ...state });
            }}
            onStaffChanged={async (staffId: number) => {
              onStaffChanged(staffId);
              await refreshInstruction();
              refreshIndividualReports(state.instructions);
              setState({ ...state });
            }}
            onReportChanged={onCommonReportChanged}
            onReportChangedWithInstructionChanges={
              onCommonReportChangedWithInstructionChanges
            }
          />
        </ui.Grid>
        <ui.Grid item xs={12}>
          <IndividualInstructionReports
            confirm={state.confirm}
            instructions={
              state.excludingFinishedInstruction
                ? state.instructions.filter((i) => !i.is_finished)
                : state.instructions
            }
            individualReports={
              !state.report.is_meeting && !state.report.is_training
                ? state.report.individual_reports
                : []
            }
            onChanged={onIndividualReportChanged}
          />
        </ui.Grid>
        <ui.Grid item xs={12}>
          <ReportImageInput
            reportId={state.report.id}
            instructions={state.instructions}
            confirm={state.confirm}
            images={state.report.report_images}
            onFileChanged={onReportImagesChanged}
          />
        </ui.Grid>

        <ui.Grid container item xs={12} spacing={2} justifyContent="flex-start">
          {state.confirm && !props.submitting && (
            <ui.Grid item>
              <ui.Button variant="contained" type="submit" onClick={onCancel}>
                {labels.edit}
              </ui.Button>
            </ui.Grid>
          )}

          <IndicatorButton
            showIndicator={props.submitting}
            indicator={{
              label: labels.submitting,
            }}
            button={Object.assign(state.validator.submitButtonProps, {
              onClick: onSubmit,
              label: state.confirm ? labels.submit : labels.confirm,
            })}
          />
          {deletable && (
            <ui.Grid item>
              <ui.Button
                variant="contained"
                color="secondary"
                type="submit"
                onClick={onDeleteClicked}
              >
                {labels.delete}
              </ui.Button>
            </ui.Grid>
          )}
        </ui.Grid>
      </ui.Grid>
    </ui.Container>
  );
}

export { Form };
