import react from "react";
import * as ui from "@material-ui/core";
import * as auth from "../../auth";
import * as rest from "../../rest";
import { definitions } from "../../schema/api";
import { rules } from "../../validation/rules";
import { ValidationResult } from "../../validation/validator";
import { createUser } from "../../const/definitions/user";
import { createStaff } from "../../const/definitions/staff";
import { CsvTable } from "../../components/csv_table";
import * as format from "../../strings/format";

const labels = {
  clientName: "担当者名",
  clientIdSample: "管理者として登録されている氏名を入力",
  clientIdInvalid: "(存在しない管理者名です)",
  invalidEmailReason: "ログイン用メールアドレスに重複があります",
  succeed: "一括登録に成功しました",
  failed: "一括登録に失敗しました",
};

const staffPropertyLabelMap: { [name: string]: string } = {
  email: "ログイン用メールアドレス",
  password: "ログイン用パスワード",
  client_id: "担当者 ID",
  name: "氏名",
  name_kana: "氏名カナ",
  phone: "電話番号",
  group: "グループ",
  area: "エリア",
  email_pc: "メールアドレス (PC)",
};

type StaffUser = definitions["Staff"] & definitions["User"];

type BulkInsertState = {
  clients: definitions["Client"][];
  clientIdMap: { [name: string]: number };
  clientNameMap: { [id: number]: string };
  initialized: boolean;
};
type BulkInsertProps = {
  showGlobalNotification?: (message: string) => void;
};

function renderColumnTitle(col: string): string {
  if (col === "client_id") {
    return labels.clientName;
  }
  return (staffPropertyLabelMap as any)[col];
}

function BulkInsert(props: BulkInsertProps) {
  const [state, setState] = react.useState<BulkInsertState>({
    clients: [],
    clientIdMap: {},
    clientNameMap: {},
    initialized: false,
  });

  const staffTemplate: StaffUser = Object.assign(createUser(), createStaff());

  const dataMediator = (col: string, val: string, ref: StaffUser) => {
    switch (col) {
      case "client_id": {
        const clientId = state.clientIdMap[val];
        if (clientId) {
          ref.client_id = clientId;
        }
        break;
      }
      case "name":
      case "name_kana":
      case "group":
      case "area": {
        (ref as any)[col] = format.trim(format.singleByteSpace(val));
        break;
      }
      case "phone": {
        (ref as any)[col] = format.phone(val);
        break;
      }
      case "email_pc": {
        (ref as any)[col] = format.looseEmail(val);
        break;
      }
      default: {
        (ref as any)[col] = val;
        break;
      }
    }
  };

  const renderSampleValue = (col: string) => {
    if (col === "client_id") {
      return labels.clientIdSample;
    }
    return null;
  };

  const renderColumnData = (col: string, staff: StaffUser) =>
    col === "client_id"
      ? staff.client_id && state.clientNameMap[staff.client_id]
        ? state.clientNameMap[staff.client_id]
        : labels.clientIdInvalid
      : (staff as any)[col];

  const validateUniqueEmail = (
    rows: string[][]
  ): { [rowNumber: number]: ValidationResult } => {
    const errors: { [rowNumber: number]: ValidationResult } = {};
    const emails = [];
    // ignore header
    for (let i = 1; i < rows.length; i += 1) {
      const row = rows[i] as string[];
      // ignore empty line
      if (row.length === 1 && row[0] === "") {
        continue;
      }

      const email = row[0];

      if (emails.indexOf(email) !== -1) {
        if (!errors[i]) {
          errors[i] = {};
        }
        errors[i].email = {
          valid: false,
          reason: labels.invalidEmailReason,
        };
      }

      emails.push(email);

      const clientName = row[2];
      if (
        !state.clientIdMap[clientName] ||
        state.clientIdMap[clientName] === 0
      ) {
        if (!errors[i]) {
          errors[i] = {};
        }
        errors[i].client_id = {
          valid: false,
          reason: labels.clientIdInvalid,
        };
      }
    }

    return errors;
  };

  const onRegister = (staffs: StaffUser[]) => {
    const putData: rest.StaffsPutParam = [];
    for (let i = 0; i < staffs.length; i += 1) {
      const user: definitions["User"] = {
        id: 0,
        email: staffs[i].email,
        password: staffs[i].password,
        name: staffs[i].name,
      };
      const staff: definitions["Staff"] = {
        id: 0,
        user_id: 0,
        client_id: staffs[i].client_id,
        name: staffs[i].name,
        name_kana: staffs[i].name_kana,
        phone: staffs[i].phone,
        group: staffs[i].group,
        area: staffs[i].area,
        email_pc: staffs[i].email_pc,
        is_quitted: false,
      };
      putData.push({ user, staff });
    }
    const staff = new rest.Staff();
    staff
      .put(putData, auth.getToken())
      .then((ret: object) => {
        props.showGlobalNotification &&
          props.showGlobalNotification(labels.succeed);
      })
      .catch(() => {
        props.showGlobalNotification &&
          props.showGlobalNotification(labels.failed);
      });
  };

  react.useEffect(() => {
    if (!state.initialized) {
      state.initialized = true;
      new rest.Client()
        .getAll(auth.getToken())
        .then((json: rest.ClientsGetResponse) => {
          state.clients = json;
          for (let i = 0; i < json.length; i += 1) {
            const client = json[i];
            state.clientIdMap[client.name] = client.id;
            state.clientNameMap[client.id] = client.name;
          }
          setState({ ...state });
        });
    }
  });

  return (
    <ui.Container>
      <ui.Grid container spacing={2} xs={12}>
        <ui.Grid item xs={12} />
        <CsvTable<StaffUser>
          colLabels={staffPropertyLabelMap}
          default={staffTemplate}
          rule={Object.assign(rules.user, rules.staff)}
          titleMediator={renderColumnTitle}
          dataMediator={dataMediator}
          sampleMediator={renderSampleValue}
          dataRenderer={renderColumnData}
          customValidator={validateUniqueEmail}
          onRegister={onRegister}
        />
      </ui.Grid>
    </ui.Container>
  );
}

export { BulkInsert };
