import react from "react";
import * as ui from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import * as auth from "../../auth";
import * as rest from "../../rest";
import { resources, controls } from "../../const/resource";
import { definitions } from "../../schema/api";
import { ClientCompanySelector } from "../../components/client_company_selector";
import { RecordList } from "../../components/record_list";
import { Loading } from "../../components/loading";

import NoteAdd from "@material-ui/icons/NoteAdd";

const labels: { [key: string]: string } = {
  create: "新規登録",
  name: "氏名",
  nameKana: "氏名カナ",
  notFound: "指定した会社に担当者が登録されていません",
};

type RetrieveState = {
  initialized: boolean;
  clientCompanyId: number;
  clientReaders: definitions["ClientReader"][];
  clientCompanies: definitions["ClientCompany"][];
};

function Retrieve() {
  const navigate = useNavigate();
  const [state, setState] = react.useState<RetrieveState>({
    initialized: false,
    clientCompanyId: 0,
    clientReaders: [],
    clientCompanies: [],
  });

  const onRowClicked = auth.isAuthorized(
    resources.client_reader.identifier,
    auth.resourceCrudFlag.update
  )
    ? (clientReader: definitions["ClientReader"]) => {
      navigate(`/${resources.client_reader.identifier}/${controls.update.identifier}/${clientReader.id}`);
      }
    : undefined;

  const onClientCompanyChanged = (clientCompanyId: number) => {
    state.clientCompanyId = clientCompanyId;
    refreshClientReaders().then(() => setState({ ...state }));
  };

  const refreshClientReaders = () => {
    return new rest.ClientReader()
      .getAllByClientCompanyId(state.clientCompanyId, auth.getToken())
      .then((json: rest.ClientReadersGetResponse) => {
        state.clientReaders = json;
      });
  };

  react.useEffect(() => {
    if (!state.initialized) {
      state.initialized = true;

      new rest.ClientCompany()
        .getAll(auth.getToken())
        .then((json: rest.ClientCompaniesGetResponse) => {
          state.clientCompanies = json;
          if (state.clientCompanies && state.clientCompanies.length !== 0) {
            state.clientCompanyId = state.clientCompanies[0].id;
          }
        })
        .then(() => {
          if (state.clientCompanyId === 0) {
            return;
          }
          return refreshClientReaders();
        })
        .then(() => setState({ ...state }));
    }
  });

  if (!state.initialized) {
    return <Loading />;
  }

  return (
    <ui.Container>
      <ui.Grid container spacing={4}>
        <ui.Grid item xs={12}></ui.Grid>
        <ui.Grid item xs={6}>
          <ClientCompanySelector
            clientCompanies={state.clientCompanies}
            selectedValue={state.clientCompanyId}
            onChanged={onClientCompanyChanged}
          />
        </ui.Grid>
        <ui.Grid item xs={6}>
          {auth.isAuthorized(
            resources.client_reader.identifier,
            auth.resourceCrudFlag.create
          ) && (
            <ui.Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              height="100%"
            >
              <ui.IconButton
                onClick={(e: any) =>
                  (window.location.href = `/${resources.client_reader.identifier}/${controls.create.identifier}`)
                }
              >
                <NoteAdd />
                <ui.Typography variant="body2">{labels.create}</ui.Typography>
              </ui.IconButton>
            </ui.Box>
          )}
        </ui.Grid>
        <ui.Grid item xs={12}>
          <RecordList<definitions["ClientReader"]>
            defaultText={labels.notFound}
            cols={[
              { label: labels.name, key: "name" },
              { label: labels.nameKana, key: "name_kana" },
            ]}
            items={state.clientReaders}
            onRowClicked={onRowClicked}
          />
        </ui.Grid>
      </ui.Grid>
    </ui.Container>
  );
}

export { Retrieve };
