import * as ui from "@material-ui/core";

const labels = { month: "月" };

type MonthSelectorProps = {
  onChanged: (month: number) => void;
  value: number;
};

function createMonth(): JSX.Element[] {
  const doms = [];
  for (let month = 1; month <= 12; month += 1) {
    doms.push(
      <ui.MenuItem key={`month${month}`} value={month}>
        {month}
      </ui.MenuItem>
    );
  }
  return doms;
}

function MonthSelector(props: MonthSelectorProps) {
  return (
    <>
      <ui.InputLabel id="month-id-label">{labels.month}</ui.InputLabel>
      <ui.Select
        labelId="month-id-label"
        id="month-id"
        name="month"
        value={props.value}
        onChange={(e: any) => props.onChanged(parseInt(e.target.value, 10))}
      >
        {createMonth()}
      </ui.Select>
    </>
  );
}

export { MonthSelector };
