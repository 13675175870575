import react from "react";
import { useParams } from "react-router-dom";
import * as ui from "@material-ui/core";
import * as auth from "../../auth";
import * as rest from "../../rest";
import { createStaff } from "../../const/definitions/staff";
import { createUser } from "../../const/definitions/user";
import { roles } from "../../const/role";
import { definitions } from "../../schema/api";
import { Loading } from "../../components/loading";
import { Form } from "./form";

const labels: { [key: string]: string } = {
  succeed: "登録しました",
  failed: "登録に失敗しました",
};

type UpdateState = {
  user: definitions["User"];
  staff: definitions["Staff"];
  client?: definitions["Client"];
  clientCompany?: definitions["ClientCompany"];
  clients: rest.ClientsGetResponse;
  clientCompanies: definitions["ClientCompany"][];
  clientCompanyId: number;
  updateLoginInfo: boolean;
  initialized: boolean;
};
type UpdateProps = {
  showGlobalNotification?: (message: string) => void;
};

type UpdateParams = {
  id: string;
};

function Update(props: UpdateProps) {
  const params = useParams<UpdateParams>();
  const staffId = parseInt(params.id as string, 10);
  const [state, setState] = react.useState<UpdateState>({
    user: createUser(),
    staff: createStaff(),
    clients: [],
    clientCompanies: [],
    clientCompanyId: 0,
    updateLoginInfo: false,
    initialized: false,
  });

  const refreshClients = (clientCompanyId: number) => {
    return new rest.Client()
      .getAllByClientCompanyId(clientCompanyId, [], auth.getToken())
      .then((json: rest.ClientsGetResponse) => {
        if (state.staff.client_id > 0) {
          const client = json.find(
            (client) => client.id === state.staff.client_id
          );
          if (!client) {
            state.staff.client_id = json.length > 0 ? json[0].id : 0;
          }
        }
        state.clients = json;
      });
  };

  react.useEffect(() => {
    if (!state.initialized) {
      state.initialized = true;
      Promise.all([
        new rest.User()
          .getMe(["Client.ClientCompany"], auth.getToken())
          .then((user: rest.UserGetMeResponse) => {
            const role = auth.getRole();
            if (role?.roleName === roles.client.identifier) {
              state.client = user.client!;
              state.clientCompany = user.client!.client_company;
            }
          }),
        new rest.Staff()
          .get(staffId, ["Client"], auth.getToken())
          .then((json: rest.StaffGetIdResponse) => {
            state.staff = json;
            state.clientCompanyId = json.client?.client_company_id || 0;
            delete state.staff.client;
          }),
        new rest.ClientCompany()
          .getAll([], auth.getToken())
          .then((json: rest.ClientCompaniesGetResponse) => {
            state.clientCompanies = json;
          }),
      ])
        .then(() =>
          Promise.all([
            refreshClients(state.clientCompanyId),
            new rest.User()
              .getSensitive(state.staff.user_id, true, [], auth.getToken())
              .then((json: definitions["User"]) => {
                state.user = json;
              }),
          ])
        )
        .then(() => setState({ ...state }));
    }
  });

  const onNameChanged = (name: string) => {
    state.user.name = name;
    state.staff.name = name;
    setState({ ...state });
  };

  const onChanged = (
    attribute: "name_kana" | "phone" | "area" | "group" | "email_pc",
    value: string
  ) => {
    state.staff[attribute] = value;
    setState({ ...state });
  };

  const onEmailChanged = (email: string) => {
    state.user.email = email;
    setState({ ...state });
  };
  const onPasswordChanged = (password: string) => {
    state.user.password = password;
    setState({ ...state });
  };

  const onClientChanged = (clientId: number) => {
    state.staff.client_id = clientId;
    setState({ ...state });
  };
  const onClientCompanyChanged = (id: number) => {
    state.clientCompanyId = id;
    refreshClients(state.clientCompanyId).then(() => setState({ ...state }));
  };
  const onIsQuittedChanged = (quitted: boolean) => {
    state.staff.is_quitted = quitted;
    setState({ ...state });
  };

  const onUserFormEnabled = () => {
    state.updateLoginInfo = true;
    setState({ ...state });
  };

  const onSubmit = () => {
    const postStaff = { ...state.staff };
    delete postStaff.stores;
    const postUser = { ...state.user };
    delete postUser.admin;
    delete postUser.client;
    delete postUser.client_reader;
    delete postUser.role;
    delete postUser.staff;

    new rest.Staff()
      .postSensitive(
        staffId,
        state.updateLoginInfo,
        { staff: postStaff, user: postUser },
        auth.getToken()
      )
      .then((ret: object) => {
        props.showGlobalNotification &&
          props.showGlobalNotification(labels.succeed);
      })
      .catch(() => {
        props.showGlobalNotification &&
          props.showGlobalNotification(labels.failed);
      });
  };

  if (!state.initialized) {
    return <Loading />;
  }

  return (
    <ui.Container>
      <ui.Grid
        container
        spacing={0}
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        <Form
          clientCompanyId={state.clientCompanyId}
          clients={state.clients}
          client={state.client}
          clientCompany={state.clientCompany}
          user={state.user}
          staff={state.staff}
          onUserFormEnabled={onUserFormEnabled}
          onClientCompanyChanged={onClientCompanyChanged}
          onClientChanged={onClientChanged}
          onNameChanged={onNameChanged}
          onNameKanaChanged={(v) => onChanged("name_kana", v)}
          onGroupChanged={(v) => onChanged("group", v)}
          onAreaChanged={(v) => onChanged("area", v)}
          onEmailPcChanged={(v) => onChanged("email_pc", v)}
          onPhoneChanged={(v) => onChanged("phone", v)}
          onEmailChanged={onEmailChanged}
          onIsQuittedChanged={onIsQuittedChanged}
          onPasswordChanged={onPasswordChanged}
          onSubmit={onSubmit}
        />
      </ui.Grid>
    </ui.Container>
  );
}

export { Update };
