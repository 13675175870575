import * as ui from "@material-ui/core";
import { Link } from "react-router-dom";
import * as auth from "../../../auth";
import { resources, controls, Names } from "../../../const/resource";
import { roles } from "../../../const/role";

import NoteAdd from "@material-ui/icons/NoteAdd";
import ViewList from "@material-ui/icons/ViewList";
import Publish from "@material-ui/icons/Publish";

const controlIcon: { [key: string]: JSX.Element } = Object.freeze({
  create: <NoteAdd fontSize="large" />,
  retrieve: <ViewList fontSize="large" />,
  bulk_insert: <Publish fontSize="large" />,
});

interface ResourceItemProps {
  flag: number;
  resource: string;
  onClicked?: (e: any) => void;
}

function hasFlas(flags: number, flag: number): boolean {
  return (flags & flag) === flag;
}

function isHideMenu(
  resourceId: string,
  flag: number,
  role: auth.Role | null
): boolean {
  if (resourceId === resources.report_image.identifier) {
    if (flag === auth.resourceCrudFlag.create) {
      return true;
    }
  }

  if (
    resourceId === resources.calendar.identifier &&
    flag === auth.resourceCrudFlag.retrieve &&
    role?.roleName === roles.staff.identifier
  ) {
    return true;
  }
  return false;
}

function createItem(
  resource: string,
  control: Names,
  callback?: (e: any) => void
): JSX.Element {
  return (
    <ui.IconButton
      key={`${resource}-${control.identifier}`}
      component={Link}
      to={`/${resource}/${control.identifier}`}
    >
      {controlIcon[control.identifier] || ""}
    </ui.IconButton>
  );
}

function ResourceItem(props: ResourceItemProps) {
  const role = auth.getRole();
  const items: JSX.Element[] = [];
  if (hasFlas(props.flag, auth.resourceCrudFlag.create)) {
    if (!isHideMenu(props.resource, auth.resourceCrudFlag.create, role)) {
      if (
        props.resource === resources.store.identifier ||
        props.resource === resources.chain.identifier ||
        props.resource === resources.staff.identifier
      ) {
        items.push(
          createItem(props.resource, controls.bulk_insert, props.onClicked)
        );
      }
      items.push(createItem(props.resource, controls.create, props.onClicked));
    }
  }
  if (hasFlas(props.flag, auth.resourceCrudFlag.retrieve)) {
    if (!isHideMenu(props.resource, auth.resourceCrudFlag.retrieve, role)) {
      items.push(
        createItem(props.resource, controls.retrieve, props.onClicked)
      );
    }
  }

  return <div>{items}</div>;
}

export { ResourceItem };
export type { ResourceItemProps };
