import { locales } from "../const/locale";

function fromRFC3339ToYmd(rfc3399: string): string {
  const d = new Date(rfc3399);
  return `${d.getFullYear()}/${d.getMonth() + 1}/${d.getDate()}`;
}

function fromRFC3339ToMd(rfc3399: string): string {
  const d = new Date(rfc3399);
  return `${d.getMonth() + 1}/${d.getDate()}`;
}

function toRFC3339(date: Date): string {
  const d = new Date(date);
  const iso = d.toISOString();
  return `${iso.substring(0, iso.length - 1)}+00:00`;
}

function toTextInputCalendarFormat(date: Date): string {
  const year = date.getFullYear();
  const month = `${date.getMonth() + 1}`.padStart(2, "0");
  const day = `${date.getDate()}`.padStart(2, "0");
  return `${year}-${month}-${day}`;
}
function toLocaleFormat(date: Date, locale: string): string {
  if (locale === locales.ja) {
    const year = date.getFullYear();
    const month = `${date.getMonth() + 1}`.padStart(2, "0");
    const day = `${date.getDate()}`.padStart(2, "0");
    return `${year}/${month}/${day}`;
  }

  // default
  const year = date.getFullYear();
  const month = `${date.getMonth() + 1}`.padStart(2, "0");
  const day = `${date.getDate()}`.padStart(2, "0");
  return `${year}/${month}/${day}`;
}
function fromTextInputCalendarFormat(
  date: string,
  timeZone: string = "+09:00"
): Date {
  return new Date(`${date}T00:00:00${timeZone}`);
}

function weekDayToText(day: number): string {
  switch (day) {
    case 0:
      return "日";
    case 1:
      return "月";
    case 2:
      return "火";
    case 3:
      return "水";
    case 4:
      return "木";
    case 5:
      return "金";
    case 6:
      return "土";
  }

  return "";
}

function isZeroDate(dateStr: string): boolean {
  if (dateStr === "") {
    return true;
  }
  return new Date(dateStr).getFullYear() < 1970;
}

export {
  fromRFC3339ToYmd,
  fromRFC3339ToMd,
  toRFC3339,
  toTextInputCalendarFormat,
  toLocaleFormat,
  fromTextInputCalendarFormat,
  weekDayToText,
  isZeroDate,
};
