import react from "react";
import * as ui from "@material-ui/core";
import * as Papa from "papaparse";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import * as validator from "../validation/validator";
import { utf8bom, sampleCsvFileName } from "../const/file";
import { downloadAsFile } from "../window";
import { Buffer } from "buffer";

const labels = {
  buttonRegister: "CSVファイルを選択...",
  buttonDownload: "登録済みデータをダウンロード",
  buttonSample: "サンプルをダウンロード",
  confirmed: "OK",
  row: "行",
  register: "登録",
  confirmRegister: "一括登録します、よろしいですか？",
  rowUnit: "行目",
  errorTitle: "CSV エラー",
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorText: { color: theme.palette.error.main },
    errorBackground: {
      backgroundColor: theme.palette.error.main,
      color: "white",
      fontWeight: "bold",
    },
  })
);

type DataMediator<T> = (col: string, value: string, ref: T) => void;
type TitleMediator = (col: string) => string;
type SampleMediator = (col: string) => string | null;
type DataRenderer<T> = (col: string, data: T) => string;
type CustomValidator = (datum: string[][]) => {
  [row: number]: validator.ValidationResult;
};

type CsvTableState<T> = {
  datum: T[];
  errors: { [row: number]: validator.ValidationResult };
  acknowledged: boolean;
};

type CsvTableProps<T> = {
  colLabels: { [name: string]: string };
  default: T;
  rule: validator.ValidationRule;
  onDownloadClicked?: () => void;
  acknowledgementText?: string;
  titleMediator?: TitleMediator;
  dataMediator?: DataMediator<T>;
  sampleMediator?: SampleMediator;
  dataRenderer?: DataRenderer<T>;
  customValidator?: CustomValidator;
  onRegister: (datum: T[]) => void;
};

function parseCsv<T>(
  csv: string,
  cols: string[],
  rule: validator.ValidationRule,
  defaultData: T,
  mediator?: DataMediator<T>,
  customValidator?: CustomValidator
): { data: T[]; errors: { [row: number]: validator.ValidationResult } } {
  const errors: { [row: number]: validator.ValidationResult } = {};
  const datum: T[] = [];

  const parsed = Papa.parse(csv);
  for (let i = 1; i < parsed.data.length; i += 1) {
    const data: T = { ...defaultData };
    const row = parsed.data[i] as string[];
    // ignore empty line
    if (row.length === 1 && row[0] === "") {
      continue;
    }

    if (mediator) {
      for (let j = 0; j < cols.length; j += 1) {
        mediator(cols[j], row[j], data);
      }
    } else {
      for (let j = 0; j < cols.length; j += 1) {
        (data as any)[cols[j]] = row[j];
      }
    }

    const results = validator.validate(data, rule);
    const propertyNames = Object.keys(results);

    for (let j = 0; j < propertyNames.length; j += 1) {
      const propertyName = propertyNames[j];
      const fieldResult = results[propertyName];
      if (!fieldResult.reason) {
        delete results[propertyNames[j]];
      }
    }

    if (Object.keys(results).length > 0) {
      errors[i] = results;
    }

    datum.push(data);
  }

  if (customValidator) {
    const customErrors = customValidator(parsed.data as string[][]);
    const rowNumbers = Object.keys(customErrors);
    for (let i = 0; i < rowNumbers.length; i += 1) {
      const rowNumber = parseInt(rowNumbers[i], 10);
      if (!errors[rowNumber]) {
        errors[rowNumber] = {};
      }

      const fields = Object.keys(customErrors[rowNumber]);

      for (let j = 0; j < fields.length; j += 1) {
        const field = fields[j];
        if (!errors[rowNumber][field]) {
          errors[rowNumber][field] = customErrors[rowNumber][field];
        }
      }
    }
  }

  return { errors, data: datum };
}

function renderDataRows<T>(
  cols: string[],
  datum: T[],
  classes: any,
  errors: { [row: number]: validator.ValidationResult },
  renderer?: DataRenderer<T>
): JSX.Element[] {
  return datum.map((data: T, index: number) => (
    <ui.TableRow key={`data-${index}`}>
      <ui.TableCell id={`data-table-row-${index + 1}`}>
        {index + 1}
      </ui.TableCell>
      {cols.map((col) => (
        <ui.TableCell
          id={`data-table-${col}-${index + 1}`}
          key={`data-table-${col}-${index + 1}`}
          className={
            !!(errors[index + 1] && errors[index + 1][col])
              ? classes.errorBackground
              : ""
          }
        >
          {renderer ? renderer(col, data) : (data as any)[col]}
        </ui.TableCell>
      ))}
    </ui.TableRow>
  ));
}

function CsvTable<T>(props: CsvTableProps<T>) {
  const classes = useStyles();
  const [state, setState] = react.useState<CsvTableState<T>>({
    datum: [],
    errors: {},
    acknowledged: !props.acknowledgementText,
  });

  const cols = Object.keys(props.colLabels);

  const onDownloadClicked = () => {
    props.onDownloadClicked!();
  };

  const onSampleClicked = (event: any) => {
    const physicalNames = Object.keys(props.colLabels);
    const logicalNames = props.titleMediator
      ? Object.keys(props.colLabels).map((col) => props.titleMediator!(col))
      : physicalNames.map((name) => props.colLabels[name]);

    const sampleLine = physicalNames
      .map((name) => {
        const validators = props.rule[name];
        if (!validators) {
          return "";
        }

        if (props.sampleMediator) {
          const sample = props.sampleMediator(name);
          if (sample !== null) {
            return sample;
          }
        }

        const validatorWithSamples = validators.filter((v) => v.sample);
        if (validatorWithSamples.length === 0) {
          return "";
        }

        const v = validators.find(
          (v) => v.identifier !== validator.validators.required.identifier
        );
        if (v) {
          return v.sample;
        }

        return validatorWithSamples[0].sample;
      })
      .join(",");

    const csvContent = [logicalNames.join(","), sampleLine].join("\n");

    const utf8CsvBuffer = Buffer.concat([
      Buffer.from(utf8bom),
      Buffer.from(csvContent, "utf-8"),
    ]);

    downloadAsFile(utf8CsvBuffer, sampleCsvFileName);
  };

  const onFileChange = (event: any) => {
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsText(event.target.files[0]);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    }).then((csv: any) => {
      state.errors = {};
      state.datum = [];

      const { data, errors } = parseCsv<T>(
        csv,
        cols,
        props.rule,
        props.default,
        props.dataMediator,
        props.customValidator
      );

      state.errors = errors;
      state.datum = data;

      event.target.value = null;

      setState({ ...state });
    });
  };

  const onAcknowledgeChange = (acknowledged: boolean) => {
    state.acknowledged = acknowledged;
    setState({ ...state });
  };

  const errorRows = Object.keys(state.errors);

  return (
    <ui.Grid container item spacing={2}>
      <ui.Grid container item xs={12} spacing={2} direction="row">
        {props.onDownloadClicked && (
          <ui.Grid item>
            <ui.Button
              variant="contained"
              component="label"
              onClick={onDownloadClicked}
            >
              {labels.buttonDownload}
            </ui.Button>
          </ui.Grid>
        )}
        <ui.Grid item>
          <ui.Button
            variant="contained"
            component="label"
            onClick={onSampleClicked}
          >
            {labels.buttonSample}
          </ui.Button>
        </ui.Grid>
        <ui.Grid item>
          <ui.Button variant="contained" component="label">
            {labels.buttonRegister}
            <input
              type="file"
              accept="text/csv"
              hidden
              onChange={onFileChange}
            />
          </ui.Button>
        </ui.Grid>
        <ui.Grid item>
          <ui.Button
            variant="contained"
            color="primary"
            disabled={
              state.datum.length === 0 ||
              errorRows.length > 0 ||
              !state.acknowledged
            }
            onClick={() => {
              if (window.confirm(labels.confirmRegister)) {
                props.onRegister(state.datum);
              }
            }}
          >
            {labels.register}
          </ui.Button>
        </ui.Grid>

        {props.acknowledgementText && (
          <ui.Grid item>
            <ui.Grid item>
              <ui.Typography variant="caption">
                {props.acknowledgementText}
              </ui.Typography>
            </ui.Grid>

            <ui.Grid item>
              <ui.FormControlLabel
                key="acknowledgement"
                control={
                  <ui.Checkbox
                    value="1"
                    onChange={(e) => onAcknowledgeChange(e.target.checked)}
                    checked={state.acknowledged}
                    tabIndex={-1}
                    disableRipple
                  />
                }
                label={labels.confirmed}
              />
            </ui.Grid>
          </ui.Grid>
        )}
      </ui.Grid>

      {errorRows.length > 0 && (
        <ui.Grid className={classes.errorText} item xs={12}>
          <ui.Typography variant="h6">{labels.errorTitle}</ui.Typography>
          {errorRows.map((rowNumber: string, index: number) => {
            const doms = [];
            const results = state.errors[parseInt(rowNumber, 10)]!;
            const propertyNames = Object.keys(results);
            for (let i = 0; i < propertyNames.length; i += 1) {
              const propertyName = propertyNames[i];
              doms.push(
                <ui.Box key={`data-table-${propertyName}-${rowNumber}`}>
                  <ui.Link
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      window.location.href = `#data-table-${propertyName}-${rowNumber}`;
                    }}
                  >
                    {props.titleMediator
                      ? props.titleMediator(propertyName)
                      : props.colLabels[propertyName]}
                  </ui.Link>
                  : {results[propertyName].reason}
                </ui.Box>
              );
            }
            return doms.length > 0 ? (
              <ui.Box>
                <ui.Typography variant="caption">{`${rowNumber}${labels.rowUnit}`}</ui.Typography>
                {doms}
              </ui.Box>
            ) : null;
          })}
        </ui.Grid>
      )}

      <ui.Grid item xs={12}>
        <ui.TableContainer component={ui.Paper} style={{ width: "100%" }}>
          <ui.Table
            stickyHeader
            size="small"
            aria-label="data-table"
            style={{ whiteSpace: "nowrap", overflow: "scroll" }}
          >
            <ui.TableHead>
              <ui.TableRow>
                <ui.TableCell>{labels.row}</ui.TableCell>
                {cols.map((col) => (
                  <ui.TableCell key={`table-header-${col}`}>
                    {props.titleMediator
                      ? props.titleMediator(col)
                      : props.colLabels[col]}
                  </ui.TableCell>
                ))}
              </ui.TableRow>
            </ui.TableHead>
            <ui.TableBody>
              {renderDataRows<T>(
                cols,
                state.datum,
                classes,
                state.errors,
                props.dataRenderer
              )}
            </ui.TableBody>
          </ui.Table>
        </ui.TableContainer>
      </ui.Grid>
    </ui.Grid>
  );
}

export { CsvTable };
