import { request, methods } from "./request";
import { definitions, paths } from "../schema/api";

type ReportsGetParam = paths["/reports"]["get"]["parameters"]["query"];
type ReportsGetYearMonthParam =
  paths["/reports/{year}/{month}"]["get"]["parameters"]["query"];
type ReportsGetYearMonthRangeParam =
  paths["/reports/year_month_range"]["get"]["parameters"]["query"];
type ReportsPutYearMonthDateParam =
  paths["/reports/{year}/{month}/{date}"]["put"]["parameters"]["body"]["reports"];
type ReportsGetResponse = paths["/reports"]["get"]["responses"][200]["schema"];
type ReportsPutYearMonthDateResponse =
  paths["/reports/{year}/{month}/{date}"]["put"]["responses"][200]["schema"];
type ReportPostIdResponse =
  paths["/report/{id}"]["post"]["responses"][200]["schema"];
type ReportGetResponse =
  paths["/report/{id}"]["get"]["responses"][200]["schema"];
type ReportsGetYearMonthResponse = definitions["Report"][];
type ReportsGetYearMonthRangeResponse =
  paths["/reports/year_month_range"]["get"]["responses"][200]["schema"];
type ReportDeleteResponse =
  paths["/report/{id}"]["delete"]["responses"][200]["schema"];

class Report {
  get(
    id: number,
    arg2: string[],
    arg3: string = ""
  ): Promise<ReportGetResponse | definitions["Response404"]> {
    let params = {};
    let token = "";
    if (typeof arg2 === "string") {
      token = arg2;
    } else {
      params = { scope: arg2.join(",") };
      token = arg3;
    }

    return request({
      token,
      params,
      resource: `report/${id}`,
      method: methods.Get,
    });
  }
  getAll(
    arg1: ReportsGetParam | string | string[],
    arg2: string | string[] = "",
    arg3: string = ""
  ): Promise<ReportsGetResponse> {
    let params: ReportsGetParam = {};
    let token: string = "";

    if (typeof arg1 === "string") {
      token = arg1;
    } else if (arg1.constructor.name === "Array") {
      params.scope = (arg1 as string[]).join(",");
      token = arg2 as string;
    } else {
      params = arg1 as ReportsGetParam;
      if (arg2.constructor.name === "Array") {
        params.scope = (arg2 as string[]).join(",");
        token = arg3;
      } else {
        token = arg2 as string;
      }
    }

    return request({
      token,
      params,
      resource: "reports",
      method: methods.Get,
    });
  }
  getYearMonth(
    year: number,
    month: number,
    arg3: ReportsGetYearMonthParam | string,
    arg4: string = ""
  ): Promise<ReportsGetYearMonthResponse> {
    let params: ReportsGetYearMonthParam = {};
    const headers: { Accept?: string } = {};
    let token = "";
    if (typeof arg3 === "string") {
      token = arg3;
    } else {
      params = arg3;
      token = arg4;
    }

    if (params.format === "csv") {
      headers.Accept = "text/csv";
    }

    return request({
      token,
      headers,
      params,
      resource: `reports/${year}/${month}`,
      method: methods.Get,
    });
  }
  getRange(
    arg1: ReportsGetYearMonthRangeParam | string,
    arg2: string = ""
  ): Promise<ReportsGetYearMonthRangeResponse> {
    let params = {};
    let token = "";
    if (typeof arg1 === "string") {
      token = arg1;
    } else {
      params = arg1;
      token = arg2;
    }
    return request({
      token,
      params,
      resource: `reports/year_month_range`,
      method: methods.Get,
    });
  }
  putYearMonthDate(
    year: number,
    month: number,
    date: number,
    params: ReportsPutYearMonthDateParam,
    token: string = ""
  ): Promise<ReportsPutYearMonthDateResponse> {
    return request({
      token,
      params,
      resource: `reports/${year}/${month}/${date}`,
      method: methods.Put,
    });
  }
  post(
    id: number,
    report: definitions["Report"],
    token: string = ""
  ): Promise<ReportPostIdResponse> {
    return request({
      token,
      params: report,
      resource: `report/${id}`,
      method: methods.Post,
    });
  }
  delete(id: number, token: string): Promise<ReportDeleteResponse> {
    return request({
      token,
      resource: `report/${id}`,
      method: methods.Delete,
    });
  }
  check(reportId: number, userId: number, token: string) {
    return request({
      token,
      params: { report_id: reportId, user_id: userId },
      resource: `report/check`,
      method: methods.Put,
    });
  }
}

export { Report };
export type {
  ReportGetResponse,
  ReportsGetResponse,
  ReportsGetYearMonthParam,
  ReportsGetYearMonthResponse,
  ReportsGetYearMonthRangeResponse,
  ReportsPutYearMonthDateParam,
  ReportsPutYearMonthDateResponse,
  ReportPostIdResponse,
};
