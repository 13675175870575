import react from "react";
import * as ui from "@material-ui/core";
import { definitions } from "../schema/api";
import * as auth from "../auth";
import * as rest from "../rest";

const labels: { [key: string]: string } = {
  clientCompany: "クライアント会社選択",
};

interface ClientCompanySelectorProps {
  clientCompanies?: definitions["ClientCompany"][];
  selectedValue: number;
  onChanged: (e: number) => void;
}

type ClientCompanySelectorState = {
  initialized: boolean;
  selectedValue: number;
  clientCompanies: definitions["ClientCompany"][];
};

function clientCompaniesToMenuItems(
  clientCompanies: definitions["ClientCompany"][]
): JSX.Element[] {
  const doms = [];
  for (let i = 0; i < clientCompanies.length; i += 1) {
    const clientCompany = clientCompanies[i];
    doms.push(
      <ui.MenuItem
        key={`clientcompany${clientCompany.id}`}
        value={clientCompany.id}
      >
        {clientCompany.name}
      </ui.MenuItem>
    );
  }
  return doms;
}

function ClientCompanySelector(props: ClientCompanySelectorProps) {
  const [state, setState] = react.useState<ClientCompanySelectorState>({
    initialized: false,
    selectedValue: props.selectedValue,
    clientCompanies: props.clientCompanies || [],
  });

  react.useEffect(() => {
    if (props.clientCompanies) {
      state.clientCompanies = props.clientCompanies;
      state.initialized = true;
      setState({ ...state });
    } else {
      (async () => {
        const json: rest.ClientCompaniesGetResponse = await (new rest.ClientCompany()).getAll(auth.getToken());
        state.clientCompanies = json;
        const hasSelectedValue = !!json.find(({ id }) => id === state.selectedValue);
        if (!hasSelectedValue) {
          state.selectedValue = json[0].id;
        }
        state.initialized = true;
        setState(Object.assign({}, state));
        if (!hasSelectedValue) {
          props.onChanged(state.selectedValue);
        }
      })();
    }
  }, []);

  return (
    <ui.Box>
      <ui.InputLabel id="clientcompany-id-label">
        {labels.clientCompany}
      </ui.InputLabel>
      <ui.Select
        fullWidth
        labelId="clientcompany-id-label"
        id="clientcompany-id"
        name="clientcompany_id"
        value={props.selectedValue || state.selectedValue || ""}
        onChange={(e: any) => {
          props.onChanged(parseInt(e.target.value, 10));
        }}
      >
        {clientCompaniesToMenuItems(state.clientCompanies)}
      </ui.Select>
    </ui.Box>
  );
}

export { ClientCompanySelector };
