type StorageDescription = {
  key: string;
  expires: number;
};
const storage: { [key: string]: StorageDescription } = Object.freeze({
  successNotification: {
    key: "success_notification",
    expires: 5,
  },
  token: {
    key: "storelinktoken",
    expires: 24 * 60 * 60,
  },
  authorization: {
    key: "storelinkauth",
    expires: 24 * 60 * 60,
  },
  role: {
    key: "storelinkrole",
    expires: 24 * 60 * 60,
  },
});

export { storage };
