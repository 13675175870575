type Names = { identifier: string; label: string; shortLabel: string };

const resources: { [resource: string]: Names } = Object.freeze({
  chain: {
    identifier: "chain",
    label: "チェーンマスター",
    shortLabel: "チェーン",
  },
  store: {
    identifier: "store",
    label: "店舗マスター",
    shortLabel: "店舗",
  },
  client: {
    identifier: "client",
    label: "管理者",
    shortLabel: "管理者",
  },
  client_company: {
    identifier: "client_company",
    label: "クライアント企業",
    shortLabel: "企業",
  },
  client_reader: {
    identifier: "client_reader",
    label: "クライアント担当者",
    shortLabel: "担当者",
  },
  staff: {
    identifier: "staff",
    label: "スタッフ",
    shortLabel: "スタッフ",
  },
  calendar: {
    identifier: "calendar",
    label: "訪問計画表",
    shortLabel: "計画表",
  },
  report: {
    identifier: "report",
    label: "日報",
    shortLabel: "日報",
  },
  report_image: {
    identifier: "report_image",
    label: "日報画像",
    shortLabel: "画像",
  },
  individual_report: {
    identifier: "individual_report",
    label: "指示書別日報",
    shortLabel: "指示書別日報",
  },
  instruction: {
    identifier: "instruction",
    label: "指示書",
    shortLabel: "指示書",
  },
  work_time: {
    identifier: "work_time",
    label: "出退勤時間",
    shortLabel: "出退勤",
  },
  notice_board: {
    identifier: "notice_board",
    label: "掲示板",
    shortLabel: "掲示板",
  },
});
const controls: { [control: string]: Names } = Object.freeze({
  copy: {
    identifier: "copy",
    label: "複製",
    shortLabel: "複製",
  },
  create: {
    identifier: "create",
    label: "作成",
    shortLabel: "作成",
  },
  retrieve: {
    identifier: "retrieve",
    label: "一覧",
    shortLabel: "一覧",
  },
  show: {
    identifier: "show",
    label: "詳細",
    shortLabel: "詳細",
  },
  update: {
    identifier: "update",
    label: "更新",
    shortLabel: "更新",
  },
  inherit: {
    identifier: "inherit",
    label: "引き継ぎ",
    shortLabel: "引継",
  },
  remove: {
    identifier: "delete",
    label: "削除",
    shortLabel: "削除",
  },
  bulk_insert: {
    identifier: "bulk_insert",
    label: "一括登録",
    shortLabel: "一括登録",
  },
  work_time_retrieve: {
    identifier: "work_time_retrieve",
    label: "出退勤時間",
    shortLabel: "出退勤",
  },
});

export { resources, controls };
export type { Names };
