import * as ui from "@material-ui/core";
import { definitions } from "../../../schema/api";
import { IndividualInstructionReport } from "./individual_instruction_report";

interface IndividualInstructionReportsProps {
  confirm?: boolean;
  instructions: definitions["Instruction"][];
  individualReports: definitions["IndividualReport"][];
  onChanged: (individualReports: definitions["IndividualReport"][]) => void;
}

function IndividualInstructionReports(
  props: IndividualInstructionReportsProps
) {
  const onIndividualReportChanged = (
    report: definitions["IndividualReport"],
    instructionId: number
  ) => {
    for (let i = 0; i < props.individualReports.length; i += 1) {
      const individualReport = props.individualReports[i];
      if (individualReport.instruction_id === instructionId) {
        props.individualReports[i] = report;
        props.onChanged(props.individualReports);
        return;
      }
    }
  };

  return (
    <ui.Container>
      <ui.Grid
        container
        spacing={4}
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        {props.individualReports.map(
          (individualReport: definitions["IndividualReport"]) => {
            for (let i = 0; i < props.instructions.length; i += 1) {
              if (
                props.instructions[i].id === individualReport.instruction_id
              ) {
                return (
                  <ui.Grid item xs={12} key={individualReport.id}>
                    <IndividualInstructionReport
                      confirm={props.confirm}
                      key={individualReport.instruction_id}
                      instruction={props.instructions[i]}
                      individualReport={individualReport}
                      onChanged={(report: definitions["IndividualReport"]) =>
                        onIndividualReportChanged(
                          report,
                          individualReport.instruction_id
                        )
                      }
                    />
                  </ui.Grid>
                );
              }
            }
            return <div />;
          }
        )}
      </ui.Grid>
    </ui.Container>
  );
}

export { IndividualInstructionReports };
export type { IndividualInstructionReportsProps };
