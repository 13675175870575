import * as ui from "@material-ui/core";

const labels = {
  loading: "読み込み中...",
};

function Loading() {
  return (
    <ui.Container>
      <ui.Grid
        container
        spacing={0}
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        <ui.Grid container spacing={4}>
          <ui.Grid item xs={12}></ui.Grid>
          <ui.Grid item xs={12}>
            <ui.Typography variant="h6">{labels.loading}</ui.Typography>
          </ui.Grid>
        </ui.Grid>
      </ui.Grid>
    </ui.Container>
  );
}

export { Loading };
