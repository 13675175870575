import { definitions } from "../../schema/api";

const createStaff = (): definitions["Staff"] => {
  return {
    id: 0,
    user_id: 0,
    client_id: 0,
    name: "",
    name_kana: "",
    phone: "",
    email_pc: "",
    area: "",
    group: "",
    is_quitted: false,
  };
};

export { createStaff };
